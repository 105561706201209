import {
    RosterDates,
    CarerHolidayModel,
    CreateCarerHoliday,
  } from "../../app/types";
import axios, { AxiosInstance } from "axios";
import { getToken } from "../account/sessionManager";
import environment from "../../environment";

const api: AxiosInstance = axios.create({
    baseURL: environment.apiBaseAddress,
    validateStatus: (status) => true,
    timeout: 20000,
});

export function fetchCarerHolidays(dates: RosterDates) {
    const token = getToken();
    let headers = {};
    headers = { Authorization: `Bearer ${token}` };
    let config = { headers: headers };
  
    return new Promise<CarerHolidayModel[]>((resolve, reject) =>
      api
        .get(
          "/carer-holidays?startDate=" +
            dates.startDate +
            "&endDate=" +
            dates.endDate,
          config
        )
        .then((response) => {
          switch (response.status) {
            case 200:
              resolve(response.data);
              break;
            case 401:
              reject("Request failed for supplied credentials.");
              break;
            case 500:
              reject(
                "API is reporting a technical problem. Please try again later."
              );
              break;
            default:
              reject(
                "Unexpected error returned from API. Please try again later"
              );
          }
        })
        .catch((error) => {
          reject(
            "Unexpected error trying to contact the server. Please check the connection or try again later."
          );
        })
    );
}

export function postCarerHoliday(newCarerHoliday: CreateCarerHoliday) {
    const token = getToken();
    let headers = {};
    headers = { Authorization: `Bearer ${token}` };
  
    let config = {
      headers: headers,
    };
  
    return new Promise<CarerHolidayModel>((resolve, reject) =>
      api
        .post("/carer-holidays", newCarerHoliday, config)
        .then((response) => {
          switch (response.status) {
            case 201:
              resolve(response.data);
              break;
            case 400:
              reject(response.data);
              break;
            case 401:
              reject("Request failed for supplied credentials.");
              break;
            case 500:
              reject(
                "API is reporting a technical problem. Please try again later."
              );
              break;
            default:
              reject(
                "Unexpected error returned from API. Please try again later"
              );
          }
        })
        .catch((error) => {
          reject(
            "Unexpected error trying to contact the server. Please check the connection or try again later."
          );
        })
    );
  }

  export function deleteCarerHoliday(id: string) {
    const token = getToken();
    let headers = {};
    headers = { Authorization: `Bearer ${token}` };
  
    let config = {
      headers: headers,
    };
  
    return new Promise((resolve, reject) =>
      api
        .delete("/carer-holidays/" + id, config)
        .then((response) => {
          switch (response.status) {
            case 200:
              resolve(response.data);
              break;
            case 401:
              reject("Request failed for supplied credentials.");
              break;
            case 500:
              reject(
                "API is reporting a technical problem. Please try again later."
              );
              break;
            default:
              reject(
                "Unexpected error returned from API. Please try again later"
              );
          }
        })
        .catch((error) => {
          reject(
            "Unexpected error trying to contact the server. Please check the connection or try again later."
          );
        })
    );
  }