import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { deleteShiftAssignmentAsync, selectDeleteModalFor, shiftAssignmentFormState, DeleteShiftAssignmentModel, hideDeleteModal } from "./ShiftAssignmentSlice";
import moment from "moment";
import ViewDeleteShiftAssignmentModal from './ViewDeleteShiftAssignmentModal';
import { RosteredShift } from "../../app/types";

export type IProps = {
    rosters: Map<string, Map<string, RosteredShift>> | undefined;
}

export default function DeleteShiftAssignmentModal(props: IProps) {
    const deleteModalFor: DeleteShiftAssignmentModel | undefined = useAppSelector(selectDeleteModalFor);
    const [associatedShift, setAssociatedShift] = useState<RosteredShift | undefined>(undefined);

    const currentShiftAssignmentFormState = useAppSelector(shiftAssignmentFormState);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const handleCloseModal = useCallback(() => {
        dispatch(hideDeleteModal());
    }, [dispatch]);

    useEffect(() => {
        if (deleteModalFor) {
            const rostersForDate: Map<string, RosteredShift> | undefined = props.rosters ? props.rosters.get(moment(deleteModalFor.shiftAssignment.date).format('YYYY-MM-DD')) : undefined;
            const rosteredShift: RosteredShift | undefined = rostersForDate ? rostersForDate.get(deleteModalFor.shiftAssignment.shiftId) : undefined;
            if (rosteredShift && (!associatedShift || associatedShift.shiftId !== rosteredShift.shiftId)) {
                setAssociatedShift(rosteredShift);
            }
        }
        if (!deleteModalFor && associatedShift !== undefined) {
            setAssociatedShift(undefined);
        }
    }, [props.rosters, deleteModalFor, associatedShift]);

    useEffect(() => {
        if (currentShiftAssignmentFormState === "success" && deleteModalFor !== undefined) {
            handleCloseModal();
        }

        if (currentShiftAssignmentFormState === "sending") {
             setLoading(true);
        }

        if (currentShiftAssignmentFormState === "default") {
             setLoading(false);
        }
    }, 
    [
        currentShiftAssignmentFormState,
        deleteModalFor,
        dispatch,
        handleCloseModal,
    ]);

    const onFormSubmit = () => {
        if (deleteModalFor) {
            let shiftAssignment = deleteModalFor.shiftAssignment;
            dispatch(deleteShiftAssignmentAsync(shiftAssignment.id));
        }
    }

    return (
        <ViewDeleteShiftAssignmentModal
            showModal={deleteModalFor !== undefined}
            shiftAssignmentDate={deleteModalFor ? moment(deleteModalFor.shiftAssignment.date).format("MMM Do, YYYY") : undefined}
            duration={deleteModalFor ? deleteModalFor.duration : undefined}
            carerFirstname={deleteModalFor ? deleteModalFor.carer.firstName : ''}
            carerSurname={deleteModalFor ? deleteModalFor.carer.surname : ''}
            clientFirstname={deleteModalFor ? deleteModalFor.client.firstName : ''}
            clientSurname={deleteModalFor ? deleteModalFor.client.surname : ''}
            loading={loading}
            associatedShiftActive={associatedShift ? associatedShift.isActive : undefined}
            assignedCarers={associatedShift ? associatedShift.assignedCarerIds.length : undefined}
            requiredCarers={associatedShift ? associatedShift.numberOfCarers : undefined}
            onFormSubmit={onFormSubmit}
            handleCloseModal={handleCloseModal}
        />
    );
}