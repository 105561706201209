import { useAppSelector } from '../../app/hooks';
import moment, { Moment } from 'moment';
import { useMediaQuery } from 'react-responsive';
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";
import { selectRole } from "../account/accountSlice";
import RosterNavigationView from "./view";
import { selectClientFilter, selectedClient } from '../clients/clientSlice';
import { selectBranchFilter } from '../branches/branchSlice';

export type IProps = {
    date: Moment;
    showClients: boolean;
    showFilters: boolean;
    showClientsButtonAction: () => void;
    showFiltersButtonAction: () => void;
    closeMenuButtonAction: () => void;
}

export default function RosterNavigation(props: IProps) {
    const appInsights = useAppInsightsContext();
    useTrackMetric(appInsights, "NavigationBar");

    const userRole = useAppSelector(selectRole);

    const clientSelected = useAppSelector(selectedClient); 
    const filterOnClientId = useAppSelector(selectClientFilter);
    const filterOnBranchId = useAppSelector(selectBranchFilter);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    let firstDayToShow: Moment = moment(props.date).weekday(1); // Desktop - show from Monday

    let numberOfDaysToShow = 7;
    if (isTabletOrMobile) {
        numberOfDaysToShow = 2; // Big Mobile or Tablet show two days
        firstDayToShow = moment(props.date); // Tablet or mobile start at current date
    }

    if (isMobile) {
        numberOfDaysToShow = 1; // Mobile show 1 day
        firstDayToShow = moment(props.date); // Mobile start at current date
    }

    const endDate = moment(firstDayToShow).add(numberOfDaysToShow -1, 'days');
    const dateString = `${numberOfDaysToShow !== 1 ? firstDayToShow.format("MMM Do -") : ''} ${moment(endDate).format("MMM Do, YYYY")}`;
    const previous = moment(props.date).subtract(numberOfDaysToShow, 'days').format('YYYY-MM-DD').toString();
    const next = moment(props.date).add(numberOfDaysToShow, 'days').format('YYYY-MM-DD').toString();
    const today = moment().format('YYYY-MM-DD').toString();
   
    return (
        <RosterNavigationView
            dateString={dateString}
            today={today}
            next={next}
            previous={previous}
            showClients={props.showClients}
            showFilters={props.showFilters}
            userRole={userRole}
            clientSelectionApplied={clientSelected ? true : false}
            filterApplied={filterOnClientId || filterOnBranchId ? true : false}
            showClientsButtonAction={props.showClientsButtonAction}
            showFiltersButtonAction={props.showFiltersButtonAction}
            closeMenuButtonAction={props.closeMenuButtonAction}
        />
    )
}