import { Row, Col, Card } from "react-bootstrap";
import ClientList from '../clients';
import RotaFilters from '../rota-filters';
import NavigationBar from '../roster-navigation';
import { CarerModel } from '../carers/carerSlice';
import moment, { Moment } from "moment";
import DayHeading from './DayHeading';
import OpenShiftsDay from './OpenShiftsDay';
import { CarerHolidayModel, RosteredShift, ShiftAssignment } from "../../app/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/pro-duotone-svg-icons";
import DeleteRosteredShiftPopup from '../shift/DeletePopup';
import ViewShiftModal from '../shift/ViewShiftModal';
import AutomaticShiftAssignmentModal from '../automatic-shift-assignment/AutomaticShiftAssignmentModal';
import ClientModal from '../clients/ClientModal';
import ShiftForm from '../shift/ShiftForm';
import ShiftAssignmentModal from "../shift-assignment/ShiftAssignmentModal";
import ShiftAssignmentTimeModal from "../shift-assignment/ShiftAssignmentTimeModal";
import ShiftAssignmentRateModal from "../shift-assignment/ShiftAssignmentRateModal";
import DeleteShiftAssignmentModal from "../shift-assignment/DeleteShiftAssignmentModal";
import ShiftTimeModal from '../shift/ShiftTimeModal';
import RotaCarerRow from './RotaCarerRow';

export type IProps = {
    isSticky: boolean;
    date: string;
    days: Moment[];
    carers: CarerModel[];
    accountCarerId: string | undefined;
    userRole: string | string[] | null;
    showOtherCarersStatus: boolean;
    showClients: boolean;
    showFilters: boolean;
    shiftsRowRef: any;
    rosters: Map<string, Map<string, RosteredShift>>;
    filterOnClientId: string | undefined;
    filterOnBranchId: string | undefined;
    shiftAssignmentDictionary: Map<string, Map<string, Map<string, ShiftAssignment>>>;
    carerHolidaysAsDictionary: Map<string, Map<string, Map<string, CarerHolidayModel>>>;
    showClientsButtonAction: () => void;
    showFiltersButtonAction: () => void;
    closeMenuButtonAction: () => void;
    handleHideOtherCarers: () => void;
    handleShowOtherCarers: () => void;
} 

function RosterView(props: IProps) {
    let clientsColumnClasses: string = 'border-end h-100';
    let filtersColumnClasses: string = 'border-end h-100';

    if (!props.showClients) {
        clientsColumnClasses = 'd-none';
    }

    if (!props.showFilters) {
        filtersColumnClasses = 'd-none';
    }

    let clientsArea;
    let filtersArea;
    if (props.userRole) {
        // If they have at least one claim then they need access to the clients so they can add shifts
        clientsArea = (
            <Col sm="12" lg="2" className={clientsColumnClasses}>
                <ClientList />
            </Col>
        );

        // If they have at least one claim then they need access to the filters
        filtersArea = (
            <Col sm="12" lg="2" className={filtersColumnClasses}>
                <RotaFilters />
            </Col>
        );
    }

    let rosterFooter = (<></>);
    if (!props.userRole && props.accountCarerId) {
        rosterFooter = (
            <Card.Footer className="mt-1">
                <Row>
                    <Col>
                        {props.showOtherCarersStatus ? <FontAwesomeIcon icon={faToggleOn} className="pointer text-fhah-green" size="lg" onClick={props.handleHideOtherCarers} /> : <FontAwesomeIcon icon={faToggleOff} className="pointer" size="lg" onClick={props.handleShowOtherCarers}  />} Show associated carers
                    </Col>
                </Row>
            </Card.Footer>
        );
    }

    let stickyRow = (
        <Row className="g-0">
            <Col xs="12" sm="2" md="2" className="w-12-5 border-end border-bottom py-3 bg-light d-none d-md-block"></Col>
            {props.days.map((x, index) => (
                <DayHeading key={`dayHeading-${index}`} date={x} />
            ))}
        </Row>
    );
    if (props.isSticky && !props.showClients && !props.showFilters) {
        stickyRow = (
            <Row className="fixed-top px-1 gx-0">
                <Col xs="12" sm="2" md="2" className="w-12-5 border-end border-bottom py-3 bg-light d-none d-md-block"></Col>
                {props.days.map((x, index) => (
                    <DayHeading key={`dayHeading-${index}`} date={x} />
                ))}
            </Row>
        )
    }
    if (props.isSticky && (props.showClients || props.showFilters)) {
        stickyRow = (
            <Row className="fixed-top px-1 gx-0">
                <Col className="border-end col-lg-2 d-none d-lg-block py-3"></Col>
                <Col className="">
                    <Row className="g-0">
                        <Col xs="12" sm="2" md="2" className="w-12-5 border-end border-bottom py-3 bg-light d-none d-md-block"></Col>
                        {props.days.map((x, index) => (
                            <DayHeading key={`dayHeading-${index}`} date={x} />
                        ))}
                    </Row>
                </Col>
            </Row>
        )
    }
    return (
        <Card>
            <Card.Body className="pb-0">
                <Row>
                    <Col className="pb-2 position-relative">
                        <NavigationBar
                            date={moment(props.date)}
                            showClients={props.showClients}
                            showFilters={props.showFilters}
                            showClientsButtonAction={props.showClientsButtonAction}
                            showFiltersButtonAction={props.showFiltersButtonAction}
                            closeMenuButtonAction={props.closeMenuButtonAction}
                        />
                    </Col>
                </Row>
                <Row>
                    {filtersArea}
                    {clientsArea}
                    <Col className="border-top">
                        <Row>
                            {stickyRow}
                            <Row className="g-0">
                                <Col xs="12" sm="2" md="2" className="w-12-5 border-end border-bottom py-2 bg-success text-white">Open Shifts</Col>
                                {props.days.map((x, index) => (
                                    <OpenShiftsDay 
                                        key={`openShiftsDay-${index}`}
                                        filterOnClientId={props.filterOnClientId}
                                        filterOnBranchId={props.filterOnBranchId}
                                        isCarer={!props.userRole}
                                        date={x}
                                        rosteredShifts={props.rosters ? props.rosters.get(moment(x).format('YYYY-MM-DD')) : undefined }
                                    />
                                ))}
                            </Row>
                        </Row>
                        <Row ref={props.shiftsRowRef}>
                            {props.carers && props.carers.length > 0 && props.carers.map((x: CarerModel) => {
                                let shiftsForCarer = props.shiftAssignmentDictionary.get(x.id);
                                let holidaysForCarer = props.carerHolidaysAsDictionary.get(x.id);
                                let totalHours = 0;
                                if (shiftsForCarer) {
                                    shiftsForCarer.forEach((shiftForDateMap: Map<string, ShiftAssignment>) => {
                                        shiftForDateMap.forEach((shiftAssignment: ShiftAssignment) => {
                                            let hours = moment(shiftAssignment.shiftLength, "HH:mm:ss").hours();
                                            let mins = moment(shiftAssignment.shiftLength, "HH:mm:ss").minutes();
                                            totalHours = Number(totalHours) + Number(hours) + Number(mins/60);
                                        });
                                    });
                                }

                                return (
                                    <RotaCarerRow
                                        key={x.id+"rota-carer-row"}
                                        carer={x}
                                        accountCarerId={props.accountCarerId}
                                        totalHours={totalHours}
                                        userRole={props.userRole}
                                        days={props.days}
                                        rosters={props.rosters}
                                        shiftsForCarer={shiftsForCarer}
                                        holidaysForCarer={holidaysForCarer}
                                        filterOnClientId={props.filterOnClientId}
                                        filterOnBranchId={props.filterOnBranchId}
                                    />
                                );
                            })}
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
            {rosterFooter}
            <DeleteRosteredShiftPopup />
            <ClientModal />
            <ViewShiftModal />
            <AutomaticShiftAssignmentModal/>
            <ShiftForm />
            <ShiftAssignmentModal rosters={props.userRole ? props.rosters : undefined} />
            <ShiftAssignmentTimeModal />
            <ShiftAssignmentRateModal />
            <DeleteShiftAssignmentModal rosters={props.userRole ? props.rosters : undefined} />
            <ShiftTimeModal/>
        </Card>
    )
}

export default RosterView;