import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ClientModel, showClientModal } from '../clients/clientSlice';
import { RosteredShift, SelectedShift } from '../../app/types';
import { currentSelectedShift, resetSelectedShift, setSelectedShift } from '../shift-assignment/ShiftAssignmentSlice';
import { ShiftFormModel, showDeletePopup, showShiftFormModal, showShiftModal, showAutomaticShiftAssignmentModal, showShiftTimeModal } from './RosteredShiftSlice';
import { useEffect, useState } from 'react';
import ShiftView from './ShiftView';

export type IProps = {
    shift: RosteredShift;
    client: ClientModel;
    isCarer: boolean;
}

function Shift(props: IProps) {
    const selectedShift = useAppSelector(currentSelectedShift);
    const dispatch = useAppDispatch();

    const [selectedShiftIsThisShift, setSelectedShiftIsThisShift] = useState<boolean>(false);

    useEffect(() => {
        if (selectedShift !== null && moment(props.shift.shiftStart).isSame(selectedShift.date, "day") && props.shift.shiftId === selectedShift.shiftId && !selectedShiftIsThisShift) {
            setSelectedShiftIsThisShift(true);
        } else if (selectedShift === null && selectedShiftIsThisShift) {
            setSelectedShiftIsThisShift(false); 
        }
    }, [selectedShift, props.shift.shiftId, props.shift.shiftStart, selectedShiftIsThisShift]);

    const handleAssign = () => {
        if (selectedShift === null) {
            let selectedShift: SelectedShift = {
                id:  null,
                shiftId: props.shift.shiftId,
                date: moment(props.shift.shiftStart).format('YYYY-MM-DD'),
                shiftVersion: props.shift.shiftVersion,
            };

            dispatch(setSelectedShift(selectedShift));
        } else {
            dispatch(resetSelectedShift());
        }
    }

    const handleShowShiftForm = () => {
        const shiftFormModel: ShiftFormModel = {
            date: moment().format("YYYY-MM-DD HH:mm"),
            client: undefined,
            id: props.shift.shiftId,
            shiftType: props.shift.shiftType,
            isEdit: true,
        }

        dispatch(showShiftFormModal(shiftFormModel));
    }

    const handleShowClientModal = () => {
        dispatch(showClientModal({client: props.client, carer: undefined}));
    }

    const handleShowShiftModal = () => {
        dispatch(showShiftModal({ id: props.shift.shiftId, shiftType: props.shift.shiftType, isCarer: props.isCarer }));
    }

    const handleShowAutomaticShiftAssignmentModal = () => {
        dispatch(showAutomaticShiftAssignmentModal({id: props.shift.shiftId, shiftType: props.shift.shiftType}));
    }

    const handleShowDeleteModal = () => {
        dispatch(showDeletePopup({ id: props.shift.shiftId, shiftType: props.shift.shiftType}));
    }

    const handleShowShiftTimeModal = () => {
        dispatch(showShiftTimeModal({ id: props.shift.shiftId, shiftType: props.shift.shiftType}));
    }

    const shiftStartTime = moment(props.shift.shiftStart).format('HH:mm');

    const shiftEndTime = moment(props.shift.shiftStart).add(moment.duration(props.shift.shiftLength).asMinutes(), 'minutes').format('HH:mm');

    const duration = `${shiftStartTime} - ${shiftEndTime}`;

    return (
        <ShiftView
            isCarer={props.isCarer}
            selectedShiftIsThisShift={selectedShiftIsThisShift}
            duration={duration}
            shiftType={props.shift.shiftType}
            numberOfCarers={props.shift.numberOfCarers}
            assignedCarers={props.shift.assignedCarerIds.length}
            clientFirstName={props.client.firstName}
            clientSurname={props.client.surname}
            clientDifficulty={props.client.clientDifficulty}
            handleAssign={handleAssign}
            handleShowShiftForm={handleShowShiftForm}
            handleShowShiftModal={handleShowShiftModal}
            handleShowClientModal={handleShowClientModal}
            handleShowAutomaticShiftAssignmentModal={handleShowAutomaticShiftAssignmentModal}
            handleShowDeleteModal={handleShowDeleteModal}
            handleShowShiftTimeModal={handleShowShiftTimeModal}
        />
    )
}

export default Shift;