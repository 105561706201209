import { CarerModel, CarerResponse, PutPayload } from "./carerSlice";
import axios, { AxiosInstance } from "axios";
import { getToken } from '../account/sessionManager';
import environment from '../../environment'

const api: AxiosInstance = axios.create({
  baseURL: environment.apiBaseAddress,
  validateStatus: (status) => true,
  timeout: 20000,
});

export function getCarers() {
  const token = getToken();

  let headers = {};

  if (!token) {
    return new Promise<CarerResponse>((resolve, reject) => {
        reject('');
    });
  }

  headers = { Authorization: `Bearer ${token}` };

  let config = { headers: headers };

  return new Promise<CarerResponse>((resolve, reject) =>
  api.get('/carers', config)
  .then((response) => {
      switch (response.status) {
          case 200:
              resolve(response.data);
              break;
          case 401:
              reject('Request failed for supplied credentials.');
              break;
          case 500:
              reject(
                  'API server is reporting a technical problem. Please try again later.'
              );
              break;
          default:
              reject(
                  'Unexpected error returned from API server. Please try again later'
              );
      }
  })
  .catch((error) => {
      reject(
          'Unexpected error trying to contact the server. Please check the connection or try again later.'
      );
  }));
}

export function putCarer(putPayload: PutPayload) {
    const token = getToken();
  
    let headers = {};
  
    if (!token) {
      return new Promise<CarerModel>((resolve, reject) => {
          reject('');
      });
    }
  
    headers = { Authorization: `Bearer ${token}` };
  
    let config = { headers: headers };
  
    return new Promise<CarerModel>((resolve, reject) =>
    api.put('/carers/' + putPayload.id, putPayload.model, config)
    .then((response) => {
        switch (response.status) {
            case 200:
                resolve(response.data);
                break;
            case 401:
                reject('Request failed for supplied credentials.');
                break;
            case 500:
                reject(
                    'API server is reporting a technical problem. Please try again later.'
                );
                break;
            default:
                reject(
                    'Unexpected error returned from API server. Please try again later'
                );
        }
    })
    .catch((error) => {
        reject(
            'Unexpected error trying to contact the server. Please check the connection or try again later.'
        );
    }));
  }