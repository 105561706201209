import {
  WeeklyRecurringShiftResponse,
  WeeklyRecurringShift,
  WeeklyRecurringShiftVersioned,
  UpdateRosteredShiftStartTimeModel,
  UpdateRosteredShiftRateModel,
} from "../../app/types";
import axios, { AxiosInstance } from "axios";
import { getToken } from "../account/sessionManager";
import environment from "../../environment";

const api: AxiosInstance = axios.create({
  baseURL: environment.apiBaseAddress,
  validateStatus: (status) => true,
  timeout: 20000,
});

export function getWeeklyRecurringShift(id: string) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<WeeklyRecurringShiftVersioned>((resolve, reject) =>
    api
      .get("/weekly-recurring-shifts/" + id, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}

export function postWeeklyRecurringShift(
  weeklyRecurringShift: WeeklyRecurringShift
) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<WeeklyRecurringShiftResponse>((resolve, reject) =>
    api
      .post("/weekly-recurring-shifts", weeklyRecurringShift, config)
      .then((response) => {
        switch (response.status) {
          case 201:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}

export function putWeeklyRecurringShift(
  weeklyRecurringShiftVersioned: WeeklyRecurringShiftVersioned
) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<WeeklyRecurringShiftResponse>((resolve, reject) =>
    api
      .put(
        "/weekly-recurring-shifts/" + weeklyRecurringShiftVersioned.id,
        weeklyRecurringShiftVersioned,
        config
      )
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}

export function patchWeeklyRecurringShiftStartTime(updateDetails: UpdateRosteredShiftStartTimeModel) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<WeeklyRecurringShiftVersioned>((resolve, reject) =>
    api
      .patch(`/weekly-recurring-shifts/${updateDetails.id}/update-start-time`, { newShiftStartTime: updateDetails.newShiftStartTime, updateFutureShiftAssignments: updateDetails.updateFutureAssignments, version: updateDetails.version }, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 201:
            resolve(response.data);
            break;
          case 400:
            reject(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}

export function patchWeeklyRecurringShiftRate(updateDetails: UpdateRosteredShiftRateModel) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<WeeklyRecurringShiftVersioned>((resolve, reject) =>
    api
      .patch(`/weekly-recurring-shifts/${updateDetails.id}/update-shift-rate`, { newShiftRateId: updateDetails.newShiftRate, updateFutureShiftAssignments: updateDetails.updateFutureAssignments, version: updateDetails.version }, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 201:
            resolve(response.data);
            break;
          case 400:
            reject(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}