class Environment {
    
    public thisAppBaseAddress : string;
    public adminPortalAppBaseAddress : string;
    public apiBaseAddress : string;
    public applicationInsightsKey: string;
    
    constructor(){
        if (process.env.NODE_ENV === 'production') {
            this.thisAppBaseAddress = (window as any).env.ROSTER_APP_BASE_ADDRESS;
            this.adminPortalAppBaseAddress = (window as any).env.PORTAL_BASE_ADDRESS;
            this.apiBaseAddress = (window as any).env.API_BASE_ADDRESS;
            this.applicationInsightsKey = (window as any).env.APPLICATION_INSIGHTS_KEY;
        }else {
            this.thisAppBaseAddress = process.env.REACT_APP_ROSTER_APP_BASE_ADDRESS as string;
            this.adminPortalAppBaseAddress = process.env.REACT_APP_PORTAL_BASE_ADDRESS as string;
            this.apiBaseAddress = process.env.REACT_APP_API_BASE_ADDRESS as string;
            this.applicationInsightsKey = process.env.REACT_APP_APPLICATION_INSIGHTS_KEY as string;
        }
    }
}

let environment = new Environment();

export default environment;