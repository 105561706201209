import { CarerModel } from '../carers/carerSlice';
import CarerCell from '../carers/CarerCell';
import CarerDay from '../carers/CarerDay';
import { Row } from "react-bootstrap";
import moment, { Moment } from "moment";
import { CarerHolidayModel, RosteredShift, ShiftAssignment } from "../../app/types";

export type IProps = {
    carer: CarerModel;
    accountCarerId: string | undefined;
    totalHours: number;
    userRole: string | string[] | null;
    days: Moment[];
    rosters: Map<string, Map<string, RosteredShift>>;
    shiftsForCarer:  Map<string, Map<string, ShiftAssignment>> | undefined;
    holidaysForCarer: Map<string, Map<string, CarerHolidayModel>> | undefined;
    filterOnClientId: string | undefined;
    filterOnBranchId: string | undefined;
}

export default function RotaCarerRow(props: IProps) {
    return (
        <Row key={`carer-cell${props.carer.id}`} className="g-0">
            <CarerCell carer={props.carer} accountCarerId={props.accountCarerId} userRole={props.userRole} totalHours={props.totalHours} daysShown={props.days.length} />
            {props.days.map((d, index) => {
                let shiftsForDate = props.shiftsForCarer ? props.shiftsForCarer.get(moment(d).format('YYYY-MM-DD')) : undefined;
                let holidaysForCarerAndDate = props.holidaysForCarer ? props.holidaysForCarer.get(moment(d).format('YYYY-MM-DD')) : undefined;

                return (<CarerDay 
                    rosterShifts={props.rosters ? props.rosters.get(moment(d).format('YYYY-MM-DD')) : undefined}
                    key={`carer-day-${props.carer.id}-${index}`}
                    filterOnClientId={props.filterOnClientId}
                    filterOnBranchId={props.filterOnBranchId}
                    carerShiftsDictionary={shiftsForDate} 
                    carerHoliday={holidaysForCarerAndDate}
                    date={d} 
                    carer={props.carer}
                    accountCarerId={props.accountCarerId}
                    userRole={props.userRole}/>)
            })}
        </Row>
    )
}