import { useCallback, useEffect, useState } from "react";
import { ShiftType } from "../../app/model";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { adHocFormState, adHocResetForm, loadedAdHocShift, putAdHocShiftAsync } from "../shift-form/AdHocShiftSlice";
import { AdHocShiftVersioned, MonthlyRecurringShiftVersioned, WeeklyRecurringShiftVersioned, } from "../../app/types";
import { getWeeklyRecurringShiftAsync, loadedWeeklyRecurringShift, putWeeklyRecurringShiftAsync, weeklyFormState, weeklyResetForm } from "../shift-form/WeeklyRecurringShiftSlice";
import { getMonthlyRecurringShiftAsync, loadedMonthlyRecurringShift, monthlyFormState, putMonthlyRecurringShiftAsync, monthlyResetForm } from "../shift-form/MonthlyRecurringShiftSlice";
import { getAdHocShiftAsync } from "../shift-form/AdHocShiftSlice";
import moment from "moment";
import { selectDeletePopupFor, selectDeletePopupForShiftType, hideDeletePopup } from './RosteredShiftSlice';
import ViewDeleteModal from './ViewDeleteModal';

export default function DeletePopup() {
    const deletePopupFor: string | undefined = useAppSelector(selectDeletePopupFor);
    const deletePopupForShiftType: string | undefined = useAppSelector(selectDeletePopupForShiftType);
    const currentAdHocFormState = useAppSelector(adHocFormState);
    const currentWeeklyFormState = useAppSelector(weeklyFormState);
    const currentMonthlyFormState = useAppSelector(monthlyFormState);
    const currentLoadedAdHoc = useAppSelector(loadedAdHocShift);
    const currentLoadedMonthly = useAppSelector(loadedMonthlyRecurringShift);
    const currentLoadedWeekly = useAppSelector(loadedWeeklyRecurringShift);
    const dispatch = useAppDispatch();
    const [version, setVersion] = useState<string>('');
    const [id, setId] = useState<string>('');
    const [clientId, setClientId] = useState<string>('');
    const [rateId, setRateId] = useState<string>('');
    const [shiftDate, setShiftDate] = useState('');
    const [shiftStartTime, setShiftStartTime] = useState('');
    const [shiftLength, setShiftLength] = useState('');
    const [shiftLength24H, setShiftLength24H] = useState(false);
    const [numberOfCarers, setNumberOfCarers] = useState(1);
    const [typeOfShift, setTypeOfShift] = useState(ShiftType.AdHoc);
    const [monday, setMonday] = useState(false);
    const [tuesday, setTuesday] = useState(false);
    const [wednesday, setWednesday] = useState(false);
    const [thursday, setThursday] = useState(false);
    const [friday, setFriday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [sunday, setSunday] = useState(false);
    const [recurrenceStart, setRecurrenceStart] = useState("");
    const [recurrenceEnd, setRecurrenceEnd] = useState("");
    const [recurrenceHasEnd, setRecurrenceHasEnd] = useState(false);
    const [recurEveryNWeeks, setRecurEveryNWeeks] = useState(1);
    const [firstFullWeekOfMonth, setFirstFullWeekOfMonth] = useState(false);
    const [secondFullWeekOfMonth, setSecondFullWeekOfMonth] = useState(false);
    const [thirdFullWeekOfMonth, setThirdFullWeekOfMonth] = useState(false);
    const [fourthFullWeekOfMonth, setFourthFullWeekOfMonth] = useState(false);
    const [lastFullWeekOfMonth, setLastFullWeekOfMonth] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseDeletePopup = useCallback(() => {
        if (deletePopupForShiftType) {
            switch(deletePopupForShiftType) {
                case "AdHocShift":
                    dispatch(adHocResetForm());
                    break;
                case "WeeklyRecurringShift":
                    dispatch(weeklyResetForm());
                    break;
                case "MonthlyRecurrringShift":
                    dispatch(monthlyResetForm());
                    break;
            }
        }

        dispatch(hideDeletePopup());
    }, [deletePopupForShiftType, dispatch])

    useEffect(() => {
        if ((currentAdHocFormState === "success" && deletePopupFor !== undefined) ||
            (currentWeeklyFormState === "success" && deletePopupFor !== undefined) || 
            (currentMonthlyFormState === "success" && deletePopupFor !== undefined)) {
                handleCloseDeletePopup();
        }

        if (currentAdHocFormState === "default" ||
            currentWeeklyFormState === "default"|| 
            currentMonthlyFormState === "default") {
                setLoading(false);
        }

        if (deletePopupFor !== undefined && deletePopupForShiftType !== undefined && currentAdHocFormState === "default" && currentMonthlyFormState === "default" && currentWeeklyFormState === "default")
        {
            switch(deletePopupForShiftType) {
                case "AdHocShift":
                    dispatch(getAdHocShiftAsync(deletePopupFor));
                    break;
                case "WeeklyRecurringShift":
                    dispatch(getWeeklyRecurringShiftAsync(deletePopupFor));
                    break;
                case "MonthlyRecurrringShift":
                    dispatch(getMonthlyRecurringShiftAsync(deletePopupFor));
                    break;
            }
        }

        if ((currentAdHocFormState === "loaded" || currentMonthlyFormState === "loaded" || currentWeeklyFormState === "loaded"))
        {
            switch(deletePopupForShiftType) {
                case "AdHocShift":
                    if (currentLoadedAdHoc !== null) {
                        setVersion(currentLoadedAdHoc.version);
                        setId(currentLoadedAdHoc.id);
                        setClientId(currentLoadedAdHoc.clientId);
                        setRateId(currentLoadedAdHoc.rateId);
                        setShiftDate(moment(currentLoadedAdHoc.shiftDate).format('YYYY-MM-DD'));
                        setShiftStartTime(moment(currentLoadedAdHoc.shiftStartTime, 'HH:mm:ss').format('HH:mm'));
                        setShiftLength(currentLoadedAdHoc.shiftLength === "1.00:00:00" ? moment(currentLoadedAdHoc.shiftLength, 'd.HH:mm:ss').format('d.HH:mm') : moment(currentLoadedAdHoc.shiftLength, 'HH:mm:ss').format('HH:mm'));
                        setShiftLength24H(currentLoadedAdHoc.shiftLength === "1.00:00:00" ? true : false);
                        setNumberOfCarers(currentLoadedAdHoc.numberOfCarers);
                        setTypeOfShift(ShiftType.AdHoc);
                        setMonday(false);
                        setTuesday(false);
                        setWednesday(false);
                        setThursday(false);
                        setFriday(false);
                        setSaturday(false);
                        setSunday(false);
                        setRecurrenceStart("");
                        setRecurrenceEnd("");
                        setRecurrenceHasEnd(false);
                        setRecurEveryNWeeks(1);
                        setFirstFullWeekOfMonth(false);
                        setSecondFullWeekOfMonth(false);
                        setThirdFullWeekOfMonth(false);
                        setFourthFullWeekOfMonth(false);
                        setLastFullWeekOfMonth(false);         
                        return;
                    }
                    break;
                case "WeeklyRecurringShift":
                    if (currentLoadedWeekly !== null) {
                        setVersion(currentLoadedWeekly.version);
                        setId(currentLoadedWeekly.id);
                        setClientId(currentLoadedWeekly.clientId);
                        setRateId(currentLoadedWeekly.rateId);
                        setShiftDate("");
                        setShiftStartTime(moment(currentLoadedWeekly.shiftStartTime, 'HH:mm:ss').format('HH:mm'));
                        setShiftLength(currentLoadedWeekly.shiftLength === "1.00:00:00" ? moment(currentLoadedWeekly.shiftLength, 'd.HH:mm:ss').format('d.HH:mm') : moment(currentLoadedWeekly.shiftLength, 'HH:mm:ss').format('HH:mm'));
                        setShiftLength24H(currentLoadedWeekly.shiftLength === "1.00:00:00" ? true : false);
                        setNumberOfCarers(currentLoadedWeekly.numberOfCarers);
                        setTypeOfShift(ShiftType.Weekly);
                        setMonday(currentLoadedWeekly.monday);
                        setTuesday(currentLoadedWeekly.tuesday);
                        setWednesday(currentLoadedWeekly.wednesday);
                        setThursday(currentLoadedWeekly.thursday);
                        setFriday(currentLoadedWeekly.friday);
                        setSaturday(currentLoadedWeekly.saturday);
                        setSunday(currentLoadedWeekly.sunday);
                        setRecurrenceStart(moment(currentLoadedWeekly.recurrenceStart).format('YYYY-MM-DD'));
                        setRecurrenceEnd(currentLoadedWeekly.recurrenceEnd !== null ? moment(currentLoadedWeekly.recurrenceEnd).format('YYYY-MM-DD') : "");
                        setRecurrenceHasEnd(currentLoadedWeekly.recurrenceEnd === null ? true : false);
                        setRecurEveryNWeeks(currentLoadedWeekly.recurEveryNWeeks);
                        setFirstFullWeekOfMonth(false);
                        setSecondFullWeekOfMonth(false);
                        setThirdFullWeekOfMonth(false);
                        setFourthFullWeekOfMonth(false);
                        setLastFullWeekOfMonth(false);      
                        return;
                    }
                    break;
                case "MonthlyRecurrringShift":
                    if (currentLoadedMonthly !== null) {
                        setVersion(currentLoadedMonthly.version);
                        setId(currentLoadedMonthly.id);
                        setClientId(currentLoadedMonthly.clientId);
                        setRateId(currentLoadedMonthly.rateId);
                        setShiftDate("");
                        setShiftStartTime(moment(currentLoadedMonthly.shiftStartTime, 'HH:mm:ss').format('HH:mm'));
                        setShiftLength(currentLoadedMonthly.shiftLength === "1.00:00:00" ? moment(currentLoadedMonthly.shiftLength, 'd.HH:mm:ss').format('d.HH:mm') : moment(currentLoadedMonthly.shiftLength, 'HH:mm:ss').format('HH:mm'));
                        setShiftLength24H(currentLoadedMonthly.shiftLength === "1.00:00:00" ? true : false);
                        setNumberOfCarers(currentLoadedMonthly.numberOfCarers);
                        setTypeOfShift(ShiftType.Monthly);
                        setMonday(currentLoadedMonthly.monday);
                        setTuesday(currentLoadedMonthly.tuesday);
                        setWednesday(currentLoadedMonthly.wednesday);
                        setThursday(currentLoadedMonthly.thursday);
                        setFriday(currentLoadedMonthly.friday);
                        setSaturday(currentLoadedMonthly.saturday);
                        setSunday(currentLoadedMonthly.sunday);
                        setRecurrenceStart(moment(currentLoadedMonthly.recurrenceStart).format('YYYY-MM-DD'));
                        setRecurrenceEnd(currentLoadedMonthly.recurrenceEnd !== null ? moment(currentLoadedMonthly.recurrenceEnd).format('YYYY-MM-DD') : "");
                        setRecurrenceHasEnd(currentLoadedMonthly.recurrenceEnd === null ? true : false);
                        setRecurEveryNWeeks(1);
                        setFirstFullWeekOfMonth(currentLoadedMonthly.firstFullWeekOfMonth);
                        setSecondFullWeekOfMonth(currentLoadedMonthly.secondFullWeekOfMonth);
                        setThirdFullWeekOfMonth(currentLoadedMonthly.thirdFullWeekOfMonth);
                        setFourthFullWeekOfMonth(currentLoadedMonthly.fourthFullWeekOfMonth);
                        setLastFullWeekOfMonth(currentLoadedMonthly.lastFullWeekOfMonth);     
                        return;
                    }
                    break;
            }
            return;
        }

        if (currentAdHocFormState === "sending" ||
            currentWeeklyFormState === "sending"|| 
            currentMonthlyFormState === "sending") {           
            setLoading(true);
        }
    }, 
    [
        currentAdHocFormState,
        currentMonthlyFormState,
        currentWeeklyFormState,
        deletePopupForShiftType,
        deletePopupFor,
        currentLoadedAdHoc,
        currentLoadedWeekly,
        currentLoadedMonthly,
        handleCloseDeletePopup,
        dispatch,
    ]);

    const currentFormState = useAppSelector(adHocFormState);
    const onFormSubmit = () => {
        switch(typeOfShift) {
            case ShiftType.Weekly: { 
                const putPayload: WeeklyRecurringShiftVersioned = {
                    clientId: String(clientId),
                    rateId: String(rateId),
                    recurrenceStart: String(recurrenceStart),
                    recurrenceEnd: recurrenceEnd === "" ? null : String(recurrenceEnd),
                    recurEveryNWeeks: recurEveryNWeeks,
                    monday: monday,
                    tuesday: tuesday,
                    wednesday: wednesday,
                    thursday: thursday,
                    friday: friday,
                    saturday: saturday,
                    sunday: sunday,
                    shiftStartTime: String(shiftStartTime),
                    shiftLength: String(shiftLength),
                    numberOfCarers: numberOfCarers,
                    isActive: false,
                    version:version,
                    id:id,
                };
                dispatch(putWeeklyRecurringShiftAsync(putPayload));
                handleResetForm();
                return;
             } 
             case ShiftType.Monthly: { 
                const putPayload: MonthlyRecurringShiftVersioned = {
                    clientId: String(clientId),
                    rateId: String(rateId),
                    recurrenceStart: String(recurrenceStart),
                    recurrenceEnd: recurrenceEnd === "" ? null : String(recurrenceEnd),
                    monday: monday,
                    tuesday: tuesday,
                    wednesday: wednesday,
                    thursday: thursday,
                    friday: friday,
                    saturday: saturday,
                    sunday: sunday,
                    shiftStartTime: String(shiftStartTime),
                    shiftLength: String(shiftLength),
                    numberOfCarers: numberOfCarers,
                    isActive: false,
                    firstFullWeekOfMonth: firstFullWeekOfMonth,
                    secondFullWeekOfMonth: secondFullWeekOfMonth,
                    thirdFullWeekOfMonth: thirdFullWeekOfMonth,
                    fourthFullWeekOfMonth: fourthFullWeekOfMonth,
                    lastFullWeekOfMonth: lastFullWeekOfMonth,
                    id: id,
                    version: version
                };
                dispatch(putMonthlyRecurringShiftAsync(putPayload));
                handleResetForm();
                break; 
             } 
             case ShiftType.AdHoc: { 
                const putPayload: AdHocShiftVersioned = {
                    clientId: String(clientId),
                    rateId: String(rateId),
                    shiftDate: String(shiftDate),
                    shiftStartTime: String(shiftStartTime),
                    shiftLength: String(shiftLength),
                    numberOfCarers: Number(numberOfCarers),
                    isActive: false,
                    version: String(version),
                    id: String(id),
                };
                dispatch(putAdHocShiftAsync(putPayload));
                handleResetForm();
                break; 
             } 
             default: { 
                break; 
             } 
        }
    }

    const handleResetForm = () => {
        setVersion('');
        setId('');
        setClientId('');
        setRateId('');
        setShiftDate('');
        setShiftStartTime('');
        setShiftLength('');
        setShiftLength24H(false);
        setNumberOfCarers(1);
        setTypeOfShift(ShiftType.AdHoc);
        setMonday(false);
        setTuesday(false);
        setWednesday(false);
        setThursday(false);
        setFriday(false);
        setSaturday(false);
        setSunday(false);
        setRecurrenceStart("");
        setRecurrenceEnd("");
        setRecurrenceHasEnd(false);
        setRecurEveryNWeeks(1);
        setFirstFullWeekOfMonth(false);
        setSecondFullWeekOfMonth(false);
        setThirdFullWeekOfMonth(false);
        setFourthFullWeekOfMonth(false);
        setLastFullWeekOfMonth(false);
    };

    let displayShiftType = "";
    switch (typeOfShift) {
        case ShiftType.AdHoc:
            displayShiftType = "AdHoc"
            break;
        case ShiftType.Weekly:
            displayShiftType = "Weekly"
            break;
        case ShiftType.Monthly:
            displayShiftType = "Monthly"
            break;
        default:
            break;
    }

    return (
        <ViewDeleteModal
            deletePopupFor={deletePopupFor}
            displayShiftType={displayShiftType}
            clientId={clientId}
            rateId={rateId}
            shiftDate={shiftDate}
            shiftStartTime={shiftStartTime}
            shiftLength={shiftLength}
            shiftLength24H={shiftLength24H}
            numberOfCarers={numberOfCarers}
            typeOfShift={typeOfShift}
            monday={monday}
            tuesday={tuesday}
            wednesday={wednesday}
            thursday={thursday}
            friday={friday}
            saturday={saturday}
            sunday={sunday}
            recurrenceStart={recurrenceStart}
            recurrenceEnd={recurrenceEnd}
            recurrenceHasEnd={recurrenceHasEnd}
            recurEveryNWeeks={recurEveryNWeeks}
            firstFullWeekOfMonth={firstFullWeekOfMonth}
            secondFullWeekOfMonth={secondFullWeekOfMonth}
            thirdFullWeekOfMonth={thirdFullWeekOfMonth}
            fourthFullWeekOfMonth={fourthFullWeekOfMonth}
            lastFullWeekOfMonth={lastFullWeekOfMonth}
            loading={loading}
            isCarer={false}
            currentFormState={currentFormState}
            handleCloseDeletePopup={handleCloseDeletePopup}
            onFormSubmit={onFormSubmit}
            handleResetForm={handleResetForm}
        />
    );
}