import { useEffect, useState, ReactElement } from "react";
import { Moment } from "moment";
import Shift from '../shift';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ClientModel, selectedClient, selectClientsAsDictionary } from "../clients/clientSlice";
import styles from './Roster.module.css';
import { RosteredShift } from "../../app/types";
import { ShiftFormModel, showShiftFormModal } from "../shift/RosteredShiftSlice";
import moment from "moment";
import OpenShiftsDayView from './OpenShiftsDayView';

export type IProps = {
    date: Moment;
    filterOnClientId: string | undefined;
    filterOnBranchId: string | undefined;
    rosteredShifts: Map<string, RosteredShift> | undefined;
    isCarer: boolean;
}

function OpenShiftsDay(props: IProps) {
    const dispatch = useAppDispatch();
    const activeClient = useAppSelector(selectedClient);
    const clientDictionary = useAppSelector(selectClientsAsDictionary);

    const [classes, setClasses] = useState<string>("w-12-5 border-end border-bottom py-2 text-uppercase bg-success text-white p-2 position-relative");

    const handleLandingStripClick = () => {
        const shiftFormModel: ShiftFormModel = {
            date: moment(props.date).format("YYYY-MM-DD HH:mm"),
            client: activeClient,
            id: undefined,
            shiftType: undefined,
            isEdit: false,
        }

        dispatch(showShiftFormModal(shiftFormModel));
    }

    useEffect(() => {      
        if (activeClient) {
            if (classes !== `w-12-5 border-end border-bottom py-2 text-uppercase text-white position-relative ${styles.selectableDay} p-2`) {
                setClasses(`w-12-5 border-end border-bottom py-2 text-uppercase text-white position-relative ${styles.selectableDay} p-2`);
            }
        } else {
            if (classes !== "w-12-5 border-end border-bottom py-2 text-uppercase bg-success text-white p-2 position-relative") {
                setClasses("w-12-5 border-end border-bottom py-2 text-uppercase bg-success text-white p-2 position-relative");
            }
        }
    }, [activeClient, classes]);

    let availableShifts: ReactElement[] = [];
    if (props.rosteredShifts !== undefined && clientDictionary !== undefined) {
        props.rosteredShifts.forEach((rosteredShift, key) => {
            let client: ClientModel | undefined = clientDictionary.get(rosteredShift.clientId);
            if (rosteredShift.isActive && client && (!rosteredShift.assignedCarerIds || rosteredShift.assignedCarerIds.length !== rosteredShift.numberOfCarers) && (!props.filterOnClientId || props.filterOnClientId === client.id) && (!props.filterOnBranchId || props.filterOnBranchId === client.branchId)) {
                availableShifts.push(<Shift key={rosteredShift.shiftId} isCarer={props.isCarer} shift={rosteredShift} client={client} />);
            }
        });
    }

    return (
        <OpenShiftsDayView
            availableShifts={availableShifts}
            classes={classes}
            handleLandingStripClick={handleLandingStripClick}
        />
    );
}

export default OpenShiftsDay;