import { memo } from "react";
import { ListGroup } from "react-bootstrap";

export type IProps = {
    clientId: string;
    clientFirstName: string;
    clientSurname: string;
    classes: string;
    onClick: () => void;
} 

function ClientListRowView(props: IProps) {
    return (
        <ListGroup.Item key={props.clientId} as="li" className={props.classes} onClick={props.onClick}>
            {props.clientFirstName} {props.clientSurname}
        </ListGroup.Item>
    );
}

export default memo(ClientListRowView);