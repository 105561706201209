import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import environment from './environment'

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();


const appInsights = new ApplicationInsights({
    config: { 
        instrumentationKey: environment.applicationInsightsKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        },
    }
});

// This stops application insights from sending telemetry in the dev environment.
if (process.env.NODE_ENV === 'production') {
    appInsights.loadAppInsights();
}

export { reactPlugin, appInsights };