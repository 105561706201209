import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SelectListItem } from "../../app/model";
import { BranchModel, selectAdminOfBranches, selectBranchFilter, setBranchFilter, resetBranchFilter } from "../branches/branchSlice";
import { ClientModel, selectClients, setClientFilter, resetClientFilter, selectClientFilter, selectClientAsBranchDictionary } from "../clients/clientSlice";
import ViewRotaFilters from "./view";

export default function RotaFilters() {
    const dispatch = useAppDispatch();
    const filterOnClientId = useAppSelector(selectClientFilter);
    const filterOnBranchId = useAppSelector(selectBranchFilter);

    const [applyingFilter, setApplyingFilter] = useState<boolean>(false);

    const branchList: BranchModel[] = useAppSelector(selectAdminOfBranches);
    let branchSelectListItems = useRef<SelectListItem[]>(branchList ? branchList.map(x => ({value:x.id, text:x.name})) : []);

    const clientList: ClientModel[] = useAppSelector(selectClients);
    let clientSelectListItems = useRef<SelectListItem[]>(clientList ? clientList.map(x => ({value:x.id, text:x.firstName +" "+ x.surname})) : []);

    const clientsForBranchDictionary = useAppSelector(selectClientAsBranchDictionary);

    useEffect(() => {
        branchSelectListItems.current = branchList ? branchList.map(x => ({value:x.id, text:x.name}))  : [];
    }, [branchList]);

    useEffect(() => {
        if (!filterOnBranchId) {
            clientSelectListItems.current = clientList ? clientList.map(x => ({value:x.id, text:x.firstName +" "+ x.surname})) : [];
            setApplyingFilter(false);
        } else if (clientsForBranchDictionary && filterOnBranchId && applyingFilter) {

            const branchClientsDictionary = clientsForBranchDictionary.get(filterOnBranchId);
            let filteredClientSelectArray: SelectListItem[] = [];
            branchClientsDictionary.forEach((clientModel: ClientModel) =>  filteredClientSelectArray.push({value:clientModel.id, text:clientModel.firstName +" "+ clientModel.surname}));
            clientSelectListItems.current = filteredClientSelectArray;
            setApplyingFilter(false);
        }
        
    }, [clientList, filterOnBranchId, clientsForBranchDictionary, applyingFilter]);

    const onBranchIdInputChange = (id: string) => {
        dispatch(resetClientFilter());
        setApplyingFilter(true);

        if (id) {
            if (id !== filterOnBranchId) {
                dispatch(setBranchFilter(id));
            }
        }
        
        if (!id && filterOnBranchId) {
            dispatch(resetBranchFilter());
        }
    }

    const onClientIdInputChange = (id: string) => {
        if (id) {
            if (id !== filterOnClientId) {
                dispatch(setClientFilter(id));
            }
        }
        
        if (!id && filterOnClientId) {
            dispatch(resetClientFilter());
        }
    }

    return (
        <ViewRotaFilters
            clientSelectListItems={clientSelectListItems.current}
            branchSelectListItems={branchSelectListItems.current}
            filteredOnClientId={filterOnClientId ? filterOnClientId : ''}
            filteredOnBranchId={filterOnBranchId ? filterOnBranchId : ''}
            applyingFilter={applyingFilter}
            onClientIdInputChange={onClientIdInputChange}
            onBranchIdInputChange={onBranchIdInputChange}
        />
    )
}