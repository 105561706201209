import { useRef } from "react";
import { Accordion, Alert, Button, Modal, Spinner } from 'react-bootstrap';
import ShiftInfo from '../shift-form/shift-info';
import { ShiftType } from "../../app/model";

export type IProps = {
    deletePopupFor: string | undefined;
    displayShiftType: string;
    clientId: string;
    rateId: string;
    shiftDate: string;
    shiftStartTime: string;
    shiftLength: string;
    shiftLength24H: boolean;
    numberOfCarers: number;
    typeOfShift: ShiftType;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    recurrenceStart: string;
    recurrenceEnd: string;
    recurrenceHasEnd: boolean;
    recurEveryNWeeks: number;
    firstFullWeekOfMonth: boolean;
    secondFullWeekOfMonth: boolean;
    thirdFullWeekOfMonth: boolean;
    fourthFullWeekOfMonth: boolean;
    lastFullWeekOfMonth: boolean;
    loading: boolean;
    isCarer: boolean;
    currentFormState: string;
    handleCloseDeletePopup: () => void;
    onFormSubmit: () => void;
    handleResetForm: () => void;
}

export default function ViewDeleteModal(props : IProps) {

    let loadingPlaceholder = useRef(<span />);
    if (props.loading) {
        loadingPlaceholder.current = (
            <div className="row">
                <Spinner
                    as="div"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    className="my-3 text-fhah-green mx-auto"
                    >
                        <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    } else {
        loadingPlaceholder.current = <></>;
    }

    return (
        <Modal show={props.deletePopupFor !== undefined} onHide={props.handleCloseDeletePopup}>
            <Modal.Header className="bg-fhah-purple text-white">
                <Modal.Title>
                    {props.displayShiftType + " Shift" }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-light">
                <Alert variant={'danger'}>
                    <h4 className="alert-heading">Are you sure</h4>
                    Are you sure you want to Delete? There is no way to undo!
                </Alert>
                {loadingPlaceholder.current}  
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Info</Accordion.Header>
                        <Accordion.Body>
                            <ShiftInfo
                                clientId={props.clientId}
                                rateId={props.rateId}
                                shiftDate={props.shiftDate}
                                shiftStartTime={props.shiftStartTime}
                                shiftLength={props.shiftLength}
                                shiftLength24H={props.shiftLength24H}
                                numberOfCarers={props.numberOfCarers}
                                typeOfShift={props.typeOfShift}
                                monday={props.monday}
                                tuesday={props.tuesday}
                                wednesday={props.wednesday}
                                thursday={props.thursday}
                                friday={props.friday}
                                saturday={props.saturday}
                                sunday={props.sunday}
                                recurrenceStart={props.recurrenceStart}
                                recurrenceEnd={props.recurrenceEnd}
                                recurrenceHasEnd={props.recurrenceHasEnd}
                                recurEveryNWeeks={props.recurEveryNWeeks}
                                firstFullWeekOfMonth={props.firstFullWeekOfMonth}
                                secondFullWeekOfMonth={props.secondFullWeekOfMonth}
                                thirdFullWeekOfMonth={props.thirdFullWeekOfMonth}
                                fourthFullWeekOfMonth={props.fourthFullWeekOfMonth}
                                lastFullWeekOfMonth={props.lastFullWeekOfMonth}
                                loading={props.loading}
                                isCarer={false}
                            ></ShiftInfo>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
            <Modal.Footer className={props.loading ? "d-none": "bg-light"}>
                    <Button variant="secondary" onClick={props.handleCloseDeletePopup}>Close</Button>
                    <Button variant="primary" disabled={props.currentFormState === "sending"}  onClick={props.onFormSubmit}>Delete</Button>
            </Modal.Footer>
        </Modal>
    );
}