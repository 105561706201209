import React from "react";
import { Moment } from "moment";
import { Col } from "react-bootstrap";

export type IProps = {
    date: Moment;
}

export default function DayHeading(props: IProps) {
    return (
        <Col xs="12" sm="5" className="w-12-5 border-end border-bottom py-2 text-uppercase bg-light p-1 roster-day-heading">{props.date.format('ddd D')}</Col>
    );
}