import { TokenInfo } from "../../app/types";
import jwtDecode from "jwt-decode";
import moment from "moment";
import FhahHub from "../../app/signal-r/fhah-hub";

const storageKeyTokenInfo = "RosterAppTokenInfo";
const storageKeyToken = "RosterAppToken";
let tokenTimeoutId: number | null = null;

export function validTokenLoginCheck(
  loginAction: () => void,
  logOutAction: () => void
) {
  const tokenInfo: TokenInfo | null = getStoredTokenInfo();

  if (tokenInfo) {
    if (moment(Number(tokenInfo.exp) * 1000).isAfter(moment())) {
      // There is a token which has not expired so re-login
      loginAction();
      return;
    }
  }

  logOutAction();
  return;
}

export function decodeToken(token: string): TokenInfo | null {
  const decodedToken: any = jwtDecode(token);

  let nameidentifier: string = "";
  let name: string = "";
  let givenname: string = "";
  let surname: string = "";
  let role: string = "";
  let exp: number = 0;
  let iss: string = "";
  let aud: string = "";
  let carerId: string = "";
  let userInfoId: string = "";

  for (var i in decodedToken) {
    switch (i) {
      case "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier":
        nameidentifier = decodedToken[i];
        break;
      case "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name":
        name = decodedToken[i];
        break;
      case "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname":
        givenname = decodedToken[i];
        break;
      case "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname":
        surname = decodedToken[i];
        break;
      case "http://schemas.microsoft.com/ws/2008/06/identity/claims/role":
        role = decodedToken[i];
        break;
      case "exp":
        exp = decodedToken[i];
        break;
      case "iss":
        iss = decodedToken[i];
        break;
      case "aud":
        aud = decodedToken[i];
        break;
      case "http://schemas.friendshelpingathome.co.uk/identity/claims/carerid":
        carerId = decodedToken[i];
        break;
      case "http://schemas.friendshelpingathome.co.uk/identity/claims/userinfoid":
        userInfoId = decodedToken[i];
        break;
      default:
        break;
    }
  }

  let tokenInfo: TokenInfo = {
    nameidentifier,
    name,
    givenname,
    surname,
    role,
    exp,
    iss,
    aud,
    carerId,
    userInfoId,
  };

  window.sessionStorage.removeItem(storageKeyTokenInfo);
  window.sessionStorage.setItem(storageKeyTokenInfo, JSON.stringify(tokenInfo));

  window.sessionStorage.removeItem(storageKeyToken);
  window.sessionStorage.setItem(storageKeyToken, token);

  return tokenInfo;
}

export function getStoredTokenInfo(): TokenInfo | null {
  const storedToken = window.sessionStorage.getItem(storageKeyTokenInfo);
  if (!storedToken) {
    return null;
  }

  return JSON.parse(storedToken) as TokenInfo;
}

export function getToken(): string | null {
  const storedToken = window.sessionStorage.getItem(storageKeyToken);
  if (!storedToken) {
    return null;
  }

  return storedToken;
}

export function loggedOut() {
  FhahHub.stopConnection();
  clearTimeout();
  clearTokens();
}

export function resetTokenAccessTimer(
  tokenInfo: TokenInfo,
  action: () => void
) {
  if (tokenTimeoutId) {
    window.clearTimeout(tokenTimeoutId);
  }

  if (!tokenInfo) {
    return;
  }

  // 5 minute grace beyond token timeout time before we attempt to refresh
  let msUntilRefresh = moment(Number(tokenInfo.exp) * 1000)
    .add(-5, "minute")
    .diff(moment(), "ms");

  tokenTimeoutId = window.setTimeout(action, msUntilRefresh);
}

function clearTimeout() {
  if (tokenTimeoutId) {
    window.clearTimeout(tokenTimeoutId);
  }
}

function clearTokens() {
  window.sessionStorage.removeItem(storageKeyToken);
  window.sessionStorage.removeItem(storageKeyTokenInfo);
}
