import {
  AdHocShift,
  AdHocShiftResponse,
  AdHocShiftVersioned,
} from "../../app/types";
import axios, { AxiosInstance } from "axios";
import { getToken } from "../account/sessionManager";
import "../../environment";
import environment from "../../environment";

const api: AxiosInstance = axios.create({
  baseURL: environment.apiBaseAddress,
  validateStatus: (status) => true,
  timeout: 20000,
});

export function getAdHocShift(id: string) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<AdHocShiftVersioned>((resolve, reject) =>
    api
      .get("/ad-hoc-shifts/" + id, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}

export function postAdHocShift(adHocShift: AdHocShift) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  let data = {
    clientId: adHocShift.clientId,
    rateId: adHocShift.rateId,
    shiftDate: adHocShift.shiftDate,
    shiftStartTime: adHocShift.shiftStartTime,
    shiftLength: adHocShift.shiftLength,
    numberOfCarers: adHocShift.numberOfCarers,
    isActive: adHocShift.isActive,
  };

  return new Promise<AdHocShiftResponse>((resolve, reject) =>
    api
      .post("/ad-hoc-shifts", data, config)
      .then((response) => {
        switch (response.status) {
          case 201:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}

export function putAdHocShift(adHocShiftVersioned: AdHocShiftVersioned) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  let data = {
    clientId: adHocShiftVersioned.clientId,
    rateId: adHocShiftVersioned.rateId,
    shiftDate: adHocShiftVersioned.shiftDate,
    shiftStartTime: adHocShiftVersioned.shiftStartTime,
    shiftLength: adHocShiftVersioned.shiftLength,
    numberOfCarers: adHocShiftVersioned.numberOfCarers,
    isActive: adHocShiftVersioned.isActive,
    version: adHocShiftVersioned.version,
    id: adHocShiftVersioned.id,
  };

  return new Promise<AdHocShiftResponse>((resolve, reject) =>
    api
      .put("/ad-hoc-shifts/" + data.id, data, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}
