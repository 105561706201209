import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  WeeklyRecurringShiftVersioned,
  WeeklyRecurringShiftResponse,
  WeeklyRecurringShift,
  UpdateRosteredShiftStartTimeModel,
  UpdateRosteredShiftRateModel,
} from "../../app/types";
import {
  getWeeklyRecurringShift,
  postWeeklyRecurringShift,
  putWeeklyRecurringShift,
  patchWeeklyRecurringShiftStartTime,
  patchWeeklyRecurringShiftRate,
} from "./WeeklyRecurringShiftApi";
import { toast, Flip } from "react-toastify";

export interface WeeklyRecurringShiftState {
  weeklyRecurringShiftResponse:
    | WeeklyRecurringShiftResponse
    | WeeklyRecurringShiftVersioned
    | null;
  status: "idle" | "loading" | "failed";
  formState: "success" | "error" | "sending" | "default" | "loaded";
  patchStartTimeStatus: "initial" | "idle" | "loading" | "success" | "failed";
  patchRateStatus: "initial" | "idle" | "loading" | "success" | "failed";
}

const initialState: WeeklyRecurringShiftState = {
  weeklyRecurringShiftResponse: null,
  status: "idle",
  formState: "default",
  patchStartTimeStatus: "initial",
  patchRateStatus: "initial",
};

export const getWeeklyRecurringShiftAsync = createAsyncThunk(
  "weekly-recurring-shifts/getWeeklyRecurringShift",
  async (id: string) => {
    const response = await getWeeklyRecurringShift(id);
    return response;
  }
);

export const postWeeklyRecurringShiftAsync = createAsyncThunk(
  "weekly-recurring-shifts/postWeeklyRecurringShift",
  async (postPayload: WeeklyRecurringShift) => {
    const response = await postWeeklyRecurringShift(postPayload);
    return response;
  }
);

export const putWeeklyRecurringShiftAsync = createAsyncThunk(
  "weekly-recurring-shifts/putWeeklyRecurringShift",
  async (putPayload: WeeklyRecurringShiftVersioned) => {
    const response = await putWeeklyRecurringShift(putPayload);
    return response;
  }
);

export const patchWeeklyRecurringShiftStartTimeAsync = createAsyncThunk(
  "weekly-recurring-shifts/patchWeeklyRecurringShiftStartTime",
  async (patchPayload: UpdateRosteredShiftStartTimeModel) => {
      const response = await patchWeeklyRecurringShiftStartTime(patchPayload);
      return response;
    }
);

export const patchWeeklyRecurringShiftRateAsync = createAsyncThunk(
  "weekly-recurring-shifts/patchWeeklyRecurringShiftRate",
  async (patchPayload: UpdateRosteredShiftRateModel) => {
      const response = await patchWeeklyRecurringShiftRate(patchPayload);
      return response;
    }
);

export const weeklyRecurringShiftSlice = createSlice({
  name: "weeklyRecurringShift",
  initialState,
  reducers: {
    weeklyResetForm: (state) => {
      state.weeklyRecurringShiftResponse = null;
      state.formState = "default";
      state.status = "idle";
      state.patchStartTimeStatus = 'idle';
      state.patchRateStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWeeklyRecurringShiftAsync.pending, (state) => {
        state.status = "loading";
        state.formState = "sending";
      })
      .addCase(getWeeklyRecurringShiftAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.formState = "loaded";
        state.weeklyRecurringShiftResponse = action.payload;
      })
      .addCase(getWeeklyRecurringShiftAsync.rejected, (state, action) => {
        state.status = "idle";
        state.formState = "error";
        state.weeklyRecurringShiftResponse = null;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "validation-error",
        });
      })
      .addCase(postWeeklyRecurringShiftAsync.pending, (state) => {
        state.status = "loading";
        state.formState = "sending";
      })
      .addCase(postWeeklyRecurringShiftAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.formState = "success";
        state.weeklyRecurringShiftResponse = action.payload;
      })
      .addCase(postWeeklyRecurringShiftAsync.rejected, (state, action) => {
        state.status = "idle";
        state.formState = "error";
        state.weeklyRecurringShiftResponse = null;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "validation-error",
        });
      })
      .addCase(putWeeklyRecurringShiftAsync.pending, (state) => {
        state.status = "loading";
        state.formState = "sending";
      })
      .addCase(putWeeklyRecurringShiftAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.formState = "success";
        state.weeklyRecurringShiftResponse = action.payload;
      })
      .addCase(putWeeklyRecurringShiftAsync.rejected, (state, action) => {
        state.status = "idle";
        state.formState = "error";
        state.weeklyRecurringShiftResponse = null;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "validation-error",
        });
      })
      .addCase(patchWeeklyRecurringShiftStartTimeAsync.pending, (state) => {
        state.patchStartTimeStatus = "loading";
      })
      .addCase(patchWeeklyRecurringShiftStartTimeAsync.fulfilled, (state, action) => {
        state.patchStartTimeStatus = "success";
        toast.info("Update Complete", {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 1000,
          toastId: "successful-weekly-recurring-shift-update",
        });
      })
      .addCase(patchWeeklyRecurringShiftStartTimeAsync.rejected, (state, action) => {
        state.patchStartTimeStatus = "failed";
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful-weekly-recurring-shift-update",
        });
      })
      .addCase(patchWeeklyRecurringShiftRateAsync.pending, (state) => {
        state.patchRateStatus = "loading";
      })
      .addCase(patchWeeklyRecurringShiftRateAsync.fulfilled, (state, action) => {
        state.patchRateStatus = "success";
        toast.info("Update Complete", {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 1000,
          toastId: "successful-weekly-recurring-shift-rate-update",
        });
      })
      .addCase(patchWeeklyRecurringShiftRateAsync.rejected, (state, action) => {
        state.patchRateStatus = "failed";
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful-weekly-recurring-shift-rate-update",
        });
      });
  },
});
export const status = (state: RootState) => state.weeklyRecurringShift.status;

export const weeklyFormState = (state: RootState) =>
  state.weeklyRecurringShift.formState;

export const selectWeeklyShiftPatchStartTimeStatus = (state: RootState) =>
  state.weeklyRecurringShift.patchStartTimeStatus;

export const selectWeeklyShiftPatchRateStatus = (state: RootState) =>
  state.weeklyRecurringShift.patchRateStatus;

export const { weeklyResetForm } = weeklyRecurringShiftSlice.actions;

export const loadedWeeklyRecurringShift = (state: RootState) =>
  state.weeklyRecurringShift.formState === "loaded"
    ? state.weeklyRecurringShift.weeklyRecurringShiftResponse
    : null;

export default weeklyRecurringShiftSlice.reducer;
