export interface LoginPayload {
  username: string;
  password: string;
}

export interface SelectListItem {
  value: string;
  text: string;
}

export enum ShiftType {
  Weekly = 1,
  Monthly = 2,
  AdHoc = 3,
}

export enum Difficulty {
  Green = 10,
  Amber = 20,
  Red = 30,
}
