import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ClientDocument } from "../../app/types";
import { fetchClientDocument } from "./ClientDocumentApi";

export interface ClientDocumentState {
  clientDocument: null | ClientDocument;
  status: "initial" | "idle" | "loading" | "failed";
}

const initialState: ClientDocumentState = {
  clientDocument: null,
  status: "initial",
};

export const fetchClientDocumentAsync = createAsyncThunk(
  "client-document/fetchClientDocument",
  async (id: string) => {
    const response = await fetchClientDocument(id);
    return response;
  }
);

export const clientDocumentSlice = createSlice({
  name: "client-document",
  initialState,
  reducers: {
    clear: (state) => {
      state.clientDocument = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientDocumentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchClientDocumentAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.clientDocument = action.payload;
      });
  },
});

export const clientDocument = (state: RootState) =>
  state.clientDocument.clientDocument;

export const clientDocumentStatus = (state: RootState) =>
  state.clientDocument.status;

export const { clear } = clientDocumentSlice.actions;

export default clientDocumentSlice.reducer;
