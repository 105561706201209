import moment from "moment";
import React, { useEffect, useState } from "react";
import {Alert, Button, Col, FloatingLabel, Form, FormControl, Modal, Row, Spinner } from 'react-bootstrap';
import { useAppDispatch } from "../../app/hooks";
import { ShiftAssignmentWithTimeSheet } from "../../app/types";
import { dateDifferenceIsMoreThan5Days, dateValidation, startBeforeEndTime, startEndSameTime, timeOrTimespanValidation } from "../../app/validation";
import { postTimesheetAsync } from "./TimeSheetSlice";

export type IProps = {
    show: boolean;
    loading: boolean;
    shiftAssignmentWithTimeSheet:ShiftAssignmentWithTimeSheet | null;
    handleClose: () => void;
}

export default function AddPopup(props: IProps) {
    const dispatch = useAppDispatch();
    const [shiftStartTime, setShiftStartTime] = useState('');
    const [shiftStartDate, setShiftStartDate] = useState('');
    const [shiftStartTimeInvalid, setShiftStartTimeInvalid] = useState<undefined | boolean>(undefined);
    const [shiftStartTimeChange, setShiftStartTimeChange] = useState(false);
    const [shiftStartDateInvalid, setShiftStartDateInvalid] = useState<undefined | boolean>(undefined);
    const [shiftStartDateChange, setShiftStartDateChange] = useState(false);
    const [shiftEndTime, setShiftEndTime] = useState('');
    const [shiftEndDate, setShiftEndDate] = useState('');
    const [shiftEndTimeInvalid, setShiftEndTimeInvalid] = useState<undefined | boolean>(undefined);
    const [shiftEndTimeChange, setShiftEndTimeChange] = useState(false);
    const [shiftEndDateInvalid, setShiftEndDateInvalid] = useState<undefined | boolean>(undefined);
    const [shiftEndDateChange, setShiftEndDateChange] = useState(false);
    
    useEffect(() => {
        setShiftStartTime(props.shiftAssignmentWithTimeSheet ? moment( props.shiftAssignmentWithTimeSheet.shiftStart).format('HH:mm') : "");
        setShiftStartTimeChange(false);
        setShiftStartTimeInvalid(false);
        setShiftStartDate(props.shiftAssignmentWithTimeSheet ? moment(props.shiftAssignmentWithTimeSheet.shiftStart).format('YYYY-MM-DD') : "");
        setShiftStartDateChange(false);
        setShiftStartDateInvalid(false);
        let shiftEndTime = props.shiftAssignmentWithTimeSheet ? moment( props.shiftAssignmentWithTimeSheet.shiftEnd).format('HH:mm' ) : "";
        setShiftEndTime(shiftEndTime);
        setShiftEndTimeChange(false);
        setShiftEndTimeInvalid(false);
        setShiftEndDate(props.shiftAssignmentWithTimeSheet ? moment(props.shiftAssignmentWithTimeSheet.shiftEnd).format('YYYY-MM-DD') : "");
        setShiftEndDateChange(false);
        setShiftEndDateInvalid(false);
    }, [props.shiftAssignmentWithTimeSheet])
    
    const onShiftStartTimeInputChange = (value: string) => {
        if (value !== shiftStartTime) {
            setShiftStartTimeChange(true);
            setShiftStartTime(value);
            shiftStartTimeInvalidFunction(value);
        }
    };

    const shiftStartTimeInvalidFunction = (value : string) => {
        if (timeOrTimespanValidation(value)) {
            setShiftStartTimeInvalid(false);
            return false;
        }
        setShiftStartTimeInvalid(true);
        return true;
    };

    const onShiftStartDateInputChange = (value: string) => {
        if (value !== shiftStartDate) {
            setShiftStartDate(value);
            setShiftStartDateChange(true);
            shiftStartDateInvalidFunction(value);
        }
    };

    const shiftStartDateInvalidFunction = (value : string) => {
        if (dateValidation(value) &&
        props.shiftAssignmentWithTimeSheet &&
        dateDifferenceIsMoreThan5Days(props.shiftAssignmentWithTimeSheet.shiftStart.format("YYYY-MM-DD"), value)
        ) {
            setShiftStartDateInvalid(false);
            return false;
        }

        setShiftStartDateInvalid(true);
        return true;
    };

    const onShiftEndTimeInputChange = (value: string) => {
        if (value !== shiftEndTime) {
            setShiftEndTimeChange(true);
            setShiftEndTime(value);
            shiftEndTimeInvalidFunction(value);
        }
    };

    const shiftEndTimeInvalidFunction = (value : string) => {
        if (timeOrTimespanValidation(value)) {
            setShiftEndTimeInvalid(false);
            return false;
        }
        setShiftEndTimeInvalid(true);
        return true;
    };

    const onShiftEndDateInputChange = (value: string) => {
        if (value !== shiftEndDate) {
            setShiftEndDate(value);
            setShiftEndDateChange(true);
            shiftEndDateInvalidFunction(value);
        }
    };

    const shiftEndDateInvalidFunction = (value : string) => {
        if (dateValidation(value) &&
        props.shiftAssignmentWithTimeSheet &&
         dateDifferenceIsMoreThan5Days(props.shiftAssignmentWithTimeSheet.shiftStart.format("YYYY-MM-DD"), value)) {
            setShiftEndDateInvalid(false);
            return false;
        }
        setShiftEndDateInvalid(true);
        return true;
    };

    const handleAddSubmit = () => {

        const carerId = props.shiftAssignmentWithTimeSheet?.carerId;
        const clientId = props.shiftAssignmentWithTimeSheet?.clientId;
        const shiftAssignmentId = props.shiftAssignmentWithTimeSheet?.id;
        const start = moment(shiftStartDate).add(moment.duration(shiftStartTime).asMinutes(), 'minutes')
        const end = moment(shiftEndDate).add(moment.duration(shiftEndTime).asMinutes(), 'minutes')
        let valid = true;

        if (!startBeforeEndTime(start, end,) || !startEndSameTime(start, end,)) {
                setShiftStartDateChange(true);
                setShiftStartTimeChange(true);
                setShiftEndDateChange(true);
                setShiftEndTimeChange(true);
                setShiftStartDateInvalid(true);
                setShiftStartTimeInvalid(true);
                setShiftEndDateInvalid(true);
                setShiftEndTimeInvalid(true);
                valid = false
        } else {
            if (shiftStartDateInvalidFunction(shiftStartDate)) {
                valid = false;
            }
      
            if (shiftStartTimeInvalidFunction(shiftStartTime)) {
                valid = false;
            }
    
            if (shiftEndDateInvalidFunction(shiftEndDate)) {
                valid = false;
            }
    
            if (shiftEndTimeInvalidFunction(shiftEndTime)) {
                valid = false;
            }
        }
        console.log(end)
        if (carerId && clientId && shiftAssignmentId && valid) {
            dispatch(postTimesheetAsync({
                carerId: carerId,
                clientId: clientId,
                shiftAssignmentId: shiftAssignmentId,   
                start: start.toISOString(),
                end: end.toISOString(),      
            }));
        }
    };

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header className="bg-fhah-purple text-white">
                <Modal.Title>
                    Add TimeSheet
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-light">
            {
                    props.loading ? (            
                    <Row>
                        <Col lg="4" className="mx-auto m-4 text-center">
                            <Spinner
                                as="div"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                                className="text-fhah-green mx-auto"
                                >
                                    <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </Col>
                    </Row>
                    ):""
                }
                <Alert variant={"info"}>
                    <h4 className="alert-heading">Are you sure</h4>
                    Make sure you give us accurate timesheet entry.
                </Alert>
                <Row>
                    <Col>
                        <FloatingLabel
                            controlId="shiftStartDate"
                            label="Shift start Date"
                            className="mb-3"
                        >
                            <FormControl type="date"  value={shiftStartDate} onChange={(e) => {onShiftStartDateInputChange(e.target.value)}} isInvalid={shiftStartDateInvalid && shiftStartDateChange}/>
                            <Form.Control.Feedback type="invalid">
                                Invalid End Date
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="shiftStartTime"
                            label="Shift Start Time"
                            className="mb-3"
                        >
                            <FormControl step="60" type="time"  value={shiftStartTime} onChange={(e) => {onShiftStartTimeInputChange(e.target.value)}} isInvalid={shiftStartTimeInvalid && shiftStartTimeChange}/>
                            <Form.Control.Feedback type="invalid">
                                Invalid Start Time
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>  
                </Row>
                <Row>
                    <Col>
                        <FloatingLabel
                            controlId="shiftEndDate"
                            label="Shift End Date"
                            className="mb-3"
                        >
                            <FormControl type="date"  value={shiftEndDate} onChange={(e) => {onShiftEndDateInputChange(e.target.value)}} isInvalid={shiftEndDateInvalid && shiftEndDateChange}/>
                            <Form.Control.Feedback type="invalid">
                                Invalid End Date
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="shiftEndTime"
                            label="Shift End Time"
                            className="mb-3"
                        >
                            <FormControl step="60" type="time"  value={shiftEndTime} onChange={(e) => {onShiftEndTimeInputChange(e.target.value)}} isInvalid={shiftEndTimeInvalid && shiftEndTimeChange}/>
                            <Form.Control.Feedback type="invalid">
                                Invalid End Time
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className={"bg-light"}>
                <Button variant="secondary" onClick={props.handleClose}>Close</Button>
                <Button variant="primary" disabled={props.loading}  onClick={handleAddSubmit}>Add</Button>
            </Modal.Footer>
        </Modal>
    );
}