import { useCallback, useEffect } from 'react';
import { Menu } from './app/navigation/Menu';
import { Router } from './app/navigation/Router';
import { Flip, toast, ToastContainer } from 'react-toastify';
import './App.css';
import { authToken, refreshTokenAsync, loggedIn, logOut, isAuthorising, loggedOnAsUserInfoId } from './features/account/accountSlice';
import { useAppSelector, useAppDispatch } from './app/hooks';
import { resetTokenAccessTimer, validTokenLoginCheck } from './features/account/sessionManager';
import * as signalR from '@microsoft/signalr';
import { CarerHolidayModel, ShiftAssignmentChange, LastClientMessage, NewChatMessage } from './app/types';
import { setConnectionState, getConnectionState, rosterDateUpdated, rosterDateRangeUpdated, newCarerHoliday, newCarerHolidayRemoval} from './app/signal-r/signalRSlice';
import FhahHub from './app/signal-r/fhah-hub';
import { getCarersAsync, selectCarerSliceStatus } from './features/carers/carerSlice';
import { getBranchesAsync, selectBranchSliceStatus } from './features/branches/branchSlice';
import { getClientsAsync, selectClients, selectClientSliceStatus, updateClientLastMessage, updateClientLastViewedChatMessage } from './features/clients/clientSlice';
import { updateShiftAssignmentList } from './features/shift-assignment/ShiftAssignmentSlice';
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./applicationInsights";
import { Moment } from 'moment';
import { updateShiftList } from './features/roster/RosterSlice';
import { addToChatList, removeFromChatList } from './features/chat-system/ChatSlice';
import { updateChatMessageLastViewedAsync } from './features/chat-system/ChatLastViewedSlice';
import Loading from './app/generic-components/Loading';

function App() {
  const dispatch = useAppDispatch();
  const clients = useAppSelector(selectClients);
  const loggedOnAs = useAppSelector(loggedOnAsUserInfoId);
  // Authorisation
  const storedTokenInfo = useAppSelector(authToken);
  const authorising = useAppSelector(isAuthorising);

  const refreshTokenAction = useCallback(() => {
    dispatch(refreshTokenAsync());
  }, [dispatch]);

  const loggedInAction = useCallback(() => {
    dispatch(loggedIn());
  }, [dispatch]);

  const logOutAction = useCallback(() => {
    dispatch(logOut());
  }, [dispatch]);

  useEffect(() => {
    if (!storedTokenInfo) {
      validTokenLoginCheck(loggedInAction, logOutAction);
    }

    if (storedTokenInfo) {
      resetTokenAccessTimer(storedTokenInfo, refreshTokenAction);
    }
  }, [storedTokenInfo, refreshTokenAction, loggedInAction, logOutAction]);

  // Signal-R
  const connectionState = useAppSelector(getConnectionState);

  const onSignalRConnected = useCallback((connection: signalR.HubConnection) => {
    dispatch(setConnectionState(connection.state));
  }, [dispatch]);

  const onSignalRReconnecting = useCallback((connection: signalR.HubConnection) => {
    dispatch(setConnectionState(connection.state));
    toast.warning("Re-connecting to Server", {
        position: 'top-right',
        transition: Flip,
        closeButton: true,
        autoClose: 1000,
        toastId: "Reconnecting",
    });
  }, [dispatch]);
  
  const notificationCallBack = useCallback((name: string, message: string) => {
    if (!message) return;
    var encodedMsg = message.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
    
    toast.info(encodedMsg, {
        position: 'top-right',
        transition: Flip,
        closeButton: true,
        autoClose: 1000,
        toastId: encodedMsg,
    });
  }, []);

  const dateUpdatedCallBack = useCallback((date: Moment) => {
    dispatch(rosterDateUpdated(date));
  }, [dispatch]);

  const dateRangeUpdatedCallBack = useCallback((startDate: Moment, endDate: Moment | undefined) => {
    dispatch(rosterDateRangeUpdated({ start: startDate, end: endDate }));
  }, [dispatch]);

  const shiftAssigmentChangeCallback = useCallback((shiftAssignmentChange: ShiftAssignmentChange) => {
    dispatch(updateShiftList(shiftAssignmentChange));
    dispatch(updateShiftAssignmentList(shiftAssignmentChange));
  }, [dispatch]);

  const clientChatMessageSentCallback = useCallback((newChatMessage : NewChatMessage) => {
    dispatch(addToChatList(newChatMessage));
    dispatch(updateChatMessageLastViewedAsync({clientId: newChatMessage.chatMessage.clientId, messageId: newChatMessage.chatMessage.id}));

    if (loggedOnAs === newChatMessage.chatMessage.sentById) {
      dispatch(updateClientLastViewedChatMessage({clientId: newChatMessage.chatMessage.clientId, lastViewedChatMessage: newChatMessage.chatMessage.sent,}))
    }
  }, [dispatch, loggedOnAs]);

  const clientChatMessageRemovedCallback = useCallback((messageId: string) => {
    dispatch(removeFromChatList(messageId));
  }, [dispatch]);

  const clientChatMessageLastUpdatedCallback = useCallback((lastClientMessage: LastClientMessage) => {
    dispatch(updateClientLastMessage(lastClientMessage));
  }, [dispatch]);

  const newCarerHolidayCallback = useCallback((model: CarerHolidayModel) => {
    dispatch(newCarerHoliday(model));
  }, [dispatch]);

  const carerHolidayRemovedCallback = useCallback((id: string) => {
    dispatch(newCarerHolidayRemoval(id));
  }, [dispatch]);

  useEffect(() => {
    if (storedTokenInfo && !connectionState) {
      FhahHub.initialiseConnectionToHub(onSignalRReconnecting, logOutAction);
    }

    if (!storedTokenInfo && connectionState) {
      FhahHub.wipeConnection();
      dispatch(setConnectionState(undefined));
    }
  }, [storedTokenInfo, connectionState, onSignalRReconnecting, logOutAction, dispatch]);

  useEffect(() => {
    if (storedTokenInfo && (connectionState !== undefined || connectionState !== signalR.HubConnectionState.Connected)) {
        FhahHub.startConnection(
          onSignalRConnected, 
          dateRangeUpdatedCallBack, 
          dateUpdatedCallBack, 
          notificationCallBack,
          shiftAssigmentChangeCallback,
          clientChatMessageSentCallback,
          clientChatMessageRemovedCallback,
          clientChatMessageLastUpdatedCallback,
          newCarerHolidayCallback,
          carerHolidayRemovedCallback);
    }
  }, [
    connectionState,
    onSignalRConnected,
    dateRangeUpdatedCallBack,
    dateUpdatedCallBack,
    notificationCallBack,
    storedTokenInfo,
    shiftAssigmentChangeCallback,
    clientChatMessageSentCallback,
    clientChatMessageRemovedCallback,
    clientChatMessageLastUpdatedCallback,
    newCarerHolidayCallback,
    carerHolidayRemovedCallback,
  ]);
  
  // Set state
  const carerSliceStatus = useAppSelector(selectCarerSliceStatus);
  const clientSliceStatus = useAppSelector(selectClientSliceStatus);
  const branchSliceStatus = useAppSelector(selectBranchSliceStatus);

  useEffect(() => {
    if (storedTokenInfo) {
      if (carerSliceStatus === 'initial') {
        dispatch(getCarersAsync());
      }

      if (clientSliceStatus === 'initial') {
        dispatch(getClientsAsync());
      }

      if (branchSliceStatus === 'initial') {
        dispatch(getBranchesAsync());
      }
    }
  }, [dispatch, carerSliceStatus, clientSliceStatus, branchSliceStatus, storedTokenInfo]);

  const leftChatGroup = useCallback(() => {
    if (clients.length > 0 && connectionState === signalR.HubConnectionState.Connected) {
        const connection = FhahHub.getConnection();
        if (connection) {
            for (let index = 0; index < clients.length; index++) {
              const element = clients[index];
              FhahHub.unSubscribeFromClientChatGroup(element.id);
            }
        }
    }
  }, [connectionState, clients]);

  useEffect(() => {
    if ((connectionState && connectionState === signalR.HubConnectionState.Connected)) {
      for (let index = 0; index < clients.length; index++) {
        const element = clients[index];
        FhahHub.subscribeToclientChatGroup(element.id, (message: string) => {
          toast.error(message, {
            position: "top-right",
            transition: Flip,
            closeButton: true,
            autoClose: 1000,
            toastId: "Error",
          });
        });
      }
    }

    return () => leftChatGroup();

  }, [clients, connectionState, leftChatGroup]);

  let appUi = <div className="text-center"><Loading /></div>;
  if ((!storedTokenInfo && !authorising) ||
    (carerSliceStatus === 'idle' && clientSliceStatus === 'idle' && connectionState && connectionState === signalR.HubConnectionState.Connected)) {
    appUi = (
      <AppInsightsContext.Provider value={reactPlugin}>
        <AppInsightsErrorBoundary onError={() => <h1>I believe something went wrong</h1>} appInsights={reactPlugin}>
          <div className="App h-100">
            <header>
              <Menu />
            </header>
            <Router />
            <ToastContainer />
          </div>
        </AppInsightsErrorBoundary>
      </AppInsightsContext.Provider>
    )
  }

  if (connectionState && connectionState === signalR.HubConnectionState.Reconnecting) {
    appUi = (<div className="text-center"><div className="m-4 text-bold">Reconnecting to Server</div><Loading /></div>);
  }

  return (appUi);
}

export default App;
