import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Button, Card, ListGroup } from "react-bootstrap";
import { selectGlobalDocuments, fetchGlobalDocumentAsync } from './GlobalDocumentSlice';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import { useEffect } from 'react';
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";

export default function GlobalDocumentList() {
    const appInsights = useAppInsightsContext();
    useTrackMetric(appInsights, "GlobalDocumentList");

    const globalDocument = useAppSelector(selectGlobalDocuments);
    const dispatch = useAppDispatch();

    useEffect(() => {
            dispatch(fetchGlobalDocumentAsync());
    }, [dispatch]);

    const globalDocumentList  = (
        <ListGroup as='ul'> 
            {globalDocument.map((x) => (
                <ListGroup.Item key={x.name} as="li" className="d-flex">
                    <a href={x.url} target="_blank" rel="noreferrer" download={x.name + x.extension} className="flex-grow-1 align-self-center text-start text-truncate text-wrap lead pe-3 fhah-purple-link">{x.name + x.extension}</a> 
                    <Button className="align-self-end" as="a" href={x.url} target="_blank" rel="noreferrer" download={x.name + x.extension}><FontAwesomeIcon icon={faDownload} /></Button> 
                </ListGroup.Item>
            ))}
        </ListGroup>
    );

    let clientsPlaceholder;
    if (globalDocument.length === 0) {
        clientsPlaceholder = (<span>No Documents</span>);
    }

    return (
        <Card>
            <CardHeader className="bg-fhah-green text-white">
                <h4 className="card-title">Service Provider Toolkit</h4>
            </CardHeader>
            {globalDocumentList }
            {clientsPlaceholder}
        </Card>
    )
}