import { FloatingLabel, Form, FormSelect } from "react-bootstrap";
import { SelectListItem } from '../model';

interface IProps {
    currentValue: string;
    changeSelectList: (Id: string) => void;
    selectListItems : SelectListItem[];
    name: string;
    isInvalid: boolean | undefined;
    disabled?: boolean;
    showAllText?: boolean;
}

export default function SelectList({currentValue, changeSelectList, selectListItems, name, isInvalid, disabled, showAllText} : IProps) {
    const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

        changeSelectList(e.currentTarget.value);
    };

    const selectList = (
        <FormSelect onChange={onSelectChange} value={currentValue ? selectListItems.find(x => x.value === currentValue)?.value : ""} isInvalid={isInvalid} disabled={disabled}>
            <option value="">{!showAllText ? "Please select" : "Show all"}</option>
            {selectListItems.map((x) => (
                <option key={x.value} value={x.value}>{x.text}</option>
            ))}
        </FormSelect>
    );
    return (
        <FloatingLabel
            controlId={name+"Id"}
            label={name}
            className="mb-3"
        >
        {selectList}
        <Form.Control.Feedback type="invalid">
            Invalid {name}
        </Form.Control.Feedback>
        </FloatingLabel>
    )
}