import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { GlobalDocument } from "../../app/types";
import { fetchGlobalDocument } from "./GlobalDocumentApi";

export interface GlobalDocumentState {
  list: GlobalDocument[];
  status: "initial" | "idle" | "loading" | "failed";
}

const initialState: GlobalDocumentState = {
  list: [],
  status: "initial",
};

export const fetchGlobalDocumentAsync = createAsyncThunk(
  "global-document/fetchGlobalDocument",
  async () => {
    const response = await fetchGlobalDocument();
    return response;
  }
);

export const globalDocumentSlice = createSlice({
  name: "global-document",
  initialState,
  reducers: {
    clear: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalDocumentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGlobalDocumentAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.list = action.payload;
      });
  },
});

export const selectGlobalDocuments = (state: RootState) =>
  state.globalDocuments.list;

export const selectGlobalDocumentsStatus = (state: RootState) =>
  state.globalDocuments.status;

export default globalDocumentSlice.reducer;
