import { useCallback, useEffect, useState } from "react";
import { ShiftType } from "../../app/model";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { UpdateRosteredShiftStartTimeModel, } from "../../app/types";
import { getWeeklyRecurringShiftAsync, loadedWeeklyRecurringShift, patchWeeklyRecurringShiftStartTimeAsync, selectWeeklyShiftPatchStartTimeStatus, weeklyFormState, weeklyResetForm } from "../shift-form/WeeklyRecurringShiftSlice";
import { getMonthlyRecurringShiftAsync, loadedMonthlyRecurringShift, monthlyFormState, selectMonthlyShiftPatchStartTimeStatus, monthlyResetForm, patchMonthlyRecurringShiftStartTimeAsync } from "../shift-form/MonthlyRecurringShiftSlice";
import moment from "moment";
import { selectShiftTimeModalFor, hideShiftTimeModal, ShiftIdAndTypePayload } from './RosteredShiftSlice';
import ViewShiftTimeModal from './ViewShiftTimeModal';
import { timeOrTimespanValidation } from "../../app/validation";

export default function ShiftTimeModal() {
    const shiftTimeModalFor: ShiftIdAndTypePayload | undefined = useAppSelector(selectShiftTimeModalFor);

    const currentWeeklyFormState = useAppSelector(weeklyFormState);
    const currentMonthlyFormState = useAppSelector(monthlyFormState);

    const currentLoadedMonthly = useAppSelector(loadedMonthlyRecurringShift);
    const currentLoadedWeekly = useAppSelector(loadedWeeklyRecurringShift);

    const monthlyShiftPatchStatus = useAppSelector(selectMonthlyShiftPatchStartTimeStatus);
    const weeklyShiftPatchStatus = useAppSelector(selectWeeklyShiftPatchStartTimeStatus);

    const dispatch = useAppDispatch();
    const [version, setVersion] = useState<string>('');
    const [currentDuration, setCurrentDuration] = useState<string>('');
    const [shiftStartTime, setShiftStartTime] = useState('');
    const [shiftStartTimeInvalid, setShiftStartTimeInvalid] = useState<undefined | boolean>(undefined);
    const [shiftStartTimeChange, setShiftStartTimeChange] = useState(false);
    const [typeOfShift, setTypeOfShift] = useState(ShiftType.AdHoc);
    const [loading, setLoading] = useState(false);

    const handleCloseModal = useCallback(() => {
        dispatch(weeklyResetForm());
        dispatch(monthlyResetForm());
        dispatch(hideShiftTimeModal());
    }, [dispatch]);

    useEffect(() => {
        if ((monthlyShiftPatchStatus === "success" && shiftTimeModalFor !== undefined) || 
            (weeklyShiftPatchStatus === "success" && shiftTimeModalFor !== undefined)) {
                handleCloseModal();
        }

        if (weeklyShiftPatchStatus !== "loading" && 
            monthlyShiftPatchStatus !== "loading") {
                setLoading(false);
        }

        if (shiftTimeModalFor !== undefined && currentMonthlyFormState === "default" && currentWeeklyFormState === "default")
        {
            switch(shiftTimeModalFor.shiftType) {
                case "WeeklyRecurringShift":
                    dispatch(getWeeklyRecurringShiftAsync(shiftTimeModalFor.id));
                    break;
                case "MonthlyRecurrringShift":
                    dispatch(getMonthlyRecurringShiftAsync(shiftTimeModalFor.id));
                    break;
            }
        }

        if (shiftTimeModalFor && (currentMonthlyFormState === "loaded" || currentWeeklyFormState === "loaded"))
        {
            switch(shiftTimeModalFor.shiftType) {
                case "WeeklyRecurringShift":
                    if (currentLoadedWeekly !== null) {
                        const shiftEndTimeString = moment(currentLoadedWeekly.shiftStartTime, 'HH:mm:ss').add(moment.duration(currentLoadedWeekly.shiftLength).asMinutes(), 'minutes').format('HH:mm')
                        const duration = ` ${moment(currentLoadedWeekly.shiftStartTime, 'HH:mm:ss').format('HH:mm')} - ${shiftEndTimeString}`;

                        setCurrentDuration(duration);
                        setVersion(currentLoadedWeekly.version);
                        setShiftStartTime(moment(currentLoadedWeekly.shiftStartTime, 'HH:mm:ss').format('HH:mm'));
                        setTypeOfShift(ShiftType.Weekly);     
                        return;
                    }
                    break;
                case "MonthlyRecurrringShift":
                    if (currentLoadedMonthly !== null) {
                        const shiftEndTimeString = moment(currentLoadedMonthly.shiftStartTime, 'HH:mm:ss').add(moment.duration(currentLoadedMonthly.shiftLength).asMinutes(), 'minutes').format('HH:mm')
                        const duration = ` ${moment(currentLoadedMonthly.shiftStartTime, 'HH:mm:ss').format('HH:mm')} - ${shiftEndTimeString}`;

                        setCurrentDuration(duration);
                        setVersion(currentLoadedMonthly.version);
                        setShiftStartTime(moment(currentLoadedMonthly.shiftStartTime, 'HH:mm:ss').format('HH:mm'));
                        setTypeOfShift(ShiftType.Monthly);   
                        return;
                    }
                    break;
            }
            return;
        }

        if (currentWeeklyFormState === "sending"|| 
            currentMonthlyFormState === "sending") {           
            setLoading(true);
        }
    }, 
    [
        currentMonthlyFormState,
        currentWeeklyFormState,
        shiftTimeModalFor,
        currentLoadedWeekly,
        currentLoadedMonthly,
        monthlyShiftPatchStatus,
        weeklyShiftPatchStatus,
        handleCloseModal,
        dispatch,
    ]);

    const handleSaveChanges = (updateFutureAssignments: boolean) => {
        if (shiftTimeModalFor) {
            let patchPayload: UpdateRosteredShiftStartTimeModel = {
                id: shiftTimeModalFor.id,
                newShiftStartTime: String(shiftStartTime),
                updateFutureAssignments: updateFutureAssignments,
                version: version,
            }
            switch(typeOfShift) {
                case ShiftType.Weekly: {
                    dispatch(patchWeeklyRecurringShiftStartTimeAsync(patchPayload));
                    return;
                } 
                case ShiftType.Monthly: {
                    dispatch(patchMonthlyRecurringShiftStartTimeAsync(patchPayload));
                    break; 
                } 
                default: {
                    alert('Not weekly or monthly');
                    break; 
                } 
            }

            handleResetForm();
        }
    }

    const onShiftStartTimeInputChange = (value: string) => {
        if (value !== shiftStartTime) {
            setShiftStartTimeChange(true);
            setShiftStartTime(value);
            shiftStartTimeInvalidFunction(value);
        }
    };

    const shiftStartTimeInvalidFunction = (value : string) => {
        if (timeOrTimespanValidation(value)) {
            setShiftStartTimeInvalid(false);
            return false;
        }
        setShiftStartTimeInvalid(true);
        return true;
    };

    const handleResetForm = () => {
        setVersion('');
        setShiftStartTime('');
        setTypeOfShift(ShiftType.AdHoc);
    };

    let displayShiftType = "";
    switch (typeOfShift) {
        case ShiftType.Weekly:
            displayShiftType = "Weekly"
            break;
        case ShiftType.Monthly:
            displayShiftType = "Monthly"
            break;
        default:
            break;
    }

    return (
        <ViewShiftTimeModal
            displayShiftType={displayShiftType}
            shiftStartTime={shiftStartTime}
            shiftStartTimeInvalid={shiftStartTimeInvalid}
            shiftStartTimeChange={shiftStartTimeChange}
            showModal={shiftTimeModalFor !== undefined}
            duration={currentDuration}
            loading={loading}
            handleCloseModal={handleCloseModal}
            shiftUpdateStatusLoading={monthlyShiftPatchStatus === "loading" || weeklyShiftPatchStatus === "loading"}
            onShiftStartTimeInputChange={(value: string) => onShiftStartTimeInputChange(value)}
            updateThisShift={() => handleSaveChanges(false)}
            updateShiftAndAssignments={() => handleSaveChanges(true)}
        />
    );
}