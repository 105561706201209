import moment, { Moment } from "moment";
export const timeOrTimespanValidation = (value: string) => {
  let re = new RegExp("^([01]?[0-9]|2[0-3]):[0-5][0-9]$");
  let result = re.test(value);
  return result;
};

export const numberOfWeeksValidation = (value: number) => {
  return value > 0 && value < 5 ? false : true;
};


export const numberOfCarersValidation = (value: number) => {
  return value > 0 && value < 6 ? false : true;
};

export const numberOfWeeksRecurring = (value: number) => {
  return value > 0 && value < 4 ? false : true;
};

export const selectListValidation = (value: string) => {
  return value === "" ? false : true;
};

export const dateValidation = (value: string) => {
  var re = /^\d{4}-\d{2}-\d{2}$/;
  if (!value.match(re)) {
    return false;
  }
  var d = new Date(value);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) {
    return false;
  }
  return true;
};

export const dateDifferenceIsMoreThan5Days = (
  value: string,
  newValue: string
) => {
  let valueMinus5 = moment(moment(value).add(-5, "days").format("YYYY-MM-DD"));
  let valueAdd5 = moment(moment(value).add(5, "days").format("YYYY-MM-DD"));
  let newValueMoment = moment(newValue);
  if (
    newValueMoment.isAfter(valueAdd5) ||
    newValueMoment.isBefore(valueMinus5)
  ) {
    return false;
  }
  return true;
};

export const startBeforeEndTime = (start: Moment, end: Moment) => {
  if (start.isValid() && end.isValid() && start.isAfter(end)) {
    return false;
  }
  return true;
};

export const startEndSameTime = (start: Moment, end: Moment) => {
  if (start.isValid() && end.isValid() && start.isSame(end)) {
    return false;
  }
  return true;
};
