import { Switch, Route } from 'react-router-dom';
import { Test } from '../components/Test';
import { Dashboard } from '../components/Dashboard';
import Roster from '../../features/roster';
import ChatSystem from '../../features/chat-system';
import GlobalDocuments from '../../features/global-document-list';
import { useAppSelector } from '../hooks';
import { isAuthorising, isLoggedIn, selectAccountCarerId } from '../../features/account/accountSlice';
import LoginForm from '../../features/account/LoginPage';
import Loading from '../generic-components/Loading';
import Timesheet from '../../features/Timesheet';

export function Router() {
    const loggedIn = useAppSelector(isLoggedIn);
    const authorising = useAppSelector(isAuthorising);
    const carerId = useAppSelector(selectAccountCarerId);
    let routeSwitch = (
        <Switch>
            <Route path="/">
                <Loading/>
            </Route>
        </Switch>
    );
    
    if (loggedIn) {
        routeSwitch = (
            <Switch>
                <Route path="/chat">
                    <ChatSystem/>
                </Route>
                <Route path="/documents">
                    <GlobalDocuments/>
                </Route>
                <Route path="/test">
                    <Test/>
                </Route>
                <Route path="/rotas/:date?">
                    <Roster />
                </Route>
                {
                    carerId ?(
                    <Route path="/timesheet">
                        <Timesheet />
                    </Route>
                    ):""
                }
                <Route path="/">
                    <Dashboard/>
                </Route>
            </Switch>
        );
    }

    if (!authorising && !loggedIn) {
        routeSwitch = (
            <Switch>
                <Route path="/">
                    <LoginForm/>
                </Route>
            </Switch>
        );
    }

    return (
        <div className="flex-container h-100-minus-nav ">
            <main role="main" className="h-100">
                {routeSwitch}
            </main>
        </div>
    );
}