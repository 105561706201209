import { faStopwatch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment, { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { Alert, Button, Card, Col, ListGroup, Row,} from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RosterDates, RosteredShift, ShiftAssignmentWithTimeSheet } from "../../app/types";
import { selectAccountCarerId } from "../account/accountSlice";
import { ClientModel, selectClientsAsDictionary, selectClientSliceStatus } from "../clients/clientSlice";
import { fetchRosterAsync, resetRoster, selectRoster, selectRosterStatus } from "../roster/RosterSlice";
import { fetchTimesheetShiftAssignmentAsync, timesheetshiftAssignmentList, timesheetshiftAssignmentListResponseStatus } from "../shift-assignment/ShiftAssignmentSlice";
import AddPopup from "../Timesheet/AddPopup"
import RemovePopup from "../Timesheet/RemovePopup"
import { fetchTimeSheetsAsync, resetFormState, selectTimesheetList, timesheetFormState, timesheetListResponseStatus } from "./TimeSheetSlice";

export default function TimeSheet() {
    const dispatch = useAppDispatch();
    const clientsAsDictionary: Map<string, ClientModel> = useAppSelector(selectClientsAsDictionary);
    const accountCarerId = useAppSelector(selectAccountCarerId);
    const [startDate, setStartdate] = useState<Moment | null>(null);
    const [startDateInput, setStartdateInput] = useState<string | null>(moment().startOf("isoWeek").format("YYYY-MM-DD"));
    const [listShiftAssignmentWithTimeSheet, setListShiftAssignmentWithTimeSheet] = useState<ShiftAssignmentWithTimeSheet[] | undefined>(undefined);

    const onStartWeekDateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartdateInput(e.currentTarget.value)
        onStartWeekDateChange(e.currentTarget.value);
    };

    const onStartWeekDateChange = useCallback((value :string) => {

        let date =  moment(value)
        if (date && date.isValid() && accountCarerId &&  date.startOf("isoWeek").format("YYYY-MM-DD") !== startDate?.format("YYYY-MM-DD") && date.year() >= 2021) {
            const dateStart = date.startOf("isoWeek");
            setStartdate(dateStart);
            setStartdateInput(dateStart.format("YYYY-MM-DD"));

            let rosterDates: RosterDates = {
                endDate: date.clone().endOf("isoWeek").format("YYYY-MM-DD"),
                startDate: date.clone().startOf("isoWeek").format("YYYY-MM-DD"),
            }
            dispatch(fetchTimesheetShiftAssignmentAsync(rosterDates));
            dispatch(fetchRosterAsync(rosterDates));

            dispatch(fetchTimeSheetsAsync({
                carerId: accountCarerId,
                start: rosterDates.startDate,
                end: rosterDates.endDate
            }));
        }
    }, [dispatch, accountCarerId, startDate]);

    useEffect(() => {
        if (!startDate) {
            onStartWeekDateChange(moment().format("YYYY-MM-DD"));
        }
    }, [startDate, onStartWeekDateChange]);
    
    const [addPopupState, setAddPopupState] = useState(false);
    const [removePopupState, setRemovePopupState] = useState(false);
    const [popupLoadingState, setPopupLoadingState] = useState(false);

    const [currentShiftAssignmentWithTimeSheet, setCurrentShiftAssignmentWithTimeSheet] = useState<ShiftAssignmentWithTimeSheet | null >(null);

    const handleAddPopupOpen = (shiftAssignmentWithTimeSheet: ShiftAssignmentWithTimeSheet) => {
        setCurrentShiftAssignmentWithTimeSheet(shiftAssignmentWithTimeSheet);
        setRemovePopupState(false);
        setAddPopupState(true)
    };

    const handleRemovePopupOpen = (shiftAssignmentWithTimeSheet: ShiftAssignmentWithTimeSheet) => {
        setCurrentShiftAssignmentWithTimeSheet(shiftAssignmentWithTimeSheet);
        setRemovePopupState(true);
        setAddPopupState(false)
    };

    const handlePopupClose = () => {
        setAddPopupState(false);
        setRemovePopupState(false);
    };

    const currentTimesheetFormState =  useAppSelector(timesheetFormState);
    
    useEffect(() => {
        return () => {dispatch(resetRoster())};
    }, [dispatch]);

    useEffect(() => {

        if (currentTimesheetFormState === "default" && popupLoadingState === true) {
            setPopupLoadingState(false);
        }

        if (currentTimesheetFormState === "sending" && popupLoadingState === false) {
            setPopupLoadingState(true);
        }

        if (currentTimesheetFormState === "success" && popupLoadingState === true) {
            handlePopupClose();
            setPopupLoadingState(false);
            dispatch(resetFormState);
        }
        
    }, [currentTimesheetFormState, popupLoadingState, dispatch]);
    
    const currentClientSliceStatus = useAppSelector(selectClientSliceStatus);
    const currentTimesheetshiftAssignmentListResponseStatus = useAppSelector(timesheetshiftAssignmentListResponseStatus);
    const currentSelectRosterStatus = useAppSelector(selectRosterStatus);
    const currentTimesheetshiftAssignmentList = useAppSelector(timesheetshiftAssignmentList);
    const rosters = useAppSelector(selectRoster);
    const currentTimesheetListResponseStatus = useAppSelector(timesheetListResponseStatus);
    const currentSelectTimesheetList = useAppSelector(selectTimesheetList);

    useEffect(() => {
        if (currentClientSliceStatus !== "loading" && currentTimesheetshiftAssignmentListResponseStatus !== "loading" && currentSelectRosterStatus !== "loading" && currentTimesheetListResponseStatus !== "loading") {
            let listOfTimesheets = currentTimesheetshiftAssignmentList?.filter(
                (x) => x.carerId === accountCarerId
            );

            let shiftAssignmentWithTimeSheetList: ShiftAssignmentWithTimeSheet[] =  [];
            if (listOfTimesheets && rosters && clientsAsDictionary !== undefined && currentSelectTimesheetList) {
                listOfTimesheets.forEach(element => {
                    let rostersForDate = rosters.get(moment(element.date).format('YYYY-MM-DD'));
                    let rosteredShift: RosteredShift | undefined = rostersForDate ? rostersForDate.get(element.shiftId) : undefined;
                    let clientId  = rosteredShift ? rosteredShift.clientId : undefined;
                    if (rosteredShift && clientId)
                    {
                        let client = clientsAsDictionary.get(clientId);
                        if (client) {
                            let timesheet = currentSelectTimesheetList?.filter(x => x.shiftAssignmentId === element.id)[0];
                            shiftAssignmentWithTimeSheetList.push({
                                id: element.id,
                                shiftId: element.shiftId,
                                date: element.date,
                                carerId: element.carerId,
                                rateName: element.rateName,
                                shiftStart: timesheet ? moment(timesheet.start) : moment(element.shiftStartTime),
                                shiftEnd:  timesheet ? moment(timesheet.end) :  moment(element.shiftStartTime).add(moment.duration(element.shiftLength).asMinutes(), 'minutes'),
                                clientId: client.id,
                                clientName: client.firstName + " " + client.surname,
                                timesheetId: timesheet ? timesheet.id : null,
                                isInvoiced: timesheet ? timesheet.isInvoiced : false,
                                invoiceCreated: timesheet && timesheet.invoiceCreated  ? moment(timesheet.invoiceCreated) : null
                            });
                        }
                    }
                });
                shiftAssignmentWithTimeSheetList = shiftAssignmentWithTimeSheetList.sort((a, b) => {
                    // Client name primary sort
                    if (a.clientName > b.clientName) {
                        return 1;
                    } else if (a.clientName < b.clientName){
                        return -1;
                    }

                    // Else go to timesheet date secondary order
                    if (moment(a.shiftStart) < moment(b.shiftStart)) { 
                        return -1;
                    } else if (moment(a.shiftStart) > moment(b.shiftStart)) {
                        return 1
                    } else { // nothing to split them
                        return 0;
                    }
                });
            }
            setListShiftAssignmentWithTimeSheet(shiftAssignmentWithTimeSheetList);
        }

      }, [currentClientSliceStatus, currentTimesheetshiftAssignmentListResponseStatus, currentSelectRosterStatus, currentTimesheetListResponseStatus, currentSelectTimesheetList, accountCarerId, clientsAsDictionary, currentTimesheetshiftAssignmentList, rosters]);

    return (
        <>
            <Col>
                <Card id="Timesheets" className="ms-4 me-2 ">
                    <Card.Header className="bg-fhah-purple text-white">
                        <h4>Timesheets for week starting <small>{startDate?.isValid() ? startDate.format("DD/MM/YYYY"): ""}</small></h4>
                    </Card.Header>
                    <Card.Body>
                        <Row className="">
                            <Col>
                                Choose Week: 
                                <input type="date" onKeyDown={(e) => e.preventDefault()} onChange={onStartWeekDateInputChange} value={startDateInput ? startDateInput: ""}>
                                </input>
                            </Col>
                        </Row>
                        <Row className="">
                            {listShiftAssignmentWithTimeSheet && listShiftAssignmentWithTimeSheet.length > 0 ? listShiftAssignmentWithTimeSheet.map((x, index) => (
                                <Col lg="12" className="mt-4" key={index + "-timesheet-entry"}>
                                    <Card className={x.isInvoiced ? "card-disabled text-start" : "bg-fhah-green text-white text-start"}>
                                        <Card.Header className="m-0">
                                            <h6 className="mb-0">{x.clientName}</h6>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row className="">
                                                <Col sm="12" md="4">
                                                    <ListGroup className=" mb-2">
                                                        <ListGroup.Item> 
                                                            <small className="mb-1 d-block text-black-50">Start</small>
                                                            {x.shiftStart.format('DD/MM/YYYY HH:mm')}
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>
                                                <Col sm="12" md="4">
                                                    <ListGroup className=" mb-2">
                                                        <ListGroup.Item> 
                                                            <small className="mb-1 d-block text-black-50">End</small>
                                                            {x.shiftEnd.format('DD/MM/YYYY HH:mm')}
                                                        </ListGroup.Item>
                                                    </ListGroup>
                                                </Col>
                                                {!x.invoiceCreated && 
                                                    <>
                                                        <Col sm="12" md="3">
                                                            <ListGroup className="mb-2">
                                                                <ListGroup.Item> 
                                                                    <small className="mb-1 d-block text-black-50">Rate</small>
                                                                    {x.rateName}
                                                                </ListGroup.Item>
                                                            </ListGroup>
                                                        </Col>
                                                        <Col sm="12" md="1" className="d-flex justify-content-end">
                                                            <div className="flex-grow-1 flex-md-grow-0">
                                                                {!x.timesheetId ? (<Button onClick={() => handleAddPopupOpen(x)}><FontAwesomeIcon icon={faStopwatch} className="fa-fw" /></Button>):""}
                                                                {x.timesheetId && !x.isInvoiced ? (<Button variant="danger" onClick={() => handleRemovePopupOpen(x)}><FontAwesomeIcon icon={faTrash} className="fa-fw" /></Button>):""} 
                                                            </div>
                                                        </Col>
                                                    </>
                                                }
                                                {x.invoiceCreated &&
                                                <Col sm="12" md="4" className="d-flex justify-content-end">
                                                    <div className="flex-grow-1 flex-md-grow-0">
                                                        <ListGroup className=" w-100 mb-2">
                                                            <ListGroup.Item> 
                                                                <small className="mb-1 d-block text-black-50">Invoice Created</small>
                                                                {x.invoiceCreated.format('DD/MM/YYYY')}
                                                            </ListGroup.Item>
                                                        </ListGroup>
                                                    </div>
                                                </Col>
                                                }
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                            :
                            (
                                <Col>
                                    <Alert className="mt-4 py-4" variant={"info"}>
                                        No shifts have been found for this week.
                                    </Alert>
                                </Col>
                            )}
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <AddPopup 
                loading={popupLoadingState}
                shiftAssignmentWithTimeSheet={currentShiftAssignmentWithTimeSheet}
                handleClose ={() => {handlePopupClose()}}
                show = {addPopupState}
            ></AddPopup>
            <RemovePopup 
                loading={popupLoadingState}
                shiftAssignmentWithTimeSheet={currentShiftAssignmentWithTimeSheet}
                handleClose ={() => {handlePopupClose()}}
                show = {removePopupState}
            ></RemovePopup>
        </>    
    );
}