import { useAppSelector } from '../../app/hooks';
import { Button, Col, ListGroup, Spinner } from "react-bootstrap";
import { selectClients, selectClientSliceStatus } from '../clients/clientSlice';
import ClientListRow from './clientListRow';
import { ReactElement, useEffect, useState } from 'react';
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export type IProps = {
    handleToggle: (toggleState :boolean) => void;
}

export default function ClientList(props: IProps) {
    const appInsights = useAppInsightsContext();
    useTrackMetric(appInsights, "ChatClientList");

    const clients = useAppSelector(selectClients);
    const status = useAppSelector(selectClientSliceStatus);

    const [loadingPlaceholder, setLoadingPlaceholder] = useState<ReactElement>(<span></span>);
    const [showClients, setShowClients] = useState<boolean>(true);
    
    let clientsColumnClasses: string = 'border-end h-100 mb-3';
    let accessClientsButtonIcon: IconDefinition = faChevronLeft;

    const showClientsAction = () => {
        setShowClients(true);
        props.handleToggle(true);
    }

    const hideClientsAction = () => {
        setShowClients(false);
        props.handleToggle(false);
    }

    let showClientsButtonAction: () => void = hideClientsAction;

    if (!showClients) {
        clientsColumnClasses = 'd-none';
        accessClientsButtonIcon = faChevronRight;
        showClientsButtonAction = showClientsAction;
    }

    useEffect(() => {
        setLoadingPlaceholder(<span/>);

        if (clients.length === 0 && status === 'idle') {
            setLoadingPlaceholder(<span>No Clients</span>);
        }
    
        if (status === 'loading') {
            setLoadingPlaceholder(
                <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    className="mt-4 text-fhah-green"
                >
                    <span className="visually-hidden">Loading Clients...</span>
                </Spinner>
            );
        }
    }, [clients, status, showClients]);
    return (
        <>
            <Button className="m-1 pointer clientsChatToggleBtn" variant="secondary" title="Clients" onClick={showClientsButtonAction}><FontAwesomeIcon icon={accessClientsButtonIcon} /></Button>
            <Col sm="12" lg="2" className={clientsColumnClasses}>
                <div className="h-100 ms-3 pe-3">
                      <div> 
                        <div className="bg-fhah-green text-white">
                            <h4 className="card-title">Clients</h4>
                        </div>
                        <div className="h-100-minus-3rem overflow-auto">
                            <ListGroup as='ul'> 
                                {clients.map((x) => (
                                    <ClientListRow key={`client-list-row-${x.id}`} client={x} hideClientsAction={() => hideClientsAction() }/>
                                ))}
                            </ListGroup>
                            {loadingPlaceholder}
                        </div>
                    </div>
                </div>
            </Col>
        </>
    )
}