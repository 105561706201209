import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  AutomaticShiftAssignment,
  GetAutomaticShiftAssignmentPayload,
  DeleteAutomaticShiftAssignmentPayload,
  PostAutomaticShiftAssignmentPayload,
} from "../../app/types";
import {
  postAutomaticShiftAssignment,
  fetchAutomaticShiftAssignments,
  deleteAutomaticShiftAssignment,
  playAutomaticShiftAssignment,
  pauseAutomaticShiftAssignment,
} from "./AutomaticShiftAssignmentApi";
import { toast, Flip } from "react-toastify";

export interface AutomaticShiftAssignmentState {
  automaticShiftAssignment: AutomaticShiftAssignment | null;
  automaticShiftAssignmentList: AutomaticShiftAssignment[] | undefined;
  automaticShiftAssignmentListResponseStatus:
    | "initial"
    | "idle"
    | "loading"
    | "failed";
  automaticShiftAssignmentResponseStatus:
    | "initial"
    | "idle"
    | "loading"
    | "failed";
}

const initialState: AutomaticShiftAssignmentState = {
  automaticShiftAssignment: null,
  automaticShiftAssignmentList: undefined,
  automaticShiftAssignmentResponseStatus: "initial",
  automaticShiftAssignmentListResponseStatus: "initial",
};

export const fetchAutomaticShiftAssignmentAsync = createAsyncThunk(
  "shift-type/recurring-shift-id/get-automatic-shift-assignments",
  async (rosterDates: GetAutomaticShiftAssignmentPayload) => {
    const response = await fetchAutomaticShiftAssignments(rosterDates);
    return response;
  }
);

export const postAutomaticShiftAssignmentAsync = createAsyncThunk(
  "shift-type/recurring-shift-id/post-automatic-shift-assignments",
  async (data: PostAutomaticShiftAssignmentPayload) => {
    const response = await postAutomaticShiftAssignment(data);
    return response;
  }
);

export const deleteAutomaticShiftAssignmentAsync = createAsyncThunk(
  "shift-type/recurring-shift-id/delete-automatic-shift-assignments",
  async (data: DeleteAutomaticShiftAssignmentPayload) => {
    const response = deleteAutomaticShiftAssignment(data);
    return response;
  }
);

export const pauseAutomaticShiftAssignmentAsync = createAsyncThunk(
  "shift-type/recurring-shift-id/automatic-shift-assignments/pause",
  async (data: DeleteAutomaticShiftAssignmentPayload) => {
    const response = pauseAutomaticShiftAssignment(data);
    return response;
  }
);

export const playAutomaticShiftAssignmentAsync = createAsyncThunk(
  "shift-type/recurring-shift-id/automatic-shift-assignments/play",
  async (data: DeleteAutomaticShiftAssignmentPayload) => {
    const response = playAutomaticShiftAssignment(data);
    return response;
  }
);

export const automaticShiftAssignmentSlice = createSlice({
  name: "automaticShiftAssignment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAutomaticShiftAssignmentAsync.pending, (state) => {
        state.automaticShiftAssignmentListResponseStatus = "loading";
        state.automaticShiftAssignmentList = undefined;
      })
      .addCase(
        fetchAutomaticShiftAssignmentAsync.fulfilled,
        (state, action) => {
          state.automaticShiftAssignmentListResponseStatus = "idle";
          state.automaticShiftAssignmentList = action.payload;
        }
      )
      .addCase(postAutomaticShiftAssignmentAsync.pending, (state) => {
        state.automaticShiftAssignmentResponseStatus = "loading";
        state.automaticShiftAssignment = null;
      })
      .addCase(postAutomaticShiftAssignmentAsync.fulfilled, (state, action) => {
        state.automaticShiftAssignmentResponseStatus = "idle";
        state.automaticShiftAssignment = action.payload;
        state.automaticShiftAssignmentList?.push(action.payload);

        toast.info("Successful Automatic Assignment", {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 1000,
          toastId: "successful-automatic-assignment",
        });
      })
      .addCase(postAutomaticShiftAssignmentAsync.rejected, (state, action) => {
        state.automaticShiftAssignmentResponseStatus = "failed";
        state.automaticShiftAssignment = null;

        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful-automatic-assignment",
        });
      })

      .addCase(deleteAutomaticShiftAssignmentAsync.pending, (state, action) => {
        state.automaticShiftAssignmentResponseStatus = "loading";
        state.automaticShiftAssignment = null;
      })
      .addCase(
        deleteAutomaticShiftAssignmentAsync.fulfilled,
        (state, action) => {
          state.automaticShiftAssignmentResponseStatus = "idle";
          state.automaticShiftAssignment = null;
          let index = state.automaticShiftAssignmentList?.findIndex(
            (x) => x.id === action.payload
          ) as number;
          if (index !== -1) {
            state.automaticShiftAssignmentList?.splice(index, 1);
          }

          toast.info("Auto Assignment Removed", {
            position: "top-right",
            transition: Flip,
            closeButton: true,
            autoClose: 1000,
            toastId: "successful-automatic-cancellation",
          });
        }
      )
      .addCase(
        deleteAutomaticShiftAssignmentAsync.rejected,
        (state, action) => {
          state.automaticShiftAssignmentResponseStatus = "failed";
          state.automaticShiftAssignment = null;

          toast.error(action.error.message, {
            position: "top-right",
            transition: Flip,
            closeButton: true,
            autoClose: 4000,
            toastId: "unsuccessful-cancelation",
          });
        }
      )
      .addCase(pauseAutomaticShiftAssignmentAsync.pending, (state, action) => {
        state.automaticShiftAssignmentResponseStatus = "loading";
        state.automaticShiftAssignment = null;
      })
      .addCase(
        pauseAutomaticShiftAssignmentAsync.fulfilled,
        (state, action) => {
          state.automaticShiftAssignmentResponseStatus = "idle";
          state.automaticShiftAssignment = null;
          let index = state.automaticShiftAssignmentList?.findIndex(
            (x) => x.id === action.payload
          ) as number;
          if (index !== -1 && state.automaticShiftAssignmentList) {
            let item = state.automaticShiftAssignmentList[index];
            item.paused = true;
            state.automaticShiftAssignmentList[index] = item;
          }
        }
      )
      .addCase(pauseAutomaticShiftAssignmentAsync.rejected, (state, action) => {
        state.automaticShiftAssignmentResponseStatus = "failed";
        state.automaticShiftAssignment = null;

        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful",
        });
      })
      .addCase(playAutomaticShiftAssignmentAsync.pending, (state, action) => {
        state.automaticShiftAssignmentResponseStatus = "loading";
        state.automaticShiftAssignment = null;
      })
      .addCase(playAutomaticShiftAssignmentAsync.fulfilled, (state, action) => {
        state.automaticShiftAssignmentResponseStatus = "idle";
        state.automaticShiftAssignment = null;
        let index = state.automaticShiftAssignmentList?.findIndex(
          (x) => x.id === action.payload
        ) as number;
        if (index !== -1 && state.automaticShiftAssignmentList) {
          let item = state.automaticShiftAssignmentList[index];
          item.paused = false;
          state.automaticShiftAssignmentList[index] = item;
        }
      })
      .addCase(playAutomaticShiftAssignmentAsync.rejected, (state, action) => {
        state.automaticShiftAssignmentResponseStatus = "failed";
        state.automaticShiftAssignment = null;

        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful",
        });
      });
  },
});

export const shiftAssignmentResponseStatus = (state: RootState) =>
  state.automaticShiftAssignment.automaticShiftAssignmentResponseStatus;

export const shiftAssignmentListResponseStatus = (state: RootState) =>
  state.automaticShiftAssignment.automaticShiftAssignmentListResponseStatus;

export const automaticShiftAssignmentList = (state: RootState) =>
  state.automaticShiftAssignment.automaticShiftAssignmentList;

export default automaticShiftAssignmentSlice.reducer;
