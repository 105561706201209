import { CarerHolidayModel } from "../../app/types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-duotone-svg-icons';
import { Button, OverlayTrigger, Popover, Row, Col } from 'react-bootstrap';
import { CarerModel } from "../carers/carerSlice";
import { useAppDispatch } from "../../app/hooks";
import { deleteCarerHolidayAsync } from "./carerHolidaySlice";

export type IProps = {
    canDelete: boolean;
    holiday: CarerHolidayModel;
    carer: CarerModel;
}

export default function CarerHoliday(props: IProps) {

    const dispatch = useAppDispatch();

    const showDeleteConfirmationModal = () => {
        dispatch(deleteCarerHolidayAsync(props.holiday.id));
    }
    
    const popover = (
        <Popover id={`holiday-popover-${props.holiday.id}`} >
            <Popover.Header as="h3" className="bg-fhah-purple text-white border border-white">
                <span className="text-uppercase" title={`${props.carer.firstName} ${props.carer.surname}`}>Holiday for {props.carer.firstName} {props.carer.surname}</span>
            </Popover.Header>
            {props.canDelete &&
                <Popover.Body className="bg-light">
                    <Row className="justify-content-md-center">
                        <Col lg="12" className="text-center">
                            <Button variant="danger" className="py-2 px-3" onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => showDeleteConfirmationModal()} title="Remove Holiday">
                                <FontAwesomeIcon icon={faTrash}/>
                            </Button>
                        </Col>
                    </Row>
                </Popover.Body>
            }
        </Popover>
    );
    return (
        <div key={`holiday-${props.holiday.id}`}>
            <OverlayTrigger trigger="focus" placement="bottom" overlay={popover}>
                <Button
                    onClick={(e) => {
                        let element = e.target as HTMLElement;
                        if (element.classList.contains("btn")) {
                            element.focus();
                        } else {
                            let btn = element.closest("button");
                            if (btn) {
                                btn.focus();
                            }
                        }
                    }}
                    variant="secondary" 
                    className="text-uppercase w-100 mb-1"> 
                    <div className="text-uppercase">Holiday All Day</div>
                </Button>
            </OverlayTrigger>
        </div>
    )
}
