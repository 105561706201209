import { ReactElement } from "react";
import { Col } from "react-bootstrap";
import styles from './Roster.module.css';

export type IProps = {
    availableShifts: ReactElement[];
    classes: string;
    handleLandingStripClick: () => void;
}

function OpenShiftsDayView(props: IProps) {
    let landingStrip: ReactElement = <div/>;

    let noAvailableShifts;
    if (!props.availableShifts)
    {
        noAvailableShifts = (<small>No available shifts</small>);
    }

    if (props.classes === `w-12-5 border-end border-bottom py-2 text-uppercase text-white position-relative ${styles.selectableDay} p-2`) {
        landingStrip = <div className={styles.newShiftArea} onClick={props.handleLandingStripClick}></div>;
    }

    return (
        <Col xs="12" sm="5" className={props.classes}>
            {landingStrip}
            
            {props.availableShifts}

            {noAvailableShifts}
        </Col>
    );
}

export default OpenShiftsDayView;