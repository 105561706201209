import { useAppSelector } from '../../app/hooks';
import { ListGroup, Spinner } from "react-bootstrap";
import { selectClients,selectClientSliceStatus } from './clientSlice';
import ClientListRow from './clientListRow';
import { useEffect, useRef } from 'react';
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";

export default function ClientList() {
    const appInsights = useAppInsightsContext();
    useTrackMetric(appInsights, "ClientList");

    const clients = useAppSelector(selectClients);
    const status = useAppSelector(selectClientSliceStatus);

    let loadingPlaceholder = useRef(<span />);

    useEffect(() => {
        loadingPlaceholder.current = <span/>;

        if (clients.length === 0 && status === 'idle') {
            loadingPlaceholder.current = <span>No Clients</span>;
        }
    
        if (status === 'loading') {
            loadingPlaceholder.current = (
                <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    className="mt-4 text-fhah-green"
                >
                    <span className="visually-hidden">Loading Clients...</span>
                </Spinner>
            );
        }
    }, [clients, status]);

    return (
        <div className="h-100">
            <div className="bg-fhah-green text-white">
                <h4 className="card-title">Clients</h4>
            </div>
            <div className="h-100-minus-3rem overflow-auto">
                <ListGroup as='ul'> 
                    {clients.map((x) => (
                        <ClientListRow key={`client-list-row-${x.id}`} client={x}/>
                    ))}
                </ListGroup>
                {loadingPlaceholder.current}
            </div>
        </div>
    )
}