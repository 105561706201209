import { faBells, faClock, faEye, faUserSlash, faUser, faMoneyBill } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';
import { Button, OverlayTrigger, Popover, Row, Col } from 'react-bootstrap';
import styles from './shiftAssignment.module.css';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export type IProps = {
    accessPriviledges: boolean;
    administratorPriviledge: boolean;

    clientFirstName: string;
    clientSurname: string;
    shiftVariant: string;

    duration: string;

    shiftOverlapBorderColor: string;

    requirementsMisMatch: boolean;
    shiftSelectActive: boolean;

    handleShowTimeModal: () => void;
    handleShowRateModal: () => void;
    handleViewShiftPopupShow: () => void;
    handleShowDeleteModalShow: () => void;
    handleShowClientModal: () => void;
}

function ShiftAssignmentView(props: IProps) {
    const buttonClasses: string = props.administratorPriviledge ? "py-2 w-sm-100 my-1" : "py-2 w-100 my-1";
    const popover = (
        <Popover id="popover-basic" >
            <Popover.Header as="h3" className="bg-fhah-purple text-white border border-white">
                <span className="me-1">{props.duration}</span>
                <span className="text-uppercase" title={`${props.clientFirstName} ${props.clientSurname}`}>@ {props.clientFirstName} {props.clientSurname}</span>
            </Popover.Header>
            {props.accessPriviledges &&
                <Popover.Body className="bg-light">
                    <Row className="justify-content-md-center">
                        <Col lg="4">
                            <Button variant="success" className={buttonClasses} onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={(e) => props.handleViewShiftPopupShow()} title="View Shift Assignment Details">
                                <FontAwesomeIcon icon={faEye} className="fa-fw" />
                            </Button>
                        </Col>
                        <Col lg="4">
                            <Button variant="success" className={buttonClasses} onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={(e) => props.handleShowClientModal()} title="View Client">
                                <FontAwesomeIcon icon={faUser} className="fa-fw" />
                            </Button>
                        </Col>
                        <Col lg="4">
                            <Button variant="danger" className={buttonClasses} onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => props.handleShowDeleteModalShow()} title="Remove this Shift Assignment">
                                <FontAwesomeIcon icon={faUserSlash} className="fa-fw" />
                            </Button>
                        </Col>
                    </Row>
                    {props.administratorPriviledge &&
                        <Row className="justify-content-md-center">
                            <Col lg="4">
                                <Button variant="success" className={buttonClasses} onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => props.handleShowTimeModal()} title="Adjust Shift Assignment Start Time">
                                    <FontAwesomeIcon icon={faClock} className="fa-fw" />
                                </Button>
                            </Col>
                            <Col lg="4">
                                <Button variant="success" className={buttonClasses} onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => props.handleShowRateModal()} title="Change Shift Assignment Rate">
                                    <FontAwesomeIcon icon={faMoneyBill} className="fa-fw" />
                                </Button>
                            </Col>
                            <Col lg="4">
                            </Col>
                        </Row>
                    }
                </Popover.Body>
            }
        </Popover>
    );

    const paddingOnButtonIfShiftSelectActive = props.shiftSelectActive ? " pe-3" : "";
 
    let requirementMisMatchIcon = (<></>);

    if (props.requirementsMisMatch) {
        requirementMisMatchIcon = (<FontAwesomeIcon icon={faBells} size="lg" className="me-2"/>);
    }

    return (
        <>  
            <OverlayTrigger trigger="focus" placement="bottom" overlay={popover}>
                <Button 
                    onClick={(e) => {
                        let element = e.target as HTMLElement;
                        if (element.classList.contains("btn")) {
                            element.focus();
                        } else {
                            let btn = element.closest("button");
                            if (btn) {
                                btn.focus();
                            }
                        }
                    }}
                    variant={props.shiftVariant} 
                    className={`position-relative rounded w-100 p-1 text-truncate${paddingOnButtonIfShiftSelectActive} ${styles.shift} shift ${props.shiftOverlapBorderColor}`}> 
                    <FontAwesomeIcon className="shiftOverlapBorderIcon" icon={faExclamationTriangle} size="lg"/>
                    <span className="overflow-hidden w-100 text-truncate d-lg-block">
                        {requirementMisMatchIcon}
                        <strong className="me-1">{props.duration}</strong>
                        <strong className="text-uppercase" title={`${props.clientFirstName} ${props.clientSurname}`}>@ {props.clientFirstName} {props.clientSurname}</strong>
                    </span>
                </Button>
            </OverlayTrigger>
        </>
    )
}

export default memo(ShiftAssignmentView);