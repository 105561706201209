import {
  TimesheetFetchPayload,
  TimesheetEntry,
  TimesheetPostPayload,
  TimesheetDeletePayload,
} from "../../app/types";
import axios, { AxiosInstance } from "axios";
import { getToken } from "../account/sessionManager";
import "../../environment";
import environment from "../../environment";

const api: AxiosInstance = axios.create({
  baseURL: environment.apiBaseAddress,
  validateStatus: (status) => true,
  timeout: 20000,
});

export function fetchTimesheets(payload: TimesheetFetchPayload) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  let startDate = payload.start ? "?startDate=" + payload.start : "";

  let endDate = payload.end ? "&endDate=" + payload.end : "";

  return new Promise<TimesheetEntry[]>((resolve, reject) =>
    api
      .get(
        "/carers/" +
          payload.carerId +
          "/timesheet-entries" +
          startDate +
          endDate,
        config
      )
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}

export function postTimesheet(payload: TimesheetPostPayload) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<TimesheetEntry>((resolve, reject) =>
    api
      .post(
        "/carers/" + payload.carerId + "/timesheet-entries",
        payload,
        config
      )
      .then((response) => {
        switch (response.status) {
          case 201:
            resolve(response.data);
            break;
          case 400:
              reject(response.data);
              break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}

export function deleteTimesheet(payload: TimesheetDeletePayload) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<string>((resolve, reject) =>
    api
      .delete(
        "/carers/" +
          payload.carerId +
          "/timesheet-entries/" +
          payload.timesheetEntryId,
        config
      )
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 400:
            reject(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}
