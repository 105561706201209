import { faLink } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { Alert, Button, ListGroup, Modal, Spinner } from 'react-bootstrap';

export type IProps = {
    showModal: boolean;
    shiftAssignmentDate: string | undefined;
    duration: string | undefined;
    carerFirstname: string;
    carerSurname: string;
    clientFirstname: string;
    clientSurname: string;
    loading: boolean;
    associatedShiftActive: boolean | undefined;
    assignedCarers: number | undefined;
    requiredCarers: number | undefined;
    onFormSubmit: () => void;
    handleCloseModal: () => void;
}

export default function ViewDeleteShiftAssignmentModal(props : IProps) {

    props.showModal && console.log(`show dlete modal for shift assignment date: ${props.shiftAssignmentDate}`);

    let loadingPlaceholder = useRef(<span />);

    if (props.loading) {
        loadingPlaceholder.current = (
            <div className="row">
                <Spinner
                    as="div"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    className="mt-4 text-fhah-green mx-auto"
                    >
                        <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    } else {
        loadingPlaceholder.current = <></>;
    }

    let shiftConnectedContainer;
    let inactiveShiftMessage;
    let linkedShiftDetails;
    if (props.associatedShiftActive !== undefined) {
        shiftConnectedContainer = (<span className={props.associatedShiftActive ? "shiftConnectedIcon text-success" : "shiftConnectedIcon text-danger"} title={props.associatedShiftActive ? "Shift template active." : "This shift assignment is disconnected from the shift template which created it."}><FontAwesomeIcon icon={faLink} /></span>);
        if (!props.associatedShiftActive) {
            inactiveShiftMessage = (<p className="text-danger mt-4">Please note, the associated shift has been removed and so will not re-appear in the open shifts area.</p>)
        } else {
            linkedShiftDetails = (
                <ListGroup.Item> 
                    <small className="mb-1 d-block text-black-50">Linked Shift</small>
                    <p>The linked shift requires {props.requiredCarers} carer(s) and has {props.assignedCarers} carer(s) assigned to it.</p>
                    <p>{props.assignedCarers && props.requiredCarers && props.assignedCarers > props.requiredCarers ? "As this is over assigned, removing this assignment will not make the shift appear in open shifts." : "Removing this assignment will make it appear in the open shifts area."} </p>
                </ListGroup.Item>
            )
        }
    }

    return (
        <Modal show={props.showModal} onHide={props.handleCloseModal}>
            <Modal.Header className="bg-fhah-purple text-white">
                <div className="d-flex justify-content-between w-100">
                    <Modal.Title>
                        {props.shiftAssignmentDate && <span>{props.shiftAssignmentDate}</span>}
                    </Modal.Title>
                    <Modal.Title>
                        {props.duration && <span>{props.duration}</span>}
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                {loadingPlaceholder.current}  
                <Alert className={props.loading ? "d-none": ""} variant={'danger'}>
                    <h4 className="alert-heading">Are you sure</h4>
                    Are you sure you want to remove this shift assignment?
                    {inactiveShiftMessage}
                </Alert>
                <div className={props.loading ? "d-none": ""}>
                    <ListGroup className="disabled">
                        <ListGroup.Item> 
                            <small className="mb-1 d-block text-black-50">Carer</small>
                            {props.carerFirstname} {props.carerSurname}
                        </ListGroup.Item>
                        <ListGroup.Item> 
                            <small className="mb-1 d-block text-black-50">Client</small>
                            {props.clientFirstname} {props.clientSurname}
                        </ListGroup.Item>
                        {linkedShiftDetails}
                    </ListGroup>
                </div>
            </Modal.Body>
            <Modal.Footer className={props.loading ? "d-none": "bg-light"}>
                {shiftConnectedContainer} 
                <Button variant="secondary" onClick={props.handleCloseModal}>Close</Button>
                <Button variant="primary" disabled={props.loading === true}  onClick={props.onFormSubmit}>Delete</Button>
            </Modal.Footer>
        </Modal>
    );
}