import { Accordion, Button, Modal, Row, Col, ListGroup } from 'react-bootstrap';
import { CarerModel } from '../carers/carerSlice';
import { ClientModel, selectClientModalFor, selectClientModalForCarer, hideClientModal } from './clientSlice';
import ClientDocument from "../client-document";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

export default function ClientModal() {
    const dispatch = useAppDispatch();

    const client: ClientModel | undefined = useAppSelector(selectClientModalFor);
    const carer: CarerModel | undefined = useAppSelector(selectClientModalForCarer);

    const handleCloseModal = () => {
        dispatch(hideClientModal());
    }

    let modalHeader;
    let modalBody;

    if (client) {
        modalHeader = (
            <Modal.Header className="bg-fhah-purple text-white">
                <Modal.Title>
                    {client.firstName} {client.surname}
                </Modal.Title>
            </Modal.Header>
        )

        modalBody = (
            <Modal.Body>
                <Row>
                    <Col className="col-12 col-lg-6 mt-4">
                        <h5>Name</h5>
                        <ListGroup className="disabled">
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Title</small>
                                {client.title}
                            </ListGroup.Item>
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">First name</small>
                                {client.firstName}
                            </ListGroup.Item>
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Surname</small>
                                {client.surname}
                            </ListGroup.Item>
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Preferred Name</small>
                                {client.preferredName}
                            </ListGroup.Item>
                            {client.phone && <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Phone</small>
                                {client.phone}
                            </ListGroup.Item>}
                            {client.tellContactInfo && <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Contact Information</small>
                                {client.tellContactInfo}
                            </ListGroup.Item>}
                        </ListGroup>
                    </Col>
                    <Col className="col-12 col-lg-6 mt-4">
                        <h5>Address</h5>
                        <ListGroup className="disabled">
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Line 1</small>
                                {client.address.line1}
                            </ListGroup.Item>
                            {client.address.line2 && 
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Line 2</small>
                                {client.address.line2}
                            </ListGroup.Item>}
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">City</small>
                                {client.address.city}
                            </ListGroup.Item>
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Post code</small>
                                {client.address.postCode}
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
                {client.careRequirements.length > 0 &&
                <Row>
                    <Col className="col-12 mt-4">
                        <h5>Care Requirements</h5>
                        <ListGroup className="disabled">
                            {client.careRequirements.map((x) => {
                                let classNames = 'care-requirement border-success';
                                switch (x.difficulty) {
                                    case 30:
                                        classNames = 'care-requirement border-danger';
                                        break;
                                    case 20:
                                        classNames = 'care-requirement border-warning';
                                        break;
                                    default:
                                        classNames = 'care-requirement border-success';
                                        break;
                                } 
                                return (
                                    <ListGroup.Item key={`c-r-${x.id}`} className={classNames}>
                                        {x.name}
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    </Col>
                </Row>}
                {carer && carer.canAddressRequirements.length > 0 &&
                <Row>
                    <Col className="col-12 mt-4">
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <h5>{carer.firstName} {carer.surname} can address:</h5>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ListGroup className="disabled">
                                        {carer.canAddressRequirements.map((x) => {
                                            let classNames = 'care-requirement border-success';
                                            switch (x.difficulty) {
                                                case 30:
                                                    classNames = 'care-requirement border-danger';
                                                    break;
                                                case 20:
                                                    classNames = 'care-requirement border-warning';
                                                    break;
                                                default:
                                                    classNames = 'care-requirement border-success';
                                                    break;
                                            } 
                                            return (
                                                <ListGroup.Item key={`c-r-${x.id}`} className={classNames}>
                                                    {x.name}
                                                </ListGroup.Item>
                                            )
                                        })}
                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>}
                {
                    client.hasClientDocument === true ? 
                        (<ClientDocument client={client} />) 
                        : 
                        (<></>)
                }
                </Modal.Body>
        )
    }

    return (
        <Modal show={client !== undefined} onHide={handleCloseModal}>
            {modalHeader}
            {modalBody}
            <Modal.Footer className="bg-light">
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}