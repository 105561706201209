import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Address } from '../../app/types';
import { getCarers, putCarer } from './carerApi';
import { toast, Flip } from "react-toastify";

// Types ------------------------------------------
export type PutPayload = {
  id: string;
  model: UpdateCarerModel;
}

export type CarerResponse = {
  carers: CarerModel[];
}

export type CarerModel = {
  id: string;
  title: string;
  firstName: string;
  surname: string;
  preferredName: string;
  phone: string;
  address: Address;
  version: string;
  canAddressRequirements: CarerCanAddressRequirementModel[];
  accountNo: string;
  sortCode: string;
}

export type UpdateCarerModel = {
  title: string;
  firstName: string;
  surname: string;
  preferredName: string;
  phone: string;
  address: Address;
  accountNo: string;
  sortCode: string;
  version: string;
}

export type CarerCanAddressRequirementModel = {
  id: string;
  careRequirementId: string;
  name: string;
  difficulty: number;
};

// State ------------------------------------------
export interface CarerState {
    list: CarerModel[];
    status: 'initial' | 'idle' | 'loading' | 'failed';
    putStatus: 'initial' | 'idle' | 'loading' | 'failed';
    selectedCarer: CarerModel | undefined;
}

const initialState: CarerState = {
    list: [],
    status: 'initial',
    putStatus: 'initial',
    selectedCarer: undefined,
};

// Reducer --------------------------------------------------
export const getCarersAsync = createAsyncThunk(
    'carer/getCarers',
    async () => {
      const response = await getCarers();
      return response.carers;
    }
);

export const putCarerAsync = createAsyncThunk(
    'carer/putCarer',
    async (putPayload: PutPayload) => {
      const response = await putCarer(putPayload);
      return response;
    }
);

export const carerSlice = createSlice({
    name: 'carer',
    initialState,
    reducers: {
      resetCarers: (state) => {
        state.list = [];
        state.status = 'initial';
        state.selectedCarer = undefined;
      },
      selectCarer: (state, action: PayloadAction<CarerModel>) => {
        state.selectedCarer = action.payload;
      },
      clearSelectCarer: (state) => {
        state.selectedCarer = undefined;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getCarersAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(getCarersAsync.fulfilled, (state, action) => {
          state.status = 'idle';
          state.list = action.payload;
        })
        .addCase(putCarerAsync.pending, (state) => {
          state.putStatus = 'loading';
        })
        .addCase(putCarerAsync.fulfilled, (state, action) => {
          let updatedList = state.list as CarerModel[];
          state.putStatus = 'idle';
          const carerIndex = updatedList.findIndex(x => x.id === action.payload.id);
          if (carerIndex !== -1) {
            updatedList.splice(carerIndex, 1, action.payload);
          }
          state.list = updatedList;
        })
        .addCase(putCarerAsync.rejected, (state, action) => {
          state.putStatus = 'failed';
          toast.error(action.error.message, {
            position: "top-right",
            transition: Flip,
            closeButton: true,
            autoClose: 4000,
            toastId: "unsuccessful-fetch",
          });
        });
    },
  });
  
  export const { resetCarers, selectCarer, clearSelectCarer } = carerSlice.actions;

  export const selectCarerSliceStatus = (state: RootState) => state.carers.status;

  export const selectCarerPutStatus = (state: RootState) => state.carers.putStatus; 

  export const selectCarers = (state: RootState) => state.carers.list;

  export const selectCarersAsDictionary = (state: RootState) => {
    let carerDictionary = new Map();

    // Convert list of carers into typescript map dictionary using carer id as key
    for (let carer of state.carers.list) {
        let carerInDictionary = carerDictionary.get(carer.id);
  
        if (!carerInDictionary) {
            // No match found add a carer entry
            carerDictionary.set(carer.id, carer);
        }
    }
  
    return carerDictionary;
  }

  export const selectedCarer = (state: RootState) => state.carers.selectedCarer;

  export default carerSlice.reducer;