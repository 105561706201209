import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Button, Modal, Spinner } from "react-bootstrap";
import { clear, clientDocument, clientDocumentStatus, fetchClientDocumentAsync } from './ClientDocumentSlice';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
 
import { useEffect, useState } from 'react';
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";
import { ClientModel } from '../clients/clientSlice';

export type IProps = {
    client: ClientModel;
}

export default function ClientDocument(props: IProps) {
    const currentClientDocumentStatus = useAppSelector(clientDocumentStatus);
    const appInsights = useAppInsightsContext();
    useTrackMetric(appInsights, "GlobalDocumentList");

    const currentClientDocument = useAppSelector(clientDocument);
    const dispatch = useAppDispatch();
    
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        dispatch(clear());
        setShow(false);
    }

    useEffect(() => {
        if (show) {
            dispatch(fetchClientDocumentAsync(props.client.id));
        }
    }, [dispatch, show, props.client.id]);

    let document = (<></>);
    let spinner = (<></>);

    if (currentClientDocument !== null) {
        document = (
            <embed src={currentClientDocument.clientSasToken +"#toolbar=0&embedded=true"} width="100%" height="100%" type="application/pdf"></embed>
        );
    }

    if (currentClientDocumentStatus === "loading") {
        spinner = (
            <div className="row">
                <Spinner
                    as="div"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    className="mt-4 text-fhah-green mx-auto"
                    >
                        <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }
  
    return (
        <div className="my-4  d-flex">
            <Modal show={show} onHide={handleClose} fullscreen={true}>
                <Modal.Header className="bg-fhah-purple text-white">
                    <Modal.Title>
                        {props.client.firstName} {props.client.surname} Needs Doc
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="overflow-hidden">
                    {document}
                    {spinner}
                </Modal.Body>
                <Modal.Footer className="bg-light">
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Button variant="link" size="lg" onClick={handleShow} type="button"   className="flex-grow-1 align-self-center text-start text-truncate text-wrap lead p-0 fhah-purple-link">{props.client.firstName} {props.client.surname} Needs Doc</Button>
            <Button className="align-self-end" onClick={handleShow} ><FontAwesomeIcon icon={faEye} /></Button> 
        </div>
    )
}