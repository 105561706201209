import ClientList from "./ClientList";
import { ReactElement } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import moment, { Moment } from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentSlash } from "@fortawesome/pro-duotone-svg-icons";
import { ChatMessage } from "../../app/types";
import RemoveModal from "./RemoveModal"

interface IProps {
    setRemovePopupForChatMessage : (chatMessage: ChatMessage | null) => void;
    currentClientName : string | undefined;
    chatList: ChatMessage[] | undefined;
    activeClientId: string | undefined;
    loggedOnAsId: string | null;

    loadingBar: boolean, 
    bottomLoadingBar: boolean,
    triggeredScrollMessage: boolean,
    setTriggeredScrollMessage: (boolean: boolean) => void;
    hasLastScrolled: boolean;
    setHasLastScrolled: (boolean: boolean) => void;
    chatListOpen: boolean;
    setChatListOpen: (boolean: boolean) => void;
    isAdmin: boolean | null | string,
    message: string| undefined,
    setMessage: (message: string) => void;
    removePopupForChatMessageName : string | null,
    removePopupForChatMessageSent: string | null,
    removePopupChatMessage: string | null,

    scrollBarScroll: (event: any) => void,
    onMessageAreaClick: (event: any) => void,
    broadcastChatMessage: () => void,
    removeMessage: () => void,
    showRemovePopup: boolean
}

export default function ChatSystemView(props: IProps) {

    const activeClientId = props.activeClientId;

    let loadingBar = (<></>)

    let bottomLoadingBar = (<></>)

    if (props.loadingBar) {
        loadingBar = (
            <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                className="mb-4 text-fhah-green position-absolute"
            >
            </Spinner>
        );
    }

    if (props.bottomLoadingBar) {
        bottomLoadingBar = (
            <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                className="mb-4 text-fhah-green position-absolute"
            >
            </Spinner>
        );
    }

    const handleShowRemoveModal = (chatMessage: ChatMessage) => {
        props.setRemovePopupForChatMessage(chatMessage);
    }

    let cardHeader = props.currentClientName !== undefined ?  props.currentClientName : "Chat"

    const createMessage = (message: ChatMessage, index: number): ReactElement => {
        let encodedSentById = message.sentById;
        let encodedName = message.firstName + " " + message.surname;
        let encodedMsg = message.message
        let sent = moment(message.sent);

        let timeBox = "";
        let removeBtn = ""
        let outerClasses = "";
        let classes = 'm-1 pt-1 px-2  pb-1 text-start clearfix text-white rounded ';
        let messageName = (<div className="text-white-50">{encodedName}</div>);
        switch (encodedSentById) {
            case props.loggedOnAsId:
                timeBox = "pt-1 order-2";
                removeBtn = "px-0 pb-0 pt-0 order-1";
                messageName = (<></>);
                outerClasses = classes.concat('float-end ');
                classes = classes.concat('bg-success  text-white float-end position-relative chat-bubble');
                break;
            default:
                timeBox = "pt-1 order-1";
                removeBtn = "px-0 pb-0 pt-0 order-2";
                outerClasses = classes.concat('float-start ');
                classes = classes.concat('bg-fhah-purple float-start position-relative chat-bubble');
                break;
        }

        return (
            <div id={`message-${message.id}`} key={`message-${index}`}>
                <div className={outerClasses}>
                    <div className={classes}>
                        <small>{messageName}</small>
                        <div className="pre-wrap text-start ">{encodedMsg}</div>
                        <div className=" text-white-50  d-flex align-items-end justify-content-between">
                            {props.isAdmin ? (<Button onClick={() => {handleShowRemoveModal(message)}} variant="link" size="sm" className={removeBtn}> <FontAwesomeIcon icon={faCommentSlash}/></Button>) :""}
                            
                            <span className={timeBox}><small>{sent.format("HH:mm")}</small></span>
                        </div>
                    </div>
                    <div className="clearfix "></div>
                </div>
                <div className="clearfix "></div>
            </div>
        );
    }    
 
    const createDateBar = (sent: Moment, index: number): ReactElement => {
        return (
            <div className="border-bottom mx-5" key={`date-${sent.date}${index}`}>
                <div className=" text-black-50 text-center pe-1"><span className="mt-4">{sent.format("DD/MM/YYYY")}</span></div>
            </div>
        );
    }   

    const lastViewedBar = (index: number, isLastMessage: Boolean): ReactElement => {
        let outerboxClasses = "border-bottom mx-5";
        outerboxClasses = isLastMessage ? outerboxClasses + " d-none": outerboxClasses
        return (
            <div id="last-seen-box"  className={outerboxClasses} key={`last-viewed-bar-${index}`}>
                <div className=" text-black-50 text-center pe-1"><span className="mt-4">Last Viewed message</span></div>
            </div>
        );
    }   
    
    let chatMessages: ReactElement[] = [];

    if (props.chatList) {
        let date : Moment | null = null;
        for (let index = 0; index < props.chatList.length; index++) {

            const message = props.chatList[index];
            let currentDate = moment(message.sent);
            
            if (date === null || !currentDate.isSame(date, "day")) {
                date = currentDate;
                chatMessages.push(createDateBar(currentDate, index))
            }

            chatMessages.push(createMessage(message, index));
            if (message.isLastViewed) {
                chatMessages.push(lastViewedBar(index, props.chatList.length === index + 1))
                if (!props.triggeredScrollMessage && !props.setHasLastScrolled) {
                    props.setTriggeredScrollMessage(true);
                }             
            }     
        }
    }

    const broadcastChatMessage = (event: React.MouseEvent<HTMLElement>) => {
        props.broadcastChatMessage()
        document.getElementById("message")?.focus();
        event.preventDefault();
    }
    
    const messageOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.setMessage(event.target.value);

    }

    return (
        <Row className="position-relative no-gutters h-100">
            <ClientList handleToggle={(toggleState: boolean) => {props.setChatListOpen(toggleState)}} />
            {activeClientId ? 
            (            
                <Col className={`h-100 ${!props.chatListOpen ? "": " d-none d-lg-block"}`}>
                    <Card className="ms-4 me-2 h-100 ">
                        <Card.Header className="bg-fhah-green text-white">
                            <h4>{cardHeader}</h4>
                        </Card.Header>
                        <Card.Body className="d-flex flex-column chat-message-card-body p-1">
                            <div>
                                {loadingBar}
                            </div>
                            <div id="messages" className="bg-light overflow-scroll d-flex flex-column-reverse" onClick={(e) => {props.onMessageAreaClick(e)}} onScroll={(e) =>props.scrollBarScroll(e)}>
                                <div>
                                    {chatMessages}
                                </div>
                            </div>
                            <div>
                                {bottomLoadingBar}
                            </div>
                            <div className="d-inline-flex mt-2 send-message-area">
                                <div className="flex-grow-1 me-1">
                                    <textarea id="message"
                                        className="w-100 p-1 h-100"
                                        placeholder="message..." value={props.message} onChange={(e) => messageOnChange(e)}></textarea>
                                </div>
                                <div className="col-auto">
                                    <Button className="btn-success" id="sendmessage" onClick={(e) => broadcastChatMessage(e)}>Send</Button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            ) : 
            ( 
                <Col className="h-100 d-none d-sm-block">
                    <Card className="ms-4 me-2 h-100">
                        <Card.Header className="bg-fhah-green text-white">
                            <h4>Select A Chat</h4>
                        </Card.Header>
                        <Card.Body>

                        </Card.Body>
                    </Card>
                </Col>
            )}
            <RemoveModal
                setRemovePopupForChatMessage={(chatMessage: ChatMessage | null) => {props.setRemovePopupForChatMessage(chatMessage)}}
                currentClientName={props.currentClientName}
                removeMessage={() => {props.removeMessage()}}
                showRemovePopup={props.showRemovePopup}
                removePopupForChatMessageName={props.removePopupForChatMessageName}
                removePopupForChatMessageSent={props.removePopupForChatMessageSent}
                removePopupChatMessage={props.removePopupChatMessage}
            />
        </Row>
    );
}