import { Button, Modal, Row, Col, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-duotone-svg-icons';
import SelectListInput from '../../app/generic-components/SelectListInput';
import { SelectListItem } from '../../app/model';

export type IProps = {
    showModal: boolean;
    shiftAssignmentDate: string;
    duration: string | undefined;
    rateId: string;
    rateIdChange: boolean;
    loading: boolean;
    shiftAssignmentUpdateStatus: string;
    updateShiftEnabled: boolean;
    rateIdInvalid: boolean;
    rateSelectListItems: SelectListItem[];
    handleCloseModal: () => void;
    onShiftRateInputChange: (value : string) => void;
    updateThisAssignment: () => void;
    updateShiftAndAssignments: () => void;
}

export default function ViewShiftAssignmentRateModal(props : IProps) {
    const onRateIdInputChange = (id : string) => {
        props.onShiftRateInputChange(id);
    };

    let loadingPlaceholder;

    if (props.loading) {
        loadingPlaceholder = (
            <div className="row">
                <Spinner
                    as="div"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    className="mt-4 text-fhah-green mx-auto"
                    >
                        <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <Modal show={props.showModal} onHide={props.handleCloseModal}>
            <Modal.Header className="bg-fhah-purple text-white">
                <div className="d-flex justify-content-between w-100">
                    <Modal.Title>
                        <span>{props.shiftAssignmentDate}</span>
                    </Modal.Title>
                    <Modal.Title>
                        <span>{props.duration}</span>
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                {loadingPlaceholder}  
                <Row>
                    <Col className=" mt-4">
                        <SelectListInput selectListItems={props.rateSelectListItems} name="Rate" currentValue={props.rateId} changeSelectList={(Id: string) => onRateIdInputChange(Id)} isInvalid={props.rateIdInvalid && props.rateIdChange}/>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className={props.loading ? "d-none": "bg-light"}>
                <Button variant="secondary" onClick={props.handleCloseModal}>Close</Button>
                <Button variant="primary" disabled={props.shiftAssignmentUpdateStatus === "loading"}  onClick={props.updateThisAssignment}><FontAwesomeIcon icon={faSave}/> Update this assignment</Button>
                {props.updateShiftEnabled && <Button variant="primary" disabled={props.shiftAssignmentUpdateStatus === "loading"}  onClick={props.updateShiftAndAssignments}><FontAwesomeIcon icon={faSave}/> Update shift template and assignments from tomorrow.</Button>}
            </Modal.Footer>
        </Modal>
    );
}