import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  CarerHolidayModel,
  CreateCarerHoliday,
  CarerHolidayPayload,
  RosterDates,
} from "../../app/types";
import {
  fetchCarerHolidays,
  postCarerHoliday,
  deleteCarerHoliday,
} from "./carerHolidayApi";
import { toast, Flip } from "react-toastify";
import moment from "moment";

export interface CarerHolidayState {
    list: CarerHolidayModel[];
    listDates: undefined | RosterDates;
    listStatus: "initial" | "idle" | "loading" | "failed";
    postStatus: "idle" | "pending" | "failed";
    deleteStatus: "idle" | "pending" | "success" | "failed";
}

const initialState: CarerHolidayState = {
    list: [],
    listDates: undefined,
    listStatus: "initial",
    postStatus: "idle",
    deleteStatus: "idle",
};

export const fetchCarerHolidaysAsync = createAsyncThunk(
    "carer-holidays/fetchCarerHolidays",
    async (rosterDates: RosterDates) => {
      const response = await fetchCarerHolidays(rosterDates);
      const payload: CarerHolidayPayload = {
        holidays: response,
        range: rosterDates,
      };
      return payload;
    }
);

export const postCarerHolidayAsync = createAsyncThunk(
    "carer-holidays/postCarerHoliday",
    async (postPayload: CreateCarerHoliday) => {
      const response = await postCarerHoliday(postPayload);
      return response;
    }
);
  
export const deleteCarerHolidayAsync = createAsyncThunk(
    "carer-holidays/deleteCarerHoliday",
    async (id: string) => {
      await deleteCarerHoliday(id);
    }
);

export const carerHolidaySlice = createSlice({
    name: "carerHolidays",
    initialState,
    reducers: {
      resetHolidayDeleteStatus: (state) => {
        state.deleteStatus = "idle";
      },
      addNewHoliday: (state, action: PayloadAction<CarerHolidayModel>) => {
        const updatedList = state.list;
        updatedList.push(action.payload);
        state.list = updatedList;
      },
      removeHoliday: (state, action: PayloadAction<string>) => {
        const index = state.list.findIndex(x => x.id === action.payload);
        if (index !== -1) {
          let updatedList = state.list;
          updatedList.splice(index, 1);
          state.list = updatedList;
        }
      },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCarerHolidaysAsync.pending, (state) => {
                state.listStatus = "loading";
                state.list = [];
            })
            .addCase(fetchCarerHolidaysAsync.fulfilled, (state, action) => {
                state.listStatus = "idle";
                state.list = action.payload.holidays;
                state.listDates = action.payload.range;
            })
            .addCase(fetchCarerHolidaysAsync.rejected, (state) => {
                state.listStatus = "failed";
                state.list = [];
            })
            .addCase(postCarerHolidayAsync.pending, (state) => {
                state.postStatus = "pending";
            })
            .addCase(postCarerHolidayAsync.fulfilled, (state, action) => {
                state.postStatus = "idle";
            })
            .addCase(postCarerHolidayAsync.rejected, (state, action) => {
                state.postStatus = "failed";

                toast.error(action.error.message, {
                  position: "top-right",
                  transition: Flip,
                  closeButton: true,
                  autoClose: 4000,
                  toastId: "validation-error",
                });
            })
            .addCase(deleteCarerHolidayAsync.pending, (state) => {
                state.deleteStatus = "pending";
            })
            .addCase(deleteCarerHolidayAsync.fulfilled, (state) => {
                state.deleteStatus = "success";
            })
            .addCase(deleteCarerHolidayAsync.rejected, (state, action) => {
                state.deleteStatus = "failed";

                toast.error(action.error.message, {
                  position: "top-right",
                  transition: Flip,
                  closeButton: true,
                  autoClose: 4000,
                  toastId: "validation-error",
                });
            });
    },
});

export const selectCarerHolidaysAsDictionary = (state: RootState) => {
  let carerHolidaysDictionary = new Map();

  if (state.shiftAssignment.shiftAssignmentList) {
    // Convert list of carer holidays into typescript map dictionary for carer Id and inner dictionaries using date and then holiday id
    for (let holiday of state.carerHolidays.list) {
        let dictionaryForCarer = carerHolidaysDictionary.get(holiday.carerId);

        if (!dictionaryForCarer) {
            // No match found add a carer Id entry
            carerHolidaysDictionary.set(holiday.carerId, new Map());
            dictionaryForCarer = carerHolidaysDictionary.get(holiday.carerId);
        }

        let dictionaryForCarerAndDate = dictionaryForCarer.get(moment(holiday.date).format('YYYY-MM-DD'));

        if (!dictionaryForCarerAndDate) {
            // No match found add a dictionary for date
            dictionaryForCarer.set(moment(holiday.date).format('YYYY-MM-DD'), new Map());
            dictionaryForCarerAndDate = dictionaryForCarer.get(moment(holiday.date).format('YYYY-MM-DD'));
        }

        let dictionaryEntryForCarerAndDateAndId = dictionaryForCarerAndDate.get(holiday.id);

        if (!dictionaryEntryForCarerAndDateAndId) {
            // No match found add the holiday for date entry
            dictionaryForCarerAndDate.set(holiday.id, holiday);
        }
    }
  }

  return carerHolidaysDictionary;
}

export const selectCarerHolidayListStatus = (state: RootState) =>
  state.carerHolidays.listStatus;

export const selectCarerHolidayDeleteStatus = (state: RootState) =>
  state.carerHolidays.deleteStatus;

export const {
  resetHolidayDeleteStatus,
  addNewHoliday,
  removeHoliday,
} = carerHolidaySlice.actions;

export default carerHolidaySlice.reducer;