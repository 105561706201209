import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  MonthlyRecurringShift,
  MonthlyRecurringShiftResponse,
  MonthlyRecurringShiftVersioned,
  UpdateRosteredShiftRateModel,
  UpdateRosteredShiftStartTimeModel,
} from "../../app/types";

import { toast, Flip } from "react-toastify";
import {
  getMonthlyRecurringShift,
  postMonthlyRecurringShift,
  putMonthlyRecurringShiftt,
  patchMonthlyRecurringShiftStartTime,
  patchMonthlyRecurringShiftRate,
} from "./MonthlyRecurringShiftApi";

export interface MonthlyRecurringShiftState {
  monthlyRecurringShiftResponse:
    | MonthlyRecurringShiftResponse
    | MonthlyRecurringShiftVersioned
    | null;
  status: "idle" | "loading" | "failed";
  formState: "success" | "error" | "sending" | "default" | "loaded";
  patchStartTimeStatus: "initial" | "idle" | "loading" | "success" | "failed";
  patchRateStatus: "initial" | "idle" | "loading" | "success" | "failed";
}

const initialState: MonthlyRecurringShiftState = {
  monthlyRecurringShiftResponse: null,
  status: "idle",
  formState: "default",
  patchStartTimeStatus: "initial",
  patchRateStatus: "initial",
};

export const getMonthlyRecurringShiftAsync = createAsyncThunk(
  "monthly-recurring-shift/getMonthlyRecurringShift",
  async (id: string) => {
    const response = await getMonthlyRecurringShift(id);
    return response;
  }
);

export const postMonthlyRecurringShiftAsync = createAsyncThunk(
  "monthly-recurring-shift/postMonthlyRecurringShift",
  async (postPayload: MonthlyRecurringShift) => {
    const response = await postMonthlyRecurringShift(postPayload);
    return response;
  }
);

export const putMonthlyRecurringShiftAsync = createAsyncThunk(
  "monthly-recurring-shift/putMonthlyRecurringShift",
  async (putPayload: MonthlyRecurringShiftVersioned) => {
    const response = await putMonthlyRecurringShiftt(putPayload);
    return response;
  }
);

export const patchMonthlyRecurringShiftStartTimeAsync = createAsyncThunk(
  "monthly-recurring-shift/patchMonthlyRecurringShiftStartTime",
  async (patchPayload: UpdateRosteredShiftStartTimeModel) => {
      const response = await patchMonthlyRecurringShiftStartTime(patchPayload);
      return response;
    }
);

export const patchMonthlyRecurringShiftRateAsync = createAsyncThunk(
  "monthly-recurring-shift/patchMonthlyRecurringShiftRate",
  async (patchPayload: UpdateRosteredShiftRateModel) => {
      const response = await patchMonthlyRecurringShiftRate(patchPayload);
      return response;
    }
);

export const monthlyRecurringShiftSlice = createSlice({
  name: "AdHocShift",
  initialState,
  reducers: {
    monthlyResetForm: (state) => {
      state.monthlyRecurringShiftResponse = null;
      state.formState = "default";
      state.status = "idle";
      state.patchStartTimeStatus = "idle";
      state.patchRateStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMonthlyRecurringShiftAsync.pending, (state) => {
        state.status = "loading";
        state.formState = "sending";
      })
      .addCase(getMonthlyRecurringShiftAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.formState = "loaded";
        state.monthlyRecurringShiftResponse = action.payload;
      })
      .addCase(getMonthlyRecurringShiftAsync.rejected, (state, action) => {
        state.status = "idle";
        state.formState = "error";
        state.monthlyRecurringShiftResponse = null;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "validation-error",
        });
      })
      .addCase(postMonthlyRecurringShiftAsync.pending, (state) => {
        state.status = "loading";
        state.formState = "sending";
      })
      .addCase(postMonthlyRecurringShiftAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.formState = "success";
        state.monthlyRecurringShiftResponse = action.payload;
      })
      .addCase(postMonthlyRecurringShiftAsync.rejected, (state, action) => {
        state.status = "idle";
        state.formState = "error";
        state.monthlyRecurringShiftResponse = null;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "validation-error",
        });
      })
      .addCase(putMonthlyRecurringShiftAsync.pending, (state) => {
        state.status = "loading";
        state.formState = "sending";
      })
      .addCase(putMonthlyRecurringShiftAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.formState = "success";
        state.monthlyRecurringShiftResponse = action.payload;
      })
      .addCase(putMonthlyRecurringShiftAsync.rejected, (state, action) => {
        state.status = "idle";
        state.formState = "error";
        state.monthlyRecurringShiftResponse = null;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "validation-error",
        });
      })
      .addCase(patchMonthlyRecurringShiftStartTimeAsync.pending, (state) => {
        state.patchStartTimeStatus = "loading";
      })
      .addCase(patchMonthlyRecurringShiftStartTimeAsync.fulfilled, (state, action) => {
        state.patchStartTimeStatus = "success";
        toast.info("Update Complete", {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 1000,
          toastId: "successful-monthly-recurring-shift-update",
        });
      })
      .addCase(patchMonthlyRecurringShiftStartTimeAsync.rejected, (state, action) => {
        state.patchStartTimeStatus = "failed";
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful-monthly-recurring-shift-update",
        });
      })
      .addCase(patchMonthlyRecurringShiftRateAsync.pending, (state) => {
        state.patchRateStatus = "loading";
      })
      .addCase(patchMonthlyRecurringShiftRateAsync.fulfilled, (state, action) => {
        state.patchRateStatus = "success";
        toast.info("Update Complete", {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 1000,
          toastId: "successful-monthly-recurring-shift-rate-update",
        });
      })
      .addCase(patchMonthlyRecurringShiftRateAsync.rejected, (state, action) => {
        state.patchRateStatus = "failed";
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful-monthly-recurring-shift-rate-update",
        });
      });
  },
});
export const status = (state: RootState) => state.monthlyRecurringShift.status;

export const monthlyFormState = (state: RootState) =>
  state.monthlyRecurringShift.formState;

export const selectMonthlyShiftPatchStartTimeStatus = (state: RootState) =>
  state.monthlyRecurringShift.patchStartTimeStatus;

  export const selectMonthlyShiftPatchRateStatus = (state: RootState) =>
  state.monthlyRecurringShift.patchRateStatus;

export const { monthlyResetForm } = monthlyRecurringShiftSlice.actions;

export const loadedMonthlyRecurringShift = (state: RootState) =>
  state.monthlyRecurringShift.formState === "loaded"
    ? state.monthlyRecurringShift.monthlyRecurringShiftResponse
    : null;

export default monthlyRecurringShiftSlice.reducer;
