import { Row, Col, FormControl, FloatingLabel, FormCheck, InputGroup, Form} from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import { SelectListItem, ShiftType } from "../../app/model";
import SelectListInput from '../../app/generic-components/SelectListInput'
import { ClientModel, selectClients, selectClientsAsDictionary } from "../clients/clientSlice";
import { useEffect, useRef, useState } from "react";
import styles from './shift-form.module.css';

interface IProps {
    valid: boolean;
    clientId: string;
    clientIdInvalid: boolean | undefined;
    clientChange: boolean;
    rateId: string;
    rateIdInvalid: boolean | undefined;
    rateIdChange: boolean;
    shiftDate: string;
    shiftDateInvalid: boolean | undefined;
    shiftDateChange: boolean;
    shiftStartTime: string;
    shiftStartTimeInvalid: boolean | undefined;
    shiftStartTimeChange: boolean;
    shiftLength: string;
    shiftLengthInvalid: boolean | undefined;
    shiftLengthChange: boolean;
    shiftLength24H: boolean;
    numberOfCarers: number;
    numberOfCarersInvalid: boolean | undefined;
    numberOfCarersChange: boolean;
    typeOfShift: ShiftType;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    recurrenceStart: string;
    recurrenceStartInvalid: boolean | undefined;
    recurrenceStartChange: boolean;
    recurrenceEnd: string;
    recurrenceEndInvalid: boolean | undefined;
    recurrenceEndChange: boolean;
    recurrenceHasEnd: boolean;
    recurEveryNWeeks: number;
    recurEveryNWeeksInvalid: boolean | undefined;
    recurEveryNWeeksChange: boolean;
    firstFullWeekOfMonth: boolean;
    secondFullWeekOfMonth: boolean;
    thirdFullWeekOfMonth: boolean;
    fourthFullWeekOfMonth: boolean;
    lastFullWeekOfMonth: boolean;
    onClientIdInputChange: (value : string) => void;
    onRateIdInputChange: (value : string) => void;
    onShiftDateInputChange: (value : string) => void;
    onShiftStartTimeInputChange: (value : string) => void;
    onShiftLengthInputChange: (value : string) => void;
    onNumberOfCarersInputChange: (value : string) => void;
    onRecurrenceStartChange: (value : string) => void;
    onRecurrenceEndChange: (value : string) => void;
    onRecurEveryNWeeksInputChange: (value : string) => void;
    onMondayChange: (value : boolean) => void;
    onTusdayChange: (value : boolean) => void;
    onWednesdayChange: (value : boolean) => void;
    onThursdayChange: (value : boolean) => void;
    onFridayChange: (value : boolean) => void;
    onSaturdayChange: (value : boolean) => void;
    onSundayChange: (value : boolean) => void;
    onFirstFullWeekOfMonthChange: (value : boolean) => void;
    onSecondFullWeekOfMonthChange: (value : boolean) => void;
    onThirdFullWeekOfMonthhange: (value : boolean) => void;
    onFourthFullWeekOfMonthChange: (value : boolean) => void;
    onLastFullWeekOfMonthChange: (value : boolean) => void;
    onTypeOfShiftChange: (value : ShiftType) => void;

    onRecurrenceHasEnd: (value : boolean) => void;
    onShiftLenthIs24H: (value : boolean) => void;
    loading: boolean;
    isEdit: boolean;
}
export default function AdHocShiftForm(props : IProps) {

    const onClientIdInputChange = (id : string) => {
        props.onClientIdInputChange(id);
    };

    const onRateIdInputChange = (id : string) => {
        props.onRateIdInputChange(id);
    };

    const onShiftDateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onShiftDateInputChange(e.target.value);
    };

    const onShiftStartTimeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onShiftStartTimeInputChange(e.target.value);
    };

    const onShiftLenthIs24H = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onShiftLenthIs24H(e.currentTarget.checked);
    };

    const onShiftLengthInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onShiftLengthInputChange(e.target.value);
    };

    const onNumberOfCarersInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onNumberOfCarersInputChange(e.target.value);
    };

    const onRecurrenceStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onRecurrenceStartChange(e.target.value);
    };

    const onRecurrenceHasEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onRecurrenceHasEnd(e.currentTarget.checked);
    };

    const onRecurrenceEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onRecurrenceEndChange(e.target.value);
    };

    const onRecurEveryNWeeksInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onRecurEveryNWeeksInputChange(e.target.value);
    };

    const onMondayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onMondayChange(e.target.checked);
    };

    const onTusdayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onTusdayChange(e.target.checked);
    };
    
    const onWednesdayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onWednesdayChange(e.target.checked);
    };

    const onThursdayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onThursdayChange(e.target.checked);
    };

    const onFridayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onFridayChange(e.target.checked);
    };

    const onSaturdayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onSaturdayChange(e.target.checked);
    };
    
    const onSundayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onSundayChange(e.target.checked);
    };

    const onFirstFullWeekOfMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onFirstFullWeekOfMonthChange(e.target.checked);
    };

    const onSecondFullWeekOfMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onSecondFullWeekOfMonthChange(e.target.checked);
    };

    const onThirdFullWeekOfMonthhange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onThirdFullWeekOfMonthhange(e.target.checked);
    };

    const onFourthFullWeekOfMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onFourthFullWeekOfMonthChange(e.target.checked);
    };

    const onLastFullWeekOfMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onLastFullWeekOfMonthChange(e.target.checked);
    };

    const onTypeOfShiftChange = (shiftType: ShiftType) => {
        props.onTypeOfShiftChange(shiftType);
    };

    const clientDictionary: Map<string, ClientModel> = useAppSelector(selectClientsAsDictionary);
    const clientList: ClientModel[] = useAppSelector(selectClients);
    const [clientId, setClientId] = useState(props.clientId);
    const [rateId, setRateId] = useState(props.rateId);
    let clientSelectListItems = useRef<SelectListItem[]>(clientList.map(x => ({value:x.id, text:x.firstName +" "+ x.surname})));
    let rateSelectListItems = useRef<SelectListItem[]>([]);

    if (props.clientId !== "" && clientDictionary !== undefined) {
        const client = clientDictionary.get(clientId);
        if (client) {
            rateSelectListItems.current = client.clientRates.map(x => ({value:x.rateId, text: x.rateDescription }));
        }
    }

    let formVisibility = "";
    if (props.loading) {
        formVisibility = "d-none";
    }
    
    useEffect(() => {
        clientSelectListItems.current = clientList.map(x => ({value:x.id, text:x.firstName +" "+ x.surname}));
        rateSelectListItems.current = [];
    
        if (props.clientId !== "" && clientDictionary !== undefined) {
            const client = clientDictionary.get(props.clientId);
            if (client) {
                rateSelectListItems.current = client.clientRates.map(x => ({value:x.rateId, text: x.rateDescription }));
            }
        }
        setClientId(props.clientId);
        setRateId(props.rateId);
    }, [
        props.rateId,
        props.clientId,
        clientDictionary,
        clientList,
        props,
    ]);

    let adHocView = "mb-3 d-none";
    let weeklyAndMonthlyView = "mb-3 d-none";
    let weeklyView = "mb-3 d-none";
    let monthlyView = "mb-3 d-none";
    
    let hasEndRecurance = props.recurrenceHasEnd ?"d-none" : "";

    if (props.typeOfShift === ShiftType.AdHoc) {
        adHocView = "mb-3"
    }
    if (props.typeOfShift === ShiftType.Weekly) {
        weeklyView = "mb-3";
    }
    if (props.typeOfShift === ShiftType.Monthly) {
        monthlyView = "mb-3";
    }
    if (props.typeOfShift === ShiftType.Weekly ||  props.typeOfShift === ShiftType.Monthly) {
        weeklyAndMonthlyView = "mb-3";
    }

    let shiftLengthInput = null; 

    if (!props.shiftLength24H) {
        shiftLengthInput =(
            <Col>
                <FloatingLabel
                    controlId="shiftLength"
                    label="Shift Length"
                    className="mb-3"
                >
                    <FormControl step="60" type="time" value={props.shiftLength} onChange={onShiftLengthInputChange} isInvalid={props.shiftLengthInvalid && props.shiftLengthChange}/>
                    <Form.Control.Feedback type="invalid">
                        Invalid Shift Length
                    </Form.Control.Feedback>
                </FloatingLabel>
            </Col>
        );
    }

    let displayShiftType = null
    if (!props.isEdit) {
        displayShiftType = (<InputGroup>
            <FormCheck className="me-4 mb-3" type="radio" checked={props.typeOfShift === ShiftType.AdHoc} value={ShiftType.AdHoc} onChange={() =>onTypeOfShiftChange(ShiftType.AdHoc)} label="Ad Hoc"/>
            <FormCheck className="me-4 mb-3" type="radio" checked={props.typeOfShift === ShiftType.Weekly} value={ShiftType.Weekly} onChange={() =>onTypeOfShiftChange(ShiftType.Weekly)} label="Weekly"/>
            <FormCheck className="me-4 mb-3" type="radio" checked={props.typeOfShift === ShiftType.Monthly} value={ShiftType.Monthly} onChange={() => onTypeOfShiftChange(ShiftType.Monthly)} label="Monthly"/>
        </InputGroup>);
    }

    return (
        <Row className={formVisibility}>
            <Col className=" mt-4">
                {displayShiftType}
                {!props.isEdit ? 
                    (<SelectListInput selectListItems={clientSelectListItems.current} name="Client" currentValue={clientId} changeSelectList={(Id: string) => onClientIdInputChange(Id)} isInvalid={props.clientIdInvalid && props.clientChange}/>)
                    : ""}
                <SelectListInput selectListItems={rateSelectListItems.current} name="Rate" currentValue={rateId} changeSelectList={(Id: string) => onRateIdInputChange(Id)} isInvalid={props.rateIdInvalid && props.rateIdChange}/>
                <FloatingLabel
                    controlId="shiftDate"
                    label="Shift Date"
                    className={adHocView}
                >
                    <FormControl type="date"  value={props.shiftDate} onChange={onShiftDateInputChange} isInvalid={props.shiftDateInvalid && props.shiftDateChange}/>
                    <Form.Control.Feedback type="invalid">
                    Invalid date
                    </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel
                    controlId="shiftStartTime"
                    label="Shift Start Time"
                    className="mb-3"
                >
                    <FormControl step="60" type="time"  value={props.shiftStartTime} onChange={onShiftStartTimeInputChange} isInvalid={props.shiftStartTimeInvalid && props.shiftStartTimeChange}/>
                    <Form.Control.Feedback type="invalid">
                        Invalid Start Time
                    </Form.Control.Feedback>
                </FloatingLabel>

                <Row className={styles.checkboxFloatingLblRow } >
                    <Col md="auto">
                        <InputGroup>
                                    <FormCheck className={styles.formCheck} checked={props.shiftLength24H} value="true" onChange={onShiftLenthIs24H} label="24 hour"/>
                        </InputGroup>
                    </Col>
                    {shiftLengthInput}
                </Row>  

                <FloatingLabel
                    controlId="numberOfCarers"
                    label="Number Of Carers"
                    className="mb-3"
                >
                    <FormControl type="number"  value={props.numberOfCarers} onChange={onNumberOfCarersInputChange} isInvalid={props.numberOfCarersInvalid && props.numberOfCarersChange}/>
                    <Form.Control.Feedback type="invalid">
                        Invalid Number Of Carers
                    </Form.Control.Feedback>
                </FloatingLabel>
                <div className={weeklyAndMonthlyView}>
                    <h5>Recurrence</h5>
                    <FloatingLabel
                        controlId="RecuranceStart"
                        label="Start"
                        className="mb-3"
                    >
                        <FormControl type="date"  value={props.recurrenceStart} onChange={onRecurrenceStartChange} isInvalid={props.recurrenceStartInvalid && props.recurrenceStartChange}/>
                        <Form.Control.Feedback type="invalid">
                        Invalid date
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <Row className={styles.checkboxFloatingLblRow } >
                        <Col md="auto">
                            <InputGroup>
                                        <FormCheck className="my-3" checked={props.recurrenceHasEnd} value="true" onChange={onRecurrenceHasEnd} label="Recurring"/>
                            </InputGroup>
                        </Col>
                        <Col className={hasEndRecurance}>
                            <FloatingLabel
                                controlId="RecuranceEnd"
                                label="End"
                                className="mb-3"
                            >                        
                                <FormControl type="date"  value={props.recurrenceEnd} onChange={onRecurrenceEndChange} isInvalid={props.recurrenceEndInvalid && props.recurrenceEndChange}/>
                                <Form.Control.Feedback type="invalid">
                                Invalid date
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>  
                    <InputGroup>
                        <FormCheck checked={props.monday} value="true" onChange={onMondayChange} label="Monday"/>
                    </InputGroup>
                    <InputGroup>
                        <FormCheck checked={props.tuesday} value="true" onChange={onTusdayChange} label="Tuesday"/>
                        </InputGroup>
                    <InputGroup>
                        <FormCheck checked={props.wednesday} value="true" onChange={onWednesdayChange} label="Wednesday"/>
                        </InputGroup>
                    <InputGroup>
                        <FormCheck checked={props.thursday} value="true" onChange={onThursdayChange} label="Thursday"/>
                        </InputGroup>
                    <InputGroup>
                        <FormCheck checked={props.friday} value="true" onChange={onFridayChange} label="Friday"/>
                        </InputGroup>
                    <InputGroup>
                        <FormCheck checked={props.saturday} value="true" onChange={onSaturdayChange} label="Saturday"/>
                        </InputGroup>
                    <InputGroup>
                        <FormCheck checked={props.sunday} value="true" onChange={onSundayChange} label="Sunday"/>
                    </InputGroup>
                </div>
                <FloatingLabel
                    controlId="numberOfWeeks"
                    label="Recur every N Weeks"
                    className={weeklyView}
                >
                    <FormControl type="number" value={props.recurEveryNWeeks} onChange={onRecurEveryNWeeksInputChange} isInvalid={props.recurEveryNWeeksInvalid && props.recurEveryNWeeksChange}/>
                    <Form.Control.Feedback type="invalid">
                        Invalid Number Of Weeks
                    </Form.Control.Feedback>
                </FloatingLabel>
                <div className={monthlyView}>
                    <h6>Week of month</h6>
                    <InputGroup>
                        <FormCheck checked={props.firstFullWeekOfMonth} value="true" onChange={onFirstFullWeekOfMonthChange} label="First"/>
                    </InputGroup>
                    <InputGroup>
                        <FormCheck checked={props.secondFullWeekOfMonth} value="true" onChange={onSecondFullWeekOfMonthChange} label="Second"/>
                    </InputGroup>
                    <InputGroup>
                        <FormCheck checked={props.thirdFullWeekOfMonth} value="true" onChange={onThirdFullWeekOfMonthhange} label="Third"/>
                    </InputGroup>
                    <InputGroup>
                        <FormCheck checked={props.fourthFullWeekOfMonth} value="true" onChange={onFourthFullWeekOfMonthChange} label="Fourth"/>
                    </InputGroup>
                    <InputGroup>
                        <FormCheck checked={props.lastFullWeekOfMonth} value="true" onChange={onLastFullWeekOfMonthChange} label="Last"/>
                    </InputGroup>
                </div>
            </Col>
        </Row>
    );
}