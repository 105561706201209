import { useState } from 'react';
import { Button, Modal, Row, Col, Form, FloatingLabel, FormControl } from 'react-bootstrap';
import { CarerModel, putCarerAsync, PutPayload, selectCarerPutStatus, UpdateCarerModel } from './carerSlice';
import { toast, Flip } from "react-toastify";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Address } from '../../app/types';
import Loading from '../../app/generic-components/Loading';

export type IProps = {
    carer: CarerModel;
    show: boolean;
    handleClose: () => void;
}

export default function CarerEditModal(props: IProps) {
    const dispatch = useAppDispatch();
    const putStatus: string = useAppSelector(selectCarerPutStatus);
    
    const [title, setTitle] = useState<string>(props.carer.title);
    const [firstName, setFirstName] = useState<string>(props.carer.firstName);
    const [surname, setSurname] = useState<string>(props.carer.surname);
    const [preferredName, setPreferredName] = useState<string>(props.carer.preferredName);
    const [phone, setPhone] = useState<string>(props.carer.phone ? props.carer.phone : '');

    const [line1, setLine1] = useState<string>(props.carer.address.line1);
    const [line2, setLine2] = useState<string>(props.carer.address.line2 ? props.carer.address.line2 : '');
    const [city, setCity] = useState<string>(props.carer.address.city);
    const [postCode, setPostCode] = useState<string>(props.carer.address.postCode);

    const [accountNo, setAccountNo] = useState<string>(props.carer.accountNo ? props.carer.accountNo : '');
    const [sortCode, setSortCode] = useState<string>(props.carer.sortCode ? props.carer.sortCode : '');

    // Handle text input change
    const onTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let field = e.target.name;

        switch (field) {
            case 'Title':
                setTitle(e.currentTarget.value);
                break;
            case 'FirstName':
                setFirstName(e.currentTarget.value);
                break;
            case 'Surname':
                setSurname(e.currentTarget.value);
                break;
            case 'PreferredName':
                setPreferredName(e.currentTarget.value);
                break;
            case 'Phone':
                setPhone(e.currentTarget.value);
                break;
            case 'Line1':
                setLine1(e.currentTarget.value);
                break;
            case 'Line2':
                setLine2(e.currentTarget.value);
                break;
            case 'City':
                setCity(e.currentTarget.value);
                break;
            case 'PostCode':
                setPostCode(e.currentTarget.value);
                break;
            case 'AccountNo':
                setAccountNo(e.currentTarget.value);
                break;
            case 'SortCode':
                setSortCode(e.currentTarget.value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = () => {
        let invalidClasses = document.getElementsByClassName("is-invalid");
        if (invalidClasses.length > 0) {
            toast.warning("Please fix validation errors.", {
                position: "top-right",
                transition: Flip,
                closeButton: true,
                autoClose: 4000,
                toastId: "validation-errors",
            });
            return;
        }

        const updatedAddress: Address = {
            line1,
            line2,
            city,
            postCode
        }

        const model: UpdateCarerModel = {
            title,
            firstName,
            surname,
            preferredName,
            phone,
            address: updatedAddress,
            accountNo,
            sortCode,
            version: props.carer.version,
        }

        const payload: PutPayload = {
            id: props.carer.id,
            model
        }

        dispatch(putCarerAsync(payload));
    }

    let bodyClass: string = '';
    let loadingContainer = <></>;
    let modalHeading = 'Update your personal information';
    if (putStatus === "loading") {
        bodyClass = 'd-none';
        modalHeading = 'Processing Update';
        loadingContainer = <div className="text-center pb-5"><Loading /></div>;
    }

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header className="bg-fhah-purple text-white">
                <Modal.Title>
                    {modalHeading}
                </Modal.Title>
            </Modal.Header>
            {loadingContainer}
            <Modal.Body className={bodyClass}>
                <Row>
                    <Col className="col-12 col-lg-6 mt-4">
                        <h5>Name</h5>
                        <FloatingLabel
                            controlId="carerTitle"
                            label="Title"
                            className="mb-3"
                        >
                            <FormControl 
                                type="text"
                                name="Title"
                                value={title}
                                onChange={onTextInputChange} isInvalid={!title || title.length > 10}/>
                            <Form.Control.Feedback type="invalid">
                                Title is required and must be less than 10 characters
                            </Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="carerFirstName"
                            label="First name"
                            className="mb-3"
                        >
                            <FormControl 
                                type="text"
                                name="FirstName"
                                value={firstName}
                                onChange={onTextInputChange} isInvalid={!firstName || firstName.length > 50}/>
                            <Form.Control.Feedback type="invalid">
                                First name is required and must be less than 50 characters
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel
                            controlId="carerSurname"
                            label="Surname"
                            className="mb-3"
                        >
                            <FormControl 
                                type="text"
                                name="Surname"
                                value={surname}
                                onChange={onTextInputChange} isInvalid={!surname || surname.length > 50}/>
                            <Form.Control.Feedback type="invalid">
                                Surname is required and must be less than 50 characters
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel
                            controlId="carerPreferredName"
                            label="Preferred Name"
                            className="mb-3"
                        >
                            <FormControl 
                                type="text"
                                name="PreferredName"
                                value={preferredName}
                                onChange={onTextInputChange} isInvalid={!preferredName || preferredName.length > 100}/>
                            <Form.Control.Feedback type="invalid">
                                Preferred name is required and must be less than 100 characters
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel
                            controlId="carerPhone"
                            label="Phone"
                            className="mb-3"
                        >
                            <FormControl 
                                type="text"
                                name="Phone"
                                value={phone}
                                onChange={onTextInputChange} isInvalid={phone.length > 14}/>
                            <Form.Control.Feedback type="invalid">
                                Phone must be less than 15 characters 
                            </Form.Control.Feedback>
                        </FloatingLabel>
                        <h5 className="mt-5">Bank</h5>
                        <FloatingLabel
                            controlId="bankAccountNumber"
                            label="Account Number"
                            className="mb-3"
                        >
                            <FormControl 
                                type="text"
                                name="AccountNo"
                                value={accountNo}
                                onChange={onTextInputChange} isInvalid={!accountNo || accountNo.length !== 8}/>
                            <Form.Control.Feedback type="invalid">
                                Account Number is required and must be 8 digits 
                            </Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="bankSortCode"
                            label="Sort Code"
                            className="mb-3"
                        >
                            <FormControl 
                                type="text"
                                name="SortCode"
                                value={sortCode}
                                onChange={onTextInputChange} isInvalid={!sortCode || sortCode.length !== 6}/>
                            <Form.Control.Feedback type="invalid">
                                Sort Code is required and must be 6 digits 
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col className="col-12 col-lg-6 mt-4">
                        <h5>Address</h5>
                        <FloatingLabel
                            controlId="carerAddressLine1"
                            label="Line 1"
                            className="mb-3"
                        >
                            <FormControl 
                                type="text"
                                name="Line1"
                                value={line1}
                                onChange={onTextInputChange} isInvalid={!line1 || line1.length > 50}/>
                            <Form.Control.Feedback type="invalid">
                                Line 1 is required and must be less than 50 characters
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel
                            controlId="carerAddressLine2"
                            label="Line 2"
                            className="mb-3"
                        >
                            <FormControl 
                                type="text"
                                name="Line2"
                                value={line2}
                                onChange={onTextInputChange} isInvalid={line2.length > 50}/>
                            <Form.Control.Feedback type="invalid">
                                Line 2 must be less than 50 characters
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <FloatingLabel
                            controlId="carerAddressCity"
                            label="City"
                            className="mb-3"
                        >
                            <FormControl 
                                type="text"
                                name="City"
                                value={city}
                                onChange={onTextInputChange} isInvalid={!city || city.length > 50}/>
                            <Form.Control.Feedback type="invalid">
                                City is required and must be less than 50 characters
                            </Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="carerAddressPostcode"
                            label="Post Code"
                            className="mb-3"
                        >
                            <FormControl 
                                type="text"
                                name="PostCode"
                                value={postCode}
                                onChange={onTextInputChange} isInvalid={!postCode || postCode.length > 15}/>
                            <Form.Control.Feedback type="invalid">
                                Post code is required and must be less than 15 characters
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
                <Button variant="primary" onClick={handleSubmit}>Save Changes</Button>
                <Button variant="secondary" onClick={props.handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}