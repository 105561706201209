import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import clientReducer from "../features/clients/clientSlice";
import branchReducer from "../features/branches/branchSlice";
import carerReducer from "../features/carers/carerSlice";
import globalDocumentReducer from "../features/global-document-list/GlobalDocumentSlice";
import clientDocumentReducer from "../features/client-document/ClientDocumentSlice";
import rosterReducer from "../features/roster/RosterSlice";
import accountReducer from "../features/account/accountSlice";
import rosteredShiftReducer from "../features/shift/RosteredShiftSlice";
import adhocShiftReducer from "../features/shift-form/AdHocShiftSlice";
import weeklyShiftReducer from "../features/shift-form/WeeklyRecurringShiftSlice";
import monthlyRecurringShiftReducer from "../features/shift-form/MonthlyRecurringShiftSlice";
import shiftAssignmentReducer from "../features/shift-assignment/ShiftAssignmentSlice";
import carerHolidayReducer from "../features/carer-holiday/carerHolidaySlice";
import signalRReducer from "./signal-r/signalRSlice";
import chatReducer from "../features/chat-system/ChatSlice";
import chatLastViewedReducer from "../features/chat-system/ChatLastViewedSlice";
import timesheetReducer from "../features/Timesheet/TimeSheetSlice";
import automaticShiftAssignmentReducer from "../features/automatic-shift-assignment/AutomaticShiftAssignmentSlice";

export const store = configureStore({
  reducer: {
    account: accountReducer,
    clients: clientReducer,
    branches: branchReducer,
    carers: carerReducer,
    globalDocuments: globalDocumentReducer,
    clientDocument: clientDocumentReducer,
    roster: rosterReducer,
    rosteredShift: rosteredShiftReducer,
    adhocShift: adhocShiftReducer,
    weeklyRecurringShift: weeklyShiftReducer,
    monthlyRecurringShift: monthlyRecurringShiftReducer,
    shiftAssignment: shiftAssignmentReducer,
    carerHolidays: carerHolidayReducer,
    signalR: signalRReducer,
    chat: chatReducer,
    chatLastViewed: chatLastViewedReducer,
    timesheet: timesheetReducer,
    automaticShiftAssignment: automaticShiftAssignmentReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
