import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  ShiftAssignment,
  ShiftAssignmentPayload,
  RosterDates,
  SelectedShift,
  ShiftAssignmentPost,
  ShiftAssignmentChange,
} from "../../app/types";
import {
  getShiftAssignment,
  postShiftAssignment,
  fetchShiftAssignments,
  patchShiftAssignmentStartTime,
  patchShiftAssignmentRate,
  deleteShiftAssignment,
} from "./ShiftAssignmentApi";
import { toast, Flip } from "react-toastify";
import moment from "moment";
import { CarerModel } from "../carers/carerSlice";
import { ClientModel } from "../clients/clientSlice";

export type ShiftAssignmentModel = {
  shiftType: string | undefined;
  shift: ShiftAssignment;
  client: ClientModel;
  carer: CarerModel;
  duration: string;
}

export type DeleteShiftAssignmentModel = {
  id: string | undefined;
  shiftAssignment: ShiftAssignment;
  client: ClientModel;
  carer: CarerModel;
  duration: string
}

export type UpdateShiftStartTimeModel = {
  id: string;
  newShiftStartTime: string;
  version: string;
}

export type UpdateShiftRateModel = {
  id: string;
  newShiftRate: string;
  version: string;
}

export interface ShiftAssignmentState {
  shiftAssignment: ShiftAssignment | null;
  shiftAssignmentList: ShiftAssignment[] | undefined;
  timesheetshiftAssignmentList: ShiftAssignment[] | undefined;
  shiftAssignmentListDates: undefined | RosterDates;
  shiftAssignmentResponseStatus: "initial" | "idle" | "loading" | "failed";
  formState: "success" | "error" | "sending" | "default" | "loaded";
  shiftAssignmentUpdateStatus: "initial" | "idle" | "loading" | "failed";
  shiftAssignmentUpdateRateStatus: "initial" | "idle" | "loading" | "failed";
  shiftAssignmentListResponseStatus: "initial" | "idle" | "loading" | "failed";
  timesheetshiftAssignmentListResponseStatus:
    | "initial"
    | "idle"
    | "loading"
    | "failed";
  selectedShift: undefined | SelectedShift;
  deleteModalFor: DeleteShiftAssignmentModel | undefined;
  shiftAssignmentModalFor: ShiftAssignmentModel | undefined;
  shiftAssignmentTimeModalFor: ShiftAssignment | undefined;
  shiftAssignmentRateModalFor: ShiftAssignment | undefined;
}

const initialState: ShiftAssignmentState = {
  shiftAssignment: null,
  shiftAssignmentList: undefined,
  timesheetshiftAssignmentList: undefined,
  shiftAssignmentListDates: undefined,
  shiftAssignmentResponseStatus: "initial",
  shiftAssignmentUpdateStatus: 'initial',
  shiftAssignmentUpdateRateStatus: 'initial',
  shiftAssignmentListResponseStatus: "initial",
  timesheetshiftAssignmentListResponseStatus: "initial",
  formState: "default",
  selectedShift: undefined,
  deleteModalFor: undefined,
  shiftAssignmentModalFor: undefined,
  shiftAssignmentTimeModalFor: undefined,
  shiftAssignmentRateModalFor: undefined,
};

export const fetchShiftAssignmentAsync = createAsyncThunk(
  "shift-assignment/fetchShiftAssignment",
  async (rosterDates: RosterDates) => {
    const response = await fetchShiftAssignments(rosterDates);
    const payload: ShiftAssignmentPayload = {
      shifts: response,
      range: rosterDates,
    };
    return payload;
  }
);

export const fetchTimesheetShiftAssignmentAsync = createAsyncThunk(
  "shift-assignment/fetchTimesheetShiftAssignmentAsync",
  async (rosterDates: RosterDates) => {
    const response = await fetchShiftAssignments(rosterDates);
    const payload: ShiftAssignmentPayload = {
      shifts: response,
      range: rosterDates,
    };
    return payload;
  }
);

export const getShiftAssignmentAsync = createAsyncThunk(
  "shift-assignment/getShiftAssignment",
  async (id: string) => {
    const response = await getShiftAssignment(id);
    return response;
  }
);

export const postShiftAssignmentAsync = createAsyncThunk(
  "shift-assignment/postShiftAssignment",
  async (postPayload: ShiftAssignmentPost) => {
      const response = await postShiftAssignment(postPayload);
      return response;
    }
);

export const patchShiftAssignmentStartTimeAsync = createAsyncThunk(
  "shift-assignment/patchShiftAssignmentStartTime",
  async (patchPayload: UpdateShiftStartTimeModel) => {
      const response = await patchShiftAssignmentStartTime(patchPayload);
      return response;
    }
);

export const patchShiftAssignmentRateAsync = createAsyncThunk(
  "shift-assignment/patchShiftAssignmentRate",
  async (patchPayload: UpdateShiftRateModel) => {
      const response = await patchShiftAssignmentRate(patchPayload);
      return response;
    }
);

export const deleteShiftAssignmentAsync = createAsyncThunk(
  "shift-assignment/deleteShiftAssignment",
  async (id: string) => {
    await deleteShiftAssignment(id);
  }
);

export const shiftAssignmentSlice = createSlice({
  name: "shiftAssignment",
  initialState,
  reducers: {
    showDeleteModal: (state, action: PayloadAction<DeleteShiftAssignmentModel>) => {
      state.deleteModalFor = action.payload;
      console.log(`Show Shift Assignment Delete Modal For id: ${action.payload.shiftAssignment.id} date: ${moment(action.payload.shiftAssignment.date).format('YYYY-MM-DD')}`);
    },
    hideDeleteModal: (state) => {
      state.deleteModalFor = undefined;
      state.formState = "default";
      state.selectedShift = undefined;
      state.shiftAssignmentResponseStatus = "idle";
      console.log(`Shift Assignment Delete Modal Closed`);
    },
    resetShiftAssignments: (state) => {
      state.shiftAssignment = null;
      state.shiftAssignmentList = undefined;
      state.shiftAssignmentListDates = undefined;
      state.shiftAssignmentResponseStatus = "initial";
      state.shiftAssignmentListResponseStatus = "initial";
      state.formState = "default";
      state.selectedShift = undefined;
    },
    setSelectedShift: (state, action: PayloadAction<SelectedShift>) => {
      state.selectedShift = action.payload;
    },
    updateShiftAssignmentList: (
      state,
      action: PayloadAction<ShiftAssignmentChange>
    ) => {
      if (state.shiftAssignmentList) {
        let list = state.shiftAssignmentList as ShiftAssignment[];

        // check to see if the item exists in state.
        let matchingIndex = list.findIndex(
          (x) =>
            moment(x.date).isSame(action.payload.shiftDate, "day") &&
            x.shiftId === action.payload.shiftId &&
            x.id === action.payload.shiftAssignmentId &&
            action.payload.assignedCarerIds.filter((i) => i === x.carerId)
        );

        // if can find in State then remove as has been removed.
        if (matchingIndex !== -1) {
          list.splice(matchingIndex, 1);
        }

        // check to see if the item exists in state and add with the correct assignedCarerIds from the array.
        action.payload.assignedCarerIds.forEach((id) => {
          let matchingIndex = list.findIndex(
            (x) =>
              moment(x.date).isSame(action.payload.shiftDate, "day") &&
              x.shiftId === action.payload.shiftId &&
              x.carerId === id
          );
          // if can't find in State then Add as it has just been added.
          if (matchingIndex === -1) {
            const newShiftAssignment = {
              id: action.payload.shiftAssignmentId,
              shiftId: action.payload.shiftId,
              date: action.payload.shiftDate.toString(),
              carerId: id,
              shiftStartTime: action.payload.shiftStartTime,
              shiftLength: action.payload.shiftLength,
              rateId: action.payload.rateId,
              rateName: action.payload.rateName,
              version: action.payload.version
            };
            list.push(newShiftAssignment);
          }
        });
        state.shiftAssignmentList = list;
      }
    },
    resetSelectedShift: (state) => {
      state.selectedShift = undefined;
    },
    showShiftAssignmentModal: (state, action: PayloadAction<ShiftAssignmentModel>) => {
      state.shiftAssignmentModalFor = action.payload;
    },
    hideShiftAssignmentModal: (state) => {
        state.shiftAssignmentModalFor = undefined;
    },
    showShiftAssignmentTimeModal: (state, action: PayloadAction<ShiftAssignment>) => {
      state.shiftAssignmentTimeModalFor = action.payload;
    },
    hideShiftAssignmentTimeModal: (state) => {
        state.shiftAssignmentTimeModalFor = undefined;
    },
    showShiftAssignmentRateModal: (state, action: PayloadAction<ShiftAssignment>) => {
      state.shiftAssignmentRateModalFor = action.payload;
    },
    hideShiftAssignmentRateModal: (state) => {
        state.shiftAssignmentRateModalFor = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShiftAssignmentAsync.pending, (state) => {
        state.shiftAssignmentListResponseStatus = "loading";
        state.shiftAssignmentList = undefined;
      })
      .addCase(fetchShiftAssignmentAsync.fulfilled, (state, action) => {
        state.shiftAssignmentListResponseStatus = "idle";
        state.shiftAssignmentList = action.payload.shifts;
        state.shiftAssignmentListDates = action.payload.range;
      })
      .addCase(fetchTimesheetShiftAssignmentAsync.pending, (state) => {
        state.timesheetshiftAssignmentListResponseStatus = "loading";
        state.timesheetshiftAssignmentList = undefined;
      })
      .addCase(
        fetchTimesheetShiftAssignmentAsync.fulfilled,
        (state, action) => {
          state.timesheetshiftAssignmentListResponseStatus = "idle";

          state.timesheetshiftAssignmentList = action.payload.shifts;
        }
      )
      .addCase(getShiftAssignmentAsync.pending, (state) => {
        state.shiftAssignmentResponseStatus = "loading";
        state.shiftAssignment = null;
      })
      .addCase(getShiftAssignmentAsync.fulfilled, (state, action) => {
        state.shiftAssignmentResponseStatus = "idle";
        state.shiftAssignment = action.payload;
      })
      .addCase(getShiftAssignmentAsync.rejected, (state, action) => {
        state.shiftAssignmentResponseStatus = "failed";
        state.shiftAssignment = null;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful-fetch",
        });
      })
      .addCase(postShiftAssignmentAsync.pending, (state) => {
        state.shiftAssignmentResponseStatus = "loading";
        state.shiftAssignment = null;
      })
      .addCase(postShiftAssignmentAsync.fulfilled, (state, action) => {
        state.shiftAssignmentResponseStatus = "idle";
        state.shiftAssignment = action.payload;
        state.selectedShift = undefined;
        toast.info("Successful Assignment", {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 1000,
          toastId: "successful-assignment",
        });
      })
      .addCase(postShiftAssignmentAsync.rejected, (state, action) => {
        state.shiftAssignmentResponseStatus = "failed";
        state.shiftAssignment = null;
        state.selectedShift = undefined;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful-assignment",
        });
      })
      .addCase(patchShiftAssignmentStartTimeAsync.pending, (state) => {
        state.shiftAssignmentUpdateStatus = "loading";
        state.shiftAssignment = null;
      })
      .addCase(patchShiftAssignmentStartTimeAsync.fulfilled, (state, action) => {
        state.shiftAssignmentUpdateStatus = "idle";
        state.shiftAssignment = action.payload;
        state.selectedShift = undefined;
        state.shiftAssignmentTimeModalFor = undefined;
        toast.info("Shift Assignment Updated", {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 1000,
          toastId: "successful-assignment-update",
        });
      })
      .addCase(patchShiftAssignmentStartTimeAsync.rejected, (state, action) => {
        state.shiftAssignmentUpdateStatus = "failed";
        state.shiftAssignment = null;
        state.selectedShift = undefined;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful-assignment-update",
        });
      })
      .addCase(patchShiftAssignmentRateAsync.pending, (state) => {
        state.shiftAssignmentUpdateRateStatus = "loading";
        state.shiftAssignment = null;
      })
      .addCase(patchShiftAssignmentRateAsync.fulfilled, (state, action) => {
        state.shiftAssignmentUpdateRateStatus = "idle";
        state.shiftAssignment = action.payload;
        state.selectedShift = undefined;
        state.shiftAssignmentRateModalFor = undefined;
        toast.info("Shift Assignment Updated", {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 1000,
          toastId: "successful-assignment-rate-update",
        });
      })
      .addCase(patchShiftAssignmentRateAsync.rejected, (state, action) => {
        state.shiftAssignmentUpdateRateStatus = "failed";
        state.shiftAssignment = null;
        state.selectedShift = undefined;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful-assignment-rate-update",
        });
      })
      .addCase(deleteShiftAssignmentAsync.pending, (state) => {
        state.shiftAssignmentResponseStatus = "loading";
        state.formState = "sending";
        state.shiftAssignment = null;
      })
      .addCase(deleteShiftAssignmentAsync.fulfilled, (state) => {
        state.shiftAssignmentResponseStatus = "idle";
        state.formState = "success";
        state.shiftAssignment = null;
        toast.info("Successful Cancellation", {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 1000,
          toastId: "successful-cancelation",
        });
      })
      .addCase(deleteShiftAssignmentAsync.rejected, (state, action) => {
        state.shiftAssignmentResponseStatus = "failed";
        state.formState = "error";
        state.shiftAssignment = null;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful-cancelation",
        });
      });
  },
});

export const selectDeleteModalFor = (state: RootState) =>
  state.shiftAssignment.deleteModalFor;

export const shiftAssignmentFormState = (state: RootState) =>
  state.shiftAssignment.formState;

export const shiftAssignmentResponseStatus = (state: RootState) =>
  state.shiftAssignment.shiftAssignmentResponseStatus;

export const shiftAssignmentListResponseStatus = (state: RootState) =>
  state.shiftAssignment.shiftAssignmentListResponseStatus;

export const timesheetshiftAssignmentListResponseStatus = (state: RootState) =>
  state.shiftAssignment.timesheetshiftAssignmentListResponseStatus;

export const selectShiftAssihgnmentsAsDictionary = (state: RootState) => {
  let shiftAssignmentDictionary = new Map();

  if (state.shiftAssignment.shiftAssignmentList) {
    // Convert list of shift assignments into typescript map dictionary for carer Id and inner dictionaries using date and then shift assignment id
    for (let shiftAssignment of state.shiftAssignment.shiftAssignmentList) {
        let dictionaryForCarer = shiftAssignmentDictionary.get(shiftAssignment.carerId);

        if (!dictionaryForCarer) {
            // No match found add a carer Id entry
            shiftAssignmentDictionary.set(shiftAssignment.carerId, new Map());
            dictionaryForCarer = shiftAssignmentDictionary.get(shiftAssignment.carerId);
        }

        let dictionaryForCarerAndDate = dictionaryForCarer.get(moment(shiftAssignment.date).format('YYYY-MM-DD'));

        if (!dictionaryForCarerAndDate) {
            // No match found add a dictionary for date
            dictionaryForCarer.set(moment(shiftAssignment.date).format('YYYY-MM-DD'), new Map());
            dictionaryForCarerAndDate = dictionaryForCarer.get(moment(shiftAssignment.date).format('YYYY-MM-DD'));
        }

        let dictionaryEntryForCarerAndDateAndId = dictionaryForCarerAndDate.get(shiftAssignment.id);

        if (!dictionaryEntryForCarerAndDateAndId) {
            // No match found add the shift assignment for date entry
            dictionaryForCarerAndDate.set(shiftAssignment.id, shiftAssignment);
        }
    }
  }

  return shiftAssignmentDictionary;
}

export const selectRosterDates = (state: RootState) =>
  state.shiftAssignment.shiftAssignmentListDates;

export const timesheetshiftAssignmentList = (state: RootState) =>
  state.shiftAssignment.timesheetshiftAssignmentList;

export const shiftAssignmentResponse = (state: RootState) =>
  state.shiftAssignment.shiftAssignmentResponseStatus === "idle"
    ? state.shiftAssignment.shiftAssignment
    : null;

export const selectShiftAssignmentModalFor = (state: RootState) =>
  state.shiftAssignment.shiftAssignmentModalFor;

export const selectShiftAssignmentTimeModalFor = (state: RootState) =>
  state.shiftAssignment.shiftAssignmentTimeModalFor;

export const selectShiftAssignmentUpdateStatus = (state: RootState) =>
  state.shiftAssignment.shiftAssignmentUpdateStatus;

export const selectShiftAssignmentRateModalFor = (state: RootState) =>
  state.shiftAssignment.shiftAssignmentRateModalFor;

export const selectShiftAssignmentUpdateRateStatus = (state: RootState) =>
  state.shiftAssignment.shiftAssignmentUpdateRateStatus;

export const currentSelectedShift = (state: RootState) =>
  state.shiftAssignment.selectedShift !== undefined
    ? state.shiftAssignment.selectedShift
    : null;

export const {
  showDeleteModal,
  hideDeleteModal,
  resetShiftAssignments,
  setSelectedShift,
  resetSelectedShift,
  updateShiftAssignmentList,
  showShiftAssignmentModal,
  hideShiftAssignmentModal,
  showShiftAssignmentTimeModal,
  hideShiftAssignmentTimeModal,
  showShiftAssignmentRateModal,
  hideShiftAssignmentRateModal,
} = shiftAssignmentSlice.actions;

export default shiftAssignmentSlice.reducer;
