import { ClientDocument } from "../../app/types";
import axios, { AxiosInstance } from "axios";
import { getToken } from "../account/sessionManager";
import environment from "../../environment";

const api: AxiosInstance = axios.create({
  baseURL: environment.apiBaseAddress,
  validateStatus: (status) => true,
  timeout: 20000,
});

export function fetchClientDocument(clientId: string) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };
  let config = { headers: headers };
  return new Promise<ClientDocument>((resolve, reject) =>
    api
      .get("/client-document/" + clientId, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}
