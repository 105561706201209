import {
  AutomaticShiftAssignment,
  DeleteAutomaticShiftAssignmentPayload,
  GetAutomaticShiftAssignmentPayload,
  PostAutomaticShiftAssignmentPayload,
} from "../../app/types";
import axios, { AxiosInstance } from "axios";
import { getToken } from "../account/sessionManager";
import environment from "../../environment";

const api: AxiosInstance = axios.create({
  baseURL: environment.apiBaseAddress,
  validateStatus: (status) => true,
  timeout: 20000,
});

export function fetchAutomaticShiftAssignments(
  payload: GetAutomaticShiftAssignmentPayload
) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };
  let config = { headers: headers };
  let shiftType = payload.shiftType;
  if (payload.shiftType === "MonthlyRecurrringShift") {
    shiftType = "monthly-recurring-shifts";
  } else if (payload.shiftType === "WeeklyRecurringShift") {
    shiftType = "weekly-recurring-shifts";
  }
  return new Promise<AutomaticShiftAssignment[]>((resolve, reject) =>
    api
      .get(
        "/" +
          shiftType +
          "/" +
          payload.recurringShiftId +
          "/automatic-shift-assignments",
        config
      )
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}

export function postAutomaticShiftAssignment(
  payload: PostAutomaticShiftAssignmentPayload
) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };
  let shiftType = payload.shiftType;
  if (payload.shiftType === "MonthlyRecurrringShift") {
    shiftType = "monthly-recurring-shifts";
  } else if (payload.shiftType === "WeeklyRecurringShift") {
    shiftType = "weekly-recurring-shifts";
  }
  return new Promise<AutomaticShiftAssignment>((resolve, reject) =>
    api
      .post(
        "/" +
          shiftType +
          "/" +
          payload.recurringShiftId +
          "/automatic-shift-assignments",
        payload.automaticShiftAssignment,
        config
      )
      .then((response) => {
        switch (response.status) {
          case 201:
            resolve(response.data);
            break;
          case 400:
            reject(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}

export function deleteAutomaticShiftAssignment(
  payload: DeleteAutomaticShiftAssignmentPayload
) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };
  let shiftType = payload.shiftType;
  if (payload.shiftType === "MonthlyRecurrringShift") {
    shiftType = "monthly-recurring-shifts";
  } else if (payload.shiftType === "WeeklyRecurringShift") {
    shiftType = "weekly-recurring-shifts";
  }
  return new Promise<string>((resolve, reject) =>
    api
      .delete(
        "/" +
          shiftType +
          "/" +
          payload.recurringShiftId +
          "/automatic-shift-assignments/" +
          payload.automaticShiftAssignmentId,
        config
      )
      .then((response) => {
        switch (response.status) {
          case 200:
            //return id so that we can delete
            resolve(payload.automaticShiftAssignmentId);
            break;
          case 400:
            reject(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}

export function pauseAutomaticShiftAssignment(
  payload: DeleteAutomaticShiftAssignmentPayload
) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };
  let shiftType = payload.shiftType;
  if (payload.shiftType === "MonthlyRecurrringShift") {
    shiftType = "monthly-recurring-shifts";
  } else if (payload.shiftType === "WeeklyRecurringShift") {
    shiftType = "weekly-recurring-shifts";
  }
  return new Promise<string>((resolve, reject) =>
    api
      .post(
        "/" +
          shiftType +
          "/" +
          payload.recurringShiftId +
          "/automatic-shift-assignments/" +
          payload.automaticShiftAssignmentId +
          "/pause",
        payload,
        config
      )
      .then((response) => {
        switch (response.status) {
          case 200:
            //return id so that we can delete
            resolve(payload.automaticShiftAssignmentId);
            break;
          case 400:
            reject(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}

export function playAutomaticShiftAssignment(
  payload: DeleteAutomaticShiftAssignmentPayload
) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };
  let shiftType = payload.shiftType;
  if (payload.shiftType === "MonthlyRecurrringShift") {
    shiftType = "monthly-recurring-shifts";
  } else if (payload.shiftType === "WeeklyRecurringShift") {
    shiftType = "weekly-recurring-shifts";
  }
  return new Promise<string>((resolve, reject) =>
    api
      .post(
        "/" +
          shiftType +
          "/" +
          payload.recurringShiftId +
          "/automatic-shift-assignments/" +
          payload.automaticShiftAssignmentId +
          "/resume",
        payload,
        config
      )
      .then((response) => {
        switch (response.status) {
          case 200:
            //return id so that we can delete
            resolve(payload.automaticShiftAssignmentId);
            break;
          case 400:
            reject(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}
