import { Accordion, Button, Card, FloatingLabel, Form, FormControl, Modal, Spinner, Table } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAutomaticShiftIdFor, selectAutomaticShiftModalForShiftType, hideAutomaticShiftAssignmentModal } from '../shift/RosteredShiftSlice';
import { postAutomaticShiftAssignmentAsync, fetchAutomaticShiftAssignmentAsync, automaticShiftAssignmentList, deleteAutomaticShiftAssignmentAsync, pauseAutomaticShiftAssignmentAsync, playAutomaticShiftAssignmentAsync, shiftAssignmentResponseStatus, shiftAssignmentListResponseStatus} from '../automatic-shift-assignment/AutomaticShiftAssignmentSlice';
import { useEffect, useRef, useState } from 'react';
import { faPlay, faTrash, faPause, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutomaticShiftAssignment, DeleteAutomaticShiftAssignmentPayload, PostAutomaticShiftAssignmentPayload } from '../../app/types';
import { CarerModel, selectCarersAsDictionary, selectCarers } from '../carers/carerSlice';
import { SelectListItem, ShiftType } from '../../app/model';
import SelectListInput from '../../app/generic-components/SelectListInput';
import { numberOfWeeksValidation, selectListValidation } from '../../app/validation';
import ShiftInfo from '../shift-form/shift-info';
import {ClientModel, selectClientsAsDictionary} from '../clients/clientSlice'
import { getWeeklyRecurringShiftAsync, loadedWeeklyRecurringShift, weeklyFormState, weeklyResetForm } from "../shift-form/WeeklyRecurringShiftSlice";
import { getMonthlyRecurringShiftAsync, loadedMonthlyRecurringShift, monthlyFormState, monthlyResetForm } from "../shift-form/MonthlyRecurringShiftSlice";
import moment from 'moment';

export default function AutomaticShiftAssignmentModal() {
    const dispatch = useAppDispatch();
    let carersAsDictionary = useAppSelector(selectCarersAsDictionary);
    let clientsAsDictionary = useAppSelector(selectClientsAsDictionary);
    let carersAsList = useAppSelector(selectCarers);
    let automaticShiftAssignmentAsList = useAppSelector(automaticShiftAssignmentList);
    const [carerId, setCarerId] = useState("");
    const [carerIdInvalid, setCarerIdInvalid] = useState<undefined | boolean>(undefined);
    const [carerIdChange, setCarerIdChange] = useState(false);
    const [assignNWeeksInAdvance, setAssignNWeeksInAdvance] = useState(4);
    const [assignNWeeksInAdvanceInvalid, setAssignNWeeksInAdvanceInvalid] = useState<undefined | boolean>(undefined);
    const [assignNWeeksInAdvanceChange, setAssignNWeeksInAdvanceChange] = useState(false);
    const shiftType: string | undefined = useAppSelector(selectAutomaticShiftModalForShiftType);
    const shiftId: string | undefined = useAppSelector(selectAutomaticShiftIdFor);

    const automaticShiftAssignmentListResponseStatus = useAppSelector(shiftAssignmentListResponseStatus);
    const automaticShiftAssignmentResponseStatus = useAppSelector(shiftAssignmentResponseStatus);
    const [btnsLoading, setBtnsLoading] = useState<boolean>(false);

    const currentWeeklyFormState = useAppSelector(weeklyFormState);
    const currentMonthlyFormState = useAppSelector(monthlyFormState);
    const currentLoadedMonthly = useAppSelector(loadedMonthlyRecurringShift);
    const currentLoadedWeekly = useAppSelector(loadedWeeklyRecurringShift);

    const [clientId, setClientId] = useState<string>('');
    const [rateId, setRateId] = useState<string>('');
    const [shiftDate, setShiftDate] = useState('');
    const [shiftStartTime, setShiftStartTime] = useState('');
    const [shiftLength, setShiftLength] = useState('');
    const [shiftLength24H, setShiftLength24H] = useState(false);
    const [numberOfCarers, setNumberOfCarers] = useState(1);
    const [typeOfShift, setTypeOfShift] = useState(ShiftType.AdHoc);
    const [monday, setMonday] = useState(false);
    const [tuesday, setTuesday] = useState(false);
    const [wednesday, setWednesday] = useState(false);
    const [thursday, setThursday] = useState(false);
    const [friday, setFriday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [sunday, setSunday] = useState(false);
    const [recurrenceStart, setRecurrenceStart] = useState("");
    const [recurrenceEnd, setRecurrenceEnd] = useState("");
    const [recurrenceHasEnd, setRecurrenceHasEnd] = useState(false);
    const [recurEveryNWeeks, setRecurEveryNWeeks] = useState(1);
    const [firstFullWeekOfMonth, setFirstFullWeekOfMonth] = useState(false);
    const [secondFullWeekOfMonth, setSecondFullWeekOfMonth] = useState(false);
    const [thirdFullWeekOfMonth, setThirdFullWeekOfMonth] = useState(false);
    const [fourthFullWeekOfMonth, setFourthFullWeekOfMonth] = useState(false);
    const [lastFullWeekOfMonth, setLastFullWeekOfMonth] = useState(false);
    const [loading, setLoading] = useState(false);
    const [automaticShifts, setAutomaticShifts] = useState<AutomaticShiftAssignment[] |undefined>(undefined);

    const [carerSelectListItems, setCarerSelectListItems] = useState<SelectListItem[]>([]);

    useEffect(() => {

        if (currentWeeklyFormState === "default"|| 
            currentMonthlyFormState === "default") {
                loadingPlaceholder.current = (
                    <></>
                );
                setLoading(false);
        }

    if ((currentMonthlyFormState === "loaded" || currentWeeklyFormState === "loaded"))
    {
        switch(shiftType) {
            case "WeeklyRecurringShift":
                if (currentLoadedWeekly !== null) {

                    setClientId(currentLoadedWeekly.clientId);
                    setRateId(currentLoadedWeekly.rateId);
                    setShiftDate("");
                    setShiftStartTime(moment(currentLoadedWeekly.shiftStartTime, 'HH:mm:ss').format('HH:mm'));
                    setShiftLength(currentLoadedWeekly.shiftLength === "1.00:00:00" ? moment(currentLoadedWeekly.shiftLength, 'd.HH:mm:ss').format('d.HH:mm') : moment(currentLoadedWeekly.shiftLength, 'HH:mm:ss').format('HH:mm'));
                    setShiftLength24H(currentLoadedWeekly.shiftLength === "1.00:00:00" ? true : false);
                    setNumberOfCarers(currentLoadedWeekly.numberOfCarers);
                    setTypeOfShift(ShiftType.Weekly);
                    setMonday(currentLoadedWeekly.monday);
                    setTuesday(currentLoadedWeekly.tuesday);
                    setWednesday(currentLoadedWeekly.wednesday);
                    setThursday(currentLoadedWeekly.thursday);
                    setFriday(currentLoadedWeekly.friday);
                    setSaturday(currentLoadedWeekly.saturday);
                    setSunday(currentLoadedWeekly.sunday);
                    setRecurrenceStart(moment(currentLoadedWeekly.recurrenceStart).format('YYYY-MM-DD'));
                    setRecurrenceEnd(currentLoadedWeekly.recurrenceEnd !== null ? moment(currentLoadedWeekly.recurrenceEnd).format('YYYY-MM-DD') : "");
                    setRecurrenceHasEnd(currentLoadedWeekly.recurrenceEnd === null ? true : false);
                    setRecurEveryNWeeks(currentLoadedWeekly.recurEveryNWeeks);
                    setFirstFullWeekOfMonth(false);
                    setSecondFullWeekOfMonth(false);
                    setThirdFullWeekOfMonth(false);
                    setFourthFullWeekOfMonth(false);
                    setLastFullWeekOfMonth(false);      
                    return;
                }
                break;
            case "MonthlyRecurrringShift":
                if (currentLoadedMonthly !== null) {
                    setClientId(currentLoadedMonthly.clientId);
                    setRateId(currentLoadedMonthly.rateId);
                    setShiftDate("");
                    setShiftStartTime(moment(currentLoadedMonthly.shiftStartTime, 'HH:mm:ss').format('HH:mm'));
                    setShiftLength(currentLoadedMonthly.shiftLength === "1.00:00:00" ? moment(currentLoadedMonthly.shiftLength, 'd.HH:mm:ss').format('d.HH:mm') : moment(currentLoadedMonthly.shiftLength, 'HH:mm:ss').format('HH:mm'));
                    setShiftLength24H(currentLoadedMonthly.shiftLength === "1.00:00:00" ? true : false);
                    setNumberOfCarers(currentLoadedMonthly.numberOfCarers);
                    setTypeOfShift(ShiftType.Monthly);
                    setMonday(currentLoadedMonthly.monday);
                    setTuesday(currentLoadedMonthly.tuesday);
                    setWednesday(currentLoadedMonthly.wednesday);
                    setThursday(currentLoadedMonthly.thursday);
                    setFriday(currentLoadedMonthly.friday);
                    setSaturday(currentLoadedMonthly.saturday);
                    setSunday(currentLoadedMonthly.sunday);
                    setRecurrenceStart(moment(currentLoadedMonthly.recurrenceStart).format('YYYY-MM-DD'));
                    setRecurrenceEnd(currentLoadedMonthly.recurrenceEnd !== null ? moment(currentLoadedMonthly.recurrenceEnd).format('YYYY-MM-DD') : "");
                    setRecurrenceHasEnd(currentLoadedMonthly.recurrenceEnd === null ? true : false);
                    setRecurEveryNWeeks(1);
                    setFirstFullWeekOfMonth(currentLoadedMonthly.firstFullWeekOfMonth);
                    setSecondFullWeekOfMonth(currentLoadedMonthly.secondFullWeekOfMonth);
                    setThirdFullWeekOfMonth(currentLoadedMonthly.thirdFullWeekOfMonth);
                    setFourthFullWeekOfMonth(currentLoadedMonthly.fourthFullWeekOfMonth);
                    setLastFullWeekOfMonth(currentLoadedMonthly.lastFullWeekOfMonth);     
                    return;
                }
                break;
        }
        return;
    }

    if (currentWeeklyFormState === "sending"|| 
        currentMonthlyFormState === "sending") {
        loadingPlaceholder.current = (
            <div className="row">
                <Spinner
                    as="div"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    className="my-3 text-fhah-green mx-auto"
                    >
                        <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
        
        setLoading(true);
    }
}, 
[
    currentMonthlyFormState,
    currentWeeklyFormState,
    shiftType,
    shiftId,
    currentLoadedWeekly,
    currentLoadedMonthly,
    dispatch,
]);

let loadingPlaceholder = useRef(<span />);

    useEffect(() => {

        if(automaticShiftAssignmentResponseStatus === "loading" || automaticShiftAssignmentListResponseStatus === "loading"){
            setBtnsLoading(true);
        }else{
            setBtnsLoading(false);
        }

    },[automaticShiftAssignmentListResponseStatus, automaticShiftAssignmentResponseStatus])

    useEffect(() => {
        if (clientId && carersAsList.length > 0) {
            let client: ClientModel | undefined = clientsAsDictionary.get(clientId);
            if (client) {
                let carersForClient = carersAsList.filter(x => client && client.clientCarerIds.includes(x.id));
                setCarerSelectListItems(carersForClient.map(x => ({value:x.id, text:x.firstName +" "+ x.surname})));
            }
        }

    },[carersAsList, clientId, clientsAsDictionary])

    useEffect(() => {
        if(automaticShiftAssignmentAsList !== automaticShifts)
        setAutomaticShifts(automaticShiftAssignmentAsList);
    },[automaticShiftAssignmentAsList, automaticShifts])

    useEffect(() => {
        if (shiftType && shiftId)
        {
            dispatch(fetchAutomaticShiftAssignmentAsync({shiftType: shiftType, recurringShiftId: shiftId}));
            switch(shiftType) {
            case "WeeklyRecurringShift":
                dispatch(getWeeklyRecurringShiftAsync(shiftId));
                break;
            case "MonthlyRecurrringShift":
                dispatch(getMonthlyRecurringShiftAsync(shiftId));
                break;
        }
        }else{
            setAutomaticShifts(undefined);
        }
        setAssignNWeeksInAdvance(4);
        setAssignNWeeksInAdvanceChange(false);
        setAssignNWeeksInAdvanceInvalid(false)
        setCarerId("");
        setCarerIdChange(false);
        setCarerIdInvalid(false)
    },[shiftType, shiftId, dispatch])

    const handlePost = () => {
        setAssignNWeeksInAdvanceChange(true);
        setCarerIdChange(true);

        if(!carerIdInvalidFunction(carerId) && ( shiftType === "MonthlyRecurrringShift" || shiftType === "WeeklyRecurringShift") && !assignNWeeksInAdvanceChangeInvalidFunction(assignNWeeksInAdvance)){
            dispatch(postAutomaticShiftAssignmentAsync(
                {
                    recurringShiftId: shiftId,
                    shiftType: shiftType,
                    automaticShiftAssignment:{
                        recurringShiftId: shiftId,
                        carerId: carerId,
                        assignNWeeksInAdvance: assignNWeeksInAdvance
                    }
                } as PostAutomaticShiftAssignmentPayload));
        }
    }

    const handleDelete = (id: string) => {
        if (id) {
            dispatch(deleteAutomaticShiftAssignmentAsync(
                {
                    recurringShiftId: shiftId,
                    shiftType: shiftType,
                    automaticShiftAssignmentId: id,
                } as DeleteAutomaticShiftAssignmentPayload));
        }
        
    }

    const handlePause = (id: string) => {
        if (id) {
            dispatch(pauseAutomaticShiftAssignmentAsync(
                {
                    recurringShiftId: shiftId,
                    shiftType: shiftType,
                    automaticShiftAssignmentId: id,
                } as DeleteAutomaticShiftAssignmentPayload));
        }
        
    }

    const handlePlay = (id: string) => {

        if (id) {
            dispatch(playAutomaticShiftAssignmentAsync(
                {
                    recurringShiftId: shiftId,
                    shiftType: shiftType,
                    automaticShiftAssignmentId: id,
                } as DeleteAutomaticShiftAssignmentPayload));
        }
        
    }

    const handleCloseModal = () => {
        dispatch(hideAutomaticShiftAssignmentModal());
        if (shiftType) {
            switch(shiftType) {
                case "WeeklyRecurringShift":
                    dispatch(weeklyResetForm());
                    break;
                case "MonthlyRecurrringShift":
                    dispatch(monthlyResetForm());
                    break;
            }
        }
    }

    const onCarerIdInputChange = (value : string) => {
        if (value !== carerId) {
            setCarerId(value);
            setCarerIdChange(true);
            carerIdInvalidFunction(value);
        }
    };

    const carerIdInvalidFunction = (value :string) => {
        if (selectListValidation(value)) {
            setCarerIdInvalid(false);
            return false;
        }
        setCarerIdInvalid(true);
        return true;
    };


    const onAssignNWeeksInAdvanceChange = (value : string) => {
        let castValue: number = parseInt(value);
        if (castValue !== assignNWeeksInAdvance) {
            setAssignNWeeksInAdvance(castValue);
            setAssignNWeeksInAdvanceChange(true);
            assignNWeeksInAdvanceChangeInvalidFunction(castValue);
        }
    };

    const assignNWeeksInAdvanceChangeInvalidFunction = (value :number) => {
        if (!numberOfWeeksValidation(value)) {
            setAssignNWeeksInAdvanceInvalid(false);
            return false;
        }
        setAssignNWeeksInAdvanceInvalid(true);
        return true;
    };

    return (
        <Modal show={shiftType !== undefined && shiftId !== undefined} onHide={handleCloseModal}>
            <Modal.Header className="bg-fhah-purple text-white">
                <div className="d-flex justify-content-between w-100">
                    <Modal.Title>
                        <span>Automatic shift assignment</span>
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>

            <Accordion className="mb-3">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Info</Accordion.Header>
                        <Accordion.Body>
                            <ShiftInfo
                                clientId={clientId}
                                rateId={rateId}
                                shiftDate={shiftDate}
                                shiftStartTime={shiftStartTime}
                                shiftLength={shiftLength}
                                shiftLength24H={shiftLength24H}
                                numberOfCarers={numberOfCarers}
                                typeOfShift={typeOfShift}
                                monday={monday}
                                tuesday={tuesday}
                                wednesday={wednesday}
                                thursday={thursday}
                                friday={friday}
                                saturday={saturday}
                                sunday={sunday}
                                recurrenceStart={recurrenceStart}
                                recurrenceEnd={recurrenceEnd}
                                recurrenceHasEnd={recurrenceHasEnd}
                                recurEveryNWeeks={recurEveryNWeeks}
                                firstFullWeekOfMonth={firstFullWeekOfMonth}
                                secondFullWeekOfMonth={secondFullWeekOfMonth}
                                thirdFullWeekOfMonth={thirdFullWeekOfMonth}
                                fourthFullWeekOfMonth={fourthFullWeekOfMonth}
                                lastFullWeekOfMonth={lastFullWeekOfMonth}
                                loading={loading}
                                isCarer={false}
                            ></ShiftInfo>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Card>
                <Card.Header className="bg-fhah-purple text-white">Add Carer Automatic Shift</Card.Header>
                    <Card.Body>
                        <SelectListInput selectListItems={carerSelectListItems} name="Carer" currentValue={carerId} changeSelectList={(Id: string) => onCarerIdInputChange(Id)} isInvalid={carerIdInvalid && carerIdChange}/>
                        <FloatingLabel
                                    controlId="assignNWeeksInAdvance"
                                    label="Assign N Weeks In Advance"
                                    className="mb-3"
                                >
                                    <FormControl 
                                        type="number"
                                        name="assignNWeeksInAdvance"
                                        value={assignNWeeksInAdvance}
                                        onChange={(e)=> onAssignNWeeksInAdvanceChange(e.target.value)} isInvalid={assignNWeeksInAdvanceInvalid && assignNWeeksInAdvanceChange} />
                                    <Form.Control.Feedback type="invalid">
                                        Number of week is required
                                    </Form.Control.Feedback>
                        </FloatingLabel>
                        <div className="d-flex justify-content-end">
                            <Button disabled={btnsLoading} variant="primary" onClick={() => {handlePost()}}><FontAwesomeIcon icon={faPlus} className="fa-fw " /></Button>
                        </div>
                    </Card.Body>
                </Card>
                <Table className="bg-white mt-3 rounded-2 border" bordered size="xs">
                    <thead>
                        <tr>
                            <th>Carer Name</th>
                            <th colSpan={2} className="">Weeks In Advance</th>
                         
                        </tr>
                    </thead>
                    <tbody>
                    {
                            automaticShifts && automaticShifts.length > 0  ? (
                            automaticShifts.map((x: AutomaticShiftAssignment) => {
                                let carer = null;
                                if (x.carerId !== "" && carersAsDictionary !== undefined) {
                                    carer = carersAsDictionary.get(x.carerId) as CarerModel;                       
                                }
                                let pauseBtn = (<Button disabled={btnsLoading} onClick={() => {handlePause(x.id)}} className="me-1 bg-fhah-green"  variant="primary"><FontAwesomeIcon icon={faPause} className="fa-fw " /></Button>);
                                if(x.paused){
                                    pauseBtn = (<Button disabled={btnsLoading} onClick={() => {handlePlay(x.id)}} className="me-1 bg-fhah-green"  variant="primary"><FontAwesomeIcon icon={faPlay} className="fa-fw " /></Button>);
                                }
                                return (
                                    <tr key={x.id}>
                                        <td><span>{carer ? carer.firstName : ""} {carer ? carer.surname : ""}</span></td>
                                        <td><span>{x.assignNWeeksInAdvance}</span></td>
                                        <td style={{width: "114px"}}>
                                            <div >
                                                {pauseBtn}
                                                <Button disabled={btnsLoading} variant="danger" onClick={() => {handleDelete(x.id)}}><FontAwesomeIcon icon={faTrash} className="fa-fw "/></Button>
                                            </div>
                                        </td>
                                    </tr>
                                    )
                            })):
                            (
                            <tr>             
                                <td className="text-center" colSpan={3}>No Carers</td>
                            </tr>
                            )
                    }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer className={"bg-light"}>
                <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}