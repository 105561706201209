import { Col } from "react-bootstrap";
import { CarerModel, selectCarer, clearSelectCarer, selectedCarer } from "./carerSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import CarerModal from './CarerModal';
import CarerEditModal from './CarerEditModal';
import { useState } from "react";
import CarerCellView from './CarerCellView';

export type IProps = {
    accountCarerId?: string | undefined;
    userRole?: string | string[] | null;
    carer: CarerModel;
    totalHours: number;
    daysShown: number;
}

export default function CarerCell(props: IProps) {
    const dispatch = useAppDispatch();
    const activeCarer = useAppSelector(selectedCarer);

    const [show, setShow] = useState<boolean>(false);

    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const handleShowCarerModal = () => {
        setShow(true);
    }

    const handleShowEditModal = () => {
        setShowEditModal(true);
    }

    const handleCloseModal = () => {
        setShow(false);
    }

    const handleCloseEditModal = () => {
        setShowEditModal(false);
    }

    const handleClearSelectedCarer = () => {
        dispatch(clearSelectCarer());
    }

    const handleSelectCarer = () => {
        dispatch(selectCarer(props.carer));
    }

    let carerCellClasses = "w-12-5 border-end border-bottom pt-2 pb-3 text-start px-1 small position-relative carer-cell bg-secondary text-white";
    if (props.userRole || (props.accountCarerId && props.accountCarerId === props.carer.id)) {
        carerCellClasses = "w-12-5 border-end border-bottom pt-2 pb-3 text-start px-1 small position-relative carer-cell";
    }

    let buttonClasses = "text-underline";
    let editCarerModal = <></>;
    if (!props.userRole && props.accountCarerId === props.carer.id) {
        buttonClasses = buttonClasses.concat(" text-bold");
        editCarerModal = <CarerEditModal carer={props.carer} show={showEditModal} handleClose={handleCloseEditModal}></CarerEditModal>;
    }

    let totalHoursForWeek = <></>;
    if (props.daysShown === 7 && props.userRole) {
        totalHoursForWeek = (<small className="carer-total-hours">{props.totalHours}</small>);
    }
    
    return (
        <Col xs="12" sm="2" md="2" className={carerCellClasses}>
            <CarerCellView 
                accountCarerId={props.accountCarerId}
                userRole={props.userRole}
                carerId={props.carer.id}
                carerFirstName={props.carer.firstName}
                carerSurname={props.carer.surname}
                carerPhone={props.carer.phone}
                isActiveCarer={activeCarer && activeCarer.id === props.carer.id ? true : false}
                buttonClasses={buttonClasses}
                handleShowCarerModal={handleShowCarerModal}
                handleCloseModal={handleCloseModal}
                handleShowEditModal={handleShowEditModal}
                handleCloseEditModal={handleCloseEditModal}
                handleClearSelectedCarer={handleClearSelectedCarer}
                handleSelectCarer={handleSelectCarer}
            />
            <CarerModal carer={props.carer} show={show} handleClose={handleCloseModal}></CarerModal>
            {editCarerModal}
            {totalHoursForWeek}
        </Col>
    );
}