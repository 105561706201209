import {
  MonthlyRecurringShift,
  MonthlyRecurringShiftResponse,
  MonthlyRecurringShiftVersioned,
  UpdateRosteredShiftRateModel,
  UpdateRosteredShiftStartTimeModel,
} from "../../app/types";
import axios, { AxiosInstance } from "axios";
import { getToken } from "../account/sessionManager";
import environment from "../../environment";

const api: AxiosInstance = axios.create({
  baseURL: environment.apiBaseAddress,
  validateStatus: (status) => true,
  timeout: 20000,
});

export function getMonthlyRecurringShift(id: string) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<MonthlyRecurringShiftVersioned>((resolve, reject) =>
    api
      .get("/monthly-recurring-shifts/" + id, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}

export function postMonthlyRecurringShift(
  monthlyRecurringShift: MonthlyRecurringShift
) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<MonthlyRecurringShiftResponse>((resolve, reject) =>
    api
      .post("/monthly-recurring-shifts", monthlyRecurringShift, config)
      .then((response) => {
        switch (response.status) {
          case 201:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}

export function putMonthlyRecurringShiftt(
  monthlyRecurringShiftVersioned: MonthlyRecurringShiftVersioned
) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<MonthlyRecurringShiftResponse>((resolve, reject) =>
    api
      .put(
        "/monthly-recurring-shifts/" + monthlyRecurringShiftVersioned.id,
        monthlyRecurringShiftVersioned,
        config
      )
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}

export function patchMonthlyRecurringShiftStartTime(updateDetails: UpdateRosteredShiftStartTimeModel) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<MonthlyRecurringShiftVersioned>((resolve, reject) =>
    api
      .patch(`/monthly-recurring-shifts/${updateDetails.id}/update-start-time`, { newShiftStartTime: updateDetails.newShiftStartTime, updateFutureShiftAssignments: updateDetails.updateFutureAssignments, version: updateDetails.version }, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 201:
            resolve(response.data);
            break;
          case 400:
            reject(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}

export function patchMonthlyRecurringShiftRate(updateDetails: UpdateRosteredShiftRateModel) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<MonthlyRecurringShiftVersioned>((resolve, reject) =>
    api
      .patch(`/monthly-recurring-shifts/${updateDetails.id}/update-shift-rate`, { newShiftRateId: updateDetails.newShiftRate, updateFutureShiftAssignments: updateDetails.updateFutureAssignments, version: updateDetails.version }, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 201:
            resolve(response.data);
            break;
          case 400:
            reject(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}
