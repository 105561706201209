import { useRef } from "react";
import { Col } from "react-bootstrap";
import { CarerHolidayModel, RosteredShift, ShiftAssignment } from "../../app/types";
import styles from '../roster/Roster.module.css';
import ShiftAssignmentCell from '../shift-assignment';
import { ClientModel } from "../clients/clientSlice";
import { CarerModel } from "./carerSlice";
import CarerHolidayCell from '../carer-holiday';
import moment from "moment";

export type IProps = {
    assignedShifts: ShiftAssignment[];
    clientDictionary: Map<string, ClientModel> | undefined;
    carerShiftsDictionary: Map<string, ShiftAssignment> | undefined;
    rosterShifts: Map<string, RosteredShift> | undefined;
    carer: CarerModel;
    isActiveCarer: boolean;
    carerHoliday?: CarerHolidayModel | undefined;
    accessPrivilidges: boolean;
    administratorPriviledge: boolean;
    dayClasses: string;
    selectedShiftForThisCarer: boolean;
    handleShiftAssign: () => void;
    handleAddHoliday: () => void;
}

function CarerDayView(props: IProps) {
    let landingStrip = useRef(props.isActiveCarer ? <div className={styles.newShiftArea} onClick={props.handleAddHoliday}></div> : <div />);

    if (props.isActiveCarer && props.accessPrivilidges) {
        landingStrip.current = <div title="Add holiday" className={styles.newShiftArea} onClick={props.handleAddHoliday}></div>
    } else {
        landingStrip.current = <div/>
    }

    let landingPad;

    if (props.selectedShiftForThisCarer) {
        landingPad = (
            <div className={styles.newShiftArea} onClick={() => props.handleShiftAssign()}></div>
        );
    }

    let orderedAssignedShifts = props.assignedShifts.sort((a, b) => moment(a.shiftStartTime).diff(moment(b.shiftStartTime)));

    return (
        <Col xs="12" sm="5" className={props.dayClasses}>
            {landingStrip.current}
            {props.carerHoliday ? (<CarerHolidayCell holiday={props.carerHoliday} carer={props.carer} canDelete={props.accessPrivilidges} />) : ""}
            {landingPad}
            {orderedAssignedShifts.map((assignedShift) => {
                let rosteredShift: RosteredShift | undefined = props.rosterShifts !== undefined ? props.rosterShifts.get(assignedShift.shiftId) : undefined;
                let clientId: string = rosteredShift ? rosteredShift.clientId : '';
                let client: ClientModel | undefined = clientId && props.clientDictionary ? props.clientDictionary.get(clientId) : undefined;
                if (rosteredShift && client) {
                    return <ShiftAssignmentCell 
                        key={`shift-assignment-${assignedShift.shiftId}`} 
                        shiftAssignment={assignedShift} 
                        rosteredShift={rosteredShift}
                        carerShiftsDictionary={props.carerShiftsDictionary}
                        client={client}
                        carer={props.carer}
                        accessPriviledges={props.accessPrivilidges}
                        administratorPriviledge={props.administratorPriviledge}
                        />
                }
                return (<></>);
            })}
        </Col>
    );
}

export default CarerDayView;