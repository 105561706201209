import { Accordion, Alert, Button, Col, ListGroup, Modal, Row } from "react-bootstrap";

import { ChatMessage } from "../../app/types";

interface IProps {
    setRemovePopupForChatMessage : (chatMessage: ChatMessage | null) => void;
    currentClientName : string | undefined;
    removeMessage: () => void,
    showRemovePopup: boolean,
    removePopupForChatMessageName : string | null,
    removePopupForChatMessageSent : string | null,
    removePopupChatMessage: string | null,
}

export default function RemoveModal(props: IProps) {

    const handleCloseRemoveModal = () => {
        props.setRemovePopupForChatMessage(null);
    }

    const removeMessage = () => {
        props.removeMessage();
    };

    let removePopup = (<></>)


    if (props.showRemovePopup) {
        removePopup = (
            <Modal show={props.showRemovePopup ? true : false} onHide={handleCloseRemoveModal}>
                <Modal.Header className="bg-fhah-purple text-white">
                    <Modal.Title>
                        Remove Message
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className="col-12 mt-4">
                            <Alert variant={'danger'}>
                                <h4 className="alert-heading">Are you sure</h4>
                                Are you sure you wish to remove this message?
                            </Alert>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Message</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col className=" mt-4">
                                                <ListGroup className="disabled">
                                                <ListGroup.Item> 
                                                    <small className="mb-1 d-block text-black-50">Client</small>
                                                    {props.currentClientName !== undefined ?  props.currentClientName : ""}
                                                </ListGroup.Item>
                                                <ListGroup.Item> 
                                                    <small className="mb-1 d-block text-black-50">Sent By</small>
                                                    {props.removePopupForChatMessageName}
                                                </ListGroup.Item>
                                                <ListGroup.Item> 
                                                    <small className="mb-1 d-block text-black-50">Message Sent</small>
                                                    {props.removePopupForChatMessageSent}
                                                </ListGroup.Item>
                                                <ListGroup.Item> 
                                                    <small className="mb-1 d-block text-black-50">Message</small>
                                                    <div className="pre-wrap">{props.removePopupChatMessage}</div>
                                                </ListGroup.Item>
                                                </ListGroup>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="bg-light">
                        <Button variant="secondary" onClick={() => removeMessage()}>Remove</Button>
                        <Button variant="secondary" onClick={handleCloseRemoveModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div>
            {removePopup}
        </div>
    );
}