import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Address } from "../../app/types";
import { CarerModel } from "../carers/carerSlice";
import { getClients } from "./clientApi";
// Types ------------------------------------------
export type ClientResponse = {
  clients: ClientModel[];
};

export type ClientModel = {
  id: string;
  title: string;
  firstName: string;
  surname: string;
  preferredName: string;
  phone: string;
  tellContactInfo: string;
  address: Address;
  clientDifficulty: number;
  clientCarerIds: string[];
  clientRates: ClientRateModel[];
  careRequirements: ClientCareRequirementModel[];
  hasClientDocument: boolean;
  branchId: string;
  lastViewedChatMessage: string | null;
  lastChatMessageForClient: string | null;
};

export type ClientRateModel = {
  id: string;
  rateId: string;
  rateDescription: string;
};

export type ClientCareRequirementModel = {
  id: string;
  careRequirementId: string;
  name: string;
  difficulty: number;
};

type ShowClientModalPayload = {
  client: ClientModel;
  carer: CarerModel | undefined;
};

// State -------------------------------------------
export interface ClientState {
  list: ClientModel[];
  status: "initial" | "idle" | "loading" | "failed";
  selectedClient: ClientModel | undefined;
  clientModalFor: ClientModel | undefined;
  clientModalForCarer: CarerModel | undefined;
  filterOnClientId: string | undefined;
}

const initialState: ClientState = {
  list: [],
  status: "initial",
  selectedClient: undefined,
  clientModalFor: undefined,
  clientModalForCarer: undefined,
  filterOnClientId: undefined,
};

// Reducer --------------------------------------------------
export const getClientsAsync = createAsyncThunk(
  "client/getClients",
  async () => {
    const response = await getClients();
    return response.clients;
  }
);

export const clientSlice = createSlice({
  name: "client",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetClients: (state) => {
      state.list = [];
      state.status = "initial";
      state.selectedClient = undefined;
    },
    selectClient: (state, action: PayloadAction<ClientModel>) => {
      state.selectedClient = action.payload;
    },
    updateClient: (state, action: PayloadAction<ClientModel>) => {
      let updatedList = state.list as ClientModel[];
      const index = updatedList.findIndex((x) => x.id === action.payload.id);
      if (index !== -1) {
        updatedList.splice(index, 1, action.payload);
      }
      state.list = updatedList;
    },
    clearSelectClient: (state) => {
      state.selectedClient = undefined;
    },
    updateClientLastMessage: (
      state,
      action: PayloadAction<{ clientId: String; messageLastUpdated: string }>
    ) => {
      let updatedList = state.list as ClientModel[];
      const index = updatedList.findIndex(
        (x) => x.id === action.payload.clientId
      );
      if (index !== -1) {
        updatedList[index].lastChatMessageForClient =
          action.payload.messageLastUpdated;
      }
      state.list = updatedList;
    },
    updateClientLastViewedChatMessage: (
      state,
      action: PayloadAction<{ clientId: String; lastViewedChatMessage: string }>
    ) => {
      let updatedList = state.list as ClientModel[];
      const index = updatedList.findIndex(
        (x) => x.id === action.payload.clientId
      );
      if (index !== -1) {
        updatedList[index].lastViewedChatMessage =
          action.payload.lastViewedChatMessage;
      }
      state.list = updatedList;
    },
    showClientModal: (state, action: PayloadAction<ShowClientModalPayload>) => {
      state.clientModalFor = action.payload.client;
      state.clientModalForCarer = action.payload.carer;
    },
    hideClientModal: (state) => {
      state.clientModalFor = undefined;
      state.clientModalForCarer = undefined;
    },
    setClientFilter: (state, action: PayloadAction<string>) => {
      state.filterOnClientId = action.payload;
    },
    resetClientFilter: (state) => {
      state.filterOnClientId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getClientsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.list = action.payload;
      });
  },
});

export const {
  resetClients,
  selectClient,
  updateClient,
  clearSelectClient,
  updateClientLastMessage,
  updateClientLastViewedChatMessage,
  showClientModal,
  hideClientModal,
  setClientFilter,
  resetClientFilter,
} = clientSlice.actions;

export const selectClientFilter = (state: RootState) => state.clients.filterOnClientId;

export const selectClientSliceStatus = (state: RootState) =>
  state.clients.status;

export const selectClients = (state: RootState) => state.clients.list;

export const selectClientsAsDictionary = (state: RootState) => {
  let clientsDictionary = new Map();

  // Convert list of clients into typescript map dictionary using client id
  for (let client of state.clients.list) {
    let clientInDictionary = clientsDictionary.get(client.id);

    if (!clientInDictionary) {
      // No match found add a client entry
      clientsDictionary.set(client.id, client);
    }
  }

  return clientsDictionary;
};

export const selectedClient = (state: RootState) =>
  state.clients.selectedClient;

export const selectClientAsBranchDictionary = (state: RootState) => {
  let clientsForBranchDictionary = new Map();
  for (let client of state.clients.list) {
      let dictionaryForBranch = clientsForBranchDictionary.get(client.branchId);

      if (!dictionaryForBranch) {
        // No match found add a branch entry
        clientsForBranchDictionary.set(client.branchId, new Map());
        dictionaryForBranch = clientsForBranchDictionary.get(client.branchId);
      }

      let dictionaryForClientId = dictionaryForBranch.get(client.id);

      if (!dictionaryForClientId) {
        dictionaryForBranch.set(client.id, client);
      }
  }

  return clientsForBranchDictionary;
}

export const selectClientModalFor = (state: RootState) =>
  state.clients.clientModalFor;

export const selectClientModalForCarer = (state: RootState) =>
  state.clients.clientModalForCarer;

export default clientSlice.reducer;
