import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RosteredShift, ShiftAssignment, UpdateRosteredShiftRateModel } from "../../app/types";
import { selectShiftAssignmentRateModalFor, hideShiftAssignmentRateModal, selectShiftAssignmentUpdateRateStatus, UpdateShiftRateModel, patchShiftAssignmentRateAsync } from './ShiftAssignmentSlice';
import { patchWeeklyRecurringShiftRateAsync, selectWeeklyShiftPatchRateStatus } from '../shift-form/WeeklyRecurringShiftSlice';
import { patchMonthlyRecurringShiftRateAsync, selectMonthlyShiftPatchRateStatus } from '../shift-form/MonthlyRecurringShiftSlice';
import ViewShiftAssignmentRateModal from './ViewShiftAssignmentRateModal';
import { selectListValidation } from "../../app/validation";
import { selectRoster } from "../roster/RosterSlice";
import { SelectListItem } from "../../app/model";
import { ClientModel, selectClientsAsDictionary } from "../clients/clientSlice";

export default function ShiftAssignmentRateModal() {
    const dispatch = useAppDispatch();
    const shiftAssignmentUpdateStatus = useAppSelector(selectShiftAssignmentUpdateRateStatus);
    const rosters = useAppSelector(selectRoster);
    const clientDictionary: Map<string, ClientModel> = useAppSelector(selectClientsAsDictionary);
    
    const shiftAssignmentRateModalFor: ShiftAssignment | undefined = useAppSelector(selectShiftAssignmentRateModalFor);

    const monthlyShiftPatchStatus = useAppSelector(selectMonthlyShiftPatchRateStatus);
    const weeklyShiftPatchStatus = useAppSelector(selectWeeklyShiftPatchRateStatus);

    const [rateIdInvalid, setRateIdInvalid] = useState<boolean>(false);
    const [currentDuration, setCurrentDuration] = useState<string>('');
    const [shiftRateId, setShiftRateId] = useState<string>(shiftAssignmentRateModalFor ? shiftAssignmentRateModalFor.rateId : '');
    const [shiftRateIdChange, setShiftRateIdChange] = useState(false);
    const [shiftType, setShiftType] = useState<string | undefined>(undefined);
    const [shiftVersion, setShiftVersion] = useState<string | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(false);

    const [updateShiftEnabled, setUpdateShiftEnabled] = useState<boolean>(false);

    let rateSelectListItems = useRef<SelectListItem[]>([]);

    useEffect(() => {
        if (shiftAssignmentRateModalFor && !currentDuration) {
            const shiftStartTimeString = moment(shiftAssignmentRateModalFor.shiftStartTime).format('HH:mm');
            const shiftEndTimeString = moment(shiftAssignmentRateModalFor.shiftStartTime).add(moment.duration(shiftAssignmentRateModalFor.shiftLength).asMinutes(), 'minutes').format('HH:mm');
        
            const duration = `${shiftStartTimeString} - ${shiftEndTimeString}`;

            const shiftsForDate = rosters ? rosters.get(moment(shiftAssignmentRateModalFor.date).format('YYYY-MM-DD')) : undefined;
            const shift: RosteredShift = shiftsForDate.get(shiftAssignmentRateModalFor.shiftId);

            const rateId: string = shiftAssignmentRateModalFor.rateId;
            rateIdInvalidFunction(rateId);

            if (shift) {
                setUpdateShiftEnabled(shift.shiftType !== 'AdHocShift' && shift.isActive);
                setShiftType(shift.shiftType);
                setShiftVersion(shift.shiftVersion);
                setCurrentDuration(duration);
                setShiftRateId(rateId);
                setShiftRateIdChange(false);
                if (shift.clientId !== "" && clientDictionary !== undefined) {
                    const client = clientDictionary.get(shift.clientId);
                    if (client) {
                        rateSelectListItems.current = client.clientRates.map(x => ({value:x.rateId, text: x.rateDescription }));
                    }
                }
            }
        }

        if (!shiftAssignmentRateModalFor && currentDuration) {
            // Reset Form
            setCurrentDuration('');
            setShiftRateId('');
            setShiftRateIdChange(false);
        }

    }, [shiftAssignmentRateModalFor, currentDuration, rosters, clientDictionary]);

    useEffect(() => {
        if (shiftAssignmentUpdateStatus === 'loading' && !loading) {
            setLoading(true);
        }
        if (shiftAssignmentUpdateStatus !== 'loading' && loading) {
            setLoading(false);
        }
    }, [shiftAssignmentUpdateStatus, loading]);

    const handleCloseModal = useCallback(() => {
        dispatch(hideShiftAssignmentRateModal());
    }, [dispatch]);

    useEffect(() => {
        if (monthlyShiftPatchStatus === "success" || weeklyShiftPatchStatus === "success") {
            handleCloseModal();
        }
    }, [monthlyShiftPatchStatus, weeklyShiftPatchStatus, handleCloseModal]);

    const onRateIdInputChange = (value : string) => {
        if (value !== shiftRateId) {
            setShiftRateId(value);
            setShiftRateIdChange(true);
            rateIdInvalidFunction(value);
        }
    };

    const rateIdInvalidFunction = (value :string) => {
        if (selectListValidation(value)) {
            setRateIdInvalid(false);
            return false;
        }
        setRateIdInvalid(true);
        return true;
    };

    const updateThisAssignment = () => {
        if (shiftAssignmentRateModalFor) {
            let payload: UpdateShiftRateModel = {
                id: shiftAssignmentRateModalFor.id,
                newShiftRate: String(shiftRateId),
                version: shiftAssignmentRateModalFor.version,
            }
            dispatch(patchShiftAssignmentRateAsync(payload));
        }
    }

    const updateShiftAndAssignments = () => {
        if (shiftType && shiftVersion && shiftAssignmentRateModalFor) {
            let patchPayload: UpdateRosteredShiftRateModel = {
                id: shiftAssignmentRateModalFor.shiftId,
                newShiftRate: shiftRateId,
                updateFutureAssignments: true,
                version: shiftVersion,
            }
            switch (shiftType) {
                case "WeeklyRecurringShift":
                    dispatch(patchWeeklyRecurringShiftRateAsync(patchPayload));
                    break;
                case "MonthlyRecurrringShift":
                    dispatch(patchMonthlyRecurringShiftRateAsync(patchPayload));
                    break;
                default:
                    alert('Not weekly or monthly' + shiftType?.toString());
                    break;
            }
        }
    }

    return (
        <ViewShiftAssignmentRateModal
            rateId={shiftRateId}
            rateIdChange={shiftRateIdChange}
            showModal={shiftAssignmentRateModalFor !== undefined ? true : false}
            shiftAssignmentDate={shiftAssignmentRateModalFor ? moment(shiftAssignmentRateModalFor.date).format("MMM Do, YYYY") : ''}
            duration={currentDuration}
            loading={loading}
            shiftAssignmentUpdateStatus={shiftAssignmentUpdateStatus}
            updateShiftEnabled={updateShiftEnabled}
            rateIdInvalid={rateIdInvalid}
            rateSelectListItems={rateSelectListItems.current}
            handleCloseModal={handleCloseModal}
            onShiftRateInputChange={(value: string) => onRateIdInputChange(value)}
            updateThisAssignment={updateThisAssignment}
            updateShiftAndAssignments={updateShiftAndAssignments}
        />
    );
}