import { Col, OverlayTrigger, Popover, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEdit, faEye, faUmbrellaBeach, faPhone } from '@fortawesome/pro-duotone-svg-icons';
import { memo } from "react";

export type IProps = {
    accountCarerId?: string | undefined;
    userRole?: string | string[] | null;
    carerId: string;
    carerFirstName: string;
    carerSurname: string;
    carerPhone: string;

    isActiveCarer: boolean;
    buttonClasses: string;

    handleShowCarerModal: () => void;
    handleCloseModal: () => void;
    handleShowEditModal: () => void;
    handleCloseEditModal: () => void;
    handleClearSelectedCarer: () => void;
    handleSelectCarer: () => void;
}

function CarerCellView(props: IProps) {
    let holidayButton = <></>;
    let editCarerButton = <></>;
    let viewCarerButton = <></>;
    let carerPhone = <></>;

    if (props.userRole || (props.accountCarerId && props.accountCarerId === props.carerId)) {
        if (props.isActiveCarer) {
            holidayButton = (
                <Button variant="warning" className="py-2 px-3" onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => props.handleClearSelectedCarer()} title="Cancel adding a holiday for this carer.">
                    <FontAwesomeIcon icon={faBan}/>
                </Button>
            );
        } else {
            holidayButton = (
                <Button variant="success" className="py-2 px-3" onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => props.handleSelectCarer()} title="Add a holiday for this carer.">
                    <FontAwesomeIcon icon={faUmbrellaBeach}/>
                </Button>
            );
        }

        viewCarerButton = (
            <Button variant="success" className="py-2 px-3 me-2" onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => props.handleShowCarerModal()} title="View Carer Information.">
                <FontAwesomeIcon icon={faEye}/>
            </Button>
        );

        if (!props.userRole && props.accountCarerId === props.carerId) {
            editCarerButton = (
                <Button variant="success" className="py-2 px-3 me-2" onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => props.handleShowEditModal()} title="Edit your personal information.">
                    <FontAwesomeIcon icon={faEdit}/>
                </Button>
            );
            viewCarerButton = <></>;
        }
    } else {
        carerPhone = <span><FontAwesomeIcon icon={faPhone} className="fa-fw me-2" /><strong>{props.carerPhone}</strong></span>;
    }


    let popoverBody = (
        <Popover.Body className="bg-light">
            <Row className="justify-content-md-center">
                <Col lg="12" className="text-left">
                    {carerPhone}
                </Col>
                <Col lg="12" className="text-center">
                    {editCarerButton}
                    {viewCarerButton}
                    {holidayButton}
                </Col>
            </Row>
        </Popover.Body>
    );

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3" className="bg-fhah-purple text-white border border-white">
                <span className="text-uppercase" title={`${props.carerFirstName} ${props.carerSurname}`}>{props.carerFirstName} {props.carerSurname}</span>
            </Popover.Header>
            {popoverBody}
        </Popover>
    );
    
    return (
        <OverlayTrigger trigger="focus" placement="bottom" overlay={popover}>
            <Button 
                onClick={(e) => {
                    let element = e.target as HTMLElement;
                    if (element.classList.contains("btn")) {
                        element.focus();
                    } else {
                        let btn = element.closest("button");
                        if (btn) {
                            btn.focus();
                        }
                    }
                }}
                variant="link" 
                className={props.buttonClasses}>

                {props.carerFirstName} {props.carerSurname}
            </Button>
        </OverlayTrigger>
    );
}

export default memo(CarerCellView);