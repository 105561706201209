import SelectListInput from "../../app/generic-components/SelectListInput";
import { SelectListItem } from "../../app/model";

export type IProps = {
    clientSelectListItems: SelectListItem[];
    branchSelectListItems: SelectListItem[];
    filteredOnClientId: string;
    filteredOnBranchId: string;
    applyingFilter: boolean;
    onClientIdInputChange: (id: string) => void;
    onBranchIdInputChange: (id: string) => void;
} 

export default function ViewRotaFilters(props: IProps) {
    let branchSelectList;
    if (props.branchSelectListItems && props.branchSelectListItems.length > 1) {
        branchSelectList = (<SelectListInput selectListItems={props.branchSelectListItems} name="Branch" currentValue={props.filteredOnBranchId} changeSelectList={(id: string) => props.onBranchIdInputChange(id)} isInvalid={false} disabled={props.applyingFilter} showAllText={true}/>)
    }
    return (
        <div className="h-100">
            <div className="bg-fhah-green text-white">
                <h4 className="card-title">Filters</h4>
            </div>
            <div className="h-100-minus-3rem overflow-auto">
                {branchSelectList}
                <SelectListInput selectListItems={props.clientSelectListItems} name="Client" currentValue={props.filteredOnClientId} changeSelectList={(id: string) => props.onClientIdInputChange(id)} isInvalid={false} disabled={props.applyingFilter} showAllText={true}/>
            </div>
        </div>
    )
}