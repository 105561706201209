import { useCallback } from 'react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ClientModel, showClientModal } from '../clients/clientSlice';
import { RosteredShift, ShiftAssignment } from '../../app/types';
import { currentSelectedShift, showDeleteModal, showShiftAssignmentModal, ShiftAssignmentModel, DeleteShiftAssignmentModel, showShiftAssignmentTimeModal, showShiftAssignmentRateModal} from './ShiftAssignmentSlice';
import { CarerModel } from '../carers/carerSlice';
import ShiftAssignmentView from './ShiftAssignmentView';

export type IProps = {
    shiftAssignment: ShiftAssignment;
    rosteredShift: RosteredShift;
    client: ClientModel;
    carer: CarerModel;
    carerShiftsDictionary: Map<string, ShiftAssignment> | undefined;
    accessPriviledges: boolean;
    administratorPriviledge: boolean;
}

export default function ShiftAssignmentCell(props: IProps) {
    const selectedShift = useAppSelector(currentSelectedShift);
    const dispatch = useAppDispatch();

    const shiftStartTime = moment(props.shiftAssignment.shiftStartTime);
    const shiftStartTimeString = moment(props.shiftAssignment.shiftStartTime).format('HH:mm');
    const shiftEndTime = moment(props.shiftAssignment.shiftStartTime).add(moment.duration(props.shiftAssignment.shiftLength).asMinutes(), 'minutes');
    const shiftEndTimeString = moment(props.shiftAssignment.shiftStartTime).add(moment.duration(props.shiftAssignment.shiftLength).asMinutes(), 'minutes').format('HH:mm');

    const duration = `${shiftStartTimeString} - ${shiftEndTimeString}`;

    const handleShowTimeModal = useCallback(() => {
        dispatch(showShiftAssignmentTimeModal(props.shiftAssignment));
    }, [dispatch, props.shiftAssignment]);

    const handleShowRateModal = useCallback(() => {
        dispatch(showShiftAssignmentRateModal(props.shiftAssignment));
    }, [dispatch, props.shiftAssignment]);

    const handleViewShiftPopupShow = useCallback(() => {
        const shiftAssignmentModel: ShiftAssignmentModel = {
            shiftType: props.rosteredShift.shiftType,
            shift: props.shiftAssignment,
            client: props.client,
            carer: props.carer,
            duration: duration,
        }

        dispatch(showShiftAssignmentModal(shiftAssignmentModel));
    }, [dispatch, props.carer, props.client, props.shiftAssignment, props.rosteredShift, duration]);

    const handleShowDeleteModalShow = useCallback(() => {
        const shiftAssignmentDeleteModel: DeleteShiftAssignmentModel = {
            id: props.shiftAssignment.id,
            shiftAssignment: props.shiftAssignment,
            client: props.client,
            carer: props.carer,
            duration: duration,
        }

        dispatch(showDeleteModal(shiftAssignmentDeleteModel));
    }, [dispatch, props.carer, props.client, props.shiftAssignment, duration]);

    const handleShowClientModal = useCallback(() => {
        dispatch(showClientModal({client: props.client, carer: props.carer}));
    }, [dispatch, props.client, props.carer]);

    let shiftVariant = 'success';
    switch (props.client.clientDifficulty) {
        case 30:
            shiftVariant = 'danger';
            break;
        case 20:
            shiftVariant = 'warning';
            break;
        default:
            shiftVariant = 'success';
            break;
    } 

    let shiftOverlapBorderColor: string = "";

    props.carerShiftsDictionary && props.carerShiftsDictionary.forEach((carerShift, index) => {
        let carerShiftStart = moment(carerShift.shiftStartTime);
        let carerShiftEnd  = moment(carerShift.shiftStartTime).add(moment.duration(carerShift.shiftLength).asMinutes(), 'minutes');
        if (carerShift.shiftId !== props.rosteredShift.shiftId && 
            (
                (carerShiftStart.isSameOrBefore(shiftStartTime) && carerShiftEnd.isSameOrAfter(shiftStartTime))||
                (carerShiftStart.isSameOrBefore(shiftEndTime) && carerShiftStart.isSameOrAfter(shiftStartTime))
            )
        ) {
            shiftOverlapBorderColor = " shiftOverlapBorderColor";
        }
    });

    const paddingOnButtonIfShiftSelectActive: boolean = selectedShift !== null && moment(props.shiftAssignment.date).isSame(selectedShift.date, "day") &&  props.shiftAssignment.shiftId === selectedShift.shiftId ? true : false;
 
    let requirementsMisMatch: boolean = false;

    if (props.client.careRequirements && props.carer.canAddressRequirements) {
        for (let cri = 0; cri < props.client.careRequirements.length; cri++) {
            let carerReqIndex = props.carer.canAddressRequirements.findIndex(x => x.careRequirementId === props.client.careRequirements[cri].careRequirementId);
            if (carerReqIndex === -1) {
                requirementsMisMatch = true;
                continue;
            }
        }
    }

    return (
        <ShiftAssignmentView
            accessPriviledges={props.accessPriviledges}
            administratorPriviledge={props.administratorPriviledge}
            clientFirstName={props.client.firstName}
            clientSurname={props.client.surname}
            shiftVariant={shiftVariant}
            duration={duration}
            shiftOverlapBorderColor={shiftOverlapBorderColor}
            requirementsMisMatch={requirementsMisMatch}
            shiftSelectActive={paddingOnButtonIfShiftSelectActive}
            handleViewShiftPopupShow={handleViewShiftPopupShow}
            handleShowTimeModal={handleShowTimeModal}
            handleShowRateModal={handleShowRateModal}
            handleShowDeleteModalShow={handleShowDeleteModalShow}
            handleShowClientModal={handleShowClientModal}
        />
    )
}