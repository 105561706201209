import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Modal, Spinner } from 'react-bootstrap';
import AdHocShiftForm from '../shift-form';
import { ShiftType } from "../../app/model";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { adHocFormState, adHocResetForm, loadedAdHocShift, postAdHocShiftAsync, putAdHocShiftAsync } from "../shift-form/AdHocShiftSlice";
import { AdHocShiftVersioned, AdHocShift, WeeklyRecurringShift, MonthlyRecurringShift, MonthlyRecurringShiftVersioned, WeeklyRecurringShiftVersioned, } from "../../app/types";
import { getWeeklyRecurringShiftAsync, loadedWeeklyRecurringShift, postWeeklyRecurringShiftAsync, putWeeklyRecurringShiftAsync, weeklyFormState, weeklyResetForm } from "../shift-form/WeeklyRecurringShiftSlice";
import { getMonthlyRecurringShiftAsync, loadedMonthlyRecurringShift, monthlyFormState, monthlyResetForm, postMonthlyRecurringShiftAsync, putMonthlyRecurringShiftAsync } from "../shift-form/MonthlyRecurringShiftSlice";
import { getAdHocShiftAsync } from "../shift-form/AdHocShiftSlice";
import { dateValidation, numberOfCarersValidation, numberOfWeeksRecurring, selectListValidation, timeOrTimespanValidation } from "../../app/validation";
import moment from "moment";
import { hideShiftFormModal, selectShiftFormModalFor, ShiftFormModel } from "./RosteredShiftSlice";

export default function ShiftForm() {
    const shiftFormModalFor: ShiftFormModel | undefined = useAppSelector(selectShiftFormModalFor);

    const currentAdHocFormState = useAppSelector(adHocFormState);
    const currentWeeklyFormState = useAppSelector(weeklyFormState);
    const currentMonthlyFormState = useAppSelector(monthlyFormState);
    const currentLoadedAdHoc = useAppSelector(loadedAdHocShift);
    const currentLoadedMonthly = useAppSelector(loadedMonthlyRecurringShift);
    const currentLoadedWeekly = useAppSelector(loadedWeeklyRecurringShift);

    const dispatch = useAppDispatch();

    const [version, setVersion] = useState<string>('');
    const [id, setId] = useState<string>('');
    const [clientId, setClientId] = useState<string>('');
    const [clientIdInvalid, setClientIdInvalid] = useState<undefined | boolean>(undefined);
    const [clientChange, setClientChange] = useState(false);
    const [rateId, setRateId] = useState<string>('');
    const [rateIdInvalid, setRateIdInvalid] = useState<undefined | boolean>(undefined);
    const [rateIdChange, setRateIdChange] = useState(false);
    const [shiftDate, setShiftDate] = useState('');
    const [shiftDateInvalid, setShiftDateInvalid] = useState<undefined | boolean>(undefined);
    const [shiftDateChange, setShiftDateChange] = useState(false);
    const [shiftStartTime, setShiftStartTime] = useState('');
    const [shiftStartTimeInvalid, setShiftStartTimeInvalid] = useState<undefined | boolean>(undefined);
    const [shiftStartTimeChange, setShiftStartTimeChange] = useState(false);
    const [shiftLength, setShiftLength] = useState('');
    const [shiftLengthInvalid, setShiftLengthInvalid] = useState<undefined | boolean>(undefined);
    const [shiftLength24H, setShiftLength24H] = useState(false);
    const [shiftLengthChange, setShiftLengthChange] = useState(false);
    const [numberOfCarers, setNumberOfCarers] = useState(1);
    const [numberOfCarersInvalid, setNumberOfCarersInvalid] = useState<undefined | boolean>(undefined);
    const [numberOfCarersChange, setNumberOfCarersChange] = useState(false);
    const [isActive, setIsActive] = useState(true);

    const [typeOfShift, setTypeOfShift] = useState(ShiftType.AdHoc);
    const [monday, setMonday] = useState(false);
    const [tuesday, setTuesday] = useState(false);
    const [wednesday, setWednesday] = useState(false);
    const [thursday, setThursday] = useState(false);
    const [friday, setFriday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [sunday, setSunday] = useState(false);
    const [recurrenceStart, setRecurrenceStart] = useState("");
    const [recurrenceStartInvalid, setRecurrenceStartInvalid] = useState<undefined | boolean>(undefined);
    const [recurrenceStartChange, setRecurrenceStartChange] = useState(false);
    const [recurrenceEnd, setRecurrenceEnd] = useState("");
    const [recurrenceHasEnd, setRecurrenceHasEnd] = useState(false);
    const [recurrenceEndInvalid, setRecurrenceEndInvalid] = useState<undefined | boolean>(undefined);
    const [recurrenceEndChange, setRecurrenceEndChange] = useState(false);
    const [recurEveryNWeeks, setRecurEveryNWeeks] = useState(1);
    const [recurEveryNWeeksInvalid, setRecurEveryNWeeksInvalid] = useState<undefined | boolean>(undefined);
    const [recurEveryNWeeksChange, setRecurEveryNWeeksChange] = useState(false);
    const [firstFullWeekOfMonth, setFirstFullWeekOfMonth] = useState(false);
    const [secondFullWeekOfMonth, setSecondFullWeekOfMonth] = useState(false);
    const [thirdFullWeekOfMonth, setThirdFullWeekOfMonth] = useState(false);
    const [fourthFullWeekOfMonth, setFourthFullWeekOfMonth] = useState(false);
    const [lastFullWeekOfMonth, setLastFullWeekOfMonth] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {

      clientIdInvalidFunction(clientId);
      rateIdInvalidFunction(rateId);
      shiftDateInvalidFunction(shiftDate);
      shiftStartTimeInvalidFunction(shiftStartTime);
      shiftLengthInvalidFunction(shiftLength);
      numberOfCarersInvalidFunction(numberOfCarers);
      recurrenceEndInvalidFunction(recurrenceEnd, recurrenceHasEnd);
      recurrenceStartInvalidFunction(recurrenceStart);
      onRecurEveryNWeeksInvalidFunction(recurEveryNWeeks);

    }, [clientId,
        clientIdInvalid,
        rateId,rateIdInvalid,
        shiftDate,shiftDateInvalid,
        shiftStartTime,
        shiftStartTimeInvalid,
        shiftLength,
        shiftLengthInvalid,
        numberOfCarers,
        numberOfCarersInvalid,
        isActive,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        recurrenceStart,
        recurrenceStartInvalid,
        recurrenceEnd,
        recurrenceEndInvalid,
        recurEveryNWeeks,
        recurEveryNWeeksInvalid,
        firstFullWeekOfMonth,
        secondFullWeekOfMonth,
        thirdFullWeekOfMonth,
        fourthFullWeekOfMonth,
        lastFullWeekOfMonth,
        typeOfShift, 
        loading,
        recurrenceHasEnd])

    let loadingPlaceholder = useRef(<span />);

    const handleCloseShiftModal = useCallback(() => {
        dispatch(adHocResetForm());
        dispatch(weeklyResetForm());
        dispatch(monthlyResetForm());
        dispatch(hideShiftFormModal());
    },[dispatch]);

    useEffect(() => {
        if (shiftFormModalFor) {
            setClientId(shiftFormModalFor.client ? shiftFormModalFor.client.id : "");
            setShiftDate(moment(shiftFormModalFor.date).format('YYYY-MM-DD'));
            setShiftStartTime(moment(shiftFormModalFor.date).format('HH:mm'));
        }

        if (shiftFormModalFor !== undefined) {
            if (currentAdHocFormState === "success" || currentWeeklyFormState === "success" || currentMonthlyFormState === "success") {
                handleCloseShiftModal();
            }
        }

        if (currentAdHocFormState === "default" ||
            currentWeeklyFormState === "default"|| 
            currentMonthlyFormState === "default") {
                loadingPlaceholder.current = (
                    <></>
                );
                setLoading(false);
        }

        if (shiftFormModalFor !== undefined && shiftFormModalFor.id !== undefined && shiftFormModalFor.shiftType !== undefined && currentAdHocFormState === "default" && currentMonthlyFormState === "default" && currentWeeklyFormState === "default")
        {
            switch(shiftFormModalFor.shiftType) {
                case "AdHocShift":
                    dispatch(getAdHocShiftAsync(shiftFormModalFor.id));
                    break;
                case "WeeklyRecurringShift":
                    dispatch(getWeeklyRecurringShiftAsync(shiftFormModalFor.id));
                    break;
                case "MonthlyRecurrringShift":
                    dispatch(getMonthlyRecurringShiftAsync(shiftFormModalFor.id));
                    break;
            }
        }

        if (shiftFormModalFor && (currentAdHocFormState === "loaded" || currentMonthlyFormState === "loaded" || currentWeeklyFormState === "loaded"))
        {
            switch(shiftFormModalFor.shiftType) {
                case "AdHocShift":
                    if (currentLoadedAdHoc !== null) {
                        setVersion(currentLoadedAdHoc.version);
                        setId(currentLoadedAdHoc.id);
                        setClientId(currentLoadedAdHoc.clientId);
                        setClientIdInvalid(false);
                        setClientChange(false);
                        setRateId(currentLoadedAdHoc.rateId);
                        setRateIdInvalid(false);
                        setRateIdChange(false);
                        setShiftDate(moment(currentLoadedAdHoc.shiftDate).format('YYYY-MM-DD'));
                        setShiftDateInvalid(false);
                        setShiftDateChange(false);
                        setShiftStartTime(moment(currentLoadedAdHoc.shiftStartTime, 'HH:mm:ss').format('HH:mm'));
                        setShiftStartTimeInvalid(false);
                        setShiftStartTimeChange(false);
                        setShiftLength(currentLoadedAdHoc.shiftLength === "1.00:00:00" ? moment(currentLoadedAdHoc.shiftLength, 'd.HH:mm:ss').format('d.HH:mm') : moment(currentLoadedAdHoc.shiftLength, 'HH:mm:ss').format('HH:mm'));                            setShiftLengthInvalid(false);
                        setShiftLength24H(currentLoadedAdHoc.shiftLength === "1.00:00:00" ? true : false);
                        setShiftLengthChange(false);
                        setNumberOfCarers(currentLoadedAdHoc.numberOfCarers);
                        setNumberOfCarersInvalid(false);
                        setNumberOfCarersChange(false);
                        setIsActive(currentLoadedAdHoc.isActive);
                        setTypeOfShift(ShiftType.AdHoc);
                        setMonday(false);
                        setTuesday(false);
                        setWednesday(false);
                        setThursday(false);
                        setFriday(false);
                        setSaturday(false);
                        setSunday(false);
                        setRecurrenceStart("");
                        setRecurrenceStartInvalid(undefined);
                        setRecurrenceStartChange(false);
                        setRecurrenceEnd("");
                        setRecurrenceHasEnd(false);
                        setRecurrenceEndInvalid(undefined);
                        setRecurrenceEndChange(false);
                        setRecurEveryNWeeks(1);
                        setRecurEveryNWeeksInvalid(undefined);
                        setRecurEveryNWeeksChange(false);
                        setFirstFullWeekOfMonth(false);
                        setSecondFullWeekOfMonth(false);
                        setThirdFullWeekOfMonth(false);
                        setFourthFullWeekOfMonth(false);
                        setLastFullWeekOfMonth(false);         
                        return;
                    }
                    break;
                case "WeeklyRecurringShift":
                    if (currentLoadedWeekly !== null) {
                        setVersion(currentLoadedWeekly.version);
                        setId(currentLoadedWeekly.id);
                        setClientId(currentLoadedWeekly.clientId);
                        setClientIdInvalid(false);
                        setClientChange(false);
                        setRateId(currentLoadedWeekly.rateId);
                        setRateIdInvalid(false);
                        setRateIdChange(false);
                        setShiftDate("");
                        setShiftDateInvalid(false);
                        setShiftDateChange(false);
                        setShiftStartTime(moment(currentLoadedWeekly.shiftStartTime, 'HH:mm:ss').format('HH:mm'));
                        setShiftStartTimeInvalid(false);
                        setShiftStartTimeChange(false);
                        setShiftLength(currentLoadedWeekly.shiftLength === "1.00:00:00" ? moment(currentLoadedWeekly.shiftLength, 'd.HH:mm:ss').format('d.HH:mm') : moment(currentLoadedWeekly.shiftLength, 'HH:mm:ss').format('HH:mm'));
                        setShiftLengthInvalid(false);
                        setShiftLength24H(currentLoadedWeekly.shiftLength === "1.00:00:00" ? true : false);
                        setShiftLengthChange(false);
                        setNumberOfCarers(currentLoadedWeekly.numberOfCarers);
                        setNumberOfCarersInvalid(false);
                        setNumberOfCarersChange(false);
                        setIsActive(currentLoadedWeekly.isActive);
                        setTypeOfShift(ShiftType.Weekly);
                        setMonday(currentLoadedWeekly.monday);
                        setTuesday(currentLoadedWeekly.tuesday);
                        setWednesday(currentLoadedWeekly.wednesday);
                        setThursday(currentLoadedWeekly.thursday);
                        setFriday(currentLoadedWeekly.friday);
                        setSaturday(currentLoadedWeekly.saturday);
                        setSunday(currentLoadedWeekly.sunday);
                        setRecurrenceStart(moment(currentLoadedWeekly.recurrenceStart).format('YYYY-MM-DD'));
                        setRecurrenceStartInvalid(false);
                        setRecurrenceStartChange(false);
                        setRecurrenceEnd(currentLoadedWeekly.recurrenceEnd !== null ? moment(currentLoadedWeekly.recurrenceEnd).format('YYYY-MM-DD') : "");
                        setRecurrenceHasEnd(currentLoadedWeekly.recurrenceEnd === null ? true : false);
                        setRecurrenceEndInvalid(false);
                        setRecurrenceEndChange(false);
                        setRecurEveryNWeeks(currentLoadedWeekly.recurEveryNWeeks);
                        setRecurEveryNWeeksInvalid(false);
                        setRecurEveryNWeeksChange(false);
                        setFirstFullWeekOfMonth(false);
                        setSecondFullWeekOfMonth(false);
                        setThirdFullWeekOfMonth(false);
                        setFourthFullWeekOfMonth(false);
                        setLastFullWeekOfMonth(false);      
                        return;
                    }
                    break;
                case "MonthlyRecurrringShift":
                    if (currentLoadedMonthly !== null) {
                        setVersion(currentLoadedMonthly.version);
                        setId(currentLoadedMonthly.id);
                        setClientId(currentLoadedMonthly.clientId);
                        setClientIdInvalid(false);
                        setClientChange(false);
                        setRateId(currentLoadedMonthly.rateId);
                        setRateIdInvalid(false);
                        setRateIdChange(false);
                        setShiftDate("");
                        setShiftDateInvalid(false);
                        setShiftDateChange(false);
                        setShiftStartTime(moment(currentLoadedMonthly.shiftStartTime, 'HH:mm:ss').format('HH:mm'));
                        setShiftStartTimeInvalid(false);
                        setShiftStartTimeChange(false);
                        setShiftLength(currentLoadedMonthly.shiftLength === "1.00:00:00" ? moment(currentLoadedMonthly.shiftLength, 'd.HH:mm:ss').format('d.HH:mm') : moment(currentLoadedMonthly.shiftLength, 'HH:mm:ss').format('HH:mm'));                            setShiftLengthInvalid(false);
                        setShiftLength24H(currentLoadedMonthly.shiftLength === "1.00:00:00" ? true : false);
                        setShiftLengthChange(false);
                        setNumberOfCarers(currentLoadedMonthly.numberOfCarers);
                        setNumberOfCarersInvalid(false);
                        setNumberOfCarersChange(false);
                        setIsActive(currentLoadedMonthly.isActive);
                        setTypeOfShift(ShiftType.Monthly);
                        setMonday(currentLoadedMonthly.monday);
                        setTuesday(currentLoadedMonthly.tuesday);
                        setWednesday(currentLoadedMonthly.wednesday);
                        setThursday(currentLoadedMonthly.thursday);
                        setFriday(currentLoadedMonthly.friday);
                        setSaturday(currentLoadedMonthly.saturday);
                        setSunday(currentLoadedMonthly.sunday);
                        setRecurrenceStart(moment(currentLoadedMonthly.recurrenceStart).format('YYYY-MM-DD'));
                        setRecurrenceStartInvalid(false);
                        setRecurrenceStartChange(false);
                        setRecurrenceEnd(currentLoadedMonthly.recurrenceEnd !== null ? moment(currentLoadedMonthly.recurrenceEnd).format('YYYY-MM-DD') : "");
                        setRecurrenceHasEnd(currentLoadedMonthly.recurrenceEnd === null ? true : false);
                        setRecurrenceEndInvalid(false);
                        setRecurrenceEndChange(false);
                        setRecurEveryNWeeks(1);
                        setRecurEveryNWeeksInvalid(false);
                        setRecurEveryNWeeksChange(false);
                        setFirstFullWeekOfMonth(currentLoadedMonthly.firstFullWeekOfMonth);
                        setSecondFullWeekOfMonth(currentLoadedMonthly.secondFullWeekOfMonth);
                        setThirdFullWeekOfMonth(currentLoadedMonthly.thirdFullWeekOfMonth);
                        setFourthFullWeekOfMonth(currentLoadedMonthly.fourthFullWeekOfMonth);
                        setLastFullWeekOfMonth(currentLoadedMonthly.lastFullWeekOfMonth);     
                        return;
                    }
                    break;
            }
            return;
        }

        if (currentAdHocFormState === "sending" ||
            currentWeeklyFormState === "sending"|| 
            currentMonthlyFormState === "sending") {
            loadingPlaceholder.current = (
                <div className="row">
                    <Spinner
                        as="div"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        className="mt-4 text-fhah-green mx-auto"
                        >
                            <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            );
             setLoading(true);
        }
    }, 
    [
        shiftFormModalFor,
        currentAdHocFormState,
        currentMonthlyFormState,
        currentWeeklyFormState,
        currentLoadedAdHoc,
        currentLoadedWeekly,
        currentLoadedMonthly,
        dispatch,
        handleCloseShiftModal,
    ]);

    const onClientIdInputChange = (value : string) => {
        if (value !== clientId) {
            setClientId(value);
            setClientChange(true);
            clientIdInvalidFunction(value);
        }
    };

    const onRateIdInputChange = (value : string) => {
        if (value !== rateId) {
            setRateId(value);
            setRateIdChange(true);
            rateIdInvalidFunction(value);
        }
    };

    const onShiftDateInputChange = (value: string) => {
        if (value !== shiftDate) {
            setShiftDate(value);
            setShiftDateChange(true);
            shiftDateInvalidFunction(value);
        }
    };

    const onShiftStartTimeInputChange = (value: string) => {
        if (value !== shiftStartTime) {
            setShiftStartTimeChange(true);
            setShiftStartTime(value);
            shiftStartTimeInvalidFunction(value);
        }
    };

    const onShiftLengthInputChange = (value: string) => {
        if (value !== shiftLength) {
            setShiftLength(value);
            setShiftLengthChange(true);
            shiftLengthInvalidFunction(value);
        }
    };

    const onNumberOfCarersInputChange = (value: string) => {
        let castValue: number = parseInt(value);
        if (castValue !== numberOfCarers) {
            setNumberOfCarers(castValue);
            setNumberOfCarersChange(true);
            numberOfCarersInvalidFunction(castValue);
        }
    };

    const onRecurrenceStartChange = (value: string) => {
        if (value !== recurrenceStart) {
            setRecurrenceStart(value);
            setRecurrenceStartChange(true);
            recurrenceStartInvalidFunction(value);
        }
    };

    const onRecurrenceEndChange = (value: string) => {
        if ((value !== recurrenceEnd)) {
            setRecurrenceEnd(value);
            setRecurrenceEndChange(true);
            recurrenceEndInvalidFunction(value, recurrenceHasEnd);
        }
    };

    const onRecurEveryNWeeksInputChange = (value: string) => {
        let castValue: number = parseInt(value);
        if (castValue !== recurEveryNWeeks) {
            setRecurEveryNWeeks(castValue);
            setRecurEveryNWeeksChange(true);
            onRecurEveryNWeeksInvalidFunction(castValue);
        }
    };

    const onMondayChange = (value: boolean) => {
        setMonday(value);
    };

    const onTusdayChange = (value: boolean) => {
        setTuesday(value);
    };
    
    const onWednesdayChange = (value: boolean) => {
        setWednesday(value);
    };

    const onThursdayChange = (value: boolean) => {
        setThursday(value);
    };

    const onFridayChange = (value: boolean) => {
        setFriday(value);
    };

    const onSaturdayChange = (value: boolean) => {
        setSaturday(value);
    };
    
    const onSundayChange = (value: boolean) => {
        setSunday(value);
    };

    const onFirstFullWeekOfMonthChange = (value: boolean) => {
        setFirstFullWeekOfMonth(value);
    };

    const onSecondFullWeekOfMonthChange = (value: boolean) => {
        setSecondFullWeekOfMonth(value);
    };

    const onThirdFullWeekOfMonthhange = (value: boolean) => {
        setThirdFullWeekOfMonth(value);
    };

    const onFourthFullWeekOfMonthChange = (value: boolean) => {
        setFourthFullWeekOfMonth(value);
    };

    const onLastFullWeekOfMonthChange = (value: boolean) => {
        setLastFullWeekOfMonth(value);
    };

    const onShiftLenthIs24H = (checked: boolean) => {
        let value = "";
        if (checked) {
            value = "1.00:00"
        }
        setShiftLength24H(checked);
        setShiftLength(value);
        setShiftLengthChange(false);
        shiftLengthInvalidFunction(value);
    };

    const onRecurrenceHasEnd = (checked: boolean) => {
        let value = "";
        setRecurrenceHasEnd(checked);
        setRecurrenceEnd(value);
        setRecurrenceEndChange(false);
        recurrenceEndInvalidFunction(value, checked);
    };

    const onTypeOfShiftChange = (shiftType: ShiftType) => {
        if (shiftType !== typeOfShift) {
            setTypeOfShift(shiftType);
        }
    };

    const clientIdInvalidFunction = (value :string) => {
        if (selectListValidation(value)) {
            setClientIdInvalid(false);
            return false;
        }
        setClientIdInvalid(true);
        return true;
    };

    const rateIdInvalidFunction = (value :string) => {
        if (selectListValidation(value)) {
            setRateIdInvalid(false);
            return false;
        }
        setRateIdInvalid(true);
        return true;
    };

    const shiftDateInvalidFunction = (value : string) => {
        if (dateValidation(value)) {
            setShiftDateInvalid(false);
            return false;
        }
        setShiftDateInvalid(true);
        return true;
    };

    const shiftStartTimeInvalidFunction = (value : string) => {
        if (timeOrTimespanValidation(value)) {
            setShiftStartTimeInvalid(false);
            return false;
        }
        setShiftStartTimeInvalid(true);
        return true;
    };
    
    const shiftLengthInvalidFunction = (value : string) => {
        if (value === "1.00:00" || (value !== "00:00" && timeOrTimespanValidation(value))) {

            setShiftLengthInvalid(false);
            return false;
        }
        setShiftLengthInvalid(true);
        return true;
    };

    const numberOfCarersInvalidFunction = (value :number) => {
        if (!numberOfCarersValidation(value)) {
            setNumberOfCarersInvalid(false);
            return false;
        }
        setNumberOfCarersInvalid(true);
        return true;
    };

    const recurrenceStartInvalidFunction = (value : string) => {
        if (dateValidation(value)) {
            setRecurrenceStartInvalid(false);
            return false;
        }
        setRecurrenceStartInvalid(true);
        return true;
    };

    const recurrenceEndInvalidFunction = (value : string, recurrenceEnd : boolean) => {
        if (recurrenceEnd || dateValidation(value)) {
            setRecurrenceEndInvalid(false);
            return false;
        }
        setRecurrenceEndInvalid(true);
        return true;
    };

    const onRecurEveryNWeeksInvalidFunction = (value :number) => {
        if (!numberOfWeeksRecurring(value)) {
            setRecurEveryNWeeksInvalid(false);
            return false;
        }
        setRecurEveryNWeeksInvalid(true);
        return true;
    };

    const [validateForm, setvalidateForm] = useState(false);

    const currentFormState = useAppSelector(adHocFormState);

    const onFormSubmit = () => {
        if (clientChange !== true) {
            setClientChange(true);
        }
        if (rateIdChange !== true) {
            setRateIdChange(true);
        }
        if (shiftDateChange !== true) {
            setShiftDateChange(true);
        }
        if (shiftLengthChange !== true) {
            setShiftLengthChange(true);
        }
        if (shiftStartTimeChange !== true) {
            setShiftStartTimeChange(true);
        }
        if (numberOfCarersChange !== true) {
            setNumberOfCarersChange(true);
        }
        if (recurrenceEndChange !== true) {
            setRecurrenceEndChange(true);
        }
        if (recurrenceStartChange !== true) {
            setRecurrenceStartChange(true);
        }
        if (recurEveryNWeeksChange !== true) {
            setRecurEveryNWeeksChange(true);
        }// brouht in from form
        if (shiftFormModalFor && shiftFormModalFor.isEdit) {
            handleEdit();
        }
        else
        {
            handlePost();
        } 
    };

    const handlePost = () => {
        let isClientIdInvalid = clientIdInvalid === true ||
            clientIdInvalid === undefined;
        let isRateIdInvalid = rateIdInvalid === true ||
            rateIdInvalid === undefined;
        let isShiftDateInvalid = shiftDateInvalid === true ||
            shiftDateInvalid === undefined;
        let isShiftStartTimeInvalid = shiftStartTimeInvalid === true ||
            shiftStartTimeInvalid === undefined;
        let isShiftLengthInvalid = shiftLengthInvalid === true ||
            shiftLengthInvalid === undefined;
        let isNumberOfCarersInvalid = numberOfCarersInvalid === true ||
            numberOfCarersInvalid === undefined;
        let isRecurEveryNWeeksInvalid = recurEveryNWeeksInvalid === true ||
           recurEveryNWeeksInvalid === undefined;
        let isRecurrenceStartInvalid = recurrenceStartInvalid === true ||
            recurrenceStartInvalid === undefined;
        let isRecurrenceEndInvalid = recurrenceEndInvalid === true ||
            recurrenceEndInvalid === undefined;
        switch(typeOfShift) {
            case ShiftType.Weekly: { 
                if (!isClientIdInvalid &&
                    !isRateIdInvalid &&
                    !isShiftDateInvalid &&
                    !isShiftStartTimeInvalid &&
                    !isShiftLengthInvalid &&
                    !isNumberOfCarersInvalid &&
                    !isRecurEveryNWeeksInvalid &&
                    !isRecurrenceStartInvalid &&
                    !isRecurrenceEndInvalid) {
                        const postPayload: WeeklyRecurringShift = {
                            clientId: String(clientId),
                            rateId: String(rateId),
                            recurrenceStart: String(recurrenceStart),
                            recurrenceEnd: recurrenceEnd === "" ? null : String(recurrenceEnd),
                            recurEveryNWeeks: recurEveryNWeeks,
                            monday: monday,
                            tuesday: tuesday,
                            wednesday: wednesday,
                            thursday: thursday,
                            friday: friday,
                            saturday: saturday,
                            sunday: sunday,
                            shiftStartTime: String(shiftStartTime),
                            shiftLength: String(shiftLength),
                            numberOfCarers: numberOfCarers,
                            isActive: isActive,
                        };
                        dispatch(postWeeklyRecurringShiftAsync(postPayload));
                        return;
                    }
                break; 
             } 
             case ShiftType.Monthly: { 
                if (!isClientIdInvalid &&
                    !isRateIdInvalid &&
                    !isShiftDateInvalid &&
                    !isShiftStartTimeInvalid &&
                    !isShiftLengthInvalid &&
                    !isNumberOfCarersInvalid &&
                    !isRecurrenceStartInvalid &&
                    !isRecurrenceEndInvalid) {
                        const postPayload: MonthlyRecurringShift = {
                            clientId: String(clientId),
                            rateId: String(rateId),
                            recurrenceStart: String(recurrenceStart),
                            recurrenceEnd: recurrenceEnd === "" ? null : String(recurrenceEnd),
                            monday: monday,
                            tuesday: tuesday,
                            wednesday: wednesday,
                            thursday: thursday,
                            friday: friday,
                            saturday: saturday,
                            sunday: sunday,
                            shiftStartTime: String(shiftStartTime),
                            shiftLength: String(shiftLength),
                            numberOfCarers: numberOfCarers,
                            isActive: isActive,
                            firstFullWeekOfMonth: firstFullWeekOfMonth,
                            secondFullWeekOfMonth: secondFullWeekOfMonth,
                            thirdFullWeekOfMonth: thirdFullWeekOfMonth,
                            fourthFullWeekOfMonth: fourthFullWeekOfMonth,
                            lastFullWeekOfMonth: lastFullWeekOfMonth
                        };
                        dispatch(postMonthlyRecurringShiftAsync(postPayload));
                        return;
                    }
                break; 
             } 
             case ShiftType.AdHoc: { 
                if (!isClientIdInvalid &&
                     !isRateIdInvalid &&
                     !isShiftDateInvalid &&
                     !isShiftStartTimeInvalid &&
                     !isShiftLengthInvalid &&
                     !isNumberOfCarersInvalid) {
                        const postPayload: AdHocShift = {
                            clientId: String(clientId),
                            rateId: String(rateId),
                            shiftDate: String(shiftDate),
                            shiftStartTime: String(shiftStartTime),
                            shiftLength: String(shiftLength),
                            numberOfCarers: Number(numberOfCarers),
                            isActive: Boolean(isActive)
                        };
                        dispatch(postAdHocShiftAsync(postPayload));
                        return;
                    }
                break; 
             } 
             default: { 
                break; 
             } 
        }
          setvalidateForm(true);
    }

    const handleEdit = () => {
        let isClientIdInvalid = clientIdInvalid === true ||
            clientIdInvalid === undefined;
        let isRateIdInvalid = rateIdInvalid === true ||
            rateIdInvalid === undefined;
        let isShiftDateInvalid = shiftDateInvalid === true ||
            shiftDateInvalid === undefined;
        let isShiftStartTimeInvalid = shiftStartTimeInvalid === true ||
            shiftStartTimeInvalid === undefined;
        let isShiftLengthInvalid = shiftLengthInvalid === true ||
            shiftLengthInvalid === undefined;
        let isNumberOfCarersInvalid = numberOfCarersInvalid === true ||
            numberOfCarersInvalid === undefined;
        let isRecurEveryNWeeksInvalid = recurEveryNWeeksInvalid === true ||
           recurEveryNWeeksInvalid === undefined;
        let isRecurrenceStartInvalid = recurrenceStartInvalid === true ||
            recurrenceStartInvalid === undefined;
        let isRecurrenceEndInvalid = recurrenceEndInvalid === true ||
            recurrenceEndInvalid === undefined;
        switch(typeOfShift) {
            case ShiftType.Weekly: { 
                if (!isClientIdInvalid &&
                    !isRateIdInvalid &&
                    !isShiftStartTimeInvalid &&
                    !isShiftLengthInvalid &&
                    !isNumberOfCarersInvalid &&
                    !isRecurEveryNWeeksInvalid &&
                    !isRecurrenceStartInvalid &&
                    !isRecurrenceEndInvalid) {
                        const putPayload: WeeklyRecurringShiftVersioned = {
                            clientId: String(clientId),
                            rateId: String(rateId),
                            recurrenceStart: String(recurrenceStart),
                            recurrenceEnd: recurrenceEnd === "" ? null : String(recurrenceEnd),
                            recurEveryNWeeks: recurEveryNWeeks,
                            monday: monday,
                            tuesday: tuesday,
                            wednesday: wednesday,
                            thursday: thursday,
                            friday: friday,
                            saturday: saturday,
                            sunday: sunday,
                            shiftStartTime: String(shiftStartTime),
                            shiftLength: String(shiftLength),
                            numberOfCarers: numberOfCarers,
                            isActive: isActive,
                            version:version,
                            id:id,
                        };
                        dispatch(putWeeklyRecurringShiftAsync(putPayload));
                        handleResetForm();
                        return;
                    }
                break; 
             } 
             case ShiftType.Monthly: { 
                if (!isClientIdInvalid &&
                    !isRateIdInvalid &&
                    !isShiftStartTimeInvalid &&
                    !isShiftLengthInvalid &&
                    !isNumberOfCarersInvalid &&
                    !isRecurrenceStartInvalid &&
                    !isRecurrenceEndInvalid) {
                        const putPayload: MonthlyRecurringShiftVersioned = {
                            clientId: String(clientId),
                            rateId: String(rateId),
                            recurrenceStart: String(recurrenceStart),
                            recurrenceEnd: recurrenceEnd === "" ? null : String(recurrenceEnd),
                            monday: monday,
                            tuesday: tuesday,
                            wednesday: wednesday,
                            thursday: thursday,
                            friday: friday,
                            saturday: saturday,
                            sunday: sunday,
                            shiftStartTime: String(shiftStartTime),
                            shiftLength: String(shiftLength),
                            numberOfCarers: numberOfCarers,
                            isActive: isActive,
                            firstFullWeekOfMonth: firstFullWeekOfMonth,
                            secondFullWeekOfMonth: secondFullWeekOfMonth,
                            thirdFullWeekOfMonth: thirdFullWeekOfMonth,
                            fourthFullWeekOfMonth: fourthFullWeekOfMonth,
                            lastFullWeekOfMonth: lastFullWeekOfMonth,
                            id: id,
                            version: version
                        };
                        dispatch(putMonthlyRecurringShiftAsync(putPayload));
                        handleResetForm();
                        return;
                    }
                break; 
             } 
             case ShiftType.AdHoc: { 
                if (!isClientIdInvalid &&
                     !isRateIdInvalid &&
                     !isShiftDateInvalid &&
                     !isShiftStartTimeInvalid &&
                     !isShiftLengthInvalid &&
                     !isNumberOfCarersInvalid) {
                        const putPayload: AdHocShiftVersioned = {
                            clientId: String(clientId),
                            rateId: String(rateId),
                            shiftDate: String(shiftDate),
                            shiftStartTime: String(shiftStartTime),
                            shiftLength: String(shiftLength),
                            numberOfCarers: Number(numberOfCarers),
                            isActive: Boolean(isActive),
                            version: String(version),
                            id: String(id),
                        };
                        dispatch(putAdHocShiftAsync(putPayload));
                        handleResetForm();
                        return;
                    }
                break; 
             } 
             default: { 
                break; 
             } 
        }
          setvalidateForm(true);
    }

    const handleResetForm = () => {
        setVersion('');
        setId('');
        setClientId('');
        setClientIdInvalid(undefined);
        setClientChange(false);
        setRateId('');
        setRateIdInvalid(undefined);
        setRateIdChange(false);
        setShiftDate('');
        setShiftDateInvalid(undefined);
        setShiftDateChange(false);
        setShiftStartTime('');
        setShiftStartTimeInvalid(undefined);
        setShiftStartTimeChange(false);
        setShiftLength('');
        setShiftLengthInvalid(undefined);
        setShiftLength24H(false);
        setShiftLengthChange(false);
        setNumberOfCarers(1);
        setNumberOfCarersInvalid(undefined);
        setNumberOfCarersChange(false);
        setIsActive(true);
        setTypeOfShift(ShiftType.AdHoc);
        setMonday(false);
        setTuesday(false);
        setWednesday(false);
        setThursday(false);
        setFriday(false);
        setSaturday(false);
        setSunday(false);
        setRecurrenceStart("");
        setRecurrenceStartInvalid(undefined);
        setRecurrenceStartChange(false);
        setRecurrenceEnd("");
        setRecurrenceHasEnd(false);
        setRecurrenceEndInvalid(undefined);
        setRecurrenceEndChange(false);
        setRecurEveryNWeeks(1);
        setRecurEveryNWeeksInvalid(undefined);
        setRecurEveryNWeeksChange(false);
        setFirstFullWeekOfMonth(false);
        setSecondFullWeekOfMonth(false);
        setThirdFullWeekOfMonth(false);
        setFourthFullWeekOfMonth(false);
        setLastFullWeekOfMonth(false);
    };

    let displayShiftType = "";
    switch (typeOfShift) {
        case ShiftType.AdHoc:
            displayShiftType = "AdHoc"
            break;
        case ShiftType.Weekly:
            displayShiftType = "Weekly"
            break;
        case ShiftType.Monthly:
            displayShiftType = "Monthly"
            break;
        default:
            break;
    }

    return (
        <Modal show={shiftFormModalFor !== undefined} onHide={handleCloseShiftModal}>
            <Modal.Header className="bg-fhah-purple text-white">
                {shiftFormModalFor && <Modal.Title>
                    {shiftFormModalFor.isEdit ? "Edit " +  displayShiftType + " Shift" : "Add Shift"}
                </Modal.Title>}
            </Modal.Header>
            <Modal.Body>
                {loadingPlaceholder.current}  
                {shiftFormModalFor && <AdHocShiftForm 
                    isEdit={shiftFormModalFor.isEdit}
                    clientId={clientId}
                    clientIdInvalid={clientIdInvalid}
                    clientChange={clientChange}
                    rateId={rateId}
                    rateIdInvalid={rateIdInvalid}
                    rateIdChange={rateIdChange}
                    shiftDate={shiftDate}
                    shiftDateInvalid={shiftDateInvalid}
                    shiftDateChange={shiftDateChange}
                    shiftStartTime={shiftStartTime}
                    shiftStartTimeInvalid={shiftStartTimeInvalid}
                    shiftStartTimeChange={shiftStartTimeChange}
                    shiftLength={shiftLength}
                    shiftLengthInvalid={shiftLengthInvalid}
                    shiftLengthChange={shiftLengthChange}
                    shiftLength24H={shiftLength24H}
                    numberOfCarers={numberOfCarers}
                    numberOfCarersInvalid={numberOfCarersInvalid}
                    numberOfCarersChange={numberOfCarersChange}
                    typeOfShift={typeOfShift}
                    monday={monday}
                    tuesday={tuesday}
                    wednesday={wednesday}
                    thursday={thursday}
                    friday={friday}
                    saturday={saturday}
                    sunday={sunday}
                    recurrenceStart={recurrenceStart}
                    recurrenceStartInvalid={recurrenceStartInvalid}
                    recurrenceStartChange={recurrenceStartChange}
                    recurrenceEnd={recurrenceEnd}
                    recurrenceEndInvalid={recurrenceEndInvalid}
                    recurrenceEndChange={recurrenceEndChange}
                    recurrenceHasEnd={recurrenceHasEnd}
                    recurEveryNWeeks={recurEveryNWeeks}
                    recurEveryNWeeksInvalid={recurEveryNWeeksInvalid}
                    recurEveryNWeeksChange={recurEveryNWeeksChange}
                    firstFullWeekOfMonth={firstFullWeekOfMonth}
                    secondFullWeekOfMonth={secondFullWeekOfMonth}
                    thirdFullWeekOfMonth={thirdFullWeekOfMonth}
                    fourthFullWeekOfMonth={fourthFullWeekOfMonth}
                    lastFullWeekOfMonth={lastFullWeekOfMonth}
                    valid={validateForm}
                    onClientIdInputChange={(value: string) => onClientIdInputChange(value)}
                    onRateIdInputChange={(value: string) => onRateIdInputChange(value)}
                    onShiftDateInputChange={(value: string) => onShiftDateInputChange(value)}
                    onShiftStartTimeInputChange={(value: string) => onShiftStartTimeInputChange(value)}
                    onShiftLengthInputChange={(value: string) => onShiftLengthInputChange(value)}
                    onNumberOfCarersInputChange={(value: string) => onNumberOfCarersInputChange(value)}
                    onRecurrenceStartChange={(value: string) => onRecurrenceStartChange(value)}
                    onRecurrenceEndChange={(value: string) => onRecurrenceEndChange(value)}
                    onRecurEveryNWeeksInputChange={(value: string) => onRecurEveryNWeeksInputChange(value)}
                    onMondayChange={(value: boolean) => onMondayChange(value)}
                    onTusdayChange={(value: boolean) => onTusdayChange(value)}
                    onWednesdayChange={(value: boolean) => onWednesdayChange(value)}
                    onThursdayChange={(value: boolean) => onThursdayChange(value)}
                    onFridayChange={(value: boolean) => onFridayChange(value)}
                    onSaturdayChange={(value: boolean) => onSaturdayChange(value)}
                    onSundayChange={(value: boolean) => onSundayChange(value)}
                    onFirstFullWeekOfMonthChange={(value: boolean) => onFirstFullWeekOfMonthChange(value)}
                    onSecondFullWeekOfMonthChange={(value: boolean) => onSecondFullWeekOfMonthChange(value)}
                    onThirdFullWeekOfMonthhange={(value: boolean) => onThirdFullWeekOfMonthhange(value)}
                    onFourthFullWeekOfMonthChange={(value: boolean) => onFourthFullWeekOfMonthChange(value)}
                    onLastFullWeekOfMonthChange={(value: boolean) => onLastFullWeekOfMonthChange(value)}
                    onShiftLenthIs24H={(value: boolean) => onShiftLenthIs24H(value)}
                    onRecurrenceHasEnd={(value: boolean) => onRecurrenceHasEnd(value)}
                    onTypeOfShiftChange={(value: ShiftType) => onTypeOfShiftChange(value)}
                    loading={loading}
                ></AdHocShiftForm>}
            </Modal.Body>
            <Modal.Footer className={loading? "d-none": "bg-light"}>
                    <Button variant="secondary" onClick={handleCloseShiftModal}>Close</Button>
                    <Button variant="primary" disabled={currentFormState === "sending"}  onClick={onFormSubmit}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
}