import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "../../app/store";
import {
  RosteredShift,
  RosterDates,
  RosterPayload,
  ShiftAssignmentChange,
} from "../../app/types";
import { fetchRoster } from "./RosterApi";

export interface RosterState {
  rosters: RosteredShift[];
  status: "initial" | "idle" | "loading" | "failed";
  rosterDates: undefined | RosterDates;
  showOtherCarersStatus: boolean;
}

const initialState: RosterState = {
  rosters: [],
  status: "initial",
  rosterDates: undefined,
  showOtherCarersStatus: false,
};

export const fetchRosterAsync = createAsyncThunk(
  "roster/fetchRoster",
  async (rosterDates: RosterDates) => {
    const response = await fetchRoster(rosterDates);
    const payload: RosterPayload = { shifts: response, range: rosterDates };
    return payload;
  }
);

export const rosterSlice = createSlice({
  name: "roster",
  initialState,
  reducers: {
    showOtherCarers: (state) => {
      state.showOtherCarersStatus = true;
    },
    hideOtherCarers: (state) => {
      state.showOtherCarersStatus = false;
    },
    resetRoster: (state) => {
      state.rosters = [];
      state.status = "initial";
      state.rosterDates = undefined;
    },
    updateShiftList: (state, action: PayloadAction<ShiftAssignmentChange>) => {
      if (state.rosters) {
        let newList = state.rosters as RosteredShift[];
        
        // Update matching carers
        let matchingIndex = newList.findIndex(
          (x) =>
            moment(x.shiftStart).isSame(action.payload.shiftDate, "day") &&
            x.shiftId === action.payload.shiftId
        );
        newList[matchingIndex].assignedCarerIds = action.payload.assignedCarerIds;

        // Update shift version
        if (action.payload.shiftVersion) {
          for (let index = 0; index < newList.length; index++) {
            if (newList[index].shiftId === action.payload.shiftId) {
              newList[index].shiftVersion = action.payload.shiftVersion;
            } 
          }
        }

        state.rosters = newList;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRosterAsync.pending, (state) => {
        state.status = "loading";
        state.rosterDates = undefined;
      })
      .addCase(fetchRosterAsync.fulfilled, (state, action) => {
        state.status = "idle";

        state.rosters = action.payload.shifts;

        state.rosterDates = action.payload.range;
      });
  },
});

export const selectShowOtherCarersStatus = (state: RootState) => state.roster.showOtherCarersStatus;

export const selectRoster = (state: RootState) => {
  let rostersDictionary = new Map();

  // Convert list of shifts into typescript map dictionary for date and inner dictionaries using shift id as unique for date
  for (let rosteredShift of state.roster.rosters) {
      let dictionaryForDate = rostersDictionary.get(moment(rosteredShift.shiftStart).format('YYYY-MM-DD'));

      if (!dictionaryForDate) {
          // No match found add a date entry
          rostersDictionary.set(moment(rosteredShift.shiftStart).format('YYYY-MM-DD'), new Map());
          dictionaryForDate = rostersDictionary.get(moment(rosteredShift.shiftStart).format('YYYY-MM-DD'));
      }

      let dictionaryForShiftId = dictionaryForDate.get(rosteredShift.shiftId);

      if (!dictionaryForShiftId) {
          dictionaryForDate.set(rosteredShift.shiftId, rosteredShift);
      }
  }
  return rostersDictionary;
}

export const selectRosterStatus = (state: RootState) => state.roster.status;

export const selectRosterDates = (state: RootState) => state.roster.rosterDates;

export const { showOtherCarers, hideOtherCarers, resetRoster, updateShiftList } = rosterSlice.actions;

export default rosterSlice.reducer;
