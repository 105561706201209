import { faLink } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListGroup, Modal } from 'react-bootstrap';

export type IProps = {
    showModal: boolean;
    shiftAssignmentDate: string;
    duration: string | undefined;
    carerFirstname: string;
    carerSurname: string;
    clientFirstname: string;
    clientSurname: string;
    rateName: string;
    associatedShiftActive: boolean | undefined;
    handleCloseModal: () => void;
}

export default function ViewShiftAssignmentModal(props : IProps) {
    let shiftConnectedContainer;
    if (props.associatedShiftActive !== undefined) {
        shiftConnectedContainer = (<span className={props.associatedShiftActive ? "shiftConnectedIcon text-success" : "shiftConnectedIcon text-danger"} title={props.associatedShiftActive ? "Shift template active." : "This shift assignment is disconnected from the shift template which created it."}><FontAwesomeIcon icon={faLink} /></span>);
    }
    return (
        <Modal show={props.showModal} onHide={props.handleCloseModal}>
            <Modal.Header className="bg-fhah-purple text-white">
                <div className="d-flex justify-content-between w-100">
                    <Modal.Title>
                        <span>{props.shiftAssignmentDate}</span>  
                    </Modal.Title>
                    <Modal.Title>
                        <span>{props.duration}</span>
                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <ListGroup className="disabled">
                    <ListGroup.Item> 
                        <small className="mb-1 d-block text-black-50">Carer</small>
                        {props.carerFirstname} {props.carerSurname}
                    </ListGroup.Item>
                    <ListGroup.Item> 
                        <small className="mb-1 d-block text-black-50">Client</small>
                        {props.clientFirstname} {props.clientSurname}
                    </ListGroup.Item>
                    <ListGroup.Item> 
                        <small className="mb-1 d-block text-black-50">Rate</small>
                        {props.rateName}
                    </ListGroup.Item>
                </ListGroup>
            </Modal.Body>
            <Modal.Footer className={"bg-light"}>
                {shiftConnectedContainer} 
                <Button variant="secondary" onClick={props.handleCloseModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}