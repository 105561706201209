import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ClientModel } from "../clients/clientSlice";

export interface RosteredShiftState {
    deletePopupFor: string | undefined;
    deletePopupForShiftType: string | undefined;
    shiftModalFor: string | undefined;
    shiftModalForShiftType: string | undefined;
    shiftModalIsCarer: boolean | undefined;
    shiftFormModalFor: ShiftFormModel | undefined;
    automaticShiftIdFor: string| undefined;
    automaticShiftModalForShiftType: string | undefined;
    shiftTimeModalFor: ShiftIdAndTypePayload | undefined;
}

export type ShiftFormModel = {
    date: string;
    client: ClientModel | undefined;
    id: string | undefined;
    shiftType: string | undefined;
    isEdit: boolean;
}

export type ShiftIdAndTypePayload = {
    id: string;
    shiftType: string;
}

type ViewShiftModalPayload = {
    id: string;
    shiftType: string;
    isCarer: boolean;
}

type AutomaticShiftAssignmentModalPayload = {
  id: string;
  shiftType: string;
}

const initialState: RosteredShiftState = {
    deletePopupFor: undefined,
    deletePopupForShiftType: undefined,
    shiftModalFor: undefined,
    shiftModalForShiftType: undefined,
    shiftModalIsCarer: undefined,
    shiftFormModalFor: undefined,
    automaticShiftIdFor: undefined,
    automaticShiftModalForShiftType: undefined,
    shiftTimeModalFor: undefined,
};

export const rosteredShiftSlice = createSlice({
    name: "RosteredShift",
    initialState,
    reducers: {
        showDeletePopup: (state, action: PayloadAction<ShiftIdAndTypePayload>) => {
            state.deletePopupFor = action.payload.id;
            state.deletePopupForShiftType = action.payload.shiftType;
        },
        hideDeletePopup: (state) => {
            state.deletePopupFor = undefined;
            state.deletePopupForShiftType = undefined;
        },
        showShiftModal: (state, action: PayloadAction<ViewShiftModalPayload>) => {
            state.shiftModalFor = action.payload.id;
            state.shiftModalForShiftType = action.payload.shiftType;
            state.shiftModalIsCarer = action.payload.isCarer;
        },
        hideShiftModal: (state) => {
            state.shiftModalFor = undefined;
            state.shiftModalForShiftType = undefined;
            state.shiftModalIsCarer = undefined;
        },
        showShiftFormModal: (state, action: PayloadAction<ShiftFormModel>) => {
            state.shiftFormModalFor = action.payload;
        },
        hideShiftFormModal: (state) => {
            state.shiftFormModalFor = undefined;
        },
        showShiftTimeModal: (state, action: PayloadAction<ShiftIdAndTypePayload>) => {
          state.shiftTimeModalFor = action.payload;
        },
        hideShiftTimeModal: (state) => {
            state.shiftTimeModalFor = undefined;
        },
        showAutomaticShiftAssignmentModal: (state, action: PayloadAction<AutomaticShiftAssignmentModalPayload>) => {
          state.automaticShiftIdFor = action.payload.id;
          state.automaticShiftModalForShiftType = action.payload.shiftType;
        },
        hideAutomaticShiftAssignmentModal: (state) => {
          state.automaticShiftIdFor = undefined;
          state.automaticShiftModalForShiftType = undefined;
        },
    },  
});

export const {
    showDeletePopup,
    hideDeletePopup,
    showShiftModal,
    hideShiftModal,
    showShiftFormModal,
    hideShiftFormModal,
    showAutomaticShiftAssignmentModal,
    hideAutomaticShiftAssignmentModal,
    showShiftTimeModal,
    hideShiftTimeModal,
  } = rosteredShiftSlice.actions;

export const selectDeletePopupFor = (state: RootState) =>
  state.rosteredShift.deletePopupFor;

export const selectDeletePopupForShiftType = (state: RootState) =>
  state.rosteredShift.deletePopupForShiftType;

export const selectShiftModalFor = (state: RootState) =>
  state.rosteredShift.shiftModalFor;

export const selectShiftModalForShiftType = (state: RootState) =>
  state.rosteredShift.shiftModalForShiftType;

export const selectShiftModalIsCarer = (state: RootState) =>
  state.rosteredShift.shiftModalIsCarer;

export const selectShiftFormModalFor = (state: RootState) =>
  state.rosteredShift.shiftFormModalFor;

export const selectShiftTimeModalFor = (state: RootState) =>
  state.rosteredShift.shiftTimeModalFor;

export const selectAutomaticShiftIdFor = (state: RootState) =>
  state.rosteredShift.automaticShiftIdFor;

export const selectAutomaticShiftModalForShiftType = (state: RootState) =>
  state.rosteredShift.automaticShiftModalForShiftType;

export default rosteredShiftSlice.reducer;