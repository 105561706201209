import * as signalR from "@microsoft/signalr";
import { Moment } from "moment";
import moment from "moment";
import { getStoredTokenInfo, getToken } from "../../features/account/sessionManager";
import environment from "../../environment";
import {
  CarerHolidayModel,
  LastClientMessage,
  NewChatMessage,
  ShiftAssignmentChange,
} from "../../app/types";

class FhahHub {
  private connection: signalR.HubConnection | undefined = undefined;

  public getConnection(): signalR.HubConnection | undefined {
    return this.connection;
  }

  public startConnection(
    onConnected: (connection: signalR.HubConnection) => void,
    dateRangeUpdatedCallback: (
      startDate: Moment,
      endDate: Moment | undefined
    ) => void,
    dateUpdatedCallback: (date: Moment) => void,
    notificationCallBack: (name: string, message: string) => void,
    shiftAssignmentChanged: (
      shiftAssignmentChange: ShiftAssignmentChange
    ) => void,
    clientChatMessageSent: (newChatMessage: NewChatMessage) => void,
    clientChatMessageRemoved: (messageId: string) => void,
    clientChatMessageLastUpdated: (
      lastClientMessage: LastClientMessage
    ) => void,
    newCarerHolidayCallback: (model: CarerHolidayModel) => void,
    carerHolidayRemovedCallback: (id: string) => void
  ) {
    if (this.connection && this.connection.state === signalR.HubConnectionState.Disconnected) {
      this.connection
        .start()
        .then(() => {
          if (
            this.connection &&
            this.connection.state === signalR.HubConnectionState.Connected
          ) {
            console.log("Started Connection");
            this.bindConnectionActions(
              dateRangeUpdatedCallback,
              dateUpdatedCallback,
              notificationCallBack,
              shiftAssignmentChanged,
              clientChatMessageSent,
              clientChatMessageRemoved,
              clientChatMessageLastUpdated,
              newCarerHolidayCallback,
              carerHolidayRemovedCallback
            );
            onConnected(this.connection as signalR.HubConnection);
          }
        })
        .catch((error) => {
          onConnected(this.connection as signalR.HubConnection);
          console.log(this.connection ? this.connection.state : 'connection undefined');
          console.error(error.message);
        });

      console.log("Connection Started");
    }
  }

  public stopConnection() {
    if (this.connection && (this.connection.state === "Connected" || this.connection.state === "Reconnecting")) {
      this.connection.stop().catch((error) => console.error(error.message));
      console.log("Connection Closed");
    }
  }

  public subscribeToClientChat(
    clientId: string,
    onError: (message: string) => void
  ) {
    if (this.connection && this.connection.state === "Connected") {
      this.connection
        .invoke("subscribeToClientChat", clientId)
        .catch((error) => onError(error.message));
    }
  }

  public unSubscribeFromClientChat(clientId: string) {
    if (this.connection && this.connection.state === "Connected") {
      this.connection
        .invoke("unSubscribeFromClientChat", clientId)
        .catch((error) => console.error(error.message));
    }
  }

  public subscribeToclientChatGroup(
    clientId: string,
    onError: (message: string) => void
  ) {
    if (this.connection && this.connection.state === "Connected") {
      this.connection
        .invoke("subscribeToClientChatGroup", clientId)
        .catch((error) => onError(error.message));
    }
  }

  public unSubscribeFromClientChatGroup(clientId: string) {
    if (this.connection && this.connection.state === "Connected") {
      this.connection
        .invoke("unSubscribeFromClientChatGroup", clientId)
        .catch((error) => console.error(error.message));
    }
  }

  public subscribeToRoster(onError: (message: string) => void) {
    if (this.connection && this.connection.state === "Connected") {
      this.connection
        .invoke("subscribeToRoster")
        .catch((error) => onError(error.message));
    }
  }

  public unSubscribeFromRoster() {
    if (this.connection && this.connection.state === "Connected") {
      this.connection
        .invoke("unSubscribeFromRoster")
        .catch((error) => console.error(error.message));
    }
  }

  public subscribeToClientRoster(
    clientId: string,
    onError: (message: string) => void
  ) {
    if (this.connection && this.connection.state === "Connected") {
      this.connection
        .invoke("subscribeToClientRoster", clientId)
        .catch((error) => onError(error.message));
    }
  }

  public unSubscribeFromClientRoster(clientId: string) {
    if (this.connection && this.connection.state === "Connected") {
      this.connection
        .invoke("unSubscribeFromClientRoster", clientId)
        .catch((error) => console.error(error.message));
    }
  }

  public initialiseConnectionToHub(onReconnect: (connection: signalR.HubConnection) => void, onLogOut: () => void) {
    this.initialiseConnection(onReconnect, onLogOut);
  }

  public wipeConnection() {
    console.log('connection wiped');
    this.connection = undefined;
  }

  private bindConnectionActions(
    dateRangeUpdatedCallback: (
      startDate: Moment,
      endDate: Moment | undefined
    ) => void,
    dateUpdatedCallback: (date: Moment) => void,
    notificationCallBack: (name: string, message: string) => void,
    shiftAssignmentChanged: (
      shiftAssignmentChange: ShiftAssignmentChange
    ) => void,
    clientChatMessageSent: (newChatMessage: NewChatMessage) => void,
    clientChatMessageRemoved: (messageId: string) => void,
    clientChatMessageLastUpdated: (
      lastClientMessage: LastClientMessage
    ) => void,
    newCarerHolidayCallback: (model: CarerHolidayModel) => void,
    carerHolidayRemovedCallback: (id: string) => void
  ) {
    if (this.connection && this.connection.state === "Connected") {
      this.connection.on("broadcastDateRangeUpdated", dateRangeUpdatedCallback);
      this.connection.on("broadcastDateUpdated", dateUpdatedCallback);
      this.connection.on("broadcastNotification", notificationCallBack);
      this.connection.on("shiftAssignmentChanged", shiftAssignmentChanged);
      this.connection.on("clientChatMessageSent", clientChatMessageSent);
      this.connection.on("clientChatMessageRemoved", clientChatMessageRemoved);
      this.connection.on(
        "ClientChatMessageLastUpdated",
        clientChatMessageLastUpdated
      );
      this.connection.on("newCarerHoliday", newCarerHolidayCallback);
      this.connection.on("carerHolidayRemoved", carerHolidayRemovedCallback);

      this.connection.onclose(this.onConnectionError);
    }
  }

  private initialiseConnection(onReconnect: (connection: signalR.HubConnection) => void, onLogOut: () => void) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiBaseAddress}/hub`, {
        accessTokenFactory: () => {
          var token = getToken();
          
          if (!token) {
            // We have no token so the user is unauthorised - log out
            onLogOut();
            return "";
          }

          var tokenInfo = getStoredTokenInfo();

          if (!tokenInfo) {
            // We have no token info so the user is unauthorised - log out
            onLogOut();
            return "";
          }

          if (moment(Number(tokenInfo.exp) * 1000).isAfter(moment())) {
            // There is a token which has not expired so re-login
            return token;
          }

          // Token must have expired - log out
          onLogOut();
          return "";
        },
      })
      .withAutomaticReconnect({ nextRetryDelayInMilliseconds: (retryContext) => {
        if (this.connection) {
          // Update system of new connection status
          onReconnect(this.connection);
        }

        var tokenInfo = getStoredTokenInfo();

        if (!tokenInfo || moment(Number(tokenInfo.exp) * 1000).isBefore(moment()) || retryContext.elapsedMilliseconds > 600000) {
          // If we don't have a token, it's expired or if we have been retrying for over 10 mins close the connection
          onLogOut();

          // And stop further retrys 
          return null;
        }
        
        // Gradually reduce retries
        if (retryContext.previousRetryCount === 0){
          return 10;
        } else if (retryContext.previousRetryCount < 10){
          return 1500;
        } else if (retryContext.previousRetryCount < 100){
          return 3000;
        } else if (retryContext.previousRetryCount < 1000){
          return 10000;
        } else {
          return 30000;
        }
      }})
      .build();
      this.connection.onreconnected(() => {
        if(this.connection) {
          onReconnect(this.connection)}
        }
      )
  }

  private onConnectionError(error: any) {
    if (error && error.message) {
      console.error(error.message);
    }
  }
}


let fhahHub = new FhahHub();

export default fhahHub;
