import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ClientModel } from "../clients/clientSlice";
import { selectedClientId, clearSelectClientId, selectClientId } from './ChatSlice';

export type IProps = {
    client: ClientModel;
    hideClientsAction: () => void;
} 

export default function ClientListRow(props: IProps) {
    const activeClientId = useAppSelector(selectedClientId);
    const dispatch = useAppDispatch();

    const [classes, setClasses] = useState<string>("d-flex justify-content-between text-start pointer small");

    let onclick: any = useRef(() => dispatch(selectClientId(props.client.id)));
    useEffect(() => {
        if (activeClientId === props.client.id) {
            setClasses("d-flex justify-content-between text-start bg-success text-white pointer small");
            onclick.current = () => dispatch(clearSelectClientId());
        } else {
            setClasses("d-flex justify-content-between text-start pointer small");
            onclick.current = () => {
                props.hideClientsAction();
                dispatch(selectClientId(props.client.id));
            }
            
        }

    }, [activeClientId, props.client, onclick, classes, dispatch, props]);
    return (
        <ListGroup.Item key={props.client.id} as="li" className={classes} onClick={onclick.current}>
            <span>{props.client.firstName} {props.client.surname}</span> {(props.client.lastChatMessageForClient &&
             props.client.lastViewedChatMessage && 
             props.client.lastChatMessageForClient> props.client.lastViewedChatMessage) ||
             (props.client.lastChatMessageForClient && props.client.lastViewedChatMessage === null) ? (<div className="text-danger"><FontAwesomeIcon icon={faExclamation}/></div>) : ""}
        </ListGroup.Item>
    );
}