import axios, { AxiosInstance } from "axios";
import { AuthResponse } from "../../app/types";
import { getStoredTokenInfo, getToken } from "./sessionManager";
import environment from "../../environment";
import moment from "moment";

const authApi: AxiosInstance = axios.create({
  baseURL: environment.apiBaseAddress,
  validateStatus: (status) => true,
  timeout: 20000,
});

export function attemptLogin(username: string, password: string) {
  return new Promise<AuthResponse>((resolve, reject) =>
    authApi
      .post("/authentication/user", {
        username: username,
        password: password,
      })
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 400:
            reject("Login failed for supplied credentials.");
            break;
          case 401:
            reject("Login failed for supplied credentials.");
            break;
          case 500:
            reject(
              "Authentication server is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from authentication server. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}

export function refreshToken() {
  const token = getToken();
  
  let headers = {};

  if (!token) {
    return new Promise<AuthResponse>((resolve, reject) => {
      reject("Access token not found");
    });
  }

  const tokenInfo = getStoredTokenInfo();

  if (!tokenInfo || moment(Number(tokenInfo.exp) * 1000).isBefore(moment())) {
    // Token has expired - the refresh action would fail
    return new Promise<AuthResponse>((resolve, reject) => {
      reject("Refresh failed as the access token has expired");
    });
  }

  headers = { Authorization: `Bearer ${token}` };

  let config = { headers: headers };

  return new Promise<AuthResponse>((resolve, reject) =>
    authApi
      .post("/authentication/refresh-token", null, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Refresh token failed.");
            break;
          case 500:
            reject(
              "Authentication server is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from authentication server. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the authentication server. Please check the connection or try again later."
        );
      })
  );
}
