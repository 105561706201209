import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faUser, faFilter } from '@fortawesome/pro-duotone-svg-icons';
import { Button } from 'react-bootstrap';

export type IProps = {
    dateString: string;
    today: string;
    next: string;
    previous: string;
    showClients: boolean;
    showFilters: boolean;
    userRole: string | string[] | null;
    clientSelectionApplied: boolean;
    filterApplied: boolean;
    showClientsButtonAction: () => void;
    showFiltersButtonAction: () => void;
    closeMenuButtonAction: () => void;
}

export default function NavigationBar(props: IProps) {
    let clientsToggler;
    let filtersToggler;
    let menuToggler;
    if (props.userRole) {
        if (!props.showClients && !props.showFilters) {
            clientsToggler = (
                <Button className={!props.clientSelectionApplied ? "m-1 pointer clientsToggleBtn" : "m-1 pointer clientsToggleBtn active"} variant="secondary" title={!props.clientSelectionApplied ? "Clients" : "Client Selected"} onClick={props.showClientsButtonAction}><FontAwesomeIcon icon={faUser} /></Button>
            );
            filtersToggler = (
                <Button className={!props.filterApplied ? "m-1 pointer filtersToggleBtn" : "m-1 pointer filtersToggleBtn active"} variant="secondary" title={!props.filterApplied ? "Filters" : "Filter Applied"} onClick={props.showFiltersButtonAction}><FontAwesomeIcon icon={faFilter} /></Button>
            );
        }
        if (props.showClients || props.showFilters) {
            menuToggler = (
                <Button className="m-1 pointer closeToggleButton" variant="secondary" title="Close" onClick={props.closeMenuButtonAction}><FontAwesomeIcon icon={faChevronLeft} /></Button>
            );
        }
    }
    
    return (
        <div className="text-left" id="RosterNav">
            <h3 className="float-start pt-2">{props.dateString}</h3>
            <span className="float-end">
                <LinkContainer to={`/rotas/${props.previous}`} className="pointer">
                    <Button className="bg-fhah-green m-1"><FontAwesomeIcon icon={faChevronLeft} /></Button>
                </LinkContainer>
                <LinkContainer to={`/rotas/${props.next}`} className="pointer">
                    <Button className="bg-fhah-green m-1"><FontAwesomeIcon icon={faChevronRight} /></Button>
                </LinkContainer>
                <LinkContainer to={`/rotas/${props.today}`} className="pointer">
                    <Button className="bg-fhah-green m-1 text-uppercase">Today</Button>
                </LinkContainer>
                {menuToggler}
                {clientsToggler}
                {filtersToggler}
            </span>
        </div>
    )
}