import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import FhahHub from "../../app/signal-r/fhah-hub";
import { RootState } from "../../app/store";
import {
  ChatFetchPayload,
  ChatMessage,
  ChatPostPayload,
  ChatUpdatePayload,
  NewChatMessage,
} from "../../app/types";
import { fetchChats, postChatMessage, removeChatMessage } from "./ChatApi";
import { toast, Flip } from "react-toastify";

export interface ChatState {
  chatMessage: ChatMessage | null;
  chatList: ChatMessage[] | undefined;
  chatResponseStatus: "initial" | "idle" | "loading" | "failed";
  formState: "success" | "error" | "sending" | "default" | "loaded";
  chatListListResponseStatus:
    | "initial"
    | "idle"
    | "complete"
    | "loading"
    | "failed";
  chatListResponseStatusGettingNewerChats:
    | "initial"
    | "idle"
    | "complete"
    | "loading"
    | "failed";
  chatListResponseStatusGettingOlderChats:
    | "initial"
    | "idle"
    | "complete"
    | "complete"
    | "loading"
    | "failed";
  closestNewChatId: string | undefined;
  selectedClientId: string | undefined;
}

const initialState: ChatState = {
  chatMessage: null,
  chatList: undefined,
  chatResponseStatus: "initial",
  chatListListResponseStatus: "initial",
  chatListResponseStatusGettingNewerChats: "initial",
  chatListResponseStatusGettingOlderChats: "initial",
  formState: "default",
  closestNewChatId: undefined,
  selectedClientId: undefined,
};

export const fetchChatsMessagesAsync = createAsyncThunk(
  "chat/GetMessages",
  async (payload: ChatFetchPayload) => {
    const response = await fetchChats(payload);
    return response;
  }
);

export const fetchOlderChatsMessagesAsync = createAsyncThunk(
  "chat/GetOlderMessages",
  async (payload: ChatFetchPayload) => {
    const response = await fetchChats(payload);
    return response;
  }
);

export const fetchNewerChatsMessagesAsync = createAsyncThunk(
  "chat/GetNewerMessages",
  async (payload: ChatFetchPayload) => {
    const response = await fetchChats(payload);
    return response;
  }
);

export const postChatMessageAsync = createAsyncThunk(
  "chat/postMessage",
  async (chatPostPayload: ChatPostPayload) => {
    const response = await postChatMessage(chatPostPayload);
    return response;
  }
);

export const removeChatMessageAsync = createAsyncThunk(
  "chat/removeMessage",
  async (chatRemovePayload: ChatUpdatePayload) => {
    const response = await removeChatMessage(chatRemovePayload);
    return response;
  }
);

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    selectClientId: (state, action: PayloadAction<string>) => {
      const connection = FhahHub.getConnection();
      if (connection && state.selectedClientId) {
        FhahHub.unSubscribeFromClientChat(state.selectedClientId);
      }

      if (
        connection &&
        action.payload &&
        state.selectedClientId !== action.payload
      ) {
        state.selectedClientId = action.payload;
        FhahHub.subscribeToClientChat(action.payload, (message: string) => {
          toast.error(message, {
            position: "top-right",
            transition: Flip,
            closeButton: true,
            autoClose: 1000,
            toastId: "Error",
          });
        });
      }

      if (!connection) {
        toast.error(
          "Unable to connect to live chat system, please refresh your browser",
          {
            position: "top-right",
            transition: Flip,
            closeButton: true,
            autoClose: 4000,
            toastId: "unsuccessful-no-signal-r-connection",
          }
        );
      }
    },
    clearSelectClientId: (state) => {
      const connection = FhahHub.getConnection();
      if (connection && state.selectedClientId) {
        FhahHub.unSubscribeFromClientChat(state.selectedClientId);
      }
      state.chatList = undefined;
      state.selectedClientId = undefined;
    },
    resetListStates: (state) => {
      state.chatListListResponseStatus = "initial";
      state.chatListResponseStatusGettingOlderChats = "initial";
      state.chatListResponseStatusGettingNewerChats = "initial";
    },

    addToChatList: (state, action: PayloadAction<NewChatMessage>) => {
      if (
        state.chatList !== undefined &&
        state.selectedClientId === action.payload.chatMessage.clientId &&
        (action.payload.previousMessageId == null ||
          (action.payload.previousMessageId != null &&
            state.chatList[state.chatList.length - 1].id ===
              action.payload.previousMessageId))
      ) {
        state.chatList.push(action.payload.chatMessage);
      }
    },
    removeFromChatList: (state, action: PayloadAction<string>) => {
      if (state.chatList !== undefined) {
        const index = state.chatList.findIndex((x) => x.id === action.payload);
        if (index > -1) {
          state.chatList.splice(index, 1);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatsMessagesAsync.pending, (state) => {
        state.chatListListResponseStatus = "loading";
        state.chatList = undefined;
        state.closestNewChatId = undefined;
      })
      .addCase(fetchChatsMessagesAsync.fulfilled, (state, action) => {
        state.chatListListResponseStatus = "complete";
        state.closestNewChatId = undefined;
        state.chatList = action.payload;
      })
      .addCase(fetchOlderChatsMessagesAsync.pending, (state) => {
        state.chatListResponseStatusGettingOlderChats = "loading";
        state.closestNewChatId = undefined;
      })
      .addCase(fetchOlderChatsMessagesAsync.fulfilled, (state, action) => {
        state.chatListResponseStatusGettingOlderChats = "complete";

        for (let index = action.payload.length; index > 0; index--) {
          let message = action.payload[index - 1];
          state.chatList?.unshift(message);
        }
        state.closestNewChatId = action.payload[action.payload.length - 1]
          ? action.payload[action.payload.length - 1].id
          : undefined;
      })
      .addCase(fetchNewerChatsMessagesAsync.pending, (state) => {
        state.chatListResponseStatusGettingNewerChats = "loading";
        state.closestNewChatId = undefined;
      })
      .addCase(fetchNewerChatsMessagesAsync.fulfilled, (state, action) => {
        state.chatListResponseStatusGettingNewerChats = "complete";
        for (let index = 0; index < action.payload.length; index++) {
          const message = action.payload[index];
          state.chatList?.push(message);
        }
        state.closestNewChatId = action.payload[0]?.id;
      })
      .addCase(postChatMessageAsync.pending, (state) => {
        state.chatResponseStatus = "loading";
        state.chatMessage = null;
      })
      .addCase(postChatMessageAsync.fulfilled, (state, action) => {
        state.chatResponseStatus = "idle";
      })
      .addCase(postChatMessageAsync.rejected, (state, action) => {
        state.chatResponseStatus = "failed";
        state.chatMessage = null;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful-removal",
        });
      })
      .addCase(removeChatMessageAsync.pending, (state) => {
        state.chatResponseStatus = "loading";
        state.chatMessage = null;
      })
      .addCase(removeChatMessageAsync.fulfilled, (state, action) => {
        state.chatResponseStatus = "idle";
      })
      .addCase(removeChatMessageAsync.rejected, (state, action) => {
        state.chatResponseStatus = "failed";
        state.chatMessage = null;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful-removal",
        });
      });
  },
});

export const chat = (state: RootState) => state.chat.formState;

export const chatResponseStatus = (state: RootState) =>
  state.chat.chatResponseStatus;

export const chatListResponseStatusGettingOlderChats = (state: RootState) =>
  state.chat.chatListResponseStatusGettingOlderChats;

export const chatListListResponseStatus = (state: RootState) =>
  state.chat.chatListListResponseStatus;

export const chatListResponseStatusGettingNewerChats = (state: RootState) =>
  state.chat.chatListResponseStatusGettingNewerChats;

export const closestNewChatId = (state: RootState) =>
  state.chat.closestNewChatId;

export const selectChatList = (state: RootState) => state.chat.chatList;

export const ChatResponse = (state: RootState) =>
  state.chat.chatResponseStatus === "idle"
    ? state.chat.chatResponseStatus
    : null;

export const selectedClientId = (state: RootState) =>
  state.chat.selectedClientId;

export const {
  addToChatList,
  selectClientId,
  clearSelectClientId,
  removeFromChatList,
  resetListStates,
} = chatSlice.actions;

export default chatSlice.reducer;
