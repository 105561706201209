import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getBranches } from "./branchApi";
// Types ------------------------------------------

export type BranchModel = {
  id: string;
  name: string;
  currentUserIsBranchRosterAdmin: boolean;
};

// State -------------------------------------------
export interface BranchState {
  list: BranchModel[];
  status: "initial" | "idle" | "loading" | "failed";
  selectedBranch: BranchModel | undefined;
  filterOnBranchId: string | undefined;
}

const initialState: BranchState = {
  list: [],
  status: "initial",
  selectedBranch: undefined,
  filterOnBranchId: undefined,
};

// Reducer --------------------------------------------------
export const getBranchesAsync = createAsyncThunk(
  "branch/getBranches",
  async () => {
    const response = await getBranches();
    return response;
  }
);

export const branchSlice = createSlice({
  name: "branch",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetBranches: (state) => {
      state.list = [];
      state.status = "initial";
      state.selectedBranch = undefined;
    },
    selectBranch: (state, action: PayloadAction<BranchModel>) => {
      state.selectedBranch = action.payload;
    },
    clearSelectBranch: (state) => {
      state.selectedBranch = undefined;
    },
    setBranchFilter: (state, action: PayloadAction<string>) => {
      state.filterOnBranchId = action.payload;
    },
    resetBranchFilter: (state) => {
      state.filterOnBranchId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBranchesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBranchesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.list = action.payload;
      });
  },
});

export const {
    resetBranches,
    selectBranch,
    clearSelectBranch,
    setBranchFilter,
    resetBranchFilter,
} = branchSlice.actions;

export const selectBranchFilter = (state: RootState) => state.branches.filterOnBranchId;

export const selectBranchSliceStatus = (state: RootState) =>
  state.branches.status;

export const selectAllBranches = (state: RootState) => state.branches.list;

export const selectAdminOfBranches = (state: RootState) => {
  let branchesWhereIsAdmin: BranchModel[] = [];

  state.branches.list.forEach(branch => {
    if (branch.currentUserIsBranchRosterAdmin) {
      branchesWhereIsAdmin.push(branch);
    }
  });

  return branchesWhereIsAdmin;
}

export const selectedBranch = (state: RootState) =>
  state.branches.selectedBranch;


export default branchSlice.reducer;
