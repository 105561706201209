import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  TimesheetFetchPayload,
  TimesheetEntry,
  TimesheetPostPayload,
  TimesheetDeletePayload,
} from "../../app/types";
import {
  deleteTimesheet,
  fetchTimesheets,
  postTimesheet,
} from "./TimesheetApi";
import { toast, Flip } from "react-toastify";

export interface TimesheetState {
  timesheetList: TimesheetEntry[] | undefined;
  timesheetListResponseStatus: "initial" | "idle" | "loading" | "failed";
  timesheetResponseStatus: "initial" | "idle" | "loading" | "failed";
  formState: "success" | "error" | "sending" | "default" | "loaded";
}

const initialState: TimesheetState = {
  formState: "default",
  timesheetList: undefined,
  timesheetListResponseStatus: "initial",
  timesheetResponseStatus: "initial",
};

export const fetchTimeSheetsAsync = createAsyncThunk(
  "chat/fetchTimesheets",
  async (payload: TimesheetFetchPayload) => {
    const response = await fetchTimesheets(payload);
    return response;
  }
);

export const postTimesheetAsync = createAsyncThunk(
  "timesheet/postTimesheet",
  async (postPayload: TimesheetPostPayload) => {
    const response = await postTimesheet(postPayload);
    return response;
  }
);

export const deleteTimesheetAsync = createAsyncThunk(
  "timesheet/deleteTimesheet",
  async (deletePayload: TimesheetDeletePayload) => {
    const response = await deleteTimesheet(deletePayload);
    return response;
  }
);

export const timesheetSlice = createSlice({
  name: "timesheet",
  initialState,
  reducers: {
    resetFormState: (state) => {
      state.formState = "default";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeSheetsAsync.pending, (state) => {
        state.timesheetListResponseStatus = "loading";
        state.timesheetList = undefined;
      })
      .addCase(fetchTimeSheetsAsync.fulfilled, (state, action) => {
        state.timesheetListResponseStatus = "idle";
        state.timesheetList = action.payload;
      })
      .addCase(postTimesheetAsync.pending, (state) => {
        state.timesheetResponseStatus = "loading";
        state.formState = "sending";
      })
      .addCase(postTimesheetAsync.fulfilled, (state, action) => {
        state.timesheetResponseStatus = "idle";
        if (state.timesheetList) {
          const index = state.timesheetList.findIndex(
            (x) => x === action.payload
          );
          if (index === -1) {
            state.timesheetList.push(action.payload);
            state.formState = "success";
          }
        }
      })
      .addCase(postTimesheetAsync.rejected, (state, action) => {
        state.timesheetResponseStatus = "failed";
        state.formState = "default";
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful-removal",
        });
      })
      .addCase(deleteTimesheetAsync.pending, (state) => {
        state.timesheetResponseStatus = "loading";
        state.formState = "sending";
      })
      .addCase(deleteTimesheetAsync.fulfilled, (state, action) => {
        console.log(action.payload);
        if (state.timesheetList) {
          const index = state.timesheetList.findIndex(
            (x) => x.id === action.payload
          );
          if (index > -1) {
            state.timesheetList.splice(index, 1);
            state.formState = "success";
          }
        }
      })
      .addCase(deleteTimesheetAsync.rejected, (state, action) => {
        state.timesheetResponseStatus = "failed";
        state.formState = "default";
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "unsuccessful-removal",
        });
      });
  },
});

export const timesheetResponseStatus = (state: RootState) =>
  state.timesheet.timesheetResponseStatus;

export const timesheetListResponseStatus = (state: RootState) =>
  state.timesheet.timesheetListResponseStatus;

export const selectTimesheetList = (state: RootState) =>
  state.timesheet.timesheetList;

export const timesheetFormState = (state: RootState) =>
  state.timesheet.formState;

export const { resetFormState } = timesheetSlice.actions;

export default timesheetSlice.reducer;
