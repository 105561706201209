import React, { useState } from "react";
import { Row, Col, Card, FormControl, FloatingLabel, Button, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch } from "../../app/hooks";
import { loginAsync } from "./accountSlice";
import { LoginPayload } from "../../app/model";
import { toast, Flip } from 'react-toastify';
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";
import environment from "../../environment";

export default function LoginForm() {
    const appInsights = useAppInsightsContext();
    useTrackMetric(appInsights, "LoginForm");
    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useAppDispatch();

    const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.currentTarget.value);
    };

    const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.currentTarget.value);
    };

    const onFieldKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            performLogin();
        }
    }

    const resetPasswordUrl: string = `${environment.adminPortalAppBaseAddress}/Account/ForgotPassword`;

    const authParams: LoginPayload = {username, password};
    
    const performLogin = () => {
        if (username && password) {
            dispatch(loginAsync(authParams));
            return;
        }

        toast.error("Please enter your username and password", {
            position: 'top-right',
            transition: Flip,
            closeButton: true,
            autoClose: 2000,
            toastId: 'validation-warning',
        });
    }

    return (
        <Container>
            <Row>
                <Col lg="4" className="mx-auto mt-4">
                    <Card>
                        <Card.Header className="bg-fhah-purple text-white">
                            <FontAwesomeIcon icon={faUser} />
                            <span className="ms-2">Log in to the FHAH Roster Portal</span>
                        </Card.Header>
                        <Card.Body className="p-4">
                            <FloatingLabel
                                controlId="floatingUsername"
                                label="Email address"
                                className="mb-3"
                            >
                                <FormControl type="email" placeholder="name@example.com" value={username} onChange={onUsernameChange} onKeyPress={onFieldKeyPress} />
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingPassword" label="Password">
                                <FormControl type="password" placeholder="Password" value={password} onChange={onPasswordChange} onKeyPress={onFieldKeyPress} />
                            </FloatingLabel>
                            <Button className="mt-4 float-start" onClick={() => performLogin()}>Log in</Button>
                           
                        </Card.Body>
                        <Card.Footer>
                            <a href={resetPasswordUrl}>Forgot your password?</a>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}