import moment from "moment";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RosteredShift } from "../../app/types";
import { ShiftAssignmentModel, selectShiftAssignmentModalFor, hideShiftAssignmentModal } from './ShiftAssignmentSlice';
import ViewShiftAssignmentModal from './ViewShiftAssignmentModal';

export type IProps = {
    rosters: Map<string, Map<string, RosteredShift>> | undefined;
}

export default function ShiftAssignmentModal(props: IProps) {
    const dispatch = useAppDispatch();
    const shiftAssignmentModalFor: ShiftAssignmentModel | undefined = useAppSelector(selectShiftAssignmentModalFor);
    const [associatedShiftActive, setAssociatedShiftActive] = useState<boolean | undefined>(undefined);
 
    const handleCloseModal = () => {
        dispatch(hideShiftAssignmentModal());
    }

    useEffect(() => {
        if (shiftAssignmentModalFor) {
            const rostersForDate: Map<string, RosteredShift> | undefined = props.rosters ? props.rosters.get(moment(shiftAssignmentModalFor.shift.date).format('YYYY-MM-DD')) : undefined;
            const rosteredShift: RosteredShift | undefined = rostersForDate ? rostersForDate.get(shiftAssignmentModalFor.shift.shiftId) : undefined;
            if (rosteredShift && associatedShiftActive !== rosteredShift.isActive) {
                setAssociatedShiftActive(rosteredShift.isActive);
            }
        }
        if (!shiftAssignmentModalFor && associatedShiftActive !== undefined) {
            setAssociatedShiftActive(undefined);
        }
    }, [props.rosters, shiftAssignmentModalFor, associatedShiftActive]);

    return (
        <ViewShiftAssignmentModal
            showModal={shiftAssignmentModalFor !== undefined ? true : false}
            shiftAssignmentDate={shiftAssignmentModalFor ? moment(shiftAssignmentModalFor.shift.date).format("MMM Do, YYYY") : ''}
            duration={shiftAssignmentModalFor ? shiftAssignmentModalFor.duration : ''}
            carerFirstname={shiftAssignmentModalFor ? shiftAssignmentModalFor.carer.firstName : ''}
            carerSurname={shiftAssignmentModalFor ? shiftAssignmentModalFor.carer.surname : ''}
            clientFirstname={shiftAssignmentModalFor ? shiftAssignmentModalFor.client.firstName : ''}
            clientSurname={shiftAssignmentModalFor ? shiftAssignmentModalFor.client.firstName : ''}
            rateName={shiftAssignmentModalFor ? shiftAssignmentModalFor.shift.rateName : ''}
            associatedShiftActive={associatedShiftActive}
            handleCloseModal={handleCloseModal}
        />
    );
}