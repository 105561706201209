import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectCarers, selectCarersAsDictionary } from '../carers/carerSlice';
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import moment, { Moment } from "moment";
import { useMediaQuery } from 'react-responsive';
import { fetchRosterAsync, selectRoster, selectRosterStatus, selectRosterDates, selectShowOtherCarersStatus, showOtherCarers, hideOtherCarers, resetRoster } from './RosterSlice';
import { useEffect, useRef } from 'react';
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";
import * as signalR from '@microsoft/signalr';
import { RosterDates } from "../../app/types";
import { selectClients, selectClientSliceStatus, selectClientFilter } from "../clients/clientSlice";
import { Flip, toast } from 'react-toastify';
import { clearShifts, getConnectionState, rosterDateUpdateHandled, rosterDateRangeUpdateHandled, selectRosterDateUpdates, selectRosterDateRangeUpdates, selectNewCarerHolidayUpdates, selectCarerHolidayRemovalUpdates, newCarerHolidayHandled, carerHolidayRemovalHandled } from "../../app/signal-r/signalRSlice";
import FhahHub from '../../app/signal-r/fhah-hub';
import { fetchShiftAssignmentAsync, shiftAssignmentListResponseStatus, selectShiftAssihgnmentsAsDictionary } from "../shift-assignment/ShiftAssignmentSlice";
import { selectCarerHolidayListStatus, fetchCarerHolidaysAsync, selectCarerHolidaysAsDictionary, addNewHoliday, removeHoliday } from "../carer-holiday/carerHolidaySlice";
import { selectRole, selectAccountCarerId, checkHasAuthToken } from "../account/accountSlice";
import Loading from '../../app/generic-components/Loading';
import RosterView from './RosterView';
import { debounce } from 'ts-debounce'; 
import { selectBranchFilter } from '../branches/branchSlice';

interface ParamType {
    date: string;
}

function Roster() {
    const appInsights = useAppInsightsContext();
    useTrackMetric(appInsights, "Roster");

    const dispatch = useAppDispatch();
    let carers = useAppSelector(selectCarers);
    let carersAsDictionary = useAppSelector(selectCarersAsDictionary);
    const clients = useAppSelector(selectClients);
    const clientStatus = useAppSelector(selectClientSliceStatus);
    const rosterStatus = useAppSelector(selectRosterStatus);
    const carerHolidayListStatus = useAppSelector(selectCarerHolidayListStatus);

    const carersShiftListResponseStatus = useAppSelector(shiftAssignmentListResponseStatus);
    const activeRosterDates = useAppSelector(selectRosterDates);

    const rosterDateUpdates = useAppSelector(selectRosterDateUpdates);
    const rosterDateRangeUpdates = useAppSelector(selectRosterDateRangeUpdates);
    const newCarerHolidays = useAppSelector(selectNewCarerHolidayUpdates);
    const carerHolidaysToRemove = useAppSelector(selectCarerHolidayRemovalUpdates);

    const filterOnClientId = useAppSelector(selectClientFilter);
    const filterOnBranchId = useAppSelector(selectBranchFilter);

    const rosters = useAppSelector(selectRoster);

    const [showClients, setShowClients] = useState<boolean>(false);
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const [liveUpdates, setLiveUpdates] = useState<boolean>(false);
    const connectionState = useAppSelector(getConnectionState);

    const userRole = useAppSelector(selectRole);
    const accountCarerId = useAppSelector(selectAccountCarerId);

    const hasAuthToken = useAppSelector(checkHasAuthToken);

    const showOtherCarersStatus = useAppSelector(selectShowOtherCarersStatus);

    const shiftAssignmentDictionary = useAppSelector(selectShiftAssihgnmentsAsDictionary);

    const carerHolidaysAsDictionary = useAppSelector(selectCarerHolidaysAsDictionary);

    const [loading, setLoading] = useState<boolean>(true);

    const [isSticky, setSticky] = useState(false);
    const shiftsRowRef = useRef<HTMLDivElement | null>(null);

    const handleScroll = useCallback(() => {
        if (shiftsRowRef.current) {
            let top = shiftsRowRef.current.getBoundingClientRect().top;
            if (top <= 0 && !isSticky) {
                setSticky(true);
            } else if (top > 0 && isSticky) {
                setSticky(false);
            }
        }
    }, [isSticky]);

    useEffect(() => {
        return () => {dispatch(resetRoster())};
    }, [dispatch]);

    useEffect(() => {
        const debouncedHandleScroll = debounce(handleScroll, 100);

        window.addEventListener('scroll', debouncedHandleScroll);
    
        return () => {
            window.removeEventListener('scroll', () => debouncedHandleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        if (loading && rosterStatus === 'idle' && carerHolidayListStatus === "idle" && carersShiftListResponseStatus === "idle" && clientStatus === 'idle') {
            setLoading(false);
        }
    }, [loading, rosterStatus, carerHolidayListStatus, carersShiftListResponseStatus, clientStatus]);
    
    let { date } = useParams<ParamType>();

    if (!date) {
        date = moment().format("YYYY-MM-DD");
    }

    let numberOfDaysToShow = 7;
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    let firstDayToShow: Moment = moment(date).weekday(1); // Desktop - show from Monday

    if (isTabletOrMobile) {
        numberOfDaysToShow = 2; // Big Mobile or Tablet show two days
        firstDayToShow = moment(date); // Tablet or mobile start at current date
    }

    if (isMobile) {
        numberOfDaysToShow = 1; // Mobile show 1 day
        firstDayToShow = moment(date); // Mobile start at current date
    }

    const days: Moment[] = [];
    for (let index = 0; index < numberOfDaysToShow; index++) {
        days.push(moment(firstDayToShow).add(index, 'days'));
    }

    let startDate = days[0].format("YYYY-MM-DD");
    let endDate = days[days.length - 1].format("YYYY-MM-DD");

    const onError = useCallback((message: string) => {
        toast.error(message, {
            position: 'top-right',
            transition: Flip,
            closeButton: true,
            autoClose: 1000,
            toastId: "Error",
        });
    }, []);

    const liveUpdatesAction = useCallback((connection: signalR.HubConnection) => {
        if (hasAuthToken && clientStatus === 'idle' && clients.length > 0 && !liveUpdates && connectionState === signalR.HubConnectionState.Connected) {
            console.log("Live Roster Updates Active");
            FhahHub.subscribeToRoster(onError);

            for (let index = 0; index < clients.length; index++) {
                FhahHub.subscribeToClientRoster(clients[index].id, onError);
            }
            
            setLiveUpdates(true);
        }
    }, [liveUpdates, connectionState, clientStatus, clients, hasAuthToken, onError]);

    const leftRoster = useCallback(() => {
        if (clients.length > 0 && connectionState === signalR.HubConnectionState.Connected) {
            const connection = FhahHub.getConnection();
            if (connection && liveUpdates) {
                console.log("Cancelling Live Updates...");

                FhahHub.unSubscribeFromRoster();

                for (let index = 0; index < clients.length; index++) {
                    FhahHub.unSubscribeFromClientRoster(clients[index].id);
                }

                setLiveUpdates(false);
                console.log('Live Roster Updates Cancelled');
            }
        }
    }, [connectionState, liveUpdates, clients]);

    useEffect(() => {
        if (!liveUpdates && connectionState === signalR.HubConnectionState.Connected) {
            const connection = FhahHub.getConnection();
            if (connection) {
                liveUpdatesAction(connection);
            }
        }

        return () => leftRoster();
    }, [liveUpdates, connectionState, liveUpdatesAction, leftRoster]);

    useEffect(() => {
        let rosterDates: RosterDates = {
            startDate: startDate,
            endDate: endDate,
        }

        if (rosterStatus !== 'loading' && carerHolidayListStatus !== "loading" && carersShiftListResponseStatus !== "loading" && clientStatus === 'idle' && clients && clients.length > 0 && carers && carers.length > 0 && (activeRosterDates === undefined || rosterDates.startDate !== activeRosterDates.startDate || rosterDates.endDate !== activeRosterDates?.endDate)) {
            dispatch(fetchRosterAsync(rosterDates));
            dispatch(fetchCarerHolidaysAsync(rosterDates));
            dispatch(fetchShiftAssignmentAsync(rosterDates));
        }
      }, [dispatch, clientStatus, clients, carers, rosterStatus, endDate, startDate, activeRosterDates, carerHolidayListStatus, carersShiftListResponseStatus]);

    useEffect(() => {
        if (rosterDateUpdates.length > 0 && activeRosterDates) {
            for (let index = 0; index < rosterDateUpdates.length; index++) {
                if (moment(activeRosterDates.startDate).isSameOrBefore(rosterDateUpdates[index]) 
                    && moment(activeRosterDates.endDate).isSameOrAfter(rosterDateUpdates[index])) {
                        // Roster update effects a date in view - update the roster
                        dispatch(fetchRosterAsync(activeRosterDates));
                        dispatch(clearShifts());
                    return;
                }

                dispatch(rosterDateUpdateHandled(rosterDateUpdates[index]));
            }
        }

        if (rosterDateRangeUpdates.length > 0 && activeRosterDates) {
            for (let index = 0; index < rosterDateRangeUpdates.length; index++) {
                if (moment(rosterDateRangeUpdates[index].start).isSameOrBefore(activeRosterDates.endDate) 
                    && (!rosterDateRangeUpdates[index].end || moment(rosterDateRangeUpdates[index].end).isSameOrAfter(activeRosterDates.endDate))) {
                        // Roster Update effects date range in view - update the roster
                        dispatch(fetchRosterAsync(activeRosterDates));
                        dispatch(clearShifts());
                    return;
                }

                dispatch(rosterDateRangeUpdateHandled(rosterDateRangeUpdates[index]));
            }
        }
        if (newCarerHolidays.length > 0 && activeRosterDates && carers !== undefined) {
            for (let chIndex = 0; chIndex < newCarerHolidays.length; chIndex++) {
                if (moment(activeRosterDates.startDate).isSameOrBefore(newCarerHolidays[chIndex].date) 
                    && moment(activeRosterDates.endDate).isSameOrAfter(newCarerHolidays[chIndex].date)) {
                        // New holiday effects date range in view - update the holiday if can see carer
                        let carerIndex = carers.findIndex(x => x.id === newCarerHolidays[chIndex].carerId);
                        if (carerIndex !== -1) {
                            dispatch(addNewHoliday(newCarerHolidays[chIndex]));
                        }
                }

                dispatch(newCarerHolidayHandled(newCarerHolidays[chIndex]));
            }
        }
        
    }, [rosterDateUpdates, rosterDateRangeUpdates, newCarerHolidays, activeRosterDates, carers, dispatch]);

    useEffect(() => {
        if (carerHolidaysToRemove.length > 0) {
            for (let index = 0; index < carerHolidaysToRemove.length; index++) {
                dispatch(removeHoliday(carerHolidaysToRemove[index]));
                dispatch(carerHolidayRemovalHandled(carerHolidaysToRemove[index]));
            }
        }
    }, [carerHolidaysToRemove, dispatch]);

    const handleHidOtherCarers = () => {
        dispatch(hideOtherCarers());
    }

    const handleShowOtherCarers = () => {
        dispatch(showOtherCarers());
    }
      
    const showClientsAction = () => {
        setShowFilters(false);
        setShowClients(true);
    }

    const showFiltersAction = () => {
        setShowClients(false);
        setShowFilters(true);
    }

    if (!userRole && accountCarerId) {
        if (!showOtherCarersStatus && carersAsDictionary !== undefined) {
            carers = [];
            carers.push(carersAsDictionary.get(accountCarerId));
        }
    }

    let closeMenuButtonAction = () => {
        setShowClients(false);
        setShowFilters(false);
    }

    let rosterUi = <Loading />;

    if (!loading) {
        rosterUi = (
            <RosterView
                isSticky={isSticky}
                date={date}
                days={days}
                carers={carers}
                userRole={userRole}
                accountCarerId={accountCarerId}
                showOtherCarersStatus={showOtherCarersStatus}
                showClients={showClients}
                showFilters={showFilters}
                rosters={rosters}
                shiftsRowRef={shiftsRowRef}
                filterOnClientId={filterOnClientId}
                filterOnBranchId={filterOnBranchId}
                shiftAssignmentDictionary={shiftAssignmentDictionary}
                carerHolidaysAsDictionary={carerHolidaysAsDictionary}
                showClientsButtonAction={showClientsAction}
                showFiltersButtonAction={showFiltersAction}
                closeMenuButtonAction={closeMenuButtonAction}
                handleHideOtherCarers={handleHidOtherCarers}
                handleShowOtherCarers={handleShowOtherCarers}
            />
        )
    }

    return (
        rosterUi
    )
}

export default Roster;