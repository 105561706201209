import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RosteredShift, ShiftAssignment, UpdateRosteredShiftStartTimeModel } from "../../app/types";
import { selectShiftAssignmentTimeModalFor, hideShiftAssignmentTimeModal, selectShiftAssignmentUpdateStatus, UpdateShiftStartTimeModel, patchShiftAssignmentStartTimeAsync } from './ShiftAssignmentSlice';
import { patchWeeklyRecurringShiftStartTimeAsync, selectWeeklyShiftPatchStartTimeStatus } from '../shift-form/WeeklyRecurringShiftSlice';
import { patchMonthlyRecurringShiftStartTimeAsync, selectMonthlyShiftPatchStartTimeStatus } from '../shift-form/MonthlyRecurringShiftSlice';
import ViewShiftAssignmentTimeModal from './ViewShiftAssignmentTimeModal';
import { timeOrTimespanValidation } from "../../app/validation";
import { selectRoster } from "../roster/RosterSlice";

export default function ShiftAssignmentModal() {
    const dispatch = useAppDispatch();
    const shiftAssignmentUpdateStatus = useAppSelector(selectShiftAssignmentUpdateStatus);
    const rosters = useAppSelector(selectRoster);
    
    const shiftAssignmentTimeModalFor: ShiftAssignment | undefined = useAppSelector(selectShiftAssignmentTimeModalFor);

    const monthlyShiftPatchStatus = useAppSelector(selectMonthlyShiftPatchStartTimeStatus);
    const weeklyShiftPatchStatus = useAppSelector(selectWeeklyShiftPatchStartTimeStatus);

    const [currentDuration, setCurrentDuration] = useState<string>('');
    const [shiftStartTime, setShiftStartTime] = useState<string>(shiftAssignmentTimeModalFor && shiftAssignmentTimeModalFor.shiftStartTime ? moment(shiftAssignmentTimeModalFor.shiftStartTime, 'HH:mm:ss').format('HH:mm') : '');
    const [shiftStartTimeInvalid, setShiftStartTimeInvalid] = useState<undefined | boolean>(undefined);
    const [shiftStartTimeChange, setShiftStartTimeChange] = useState(false);
    const [shiftType, setShiftType] = useState<string | undefined>(undefined);
    const [shiftVersion, setShiftVersion] = useState<string | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(false);

    const [updateShiftEnabled, setUpdateShiftEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (shiftAssignmentTimeModalFor && !currentDuration) {
            const shiftStartTimeString = moment(shiftAssignmentTimeModalFor.shiftStartTime).format('HH:mm');
            const shiftEndTimeString = moment(shiftAssignmentTimeModalFor.shiftStartTime).add(moment.duration(shiftAssignmentTimeModalFor.shiftLength).asMinutes(), 'minutes').format('HH:mm');
        
            const duration = `${shiftStartTimeString} - ${shiftEndTimeString}`;

            let shiftsForDate = rosters ? rosters.get(moment(shiftAssignmentTimeModalFor.date).format('YYYY-MM-DD')) : undefined;
            let shift: RosteredShift = shiftsForDate.get(shiftAssignmentTimeModalFor.shiftId);

            if (shift) {
                setUpdateShiftEnabled(shift.shiftType !== 'AdHocShift');
                setShiftType(shift.shiftType);
                setShiftVersion(shift.shiftVersion);
                setCurrentDuration(duration);
                setShiftStartTime(shiftStartTimeString);
                setShiftStartTimeInvalid(false);
                setShiftStartTimeChange(false);
            }
        }

        if (!shiftAssignmentTimeModalFor && currentDuration) {
            // Reset Form
            setCurrentDuration('');
            setShiftStartTime('');
            setShiftStartTimeInvalid(undefined);
            setShiftStartTimeChange(false);
        }

    }, [shiftAssignmentTimeModalFor, currentDuration, rosters]);

    useEffect(() => {
        if (shiftAssignmentUpdateStatus === 'loading' && !loading) {
            setLoading(true);
        }
        if (shiftAssignmentUpdateStatus !== 'loading' && loading) {
            setLoading(false);
        }
    }, [shiftAssignmentUpdateStatus, loading]);

    const handleCloseModal = useCallback(() => {
        dispatch(hideShiftAssignmentTimeModal());
    }, [dispatch]);

    useEffect(() => {
        if (monthlyShiftPatchStatus === "success" || weeklyShiftPatchStatus === "success") {
            handleCloseModal();
        }
    }, [monthlyShiftPatchStatus, weeklyShiftPatchStatus, handleCloseModal]);

    const onShiftStartTimeInputChange = (value: string) => {
        if (value !== shiftStartTime) {
            setShiftStartTimeChange(true);
            setShiftStartTime(value);
            shiftStartTimeInvalidFunction(value);
        }
    };

    const shiftStartTimeInvalidFunction = (value : string) => {
        if (timeOrTimespanValidation(value)) {
            setShiftStartTimeInvalid(false);
            return false;
        }
        setShiftStartTimeInvalid(true);
        return true;
    };

    const updateThisAssignment = () => {
        if (shiftAssignmentTimeModalFor) {
            let payload: UpdateShiftStartTimeModel = {
                id: shiftAssignmentTimeModalFor.id,
                newShiftStartTime: String(shiftStartTime),
                version: shiftAssignmentTimeModalFor.version,
            }
            dispatch(patchShiftAssignmentStartTimeAsync(payload));
        }
    }

    const updateShiftAndAssignments = () => {
        if (shiftType && shiftVersion && shiftAssignmentTimeModalFor) {
            let patchPayload: UpdateRosteredShiftStartTimeModel = {
                id: shiftAssignmentTimeModalFor.shiftId,
                newShiftStartTime: String(shiftStartTime),
                updateFutureAssignments: true,
                version: shiftVersion,
            }
            switch (shiftType) {
                case "WeeklyRecurringShift":
                    dispatch(patchWeeklyRecurringShiftStartTimeAsync(patchPayload));
                    break;
                case "MonthlyRecurrringShift":
                    dispatch(patchMonthlyRecurringShiftStartTimeAsync(patchPayload));
                    break;
                default:
                    alert('Not weekly or monthly' + shiftType?.toString());
                    break;
            }
        }
    }

    return (
        <ViewShiftAssignmentTimeModal
            shiftStartTime={shiftStartTime}
            shiftStartTimeInvalid={shiftStartTimeInvalid}
            shiftStartTimeChange={shiftStartTimeChange}
            showModal={shiftAssignmentTimeModalFor !== undefined ? true : false}
            shiftAssignmentDate={shiftAssignmentTimeModalFor ? moment(shiftAssignmentTimeModalFor.date).format("MMM Do, YYYY") : ''}
            duration={currentDuration}
            loading={loading}
            updateShiftEnabled={updateShiftEnabled}
            handleCloseModal={handleCloseModal}
            shiftAssignmentUpdateStatus={shiftAssignmentUpdateStatus}
            onShiftStartTimeInputChange={(value: string) => onShiftStartTimeInputChange(value)}
            updateThisAssignment={updateThisAssignment}
            updateShiftAndAssignments={updateShiftAndAssignments}
        />
    );
}