import {
  ChatFetchPayload,
  ChatMessage,
  ChatPostPayload,
  ChatUpdatePayload,
} from "../../app/types";
import axios, { AxiosInstance } from "axios";
import { getToken } from "../account/sessionManager";
import "../../environment";
import environment from "../../environment";

const api: AxiosInstance = axios.create({
  baseURL: environment.apiBaseAddress,
  validateStatus: (status) => true,
  timeout: 20000,
});

export function fetchChats(chatFetchPayload: ChatFetchPayload) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  let sentBefore = chatFetchPayload.sentBefore
    ? "&sentBefore=" + chatFetchPayload.sentBefore
    : "";

  let centeredOn = chatFetchPayload.centeredOn
    ? "&centeredOn=" + chatFetchPayload.centeredOn
    : "";

  let sentAfter = chatFetchPayload.sentAfter
    ? "&sentAfter=" + chatFetchPayload.sentAfter
    : "";

  return new Promise<ChatMessage[]>((resolve, reject) =>
    api
      .get(
        "/clients/" +
          chatFetchPayload.clientId +
          "/chat-messages?fetchQuantity=" +
          chatFetchPayload.fetchQuantity +
          sentBefore +
          centeredOn +
          sentAfter,
        config
      )
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}

export function postChatMessage(chatPostPayload: ChatPostPayload) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise((resolve, reject) =>
    api
      .post(
        "/clients/" + chatPostPayload.clientId + "/chat-messages",
        { message: chatPostPayload.message },
        config
      )
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}

export function removeChatMessage(chatRemovePayload: ChatUpdatePayload) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise((resolve, reject) =>
    api
      .put(
        "/clients/" + chatRemovePayload.clientId + "/chat-messages",
        { messageId: chatRemovePayload.messageId },
        config
      )
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}
