import { RosteredShift, RosterDates } from "../../app/types";
import axios, { AxiosInstance } from "axios";
import { getToken } from "../account/sessionManager";
import environment from "../../environment";

const rosterApi: AxiosInstance = axios.create({
  baseURL: environment.apiBaseAddress,
  validateStatus: (status) => true,
  timeout: 20000,
});

export function fetchRoster(rosterDates: RosterDates) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };
  let config = { headers: headers };

  return new Promise<RosteredShift[]>((resolve, reject) =>
    rosterApi
      .get(
        "/roster?startDate=" +
          rosterDates.startDate +
          "&endDate=" +
          rosterDates.endDate,
        config
      )
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}
