import { Button, Modal, FloatingLabel, Row, Col, FormControl, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-duotone-svg-icons';

export type IProps = {
    showModal: boolean;
    displayShiftType: string;
    duration: string | undefined;
    shiftStartTime: string;
    shiftStartTimeInvalid: boolean | undefined;
    shiftStartTimeChange: boolean;
    loading: boolean;
    shiftUpdateStatusLoading: boolean;
    handleCloseModal: () => void;
    onShiftStartTimeInputChange: (value : string) => void;
    updateThisShift: () => void;
    updateShiftAndAssignments: () => void;
}

export default function ViewShiftTimeModal(props : IProps) {
    const onShiftStartTimeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onShiftStartTimeInputChange(e.target.value);
    };

    let loadingPlaceholder;

    if (props.loading) {
        loadingPlaceholder = (
            <div className="row">
                <Spinner
                    as="div"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    className="mt-4 text-fhah-green mx-auto"
                    >
                        <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <Modal show={props.showModal} onHide={props.handleCloseModal}>
            <Modal.Header className="bg-fhah-purple text-white">
                <Modal.Title>
                    <span>Time Adjustment</span>  
                </Modal.Title>
                <Modal.Title>
                        <span>{props.duration}</span>
                    </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loadingPlaceholder}  
                <Row>
                    <Col className=" mt-4">
                        <FloatingLabel
                            controlId="shiftStartTime"
                            label="Shift Start Time"
                            className="mb-3"
                        >
                            <FormControl step="60" type="time"  value={props.shiftStartTime} onChange={onShiftStartTimeInputChange} isInvalid={props.shiftStartTimeInvalid && props.shiftStartTimeChange}/>
                            <Form.Control.Feedback type="invalid">
                                Invalid Start Time
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className={props.loading ? "d-none": "bg-light"}>
                <Button variant="secondary" onClick={props.handleCloseModal}>Close</Button>
                <Button variant="primary" disabled={props.shiftUpdateStatusLoading}  onClick={props.updateThisShift}><FontAwesomeIcon icon={faSave}/> Update Shift</Button>
                <Button variant="primary" disabled={props.shiftUpdateStatusLoading}  onClick={props.updateShiftAndAssignments}><FontAwesomeIcon icon={faSave}/> Update Shift and Future Assignments</Button>
            </Modal.Footer>
        </Modal>
    );
}