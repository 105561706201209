import {
  RosterDates,
  ShiftAssignment,
  ShiftAssignmentPost,
} from "../../app/types";
import axios, { AxiosInstance } from "axios";
import { getToken } from "../account/sessionManager";
import environment from "../../environment";
import { UpdateShiftRateModel, UpdateShiftStartTimeModel } from "./ShiftAssignmentSlice";

const api: AxiosInstance = axios.create({
  baseURL: environment.apiBaseAddress,
  validateStatus: (status) => true,
  timeout: 20000,
});

export function fetchShiftAssignments(dates: RosterDates) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };
  let config = { headers: headers };

  return new Promise<ShiftAssignment[]>((resolve, reject) =>
    api
      .get(
        "/shift-assignments?startDate=" +
          dates.startDate +
          "&endDate=" +
          dates.endDate,
        config
      )
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}

export function getShiftAssignment(id: string) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<ShiftAssignment>((resolve, reject) =>
    api
      .get("/shift-assignments/" + id, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}

export function postShiftAssignment(shiftAssingment: ShiftAssignmentPost) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<ShiftAssignment>((resolve, reject) =>
    api
      .post("/shift-assignments", shiftAssingment, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 201:
            resolve(response.data);
            break;
          case 400:
            reject(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}

export function patchShiftAssignmentStartTime(updateDetails: UpdateShiftStartTimeModel) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<ShiftAssignment>((resolve, reject) =>
    api
      .patch(`/shift-assignments/${updateDetails.id}/update-start-time`, { newShiftStartTime: updateDetails.newShiftStartTime, version: updateDetails.version }, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 201:
            resolve(response.data);
            break;
          case 400:
            reject(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}

export function patchShiftAssignmentRate(updateDetails: UpdateShiftRateModel) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise<ShiftAssignment>((resolve, reject) =>
    api
      .patch(`/shift-assignments/${updateDetails.id}/update-shift-rate`, { newShiftRateId: updateDetails.newShiftRate, version: updateDetails.version }, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 201:
            resolve(response.data);
            break;
          case 400:
            reject(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the server. Please check the connection or try again later."
        );
      })
  );
}

export function deleteShiftAssignment(id: string) {
  const token = getToken();
  let headers = {};
  headers = { Authorization: `Bearer ${token}` };

  let config = {
    headers: headers,
  };

  return new Promise((resolve, reject) =>
    api
      .delete("/shift-assignments/" + id, config)
      .then((response) => {
        switch (response.status) {
          case 200:
            resolve(response.data);
            break;
          case 400:
            reject(response.data);
            break;
          case 401:
            reject("Request failed for supplied credentials.");
            break;
          case 500:
            reject(
              "API is reporting a technical problem. Please try again later."
            );
            break;
          default:
            reject(
              "Unexpected error returned from API. Please try again later"
            );
        }
      })
      .catch((error) => {
        reject(
          "Unexpected error trying to contact the login server. Please check the connection or try again later."
        );
      })
  );
}
