import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  AdHocShift,
  AdHocShiftVersioned,
  AdHocShiftResponse,
} from "../../app/types";
import { getAdHocShift, postAdHocShift, putAdHocShift } from "./AdHocShiftApi";
import { toast, Flip } from "react-toastify";

export interface AdhocShiftState {
  adhocShiftResponse: AdHocShiftResponse | AdHocShiftVersioned | null;
  status: "idle" | "loading" | "failed";
  formState: "success" | "error" | "sending" | "default" | "loaded";
}

const initialState: AdhocShiftState = {
  adhocShiftResponse: null,
  status: "idle",
  formState: "default",
};

export const getAdHocShiftAsync = createAsyncThunk(
  "ad-hoc-shift/getAdHocShift",
  async (id: string) => {
    const response = await getAdHocShift(id);
    return response;
  }
);

export const postAdHocShiftAsync = createAsyncThunk(
  "ad-hoc-shift/postAdHocShift",
  async (postPayload: AdHocShift) => {
    const response = await postAdHocShift(postPayload);
    return response;
  }
);

export const putAdHocShiftAsync = createAsyncThunk(
  "ad-hoc-shift/putAdHocShift",
  async (putPayload: AdHocShiftVersioned) => {
    const response = await putAdHocShift(putPayload);
    return response;
  }
);

export const adHocShiftSlice = createSlice({
  name: "AdHocShift",
  initialState,
  reducers: {
    adHocResetForm: (state) => {
      state.adhocShiftResponse = null;
      state.formState = "default";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdHocShiftAsync.pending, (state) => {
        state.status = "loading";
        state.formState = "sending";
      })
      .addCase(getAdHocShiftAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.formState = "loaded";
        state.adhocShiftResponse = action.payload;
      })
      .addCase(getAdHocShiftAsync.rejected, (state, action) => {
        state.status = "idle";
        state.formState = "error";
        state.adhocShiftResponse = null;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "validation-error",
        });
      })
      .addCase(postAdHocShiftAsync.pending, (state) => {
        state.status = "loading";
        state.formState = "sending";
      })
      .addCase(postAdHocShiftAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.formState = "success";
        state.adhocShiftResponse = action.payload;
      })
      .addCase(postAdHocShiftAsync.rejected, (state, action) => {
        state.status = "idle";
        state.formState = "error";
        state.adhocShiftResponse = null;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "validation-error",
        });
      })

      .addCase(putAdHocShiftAsync.pending, (state) => {
        state.status = "loading";
        state.formState = "sending";
      })
      .addCase(putAdHocShiftAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.formState = "success";
        state.adhocShiftResponse = action.payload;
      })
      .addCase(putAdHocShiftAsync.rejected, (state, action) => {
        state.status = "idle";
        state.formState = "error";
        state.adhocShiftResponse = null;
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "validation-error",
        });
      });
  },
});
export const status = (state: RootState) => state.adhocShift.status;

export const adHocFormState = (state: RootState) => state.adhocShift.formState;

export const { adHocResetForm } = adHocShiftSlice.actions;

export const loadedAdHocShift = (state: RootState) =>
  state.adhocShift.formState === "loaded"
    ? state.adhocShift.adhocShiftResponse
    : null;

export default adHocShiftSlice.reducer;
