import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ChatUpdatePayload } from "../../app/types";
import { toast, Flip } from "react-toastify";
import { ChatMessageLastViewed } from "./ChatLastViewedApi";

export interface ChatLastViewed {}

const initialState: ChatLastViewed = {};

export const updateChatMessageLastViewedAsync = createAsyncThunk(
  "chat/UpdateCarerLastMessage",
  async (payload: ChatUpdatePayload) => {
    const response = await ChatMessageLastViewed(payload);
    return response;
  }
);

export const chatLastViewedSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateChatMessageLastViewedAsync.pending, (state) => {})
      .addCase(
        updateChatMessageLastViewedAsync.fulfilled,
        (state, action) => {}
      )
      .addCase(updateChatMessageLastViewedAsync.rejected, (state, action) => {
        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "Somthing went wrong. Please refresh your browser",
        });
      });
  },
});

export default chatLastViewedSlice.reducer;
