import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { attemptLogin, refreshToken } from "./accountApi";
import { LoginPayload } from "../../app/model";
import { TokenInfo } from "../../app/types";
import { getStoredTokenInfo, loggedOut, decodeToken } from "./sessionManager";
import { toast, Flip } from "react-toastify";

export interface AccountState {
  authorising: boolean;
  isLoggedIn: boolean;
  token: TokenInfo | null;
  role: string | string[] | null;
  carerId: string | undefined;
}

const initialState: AccountState = {
  authorising: true,
  isLoggedIn: false,
  token: null,
  role: null,
  carerId: undefined,
};

export const loginAsync = createAsyncThunk(
  "account/attemptLogin",
  async (params: LoginPayload) => {
    const response = await attemptLogin(params.username, params.password);
    return response.token;
  }
);

export const refreshTokenAsync = createAsyncThunk(
  "account/refreshToken",
  async () => {
    const response = await refreshToken();
    return response.token;
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    loggedIn: (state) => {
      const storedToken: TokenInfo | null = getStoredTokenInfo();
      if (storedToken) {
        state.authorising = false;
        state.isLoggedIn = true;
        state.token = storedToken as TokenInfo;
        state.role = storedToken.role;
        state.carerId = storedToken.carerId;
      }
    },
    logOut: (state) => {
      state.authorising = false;
      state.isLoggedIn = false;
      state.token = null;
      state.role = null;
      state.carerId = undefined;

      loggedOut();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.authorising = true;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const storedToken: TokenInfo | null = decodeToken(action.payload);
          state.authorising = false;
          state.token = storedToken;
          state.isLoggedIn = true;
          state.role = storedToken ? storedToken.role : null;
          state.carerId = storedToken ? storedToken.carerId : undefined;
        }
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.authorising = false;
        state.token = null;
        state.isLoggedIn = false;
        state.role = null;
        state.carerId = undefined;

        loggedOut();

        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "validation-error",
        });
      })
      .addCase(refreshTokenAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const storedToken: TokenInfo | null = decodeToken(action.payload);
          state.authorising = false;
          state.token = storedToken;
          state.isLoggedIn = true;
          state.role = storedToken ? storedToken.role : null;
          state.carerId = storedToken ? storedToken.carerId : undefined;
        }
      })
      .addCase(refreshTokenAsync.rejected, (state, action) => {
        state.authorising = false;
        state.token = null;
        state.isLoggedIn = false;
        state.role = null;
        state.carerId = undefined;

        loggedOut();

        toast.error(action.error.message, {
          position: "top-right",
          transition: Flip,
          closeButton: true,
          autoClose: 4000,
          toastId: "validation-error",
        });
      });
  },
});

export const { logOut, loggedIn } = accountSlice.actions;

export const isAuthorising = (state: RootState) => state.account.authorising;

export const isLoggedIn = (state: RootState) => state.account.isLoggedIn;

export const authToken = (state: RootState) => state.account.token;

export const checkHasAuthToken = (state: RootState) =>
  state.account.token ? true : false;

export const selectRole = (state: RootState) => state.account.role;

export const selectAccountCarerId = (state: RootState) => state.account.carerId;

export const loggedAsName = (state: RootState) =>
  state.account.token
    ? `${state.account.token.givenname} ${state.account.token.surname}`
    : null;

export const loggedOnAsUserInfoId = (state: RootState) =>
  state.account.token ? `${state.account.token.userInfoId}` : null;

export default accountSlice.reducer;
