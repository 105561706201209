import { Button, Modal, Row, Col, ListGroup } from 'react-bootstrap';
import { CarerModel } from './carerSlice';

export type IProps = {
    carer: CarerModel;
    show: boolean;
    handleClose: () => void;
}

export default function CarerModal(props: IProps) {
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header className="bg-fhah-purple text-white">
                <Modal.Title>
                    {props.carer.firstName} {props.carer.surname}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className="col-12 col-lg-6 mt-4">
                        <h5>Name</h5>
                        <ListGroup className="disabled">
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Title</small>
                                {props.carer.title}
                            </ListGroup.Item>
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">First name</small>
                                {props.carer.firstName}
                            </ListGroup.Item>
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Surname</small>
                                {props.carer.surname}
                            </ListGroup.Item>
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Preferred Name</small>
                                {props.carer.preferredName}
                            </ListGroup.Item>
                            {props.carer.phone && <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Phone</small>
                                {props.carer.phone}
                            </ListGroup.Item>}
                        </ListGroup>
                    </Col>
                    <Col className="col-12 col-lg-6 mt-4">
                        <h5>Address</h5>
                        <ListGroup className="disabled">
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Line 1</small>
                                {props.carer.address.line1}
                            </ListGroup.Item>
                            {props.carer.address.line2 && 
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Line 2</small>
                                {props.carer.address.line2}
                            </ListGroup.Item>}
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">City</small>
                                {props.carer.address.city}
                            </ListGroup.Item>
                            <ListGroup.Item> 
                                <small className="mb-1 d-block text-black-50">Post code</small>
                                {props.carer.address.postCode}
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
                {props.carer.canAddressRequirements.length > 0 &&
                <Row>
                    <Col className="col-12 mt-4">
                        <h5>Can Address These Requirements</h5>
                        <ListGroup className="disabled">
                            {props.carer.canAddressRequirements.map((x) => {
                                let classNames = 'care-requirement border-success';
                                switch (x.difficulty) {
                                    case 30:
                                        classNames = 'care-requirement border-danger';
                                        break;
                                    case 20:
                                        classNames = 'care-requirement border-warning';
                                        break;
                                    default:
                                        classNames = 'care-requirement border-success';
                                        break;
                                } 
                                return (
                                    <ListGroup.Item key={`c-r-${x.id}`} className={classNames}>
                                        {x.name}
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    </Col>
                </Row>}
            </Modal.Body>
            <Modal.Footer className="bg-light">
                    <Button variant="secondary" onClick={props.handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}