import { faArrowsAlt, faClock, faEdit, faEye, faTimesSquare, faTrash, faUser } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, Popover, Row, Col } from 'react-bootstrap';
import styles from './shift.module.css';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { memo } from 'react';

export type IProps = {
    isCarer: boolean;
    selectedShiftIsThisShift: boolean;
    duration: string;
    shiftType: string;
    numberOfCarers: number;
    assignedCarers: number;
    clientFirstName: string;
    clientSurname: string;
    clientDifficulty: number;
    handleAssign: () => void;
    handleShowShiftForm: () => void;
    handleShowShiftModal: () => void;
    handleShowClientModal: () => void;
    handleShowAutomaticShiftAssignmentModal: () => void;
    handleShowDeleteModal: () => void;
    handleShowShiftTimeModal: () => void;
}

function ShiftView(props: IProps) {
    const popover = (
        <Popover id="popover-basic" >
            <Popover.Header as="h3" className="bg-fhah-purple text-white border border-white">
                <span className="me-1">{props.duration}</span>
                <span className="text-uppercase" title={`${props.clientFirstName} ${props.clientSurname}`}>@ {props.clientFirstName} {props.clientSurname}</span>
            </Popover.Header>
            <Popover.Body className="bg-light">
                <Row className="mb-4">
                    {!props.isCarer && <Col lg="4" className="text-center">
                        <Button variant="success" className="my-1 w-sm-100" onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => props.handleShowShiftForm()} title="Edit Shift">
                            <FontAwesomeIcon icon={faEdit} className="fa-fw" />
                        </Button>
                    </Col>}
                    <Col lg="4" className="text-center">
                        <Button variant="success" className="my-1 w-sm-100" onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => props.handleShowShiftModal()} title="View Shift">
                            <FontAwesomeIcon icon={faEye} className="fa-fw" />
                        </Button>
                    </Col>
                    <Col lg="4" className="text-center">
                        <Button variant="success" className="my-1 w-sm-100" onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => props.handleAssign()} title="Move / Assign Shift">
                            <FontAwesomeIcon icon={faArrowsAlt} className="fa-fw" />
                        </Button>
                    </Col>
                    <Col lg="4" className="text-center">
                        <Button variant="success" className="my-1 w-sm-100" onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => props.handleShowClientModal()} title="View Client">
                            <FontAwesomeIcon icon={faUser} className="fa-fw" />
                        </Button>
                    </Col>
                    {props.shiftType !== "AdHocShift" && !props.isCarer &&
                        <Col lg="4" className="text-center">
                            <Button variant="success" className="my-1 w-sm-100" onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => props.handleShowAutomaticShiftAssignmentModal()} title="Automatic Shift">
                                <FontAwesomeIcon icon={faCalendarDay} className="fa-fw" />
                            </Button>
                        </Col>}
                    {props.shiftType !== "AdHocShift" && !props.isCarer &&
                        <Col lg="4" className="text-center">
                            <Button variant="success" className="my-1 w-sm-100" onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => props.handleShowShiftTimeModal()} title="Adjust Shift Start Time">
                                <FontAwesomeIcon icon={faClock} className="fa-fw" />
                            </Button>
                        </Col>}      
                    {!props.isCarer && <Col lg="4" className="text-center">
                        <Button variant="danger" className="my-1 w-sm-100" onMouseDown={(e) => {e.stopPropagation(); e.preventDefault();}} onClick={() => props.handleShowDeleteModal()} title="Delete Shift">
                            <FontAwesomeIcon icon={faTrash} className="fa-fw" />
                        </Button>
                    </Col>}  
                </Row>
                <div className={"bg-fhah-purple text-white py-1 pe-2 " + styles.popupFooter}>
                    <div className=" text-end">
                        <span className="me-1">Carers: {props.assignedCarers}/{props.numberOfCarers}</span>
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    );

    let shiftVariant = 'success';
    switch (props.clientDifficulty) {
        case 30:
            shiftVariant = 'danger';
            break;
        case 20:
            shiftVariant = 'warning';
            break;
        default:
            shiftVariant = 'success';
            break;
    } 

    const paddingOnButtonIfShiftSelectActive = props.selectedShiftIsThisShift ? " pe-3 selected " : "";
 
    let cancelShiftAssignmentEl = <></>;
    if (props.selectedShiftIsThisShift) {
        cancelShiftAssignmentEl = (
            <div className={`ms-1 pointer text-dark d-inline-block position-absolute ${styles.shiftSelectionCancelBtn}`}  onClick={() => props.handleAssign()}>
                <FontAwesomeIcon className="pt-1 " icon={faTimesSquare} size="lg"/>
            </div>
        )
    }

    return (
        <>  
            <OverlayTrigger trigger="focus" placement="bottom" overlay={popover}>
                <Button 
                    onClick={(e) => {
                        let element = e.target as HTMLElement;
                        if (element.classList.contains("btn")) {
                            element.focus();
                        } else {
                            let btn = element.closest("button");
                            if (btn) {
                                btn.focus();
                            }
                        }
                    }}
                    variant={shiftVariant} className={`rounded w-100 p-1 text-truncate${paddingOnButtonIfShiftSelectActive} ${styles.shift} shift`}> 
                    <strong className="me-1">{props.duration}</strong>
                    <strong className="text-uppercase" title={`${props.clientFirstName} ${props.clientSurname}`}>@ {props.clientFirstName} {props.clientSurname}</strong>
                </Button>
            </OverlayTrigger>
            {cancelShiftAssignmentEl}
        </>
    )
}

export default memo(ShiftView);