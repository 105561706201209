import { useEffect, useRef, useState } from "react";
import { Button, Modal, Spinner } from 'react-bootstrap';
import ShiftInfo from '../shift-form/shift-info';
import { ShiftType } from "../../app/model";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { adHocFormState, adHocResetForm, loadedAdHocShift } from "../shift-form/AdHocShiftSlice";
import { hideShiftModal, selectShiftModalFor, selectShiftModalForShiftType, selectShiftModalIsCarer } from './RosteredShiftSlice';
import { getWeeklyRecurringShiftAsync, loadedWeeklyRecurringShift, weeklyFormState, weeklyResetForm } from "../shift-form/WeeklyRecurringShiftSlice";
import { getMonthlyRecurringShiftAsync, loadedMonthlyRecurringShift, monthlyFormState, monthlyResetForm } from "../shift-form/MonthlyRecurringShiftSlice";
import { getAdHocShiftAsync } from "../shift-form/AdHocShiftSlice";
import moment from "moment";

export default function ViewShiftModal() {
    const id = useAppSelector(selectShiftModalFor);
    const shiftType = useAppSelector(selectShiftModalForShiftType);
    const isCarer = useAppSelector(selectShiftModalIsCarer);

    const currentAdHocFormState = useAppSelector(adHocFormState);
    const currentWeeklyFormState = useAppSelector(weeklyFormState);
    const currentMonthlyFormState = useAppSelector(monthlyFormState);
    const currentLoadedAdHoc = useAppSelector(loadedAdHocShift);
    const currentLoadedMonthly = useAppSelector(loadedMonthlyRecurringShift);
    const currentLoadedWeekly = useAppSelector(loadedWeeklyRecurringShift);
    const dispatch = useAppDispatch();
    const [clientId, setClientId] = useState<string>('');
    const [rateId, setRateId] = useState<string>('');
    const [shiftDate, setShiftDate] = useState('');
    const [shiftStartTime, setShiftStartTime] = useState('');
    const [shiftLength, setShiftLength] = useState('');
    const [shiftLength24H, setShiftLength24H] = useState(false);
    const [numberOfCarers, setNumberOfCarers] = useState(1);
    const [typeOfShift, setTypeOfShift] = useState(ShiftType.AdHoc);
    const [monday, setMonday] = useState(false);
    const [tuesday, setTuesday] = useState(false);
    const [wednesday, setWednesday] = useState(false);
    const [thursday, setThursday] = useState(false);
    const [friday, setFriday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [sunday, setSunday] = useState(false);
    const [recurrenceStart, setRecurrenceStart] = useState("");
    const [recurrenceEnd, setRecurrenceEnd] = useState("");
    const [recurrenceHasEnd, setRecurrenceHasEnd] = useState(false);
    const [recurEveryNWeeks, setRecurEveryNWeeks] = useState(1);
    const [firstFullWeekOfMonth, setFirstFullWeekOfMonth] = useState(false);
    const [secondFullWeekOfMonth, setSecondFullWeekOfMonth] = useState(false);
    const [thirdFullWeekOfMonth, setThirdFullWeekOfMonth] = useState(false);
    const [fourthFullWeekOfMonth, setFourthFullWeekOfMonth] = useState(false);
    const [lastFullWeekOfMonth, setLastFullWeekOfMonth] = useState(false);
    const [loading, setLoading] = useState(false);

    let loadingPlaceholder = useRef(<span />);

    useEffect(() => {
        if (currentAdHocFormState === "default" ||
            currentWeeklyFormState === "default"|| 
            currentMonthlyFormState === "default") {
                loadingPlaceholder.current = (
                    <></>
                );
                setLoading(false);
            }
        if (id !== undefined && shiftType !== undefined && currentAdHocFormState === "default" && currentMonthlyFormState === "default" && currentWeeklyFormState === "default")
        {
            switch(shiftType) {
                case "AdHocShift":
                    dispatch(getAdHocShiftAsync(id));
                    break;
                case "WeeklyRecurringShift":
                    dispatch(getWeeklyRecurringShiftAsync(id));
                    break;
                case "MonthlyRecurrringShift":
                    dispatch(getMonthlyRecurringShiftAsync(id));
                    break;
            }
        }

        if ((currentAdHocFormState === "loaded" || currentMonthlyFormState === "loaded" || currentWeeklyFormState === "loaded"))
        {
            switch(shiftType) {
                case "AdHocShift":
                    if (currentLoadedAdHoc !== null) {
                        setClientId(currentLoadedAdHoc.clientId);
                        setRateId(currentLoadedAdHoc.rateId);
                        setShiftDate(moment(currentLoadedAdHoc.shiftDate).format('YYYY-MM-DD'));
                        setShiftStartTime(moment(currentLoadedAdHoc.shiftStartTime, 'HH:mm:ss').format('HH:mm'));
                        setShiftLength(currentLoadedAdHoc.shiftLength === "1.00:00:00" ? moment(currentLoadedAdHoc.shiftLength, 'd.HH:mm:ss').format('d.HH:mm') : moment(currentLoadedAdHoc.shiftLength, 'HH:mm:ss').format('HH:mm'));
                        setShiftLength24H(currentLoadedAdHoc.shiftLength === "1.00:00:00" ? true : false);
                        setNumberOfCarers(currentLoadedAdHoc.numberOfCarers);
                        setTypeOfShift(ShiftType.AdHoc);
                        setMonday(false);
                        setTuesday(false);
                        setWednesday(false);
                        setThursday(false);
                        setFriday(false);
                        setSaturday(false);
                        setSunday(false);
                        setRecurrenceStart("");
                        setRecurrenceEnd("");
                        setRecurrenceHasEnd(false);
                        setRecurEveryNWeeks(1);
                        setFirstFullWeekOfMonth(false);
                        setSecondFullWeekOfMonth(false);
                        setThirdFullWeekOfMonth(false);
                        setFourthFullWeekOfMonth(false);
                        setLastFullWeekOfMonth(false);         
                        return;
                    }
                    break;
                case "WeeklyRecurringShift":
                    if (currentLoadedWeekly !== null) {
                        setClientId(currentLoadedWeekly.clientId);
                        setRateId(currentLoadedWeekly.rateId);
                        setShiftDate("");
                        setShiftStartTime(moment(currentLoadedWeekly.shiftStartTime, 'HH:mm:ss').format('HH:mm'));
                        setShiftLength(currentLoadedWeekly.shiftLength === "1.00:00:00" ? moment(currentLoadedWeekly.shiftLength, 'd.HH:mm:ss').format('d.HH:mm') : moment(currentLoadedWeekly.shiftLength, 'HH:mm:ss').format('HH:mm'));
                        setShiftLength24H(currentLoadedWeekly.shiftLength === "1.00:00:00" ? true : false);
                        setNumberOfCarers(currentLoadedWeekly.numberOfCarers);
                        setTypeOfShift(ShiftType.Weekly);
                        setMonday(currentLoadedWeekly.monday);
                        setTuesday(currentLoadedWeekly.tuesday);
                        setWednesday(currentLoadedWeekly.wednesday);
                        setThursday(currentLoadedWeekly.thursday);
                        setFriday(currentLoadedWeekly.friday);
                        setSaturday(currentLoadedWeekly.saturday);
                        setSunday(currentLoadedWeekly.sunday);
                        setRecurrenceStart(moment(currentLoadedWeekly.recurrenceStart).format('YYYY-MM-DD'));
                        setRecurrenceEnd(currentLoadedWeekly.recurrenceEnd !== null ? moment(currentLoadedWeekly.recurrenceEnd).format('YYYY-MM-DD') : "");
                        setRecurrenceHasEnd(currentLoadedWeekly.recurrenceEnd === null ? true : false);
                        setRecurEveryNWeeks(currentLoadedWeekly.recurEveryNWeeks);
                        setFirstFullWeekOfMonth(false);
                        setSecondFullWeekOfMonth(false);
                        setThirdFullWeekOfMonth(false);
                        setFourthFullWeekOfMonth(false);
                        setLastFullWeekOfMonth(false);      
                        return;
                    }
                    break;
                case "MonthlyRecurrringShift":
                    if (currentLoadedMonthly !== null) {
                        setClientId(currentLoadedMonthly.clientId);
                        setRateId(currentLoadedMonthly.rateId);
                        setShiftDate("");
                        setShiftStartTime(moment(currentLoadedMonthly.shiftStartTime, 'HH:mm:ss').format('HH:mm'));
                        setShiftLength(currentLoadedMonthly.shiftLength === "1.00:00:00" ? moment(currentLoadedMonthly.shiftLength, 'd.HH:mm:ss').format('d.HH:mm') : moment(currentLoadedMonthly.shiftLength, 'HH:mm:ss').format('HH:mm'));
                        setShiftLength24H(currentLoadedMonthly.shiftLength === "1.00:00:00" ? true : false);
                        setNumberOfCarers(currentLoadedMonthly.numberOfCarers);
                        setTypeOfShift(ShiftType.Monthly);
                        setMonday(currentLoadedMonthly.monday);
                        setTuesday(currentLoadedMonthly.tuesday);
                        setWednesday(currentLoadedMonthly.wednesday);
                        setThursday(currentLoadedMonthly.thursday);
                        setFriday(currentLoadedMonthly.friday);
                        setSaturday(currentLoadedMonthly.saturday);
                        setSunday(currentLoadedMonthly.sunday);
                        setRecurrenceStart(moment(currentLoadedMonthly.recurrenceStart).format('YYYY-MM-DD'));
                        setRecurrenceEnd(currentLoadedMonthly.recurrenceEnd !== null ? moment(currentLoadedMonthly.recurrenceEnd).format('YYYY-MM-DD') : "");
                        setRecurrenceHasEnd(currentLoadedMonthly.recurrenceEnd === null ? true : false);
                        setRecurEveryNWeeks(1);
                        setFirstFullWeekOfMonth(currentLoadedMonthly.firstFullWeekOfMonth);
                        setSecondFullWeekOfMonth(currentLoadedMonthly.secondFullWeekOfMonth);
                        setThirdFullWeekOfMonth(currentLoadedMonthly.thirdFullWeekOfMonth);
                        setFourthFullWeekOfMonth(currentLoadedMonthly.fourthFullWeekOfMonth);
                        setLastFullWeekOfMonth(currentLoadedMonthly.lastFullWeekOfMonth);     
                        return;
                    }
                    break;
            }
            return;
        }

        if (currentAdHocFormState === "sending" ||
            currentWeeklyFormState === "sending"|| 
            currentMonthlyFormState === "sending") {
            loadingPlaceholder.current = (
                <div className="row">
                    <Spinner
                        as="div"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        className="mt-4 text-fhah-green mx-auto"
                        >
                            <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            );
             setLoading(true);
        }
    }, 
    [
        currentAdHocFormState,
        currentMonthlyFormState,
        currentWeeklyFormState,
        id,
        shiftType,
        currentLoadedAdHoc,
        currentLoadedWeekly,
        currentLoadedMonthly,
        dispatch,
    ]);

    let displayShiftType = "";
    switch (typeOfShift) {
        case ShiftType.AdHoc:
            displayShiftType = "AdHoc"
            break;
        case ShiftType.Weekly:
            displayShiftType = "Weekly"
            break;
        case ShiftType.Monthly:
            displayShiftType = "Monthly"
            break;
        default:
            break;
    }

    const handleCloseModal = () => {
        if (shiftType) {
            switch(shiftType) {
                case "AdHocShift":
                    dispatch(adHocResetForm());
                    break;
                case "WeeklyRecurringShift":
                    dispatch(weeklyResetForm());
                    break;
                case "MonthlyRecurrringShift":
                    dispatch(monthlyResetForm());
                    break;
            }
        }

        dispatch(hideShiftModal());
    }

    return (
        <Modal show={id !== undefined} onHide={handleCloseModal}>
            <Modal.Header className="bg-fhah-purple text-white">
                <Modal.Title>
                    {displayShiftType + " Shift" }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loadingPlaceholder.current}  
                {isCarer !== undefined && <ShiftInfo
                    clientId={clientId}
                    rateId={rateId}
                    shiftDate={shiftDate}
                    shiftStartTime={shiftStartTime}
                    shiftLength={shiftLength}
                    shiftLength24H={shiftLength24H}
                    numberOfCarers={numberOfCarers}
                    typeOfShift={typeOfShift}
                    monday={monday}
                    tuesday={tuesday}
                    wednesday={wednesday}
                    thursday={thursday}
                    friday={friday}
                    saturday={saturday}
                    sunday={sunday}
                    recurrenceStart={recurrenceStart}
                    recurrenceEnd={recurrenceEnd}
                    recurrenceHasEnd={recurrenceHasEnd}
                    recurEveryNWeeks={recurEveryNWeeks}
                    firstFullWeekOfMonth={firstFullWeekOfMonth}
                    secondFullWeekOfMonth={secondFullWeekOfMonth}
                    thirdFullWeekOfMonth={thirdFullWeekOfMonth}
                    fourthFullWeekOfMonth={fourthFullWeekOfMonth}
                    lastFullWeekOfMonth={lastFullWeekOfMonth}
                    loading={loading}
                    isCarer={isCarer}
                ></ShiftInfo>}
            </Modal.Body>
            <Modal.Footer className={loading? "d-none": "bg-light"}>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}