import { Row, Col, ListGroup, Badge} from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import { SelectListItem, ShiftType } from "../../app/model";
import { ClientModel, selectClients, selectClientsAsDictionary } from "../clients/clientSlice";
import { useEffect, useRef, useState } from "react";

interface IProps {
    clientId: string;
    rateId: string;
    shiftDate: string;
    shiftStartTime: string;
    shiftLength: string;
    shiftLength24H: boolean;
    numberOfCarers: number;
    typeOfShift: ShiftType;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    recurrenceStart: string;
    recurrenceEnd: string;
    recurrenceHasEnd: boolean;
    recurEveryNWeeks: number;
    firstFullWeekOfMonth: boolean;
    secondFullWeekOfMonth: boolean;
    thirdFullWeekOfMonth: boolean;
    fourthFullWeekOfMonth: boolean;
    lastFullWeekOfMonth: boolean;
    loading: boolean;
    isCarer: boolean;
}

export default function AdHocShiftForm(props : IProps) {
    const clientDictionary: Map<string, ClientModel> = useAppSelector(selectClientsAsDictionary);
    const clientList: ClientModel[] = useAppSelector(selectClients);
    const [clientId, setClientId] = useState(props.clientId);
    const [rateId, setRateId] = useState(props.rateId);
    let clientSelectListItems = useRef<SelectListItem[]>(clientList.map(x => ({value:x.id, text:x.firstName +" "+ x.surname})));
    let rateSelectListItems = useRef<SelectListItem[]>([]);

    if (props.clientId !== "" && clientDictionary !== undefined) {
        const client = clientDictionary.get(props.clientId);
        if (client) {
            rateSelectListItems.current = client.clientRates.map(x => ({value:x.rateId, text: x.rateDescription }));
        }
    }

    let formVisibility = "";
    if (props.loading) {
        formVisibility = "d-none";
    }
    
    useEffect(() => {
        clientSelectListItems.current = clientList.map(x => ({value:x.id, text:x.firstName +" "+ x.surname}));
        rateSelectListItems.current = [];
    
        if (props.clientId !== "" && clientDictionary !== undefined) {
            const client = clientDictionary.get(props.clientId);
            if (client) {
                rateSelectListItems.current = client.clientRates.map(x => ({value:x.rateId, text: x.rateDescription }));
            }
        }

        setClientId(props.clientId);
        setRateId(props.rateId);
    }, [
        props.rateId,
        props.clientId,
        clientDictionary,
        clientList,
        props,
    ]);

    return (
        <Row className={formVisibility}>
            <Col className=" mt-4">
                <ListGroup className="disabled">
                    <ListGroup.Item> 
                        <small className="mb-1 d-block text-black-50">Client</small>
                        {clientSelectListItems.current.find(x => x.value === clientId)?.text}
                    </ListGroup.Item>
                    <ListGroup.Item> 
                        <small className="mb-1 d-block text-black-50">Rate</small>
                        {rateSelectListItems.current.find(x => x.value === rateId)?.text}
                    </ListGroup.Item>
                    {ShiftType.AdHoc === props.typeOfShift ? (
                        <ListGroup.Item> 
                            <small className="mb-1 d-block text-black-50">Shift Date</small>
                            {props.shiftDate}
                        </ListGroup.Item>
                    ): ""}

                    <ListGroup.Item> 
                        <small className="mb-1 d-block text-black-50">Shift Start Time</small>
                        {props.shiftStartTime}
                    </ListGroup.Item>
                    <ListGroup.Item> 
                        <small className="mb-1 d-block text-black-50">Shift Length</small>
                        {props.shiftLength24H ? "24:00" : props.shiftLength}
                    </ListGroup.Item>
                    <ListGroup.Item> 
                        <small className="mb-1 d-block text-black-50">Number Of Carers</small>
                        {props.numberOfCarers}
                    </ListGroup.Item>
                </ListGroup>
                {!props.isCarer && (ShiftType.Weekly === props.typeOfShift || ShiftType.Monthly === props.typeOfShift) ? (
                <>
                <h5 className="mt-4">Recurrence</h5>
                <ListGroup className="disabled">
                    <ListGroup.Item> 
                        <small className="mb-1 d-block text-black-50">Started</small>
                        {props.recurrenceStart}
                    </ListGroup.Item>
                    {!props.recurrenceHasEnd ? (
                        <ListGroup.Item> 
                            <small className="mb-1 d-block text-black-50">Ended</small>
                            {props.recurrenceEnd}
                        </ListGroup.Item>
                    ): ""}
                    {ShiftType.Weekly === props.typeOfShift ? (
                        <ListGroup.Item> 
                            <small className="mb-1 d-block text-black-50">Recur every N Weeks</small>
                            {props.recurEveryNWeeks}
                        </ListGroup.Item>
                    ): ""}
                </ListGroup>
                    <h6 className="mt-4">Days of week</h6>
                    {props.monday ? (
                        <Badge className="p-2 me-1 mt-1" pill bg="success">
                            Monday
                        </Badge>
                    ): ""}
                    {props.tuesday ? (
                        <Badge className="p-2 me-1 mt-1" pill bg="success">
                            Tuesday
                        </Badge>
                    ): ""}
                    {props.wednesday ? (
                        <Badge className="p-2 me-1 mt-1" pill bg="success">
                            Wednesday
                        </Badge>
                    ): ""}
                    {props.thursday ? (
                        <Badge className="p-2 me-1 mt-1" pill bg="success">
                            Thursday
                        </Badge>
                    ): ""}
                    {props.friday ? (
                        <Badge className="p-2 me-1 mt-1" pill bg="success">
                            Friday
                        </Badge>
                    ): ""}
                    {props.saturday ? (
                        <Badge className="p-2 me-1 mt-1" pill bg="success">
                            Saturday
                        </Badge>
                    ): ""}
                    {props.sunday ? (
                        <Badge className="p-2 me-1 mt-1" pill bg="success">
                            Sunday
                        </Badge>
                    ): ""}    
                </> 
                ): ""}      
                {!props.isCarer && ShiftType.Monthly === props.typeOfShift ? (
                <>
                    <h6 className="mt-4">Weeks of month</h6>
                        {props.firstFullWeekOfMonth ? (
                            <Badge className="p-2 me-1 mt-1" pill bg="success">
                                First
                            </Badge>
                        ): ""}
                        {props.firstFullWeekOfMonth ? (
                            <Badge className="p-2 me-1 mt-1" pill bg="success">
                                Second
                            </Badge>
                        ): ""}
                        {props.firstFullWeekOfMonth ? (
                            <Badge className="p-2 me-1 mt-1" pill bg="success">
                                Third
                            </Badge>
                        ): ""}
                        {props.firstFullWeekOfMonth ? (
                            <Badge className="p-2 me-1 mt-1" pill bg="success">
                                Fourth
                            </Badge>
                        ): ""}
                        {props.firstFullWeekOfMonth ? (
                            <Badge className="p-2 me-1 mt-1" pill bg="success">
                                Last
                            </Badge>
                        ): ""}
                    </> 
                ): ""}  
            </Col>
        </Row>
    );
}