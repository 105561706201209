import { toast, Flip } from "react-toastify";
import { useCallback, useEffect, useRef, useState } from "react";
import { selectRole, loggedOnAsUserInfoId } from "../account/accountSlice";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";
import { fetchChatsMessagesAsync, selectChatList, chatListListResponseStatus, postChatMessageAsync, selectedClientId, fetchOlderChatsMessagesAsync, clearSelectClientId, chatListResponseStatusGettingNewerChats, fetchNewerChatsMessagesAsync, removeChatMessageAsync, chatListResponseStatusGettingOlderChats, resetListStates, closestNewChatId } from "./ChatSlice";
import { ClientModel, selectClients, updateClient } from "../clients/clientSlice";

import { ChatMessage } from "../../app/types";
import ChatSystemView  from "./ChatSystemView";
import moment from "moment";

export default function ChatSystem() {
    const appInsights = useAppInsightsContext();
    useTrackMetric(appInsights, "ChatSystem");

    const dispatch = useAppDispatch();
    const fetchQuantity = 20;
    const loggedOnAs = useAppSelector(loggedOnAsUserInfoId);
    const activeClientId = useAppSelector(selectedClientId);
    const currentChatListListResponseStatus = useAppSelector(chatListListResponseStatus);
    const currentChatListResponseStatusGettingNewerChats = useAppSelector(chatListResponseStatusGettingNewerChats);
    const currentchatListResponseStatusGettingOlderChats = useAppSelector(chatListResponseStatusGettingOlderChats);
    const currentClosestNewChatId = useAppSelector(closestNewChatId); // id for the closet new Chat message
    const clients = useAppSelector(selectClients);

    const [message, setMessage] = useState<string>();
    const [loadingBar, setLoadingBar] = useState(false);
    const [bottomLoadingBar, setBottomLoadingBar] = useState(false);

    const [triggeredScrollMessage, setTriggeredScrollMessage] = useState(false);
    let hasLastScrolled = useRef<boolean>(false);

    const [currentClient, setCurrentClient] = useState<ClientModel | undefined>(undefined);

    const [chatListOpen, setChatListOpen] = useState<boolean>(true);

    const userRole = useAppSelector(selectRole);

    let isAdmin = userRole && userRole.length > 0;
    const [removePopupForChatMessage, setRemovePopupForChatMessage] = useState<ChatMessage | null>(null);

    const closeSignalRConnection = useCallback(() => {
        dispatch(clearSelectClientId());
    },[dispatch]);
    
    useEffect(() => {
        toast.info("Please be professional while using the chat facilities as clients can request copies of the chats associated with them and view all that is said.", {
            position: "top-right",
            transition: Flip,
            closeButton: true,
            autoClose: false,
            toastId: "professional-emphasis",
        });
    }, []);

    useEffect(() => {
        return () => closeSignalRConnection();
    }, [closeSignalRConnection]);

    useEffect(() => {
        if (triggeredScrollMessage && hasLastScrolled.current === true) {
            setTriggeredScrollMessage(false);

            hasLastScrolled.current = false;
        }
    }, [currentClient, triggeredScrollMessage, activeClientId]);

    useEffect(() => {
        if (activeClientId && (!currentClient || currentClient.id !== activeClientId)) {
            const clientModel = clients.find(x => x.id === activeClientId);
            setCurrentClient(clientModel);
            let lastViewedChatMessage = clientModel?.lastViewedChatMessage ? clientModel.lastViewedChatMessage : "";
            dispatch(fetchChatsMessagesAsync({clientId: activeClientId, sentBefore: "", sentAfter: "", fetchQuantity: fetchQuantity, centeredOn: lastViewedChatMessage})); 
        }
        else if (!activeClientId)
        {
            setCurrentClient(undefined);
        }
    }, [activeClientId, dispatch, currentClient, clients]);

    useEffect(() => {
        if (currentChatListListResponseStatus === "loading") {
            setLoadingBar(true);
        } else if (currentChatListListResponseStatus === "complete") {
            setLoadingBar(false);
            let lastSeenBox = document.getElementById("last-seen-box") as HTMLDivElement
            let messagesBox = document.getElementById("messages") as HTMLDivElement

            if (lastSeenBox) {
                setTimeout(() => {
                messagesBox.scrollTo({
                    behavior: lastSeenBox ? "smooth" : "auto",
                    top: lastSeenBox ? lastSeenBox.offsetTop - 80 : 0
                  });
                }, 200);
            }

            dispatch(resetListStates()); 
        }
    }, [currentChatListListResponseStatus, dispatch]);

    useEffect(() => {
        // If older Chat show loading
        if (currentchatListResponseStatusGettingOlderChats === "loading") {
            setLoadingBar(true);
        } else if (currentchatListResponseStatusGettingOlderChats === "complete") {
            setLoadingBar(false);
            let messagesBox = document.getElementById('messages') as HTMLDivElement;
            if (messagesBox && hasLastScrolled.current === true && currentClosestNewChatId) {
                let previousMessageBox = document.getElementById("message-" + currentClosestNewChatId) as HTMLDivElement
                let messagesBox = document.getElementById("messages") as HTMLDivElement
                if (previousMessageBox) {
                    setTimeout(() => {
                    messagesBox.scrollTo({
                        behavior: "auto",
                        top: previousMessageBox ? previousMessageBox.offsetTop - 80 : 0
                      });
                      dispatch(resetListStates()); 
                    }, 200);
                    
                }    
            }
        }
    }, [currentchatListResponseStatusGettingOlderChats, currentClosestNewChatId, dispatch]);

    useEffect(() => {
        // If newer Chat show loading
        if (currentChatListResponseStatusGettingNewerChats === "loading") {
            setBottomLoadingBar(true);
        } else if (currentChatListResponseStatusGettingNewerChats === "complete") {
            setBottomLoadingBar(false);
            let messagesBox = document.getElementById('messages') as HTMLDivElement;
            if (messagesBox && hasLastScrolled.current === true && currentClosestNewChatId) {
                let previousMessageBox = document.getElementById("message-" + currentClosestNewChatId) as HTMLDivElement
                let messagesBox = document.getElementById("messages") as HTMLDivElement
                if (previousMessageBox) {
                    setTimeout(() => {
                    messagesBox.scrollTo({
                        behavior: "auto",
                        top: previousMessageBox ? previousMessageBox.offsetTop - (messagesBox.clientHeight - 80) : 0
                      });
                      dispatch(resetListStates()); 
                    }, 200);
                }    
            }
        }
    }, [currentChatListResponseStatusGettingNewerChats, currentClosestNewChatId, dispatch]);

    useEffect(() => {
        if (triggeredScrollMessage && hasLastScrolled.current === false) {
                
            let lastSeenBox = document.getElementById("last-seen-box") as HTMLDivElement
            let messagesBox = document.getElementById("messages") as HTMLDivElement
            if (lastSeenBox) {
                setTimeout(() => {
                messagesBox.scrollTo({
                    behavior: lastSeenBox ? "smooth" : "auto",
                    top: lastSeenBox ? lastSeenBox.offsetTop : 0
                  });
                }, 200);
                setTriggeredScrollMessage(false);
            }
        }

        hasLastScrolled.current = true;

    }, [triggeredScrollMessage]);


    let chatList = useAppSelector(selectChatList);

    useEffect(() => {
        if ((currentClient &&
            currentChatListResponseStatusGettingNewerChats === 'complete' &&
            chatList &&
            chatList[chatList.length - 1] &&
            chatList[chatList.length - 1].sent !== currentClient.lastViewedChatMessage) ||
            (currentClient && 
            currentChatListListResponseStatus === 'complete'&&
            chatList &&
            chatList[chatList.length - 1] &&
            chatList[chatList.length - 1].sent !== currentClient.lastViewedChatMessage) )  {

            const clientModel: ClientModel = {
                id: currentClient.id,
                title: currentClient.title,
                firstName: currentClient.firstName,
                surname: currentClient.surname,
                preferredName: currentClient.preferredName,
                phone: currentClient.phone,
                tellContactInfo: currentClient.tellContactInfo,
                address: currentClient.address,
                clientDifficulty: currentClient.clientDifficulty,
                clientCarerIds: currentClient.clientCarerIds,
                clientRates: currentClient.clientRates,
                careRequirements: currentClient.careRequirements,
                hasClientDocument: currentClient.hasClientDocument,
                branchId: currentClient.branchId,
                lastViewedChatMessage: chatList[chatList.length - 1].sent,
                lastChatMessageForClient: currentClient.lastChatMessageForClient,
            }
            dispatch(updateClient(clientModel));
            dispatch(resetListStates()); 
        }
    }, [chatList, currentChatListResponseStatusGettingNewerChats, currentChatListListResponseStatus, currentClient, dispatch,]);

    //handel scrolling
    const scrollBarScroll = (event: any) => {
        let container = event.target as HTMLDivElement;
        let isAtTop = (container.scrollHeight <= Math.abs(container.scrollTop - (container.clientHeight + 10)));
        let isAtbottom = (container.scrollTop >= 0);

        if (activeClientId && chatList && currentchatListResponseStatusGettingOlderChats !== "loading" && currentchatListResponseStatusGettingOlderChats !== "complete"  && isAtTop) {
            if (chatList[0]) {
                dispatch(fetchOlderChatsMessagesAsync({clientId: activeClientId, sentBefore: chatList[0].sent, sentAfter: "", fetchQuantity: fetchQuantity, centeredOn: ""})); 
            }
        }

        if (activeClientId && chatList && currentChatListResponseStatusGettingNewerChats !== "loading" && currentChatListListResponseStatus !== "complete"  && isAtbottom) {
            let lastChat = chatList[chatList.length - 1];
            if (lastChat) {
                dispatch(fetchNewerChatsMessagesAsync({clientId: activeClientId, sentBefore: "", sentAfter: lastChat.sent, fetchQuantity: fetchQuantity, centeredOn: ""})); 
            }
        }
    }

    // Handel update when chat is open with no scroll or at bottom of the page
    const onMessageAreaClick = (event: any) => {
        let container = document.getElementById("messages") as HTMLDivElement;
        let isAtBottom = (container.scrollTop >= 0);
        if (currentClient && chatList && isAtBottom && chatList[chatList.length - 1].sent !== currentClient.lastViewedChatMessage) {
            const clientModel: ClientModel = {
                id: currentClient.id,
                title: currentClient.title,
                firstName: currentClient.firstName,
                surname: currentClient.surname,
                preferredName: currentClient.preferredName,
                phone: currentClient.phone,
                tellContactInfo: currentClient.tellContactInfo,
                address: currentClient.address,
                clientDifficulty: currentClient.clientDifficulty,
                clientCarerIds: currentClient.clientCarerIds,
                clientRates: currentClient.clientRates,
                careRequirements: currentClient.careRequirements,
                hasClientDocument: currentClient.hasClientDocument,
                branchId: currentClient.branchId,
                lastViewedChatMessage: chatList[chatList.length - 1].sent,
                lastChatMessageForClient: currentClient.lastChatMessageForClient,
            } 
            dispatch(updateClient(clientModel));
        }
    }

    const removeMessage = () => {
        if (activeClientId && removePopupForChatMessage) {
            dispatch(removeChatMessageAsync({clientId: activeClientId, messageId: removePopupForChatMessage.id}))
            setRemovePopupForChatMessage(null);
        }
    }

    const broadcastChatMessage = () => {
        if (message && activeClientId) {
            dispatch(postChatMessageAsync({clientId: activeClientId, message}));
            setMessage('');
        }
    }

    return (
        <ChatSystemView
            setRemovePopupForChatMessage={(chatMessage: ChatMessage | null) => {setRemovePopupForChatMessage(chatMessage)}}
            currentClientName={currentClient ? currentClient.firstName + " " + currentClient.surname : undefined }
            chatList={chatList}
            activeClientId={activeClientId}
            loggedOnAsId={loggedOnAs}
            loadingBar={loadingBar}
            bottomLoadingBar={bottomLoadingBar}
            triggeredScrollMessage={triggeredScrollMessage}
            setTriggeredScrollMessage={(boolean :boolean) => {setTriggeredScrollMessage(boolean)}}
            hasLastScrolled={hasLastScrolled.current}
            setHasLastScrolled={(boolean: boolean) => {hasLastScrolled.current = boolean}}
            chatListOpen={chatListOpen}
            setChatListOpen={(boolean: boolean) => {setChatListOpen(boolean)}}
            isAdmin={isAdmin}
            message={message}
            setMessage={(message :string) => {setMessage(message)}}
            showRemovePopup={isAdmin && removePopupForChatMessage ? true : false}
            removePopupForChatMessageName ={removePopupForChatMessage ? removePopupForChatMessage.firstName + " " + removePopupForChatMessage.surname : null }
            removePopupForChatMessageSent={removePopupForChatMessage ? moment(removePopupForChatMessage.sent).format("DD/MM/YYYY HH:mm") : null }
            removePopupChatMessage={removePopupForChatMessage ? removePopupForChatMessage.message : null }

            onMessageAreaClick={(event: any) => {onMessageAreaClick(event)}}
            scrollBarScroll={(event: any) => {scrollBarScroll(event)}}
            broadcastChatMessage={() => {broadcastChatMessage()}}
            removeMessage={() => {removeMessage()}}

        />
    );
}