import moment from 'moment';
import { useEffect, useState } from 'react';
import {Alert, Button, Col, FloatingLabel, Form, FormControl, Modal, Row, Spinner } from 'react-bootstrap';
import { useAppDispatch } from '../../app/hooks';
import { ShiftAssignmentWithTimeSheet } from '../../app/types';
import { deleteTimesheetAsync } from './TimeSheetSlice';

export type IProps = {
    show: boolean;
    loading: boolean;
    shiftAssignmentWithTimeSheet:ShiftAssignmentWithTimeSheet | null;
    handleClose: () => void;
}

export default function RemovePopup(props: IProps) {
    const dispatch = useAppDispatch();
    const [shiftStartTime, setShiftStartTime] = useState('');
    const [shiftStartDate, setShiftStartDate] = useState('');
    const [shiftEndTime, setShiftEndTime] = useState('');
    const [shiftEndDate, setShiftEndDate] = useState('');

    useEffect(() => {
        setShiftStartTime(props.shiftAssignmentWithTimeSheet ? moment( props.shiftAssignmentWithTimeSheet.shiftStart).format('HH:mm') : "");
        setShiftStartDate(props.shiftAssignmentWithTimeSheet ? moment(props.shiftAssignmentWithTimeSheet.shiftStart).format('YYYY-MM-DD') : "");
        let shiftEndTime = props.shiftAssignmentWithTimeSheet ? moment( props.shiftAssignmentWithTimeSheet.shiftEnd).format('HH:mm' ) : "";
        setShiftEndTime(shiftEndTime);
        setShiftEndDate(props.shiftAssignmentWithTimeSheet ? moment(props.shiftAssignmentWithTimeSheet.shiftEnd).format('YYYY-MM-DD') : "");
    }, [props.shiftAssignmentWithTimeSheet])
    
    const handleRemoveSubmit = () => {

        const carerId = props.shiftAssignmentWithTimeSheet?.carerId;
        const timesheetId = props.shiftAssignmentWithTimeSheet?.timesheetId
        if (carerId && timesheetId) {
            dispatch(deleteTimesheetAsync({
                timesheetEntryId: timesheetId,
                carerId: carerId
            }));
        }
    };
    
    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header className="bg-fhah-purple text-white">
                <Modal.Title>
                    Remove Timesheet Entry
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-light">
                {
                    props.loading ? (            
                    <Row>
                        <Col lg="4" className="mx-auto m-4 text-center">
                            <Spinner
                                as="div"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                                className="text-fhah-green mx-auto"
                                >
                                    <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </Col>
                    </Row>
                    ):""
                }

                <Alert variant={'danger'}>
                    <h4 className="alert-heading">Are you sure</h4>
                    Are you sure you wish to remove TimeSheet Entry?
                </Alert>
                <Row>
                    <Col>
                        <FloatingLabel
                            controlId="shiftStartDate"
                            label="Shift start Date"
                            className="mb-3"
                        >
                            <FormControl type="date"  value={shiftStartDate} disabled/>
                            <Form.Control.Feedback type="invalid">
                                Invalid End Date
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="shiftStartTime"
                            label="Shift Start Time"
                            className="mb-3"
                        >
                            <FormControl step="60" type="time"  value={shiftStartTime} disabled/>
                            <Form.Control.Feedback type="invalid">
                                Invalid Start Time
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>  
                </Row>
                <Row>
                    <Col>
                        <FloatingLabel
                            controlId="shiftEndDate"
                            label="Shift End Date"
                            className="mb-3"
                        >
                            <FormControl type="date"  value={shiftEndDate} disabled/>
                            <Form.Control.Feedback type="invalid">
                                Invalid End Date
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel
                            controlId="shiftEndTime"
                            label="Shift End Time"
                            className="mb-3"
                        >
                            <FormControl step="60" type="time"  value={shiftEndTime} disabled/>
                            <Form.Control.Feedback type="invalid">
                                Invalid End Time
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className={"bg-light"}>
                <Button variant="secondary" onClick={props.handleClose}>Close</Button>
                <Button variant="danger" disabled={props.loading} onClick={handleRemoveSubmit}>Remove</Button>
            </Modal.Footer>
        </Modal>
    );
}
