import React from "react";
import { Row, Col, Container, Spinner } from "react-bootstrap";

export default function Loading() {
    return (
        <Container>
            <Row>
                <Col lg="4" className="mx-auto mt-4">
                    <Spinner
                        as="div"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        className="mt-4 text-fhah-green mx-auto"
                        >
                            <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Col>
            </Row>
        </Container>
    )
}