import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ClientModel, selectClient, clearSelectClient, selectedClient } from './clientSlice';
import ClientListRowView from './clientListRowView';

export type IProps = {
    client: ClientModel;
} 

export default function ClientListRow(props: IProps) {
    const activeClient = useAppSelector(selectedClient);
    const dispatch = useAppDispatch();

    const [classes, setClasses] = useState<string>("text-start pointer small");

    let onClick: any = useRef(() => dispatch(selectClient(props.client)));

    useEffect(() => {
        if (activeClient && activeClient.id === props.client.id) {
            if (classes !== "text-start bg-success text-white pointer small") {
                setClasses("text-start bg-success text-white pointer small");
            }
            
            onClick.current = () => dispatch(clearSelectClient());
        } else {
            if (classes !== "text-start pointer small") {
                setClasses("text-start pointer small");
            }
            
            onClick.current = () => dispatch(selectClient(props.client));
        }
    }, [activeClient, props.client, classes, dispatch]);

    return (
        <ClientListRowView 
            clientId={props.client.id}
            clientFirstName={props.client.firstName}
            clientSurname={props.client.surname}
            classes={classes}
            onClick={onClick.current}
        />
    );
}