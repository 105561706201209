import { useCallback, useState, useEffect } from "react";
import moment, { Moment } from "moment";
import { CarerHolidayModel, CreateCarerHoliday, RosteredShift, ShiftAssignment, ShiftAssignmentPost } from "../../app/types";
import { ClientModel, selectClientsAsDictionary } from "../clients/clientSlice";
import { CarerModel, selectedCarer } from "./carerSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { postCarerHolidayAsync } from "../carer-holiday/carerHolidaySlice";
import { currentSelectedShift, postShiftAssignmentAsync } from "../shift-assignment/ShiftAssignmentSlice";
import { toast, Flip } from "react-toastify";
import CarerDayView from './CarerDayView';

export type IProps = {
    accountCarerId?: string | undefined;
    userRole?: string | string[] | null;
    carerShiftsDictionary: Map<string, ShiftAssignment> | undefined;
    carerHoliday: Map<string, CarerHolidayModel> | undefined;
    rosterShifts: Map<string, RosteredShift> | undefined;
    date: Moment;
    carer: CarerModel;
    filterOnClientId: string | undefined;
    filterOnBranchId: string | undefined;
}

function CarerDay(props: IProps) {
    const activeCarer = useAppSelector(selectedCarer);
    const selectedShift = useAppSelector(currentSelectedShift);
    const clientDictionary = useAppSelector(selectClientsAsDictionary);

    const dispatch = useAppDispatch();
    let assignedShifts: ShiftAssignment[] = [];

    const [selectedShiftForThisCarer, setSelectedShiftForThisCarer] = useState<boolean>(false);

    useEffect(() => {
        if (selectedShift !== null && props.date.isSame(selectedShift.date, "day") && (props.userRole || props.accountCarerId === props.carer.id) && !selectedShiftForThisCarer) {
            let shiftId: string = selectedShift.shiftId;
            let rosteredShift: RosteredShift | undefined = props.rosterShifts ? props.rosterShifts.get(shiftId) : undefined;
    
            if (rosteredShift !== undefined) {
                const clientId: string = rosteredShift.clientId;
                let client: ClientModel | undefined = clientDictionary.get(clientId);
    
                if (client && client.clientCarerIds.includes(props.carer.id)) {
                    setSelectedShiftForThisCarer(true);
                }
            }
        } else if (selectedShift === null && selectedShiftForThisCarer) {
            setSelectedShiftForThisCarer(false); 
        }
    }, [selectedShift, props.date, props.userRole, props.accountCarerId, props.carer.id, props.carer.firstName, props.carer.surname, selectedShiftForThisCarer, clientDictionary, props.rosterShifts]);

    clientDictionary && props.carerShiftsDictionary && props.carerShiftsDictionary.forEach((shiftAssignment, index) => {
        let rosteredShift: RosteredShift | undefined = props.rosterShifts !== undefined ? props.rosterShifts.get(shiftAssignment.shiftId) : undefined;
        let clientId: string = rosteredShift ? rosteredShift.clientId : '';
        let client: ClientModel | undefined = clientId ? clientDictionary.get(clientId) : undefined;

        if (rosteredShift && client && (!props.filterOnClientId || props.filterOnClientId === client.id) && (!props.filterOnBranchId || props.filterOnBranchId === client.branchId)) {
            assignedShifts.push(shiftAssignment);
        }
    });

    const handleShiftAssign = useCallback(() => {
        if (selectedShift === null) {
            return;
        }

        toast.info("Assignment In Progress", {
            position: "top-right",
            transition: Flip,
            closeButton: true,
            autoClose: 1000,
            toastId: "assignment-in-progress",
        });

        const shiftDate = selectedShift.date;
        const payload: ShiftAssignmentPost = {
            shiftId: selectedShift.shiftId,
            date: shiftDate,
            carerId: props.carer.id,
            shiftVersion: selectedShift.shiftVersion,
        };

        dispatch(postShiftAssignmentAsync(payload));
    }, [dispatch, props.carer.id, selectedShift]);

    const handleAddHoliday = useCallback(() => {
        const dto: CreateCarerHoliday = { date: moment(props.date).format('YYYY-MM-DD'), carerId: props.carer.id };
        dispatch(postCarerHolidayAsync(dto));
    }, [dispatch, props.date, props.carer]);

    let dayClasses = "w-12-5 border-end border-bottom py-2 text-uppercase bg-white position-relative px-2 carer-day";
    if (!props.userRole && props.accountCarerId !== props.carer.id) {
        dayClasses = "w-12-5 border-end border-bottom py-2 text-uppercase bg-secondary position-relative px-2 carer-day";
    }

    const accessPrivilidges = props.userRole || props.accountCarerId === props.carer.id ? true : false;
    const administratorPriviledge: boolean = props.userRole ? true : false;

    return (
        <CarerDayView
            assignedShifts={assignedShifts}
            carer={props.carer}
            clientDictionary={clientDictionary}
            carerShiftsDictionary={props.carerShiftsDictionary}
            rosterShifts={props.rosterShifts}
            isActiveCarer={activeCarer && activeCarer.id === props.carer.id ? true : false}
            carerHoliday={props.carerHoliday ? props.carerHoliday.values().next().value : undefined}
            accessPrivilidges={accessPrivilidges}
            administratorPriviledge={administratorPriviledge}
            dayClasses={dayClasses}
            selectedShiftForThisCarer={selectedShiftForThisCarer}
            handleShiftAssign={handleShiftAssign}
            handleAddHoliday={handleAddHoliday}
        />
    );
}

export default CarerDay;